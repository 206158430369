import {Column, Row} from "simple-flexbox";
import React from "react";
import headerComponent from "../../../common/components/CustomHeader";
import {Button, Select, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import CustomSelectInput from "../../../common/components/CustomSelectInput";
import MenuItem from "@material-ui/core/MenuItem";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import Dialog from "@material-ui/core/Dialog";
import {genericConstants, stringConstants} from "../../../constants";
import {getSignedURL} from "../../../managers/awsService";
import SortingComponent from "../../../common/components/SortingComponent";
import Utility from "../../../utility";

const SearchFilterComponent = (props) => {
    function searchInList(event) {
        props.counselFilteredListSetValues(event.target.id, event.target.value);
    }

    let {state} = props;
    return (
        <Column>
            <Row className="bg-color-white fs-12 mt-4" vertical="center">
                <Row className="br-2 bg-pale-grey p-2 b-1-pale-grey-three">
                    <input placeholder={'Search Records'} id={'search'}
                           onChange={searchInList}
                           className="outline-none b-none fc-bluey-grey bg-pale-grey fw-500"/>
                    <img className="mx-2" src="/images/search-icon.svg" alt="search"/>
                </Row>
                <Select value={state.locationFilter} className="br-2 mx-3 fc-warm-grey"
                        style={{borderBottom: '0px', transition: 'none'}}
                        onChange={props.onFilterChanged}
                        input={<CustomSelectInput name="locationFilter"/>}>
                    <MenuItem value={genericConstants.INITIAL_FILTER_VALUES.LOCATION}>
                        {genericConstants.INITIAL_FILTER_VALUES.LOCATION}
                    </MenuItem>
                    {getUniqueLocationMenuOption(state)}
                </Select>
                <Select value={state.statusFilter}
                        className="br-2 mx-2 fc-warm-grey"
                        onChange={props.onFilterChanged}
                        input={<CustomSelectInput name="statusFilter"/>}>
                    <MenuItem className="p-2 cursor-pointer outline-none"
                              value={genericConstants.INITIAL_FILTER_VALUES.STATUS}>
                        {genericConstants.INITIAL_FILTER_VALUES.STATUS}
                    </MenuItem>
                    {getUniqueStatusMenuOption(state)}
                </Select>
            </Row>
        </Column>
    )
};

const getUniqueLocationMenuOption = (state) => {

    if (!state.counselList)
        return;
    let cityArray = new Set();
    state.counselList.map((counsel) => {
        if (counsel.location && counsel.location.city)
            cityArray.add(counsel.location.city);
    });
    if (!cityArray.size)
        return;
    let menuOptions = [];
    cityArray.forEach((city, index) => {
        menuOptions.push(renderMenuItem(city, index));
    });
    return menuOptions;
};

const getUniqueStatusMenuOption = (state) => {
    let menuOptions = [];
    let statusArray = Utility.generateUniqueEntityList(state.counselList, "invitationStatus");
    statusArray.forEach((status, index) => {
        menuOptions.push(renderMenuItem(status, index));
        // menuOptions.push(renderMenuItem(Utility.viewInvitationStatus(status), index));
    });
    return menuOptions;
};

const renderMenuItem = (value, index) => {
    if (!value)
        return "";

    return <MenuItem className="p-2 cursor-pointer outline-none" value={value} key={index}>{value}</MenuItem>
};

const RecordsTableComponent = (props) => {
    const {handlePageChange, state, onSortClicked, roleType, toggleEditCounselDialog, companyId, updateInvitation} = props;

    let [sortingState, updateSortingState] = React.useState({
        name: null,
        invitationStatus: null,
        "location.city": null
    });

    function onDescendingSort(key) {
        onSortClicked(key, genericConstants.SORTING_ORDER.DESCENDING);
        updateSortingState({[key]: genericConstants.SORTING_ORDER.DESCENDING});
    }

    function onAscendingSort(key) {
        onSortClicked(key, genericConstants.SORTING_ORDER.ASCENDING);
        updateSortingState({[key]: genericConstants.SORTING_ORDER.ASCENDING});
    }

    return (
        <div className="my-2 overflow-x-auto h-100 ">
            {state.data && state.data.length > 0 ?
                <Column className="bg-white h-100-per fs-15">
                    <Table>
                        <TableHead>
                            <TableRow style={{padding: 20}}>
                                <TableCell className='fs-15'>Logo</TableCell>
                                <TableCell className='fs-15'>
                                    <Row vertical={"center"}>
                                        Counsel Name
                                        <SortingComponent state={sortingState.name}
                                                          onDescendingSort={() => onDescendingSort("name")}
                                                          onAscendingSort={() => onAscendingSort("name")}/>
                                    </Row>
                                </TableCell>
                                <TableCell className='fs-15'>
                                    <Row>Contact Person</Row>
                                </TableCell>
                                <TableCell className='fs-15'>
                                    <Row>Email</Row>
                                </TableCell>
                                <TableCell className='fs-15'>
                                    <Row vertical={"center"}>
                                        Location
                                        <SortingComponent state={sortingState["location.city"]}
                                                          onDescendingSort={() => onDescendingSort("location.city")}
                                                          onAscendingSort={() => onAscendingSort("location.city")}/>
                                    </Row>
                                </TableCell>
                                <TableCell className='fs-15'>
                                    <Row vertical={"center"}>
                                        Status
                                        <SortingComponent state={sortingState["invitationStatus"]}
                                                          onDescendingSort={() => onDescendingSort("invitationStatus")}
                                                          onAscendingSort={() => onAscendingSort("invitationStatus")}/>

                                    </Row>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.filteredList.length !== 0 ? state.filteredList.map((counsels, index) => (
                                <TableRow className='h-60px' id={index}>
                                    <TableCell component="th" scope="row">
                                        <img
                                            src={counsels && counsels.logo ? getSignedURL(counsels.logo) : "/images/user-placeholder.svg"}
                                            className="w-60" alt="counsel-logo"/>
                                    </TableCell>
                                    <TableCell className='fs-15 fc-blue-grey'>{counsels.name}</TableCell>
                                    <TableCell className='fs-15 fc-blue-grey'>{counsels.contactName}</TableCell>
                                    <TableCell className='fs-15 fc-blue-grey'>{counsels.email}</TableCell>
                                    <TableCell
                                        className='fs-15 fc-blue-grey'>{counsels.location.city + ", " + counsels.location.state}</TableCell>
                                    <TableCell
                                        className='fs-15 fc-blue-grey'>{counsels.invitationStatus === genericConstants.INVITATION_STATUS.PENDING ? stringConstants.INVITATION_PENDING :
                                        counsels.invitationStatus === genericConstants.INVITATION_STATUS.ACCEPTED || counsels.invitationStatus === genericConstants.INVITATION_STATUS.ACTIVE ? stringConstants.ACTIVE :
                                            counsels.invitationStatus === genericConstants.INVITATION_STATUS.REJECTED ? stringConstants.REJECTED :
                                                counsels.invitationStatus === genericConstants.INVITATION_STATUS.DELETED ? stringConstants.DELETED : stringConstants.INACTIVE}</TableCell>
                                    <TableCell>
                                        <div className="cursor-pointer">
                                            {counsels.invitationStatus === 'PENDING' && counsels.invitedBy !== companyId ?
                                                <Row>
                                                    <img src='/images/tick-icon.svg'
                                                         className='tick-icon-v2'
                                                         onClick={() => updateInvitation(counsels, genericConstants.INVITATION_STATUS.ACCEPTED)}
                                                    />
                                                </Row>
                                                : <img src='/images/edit-icon.svg'
                                                       onClick={() => toggleEditCounselDialog(counsels)}/>
                                            }
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )) : null
                            }
                        </TableBody>
                    </Table>

                </Column>
                : <div className="w-100 h-100 justify-content-center flex-container bg-color-white">
                    {roleType === genericConstants.ROLES.ORIGINATION_COUNSEL ? "No Origination Counsels Found" : "No Seller Counsels Found"}
                </div>}
            {state.filteredList && state.filteredList.length > 0 ?
                <div className="display-none">
                    <Pagination style={{marginTop: 20}}
                                onChange={(pn) => handlePageChange(pn)}
                                current={state.activePage}
                                total={state.filteredList ? state.filteredList.length : 0}
                                pageSize={10}
                    />
                </div>
                :
                ""}
        </div>
    );
};


const inviteOCComponent = (props) => {
    let {onChangeEvent, toggleInviteOCDialog, state, hideSuggestionBox, onInviteCounselButtonClicked, deleteCounsel} = props;

    return (
        <Dialog maxWidth={"sm"} open={state.isInviteOCDialogOpen} fullWidth={true}>
            <form className='overflow-hidden'>
                <Column style={{width: '90%', margin: 'auto'}}>
                    <Row>
                        <div
                            className="fs-20 fw-500 pt-4">{props.roleType === genericConstants.ROLES.ORIGINATION_COUNSEL ?
                            state.subUserObjectForEdit && Object.keys(state.subUserObjectForEdit).length > 0 ?
                                "Origination Counsel" : "Invite New Origination Counsel" :
                            state.subUserObjectForEdit && Object.keys(state.subUserObjectForEdit).length > 0 ? "Seller Counsel" :
                                "Invite New Seller Counsel"}</div>
                    </Row>
                    <Column style={{margin: '50px 0px'}}>
                        <label className="input-label">Counsel Name</label>
                        <div style={{width: '100%'}} className={state.predictions.length > 0 ? '' : 'mb-4'}>
                            <input type="text" className="input-border counsel-entities form-control" name='counselName'
                                   onChange={onChangeEvent} value={state.counselName}
                                   disabled={state.subUserObjectForEdit && Object.keys(state.subUserObjectForEdit).length > 0}
                            />
                            <div
                                className={state && state.predictions && state.predictions.length > 0 ? 'suggestion-box' : 'display-none'}>
                                {

                                    state && state.predictions && state.counselName && state.counselName.length > 2 ? state.predictions.map((item, index) => (
                                        <div className="p-3 bg-white" key={index + item}
                                             onClick={() => hideSuggestionBox(item)}><span
                                            className='ml-2 counsel-entities'>{item.name + ','}</span> <span
                                            className='city-and-state'>{item.address.city + ',' + item.address.state}</span>
                                        </div>
                                    )) : ''
                                }
                            </div>

                        </div>
                        <div className='position-relative'>
                            <label className="input-label">Contact Person</label>
                            <input type="text" className="mb-4 counsel-entities input-border form-control"
                                   name={'contactPerson'}
                                   onChange={onChangeEvent} value={state.contactPerson}
                                   disabled={state.subUserObjectForEdit && Object.keys(state.subUserObjectForEdit).length > 0}
                            />
                        </div>

                        <label className="input-label">Address</label>
                        <input type="text" className="mb-4 counsel-entities input-border form-control" name={'address'}
                               onChange={onChangeEvent} value={state.address}
                               disabled={state.subUserObjectForEdit && Object.keys(state.subUserObjectForEdit).length > 0}
                        />
                        {state.subUserObjectForEdit && Object.keys(state.subUserObjectForEdit).length > 0 && (state.invitationStatus === genericConstants.INVITATION_STATUS.ACCEPTED || state.invitationStatus === genericConstants.INVITATION_STATUS.ACTIVE || state.invitationStatus === genericConstants.INVITATION_STATUS.IN_ACTIVE) ?
                            <div>
                                <label className="input-label">Status</label>
                                <Row className="br-2">
                                    <Select value={state.invitationStatus} name="invitationStatus"
                                            className="mb-4 counsel-entities input-border form-control p-0"
                                            onChange={onChangeEvent}
                                            input={<CustomSelectInput name="invitationStatus" id="pools-select"/>}
                                    >

                                        <MenuItem className="p-2 cursor-pointer outline-none"
                                                  value={state.invitationStatus}>
                                            Select Values
                                        </MenuItem>
                                        <MenuItem className="p-2 cursor-pointer outline-none"
                                                  value={genericConstants.INVITATION_STATUS.ACTIVE}>{stringConstants.ACTIVE}</MenuItem>
                                        <MenuItem className="p-2 cursor-pointer outline-none"
                                                  value={genericConstants.INVITATION_STATUS.IN_ACTIVE}>{stringConstants.INACTIVE}</MenuItem>
                                    </Select>
                                </Row>
                            </div>

                            : ""}
                        <label className="input-label">Email</label>
                        <Row className="br-2 bg-pale-grey p-2 b-1-pale-grey-three justify-content-between">

                            <input id={'search'}
                                   name={'emailID'}
                                   onChange={onChangeEvent}
                                   value={state.email}
                                   disabled={state.subUserObjectForEdit && Object.keys(state.subUserObjectForEdit).length > 0}
                                   className="outline-none counsel-entities b-none bg-pale-grey w-100"/>

                        </Row>
                    </Column>
                    {state.subUserObjectForEdit && Object.keys(state.subUserObjectForEdit).length > 0 && (state.invitationStatus === genericConstants.INVITATION_STATUS.PENDING || state.invitationStatus === genericConstants.INVITATION_STATUS.REJECTED) ?
                        <Row className="mb-5 justify-content-between">
                            <Column>
                                <Button onClick={deleteCounsel}
                                        className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">Delete
                                </Button>
                            </Column>
                            <Row>
                                <Button onClick={toggleInviteOCDialog}
                                        className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two fc-blue mx-1 fw-500 px-3 cursor-pointer">Cancel
                                </Button>
                                <Button onClick={onInviteCounselButtonClicked}
                                        className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                                    {state.subUserObjectForEdit && Object.keys(state.subUserObjectForEdit).length > 0 ? "Update" : "Invite"}
                                </Button>
                            </Row>
                        </Row>
                        :
                        <Row horizontal={"end"} className="mb-5">
                            <Button onClick={toggleInviteOCDialog}
                                    className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two fc-blue mx-1 fw-500 px-3 cursor-pointer">Cancel
                            </Button>
                            <Button onClick={onInviteCounselButtonClicked}
                                    className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                                {state.subUserObjectForEdit && Object.keys(state.subUserObjectForEdit).length > 0 ? "Update" : "Invite"}
                            </Button>
                        </Row>}

                </Column>
            </form>
        </Dialog>
    )
};

const originationCounselComponent = (props) => {
    let updatedProps = {
        componentName: props.roleType === genericConstants.ROLES.ORIGINATION_COUNSEL ? 'Loan Origination Counsel' : 'Loan Seller Counsel',
        buttonName: 'Invite Counsel',
        invitationButtonClicked: props.toggleInviteOCDialog
    };
    return (
        <Column className="min-vh-100 p-4 fc-dark-slate-blue" style={{maxHeight: props.height}}>
            {headerComponent(updatedProps)}
            {SearchFilterComponent(props)}
            {RecordsTableComponent(props)}
            {inviteOCComponent(props)}
        </Column>
    )
};
export default originationCounselComponent;
