import {Row} from "simple-flexbox";
import React from "react";

const activityCell = (props) => {

    return (
        <Row key={props.key} className='display-flex justify-content-center align-items-center'>
            {props && props.activityText && props.activityTime ? <Row flex={1}>
                <Row className="justify-content-between fs-14 mb-4">
                    <Row className="flex-6p5 pr-2">{props.activityText}</Row>
                    <Row className="justify-content-end fs-14 fc-warm-grey">{props.activityTime}</Row>
                </Row>
            </Row> : ""}
        </Row>
    )
};

export default activityCell;
