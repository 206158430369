import React from 'react'
import {Column, Row} from "simple-flexbox";
import {history} from "../../managers/history";
import {Button} from "@material-ui/core";
import sessionManager from "../../managers/sessionManager";
import {cookiesConstants, genericConstants, stringConstants} from "../../constants";
import CustomInput from "../../common/components/CustomInput";
import Dropzone from "react-dropzone";
import ProcessingLoader from "../../common/components/processingLoader";
import Utils from "../../utility";
import {getSignedURL} from "../../managers/awsService";
import AvatarView from "../../common/components/AvatarView";
import Avatar from "@material-ui/core/Avatar/Avatar";

const ChangePasswordComponent = (props) => {
    let {toggleChangePassword, changeUserPassword} = props;
    let [passwordInfo, updatePasswordInfo] = React.useState({
        currentPassword: '',
        newPassword: ''
    });
    let [errorPasswordInfo, updateErrorPasswordInfo] = React.useState({
        currentPassword: '',
        newPassword: ''
    });

    function cancelChangePassword() {
        updatePasswordInfo({
            currentPassword: '',
            newPassword: ''
        });
        updateErrorPasswordInfo({
            currentPassword: '',
            newPassword: ''
        });
        toggleChangePassword(false);
    }

    function validatePasswordField() {
        let isPasswordFieldValid = true;
        if (!passwordInfo || Object.keys(passwordInfo).length < 1) {
            isPasswordFieldValid = false;
            updateErrorPasswordInfo({
                currentPassword: '',
                newPassword: ''
            });
            return isPasswordFieldValid;
        }

        let errorPasswordObj = Object.assign({}, errorPasswordInfo);
        for (let key in passwordInfo) {
            if (passwordInfo[key]) {
                if (passwordInfo[key][0] === passwordInfo[key][0].toLowerCase()) {
                    errorPasswordObj[key] = "First character should be capital letter";
                    isPasswordFieldValid = false;
                } else if (passwordInfo[key].length < 8) {
                    errorPasswordObj[key] = "Password should be 8 character long";
                    isPasswordFieldValid = false;
                } else if (!Utils.isPasswordValid(passwordInfo[key])) {
                    errorPasswordObj[key] = "Password should contain 1 special character";
                    isPasswordFieldValid = false;
                } else
                    errorPasswordObj[key] = "";
            } else {
                errorPasswordObj[key] = "Please fill this field";
                isPasswordFieldValid = false;
            }
        }

        updateErrorPasswordInfo(errorPasswordObj);
        return isPasswordFieldValid;
    }

    async function onUpdatePassword() {
        if (!validatePasswordField()) {
            return;
        }
        changeUserPassword(passwordInfo);
        toggleChangePassword(false);

    }

    function handleInputChange(event) {
        updatePasswordInfo({...passwordInfo, [event.target.id]: event.target.value})
        updateErrorPasswordInfo({...errorPasswordInfo, [event.target.id]: ''})
    }

    return (<div className="mt-3 mb-3">
            <hr/>
            <div className="fc-blue-grey fw-500 mt-2">Current Password<label className="fc-red">*</label></div>
            <CustomInput id="currentPassword" className=" p-2"
                         type="password"
                         onChange={handleInputChange} required={true} placeholder=""
                         value={passwordInfo.currentPassword} error={errorPasswordInfo.currentPassword}
                         autoComplete="off"/>

            <div className="fc-blue-grey fw-500 mt-2">New Password</div>
            <CustomInput id="newPassword" className="p-2" type="password" onChange={handleInputChange} placeholder=""
                         value={passwordInfo.newPassword} error={errorPasswordInfo.newPassword} autoComplete="off"/>

            <Row horizontal={"end"} className="mt-2">
                <Column>
                    <Button className="outline-none text-transform-capitalize p-2
                    br-4 b-1-white-two fc-blue mx-1 fw-500 px-3 cursor-pointer"
                            onClick={() => cancelChangePassword()}>
                        Cancel
                    </Button>
                </Column>
                <Column>
                    <Button className="outline-none text-transform-capitalize p-2
                    br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer"
                            onClick={() => onUpdatePassword()}>
                        Change Password
                    </Button>
                </Column>
            </Row>
            <hr/>
        </div>

    )
};

function ProfileSettingsComponent(props) {
    let [isAbleToChangePassword, toggleChangePassword] = React.useState(false);
    let {state, user, onFileCancel, onFileDrop, updateUserDetails, changeUserPassword, onChangeEvent, toggleSaveButton} = props;

    let acceptanceString = '.jpg, .jpeg, .png, .svg';
    let loader = user && user.userDetails && user.userDetails.photo && user.userDetails.photo.fileName && state.logoLoading ?
        <ProcessingLoader/> : null;
    let profilePhoto = user && user.userDetails && user.userDetails.photo && user.userDetails.photo.fileName;
    return (
        <Row className="w-100 p-3 px-5 justify-content-start m-5 h-100" wrap={true}>
            <Column className="display-flex flex-1 flex-direction-column align-items-center" flex="25">
                <Row className="fc-blue-grey fs-15 mt-3 mb-4 fw-500">Profile Picture</Row>
                <Row className="justify-content-center w-150 h-135px">
                    {loader ? loader :
                        <AvatarView
                            source={state.profile && state.profile.logoURL ? state.profile.logoURL : '/images/user-placeholder.svg'}
                            alt='limb-logo' class="max-h-130 w-130 h-100 width-fit-content"/>
                    }
                </Row>
                <Row className="my-4">
                    <Dropzone
                        multiple={false}
                        accept={acceptanceString}
                        onDrop={(files) => {
                            onFileDrop(files, user, genericConstants.ACTION_TYPE.UPDATE_PROFILE_IMAGE)
                        }}
                        noClick={true}
                        disableClick
                        onFileDialogCancel={() => onFileCancel()}>
                        {({getRootProps, getInputProps, open}) => (
                            <div {...getRootProps()} className="outline-none">
                                <input {...getInputProps()} />
                                <Button
                                    className="text-transform-capitalize p-2 b-1-blue outline-none fs-15  fc-blue mx-1 fw-500 px-3 cursor-pointer"
                                    onClick={() => open()}>
                                    Change
                                </Button>
                            </div>
                        )}
                    </Dropzone>
                </Row>
                {LogoutComponent(props)}
            </Column>
            <div className="b-1-pale-grey-three m-h-30p h-auto"/>
            <Column flex={75}>
                <div className="w-40-per min-w-350">
                    {state && state.firstName ? <div className="mb-2">
                        <Row className="fc-blue-grey  mt-3 fw-500" horizontal={"start"}>Full Name</Row>
                        <CustomInput id="firstName" type="text" placeholder={''}
                                     onChange={onChangeEvent}
                                     value={state.firstName} className="fc-dark-slate-blue fs-13 p-2"
                        />
                    </div> : ""}
                    {user && user.userDetails && user.userDetails.emailID ? <div className="mb-2">
                        <Row className="fc-blue-grey  mt-3 fw-500" horizontal={"start"}>Email</Row>
                        <div className="fc-dark-slate-blue p-2 bg-pale-grey-two">
                            {user.userDetails && user.userDetails.emailID}
                        </div>
                    </div> : ""}
                    {user && user.userDetails && user.userDetails ? <div className="mb-2">
                        <Row className="fc-blue-grey  mt-3 fw-500" horizontal={"start"}>User Role</Row>
                        <div className="fc-dark-slate-blue p-2 bg-pale-grey-two">
                            {Utils.viewRoleLevel(Utils.getUserRoleLevel(user.userDetails))}
                        </div>
                    </div> : ""}

                    <div className="mb-2">
                        <Row className="fc-blue-grey  mt-3 fw-500" horizontal={"start"}>Password</Row>
                        {isAbleToChangePassword ?
                            <ChangePasswordComponent
                                state={state}
                                toggleChangePassword={toggleChangePassword}
                                changeUserPassword={changeUserPassword}
                            /> :
                            <div className="position-relative">
                                <CustomInput id="password" type="password" placeholder={''}
                                             value={"12345567"} className="fc-dark-slate-blue fs-13 p-2"/>
                                <Button className="text-transform-capitalize pl-1 outline-none
                      fs-15  fc-blue mx-1 fw-500 px-3 cursor-pointer position-absolute top-0 right-0"
                                        onClick={() => toggleChangePassword(true)}>
                                    Change
                                </Button>
                            </div>
                        }
                    </div>
                    <Row className="mt-4 mb-2 fc-blue-grey mt-3 fw-500" horizontal={"start"}>Notifications</Row>
                    <Row className="mb-2 justify-content-between fw-500 fc-dark-slate-blue">
                        <div className="p-2">Email Notifications</div>
                        <div><input id="Notifications" type="checkbox" placeholder={''}
                                    value={""}
                                    className="fc-dark-slate-blue fs-15 p-2"/></div>
                    </Row>
                    {state.showSaveButton ?
                        <Row horizontal={"end"}>
                            <Button className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two fc-blue mx-1
                                fw-500 px-3 cursor-pointer outline-none" onClick={toggleSaveButton}>
                                Cancel
                            </Button>
                            <Button className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white
                                mx-1 fw-500 px-3 cursor-pointer outline-none"
                                    onClick={() => updateUserDetails({
                                        userID: user && user.userDetails ? user.userDetails.userID : null,
                                        firstName: state.firstName
                                    })}>
                                Save
                            </Button>
                        </Row>
                        : ""}
                </div>

            </Column>

        </Row>
    );
}

function LogoutComponent(props) {
    let {onLogoutClicked} = props;
    return (<Button className="outline-none text-transform-capitalize p-2 m-5
                br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer"
                    onClick={() => onLogoutClicked()}>
        Logout
    </Button>);

}

function SettingsComponent(props) {
    return (
        <div className="p-4 m-2 h-100">
            <Row className="fc-dark-slate-blue fs-24 font-weight-bold">Settings</Row>
            {ProfileSettingsComponent(props)}
        </div>
    )

}

export default SettingsComponent;
