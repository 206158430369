import React from "react";
import BaseComponent from '../baseComponent'
import NotificationComponent from './notificationComponent'
import Utils, {dispatchAction} from "../../utility";
import connect from "react-redux/es/connect/connect";
import {genericConstants} from "../../constants";
import {NotificationService} from "../../services";

class Notification extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            notificationCount: 0,
        }
    }

    componentDidMount() {
        this.getNotificationList();
        this.getNotificationCount();
    }

    getNotificationList = async () => {
        let requestObj = this.getRequestParameter();
        if (!requestObj || Object.keys(requestObj).length < 1)
            return;

        let [error, response] = await Utils.parseResponse(NotificationService.getNotifications(requestObj));

        this.setState({notificationList: error ? [] : response, isLoading: false});

    };
    getNotificationCount = async () => {
        let requestObj = this.getRequestParameter(true);
        if (!requestObj || Object.keys(requestObj).length < 1)
            return;
        let [error, response] = await Utils.parseResponse(NotificationService.getNotificationCount(requestObj));
        this.setState({notificationCount: error ? 0 : response.count, isLoading: false});

    };
    markReadNotifications = async (lastIndex) => {
        let requestObj = this.getRequestParameter(true);
        if (!requestObj || Object.keys(requestObj).length < 1)
            return;
        requestObj[genericConstants.QUERY_OBJECT_KEY.NOTIFICATION_ID_ARRAY] = this.getNotificationIDsList(lastIndex);
        let [error, response] = await Utils.parseResponse(NotificationService.markReadNotifications(requestObj));
        if (!error)
            this.getNotificationList();
        this.setState({notificationCount: error ? 0 : response.count, isLoading: false});

    };
    getNotificationIDsList = (lastIndex) => {
        let {notificationList} = this.state;
        if (!notificationList || notificationList.length < 1)
            return [];
        let notificationIDsList = [];
        notificationList.forEach((obj, index) => {
            if (index > lastIndex)
                return;
            notificationIDsList.push(obj._id);
        });
        return notificationIDsList;
    };
    getRequestParameter = (isForNotificationCount = false) => {
        if (!this.props.user || !this.props.user.userDetails)
            return;
        let queryObj = {};
        let requestObj = {};
        queryObj[genericConstants.QUERY_OBJECT_KEY.IS_CLEARED] = false;
        queryObj[genericConstants.QUERY_OBJECT_KEY.POSTED_TO] = this.props.user.userDetails.emailID;
        // queryObj[genericConstants.QUERY_OBJECT_KEY.PAYLOAD_NOTIFICATION_TYPE] = {'$in': []};
        if (isForNotificationCount) {
            queryObj[genericConstants.QUERY_OBJECT_KEY.IS_READ] = false;

        } else {
            requestObj[genericConstants.QUERY_OBJECT_KEY.SELECTION_STRING] = "payload title _id addedOn isRead isCleared";

        }
        requestObj[genericConstants.QUERY_OBJECT_KEY.QUERY_OBJECT] = queryObj;
        return requestObj;
    }

    render() {
        return (
            <NotificationComponent state={this.state}
                                   notificationList={this.state.notificationList}
                                   markReadNotifications={this.markReadNotifications}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user}
};

export default connect(mapStateToProps, {dispatchAction})(Notification);
