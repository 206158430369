import React from "react";
import BaseComponent from '../baseComponent'
import Utils, {dispatchAction} from "../../utility";
import {DocumentService, RecordService} from "../../services";
import connect from "react-redux/es/connect/connect";
import {
    apiFailureConstants,
    eventConstants,
    genericConstants,
    poolRelatedQuestionIds,
    underlinedQuestionList
} from "../../constants";
import {Column, Row} from "simple-flexbox";
import {Button} from "@material-ui/core";
import QuestionCell from "../sectionDetails/questionComponent";
import OptionsComponent from "../../common/components/OptionComponent";
import NoteDialogComponent from "../../common/components/NoteDialogComponent";
import Dropzone from "react-dropzone";
import {getSignedURL, uploadFileToS3} from "../../managers/awsService";
import DatePickerComponent from "../../common/components/DatePicker";

class QuestionComponent extends BaseComponent {
    constructor(props) {
        super(props);
        let answerState = this.props.questionObject ? this.props.questionObject.answer : null;
        let userRole = Utils.getUserRole(this.props.user.userDetails);
        this.state = {
            questionList: [],
            files: [],
            questionObject: this.props.questionObject,
            subQuestions: answerState || (this.props.questionObject && this.props.questionObject.type === genericConstants.QUESTION_TYPE.INFO) ? this.extractNestedQuestionOnOptionSelection(this.props.questionObject, answerState.id) : [],
            userRole: userRole,
            isSaveButtonVisible: false,
            isNoteDialogOpen: false,
            answer: {
                id: answerState ? answerState.id : null,
                value: answerState ? answerState.value : null,
                type: answerState ? answerState.type : null
            },
            userInput: answerState ? answerState.id : "",
        };
    }

    componentDidMount() {

    }

    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };

    handleUserInput = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };

    handleAnswerInput = (event) => {
        this.setState({
            answer: {
                ...this.state.answer,
                value: event.target.value,
                type: genericConstants.ANSWER_TYPE.DESCRIPTION
            }
        });
    };
    handleDataChange = async (event, questionObject) => {
        await this.setState({
            answer: {
                ...this.state.answer,
                value: event.target.value || " ",
                type: genericConstants.ANSWER_TYPE.DESCRIPTION
            }
        });
        this.submitTextInputAnswer(questionObject, true);
    };

    ifEmptySubmissionAcceptable = (questionObject) => {
        return poolRelatedQuestionIds.includes(questionObject.questionId);
    };

    handleCheckboxSelection = async (optionObject) => {
        await this.setState({
            answer: {
                id: optionObject.id,
                value: optionObject.value,
                type: genericConstants.ANSWER_TYPE.CHECKBOX
            }
        });
        this.readCheckboxAnswer(optionObject);
    };

    handleQuestionCheckboxSelection = async (questionObj) => {
        if (!questionObj || !questionObj.optionArray || questionObj.optionArray.length === 0)
            return;
        let isAnswerAlreadyMarked = questionObj && questionObj.answer && !Utils.isEmpty(questionObj.answer.id);
        let optionObj = {
            id: isAnswerAlreadyMarked ? "" : questionObj.optionArray[0].id,
            _id: isAnswerAlreadyMarked ? "" : questionObj.optionArray[0]._id,
            value: isAnswerAlreadyMarked ? "" : genericConstants.QUESTION_TYPE.QUESTION_CHECKBOX,
            type: isAnswerAlreadyMarked ? "" : genericConstants.QUESTION_TYPE.QUESTION_CHECKBOX
        };
        await this.setState({answer: optionObj});
        this.readCheckboxAnswer(optionObj);
    };

    async submitTextInputAnswer(questionObject, isItForDatePicker = false) {
        if (!this.state.isSaveButtonVisible && !isItForDatePicker)
            return;
        if (!this.state.answer)
            return;
        if (!this.state.answer.value && !this.ifEmptySubmissionAcceptable(questionObject))
            return;

        //Setting Space Char as Answer!
        if (!this.state.answer.value && this.ifEmptySubmissionAcceptable(questionObject)) {
            await this.setState({
                answer: {
                    ...this.state.answer,
                    value: " ",
                    type: genericConstants.ANSWER_TYPE.DESCRIPTION
                }
            })
        }

        this.submitAnswer(questionObject, this.state.answer)
            .catch(error => {
                Utils.consoleLogger("submitAnswer error", error);
            });
    }

    updateState(key, value) {

        this.setState({[key]: value});
    }

    async readCheckboxAnswer(optionObject) {
        let {questionObject} = this.state;
        this.submitAnswer(questionObject, this.state.answer).catch(error => {
            Utils.consoleLogger("submitAnswer error", error);
        });
    }


    extractNestedQuestionOnOptionSelection(questionObject, selection) {
        if (!questionObject || !questionObject.optionArray || !questionObject.optionArray.length
            || !questionObject.subQuesArray || !Object.keys(questionObject.subQuesArray).length) {
            return [];
        }
        if (questionObject.type === genericConstants.QUESTION_TYPE.INFO)
            selection = questionObject.optionArray[0].id;

        if (!selection)
            return [];

        let subQuestionIdsArray = questionObject.subQuesArray[selection];
        let {questionsList} = this.props;
        let questions = [];

        if (!subQuestionIdsArray || !subQuestionIdsArray.length || !questionsList || !questionsList.length)
            return [];

        for (let nestedQuestion of subQuestionIdsArray) {
            let ques = questionsList.find((question) => {
                return question.questionId === nestedQuestion
            });
            if (ques)
                questions.push(ques);

        }
        return questions;
    }

    //To submit Answers
    submitAnswer = async (questionObject, answer) => {
        this.props.removeQuestionInCompleteQuestionIdsList(questionObject.questionId);
        let requestData = {};
        requestData['recordId'] = questionObject.recordId;
        requestData['questionId'] = questionObject.questionId;
        requestData['sectionId'] = questionObject.sectionId;
        requestData['isDocumentUploaded'] = questionObject.isDocumentUploaded || false;
        requestData['answer'] = questionObject.answer && !Utils.isEmpty(questionObject.answer.id) && questionObject.answer.id === answer.id ? {
            id: "",
            value: ""
        } : answer;

        let subQuesArray = [];
        requestData['parentQuestionId'] = questionObject && questionObject.parentQuestionId ? questionObject.parentQuestionId : "";
        if (requestData['answer'].id && questionObject.optionArray && questionObject.optionArray.length > 0) {
            subQuesArray = questionObject.optionArray.filter(obj => obj.id === requestData['answer'].id);
        }

        requestData['subQuesArray'] = subQuesArray.length > 0 && questionObject.subQuesArray && Object.keys(questionObject.subQuesArray).length > 0 ?
            questionObject.subQuesArray[subQuesArray[0].id] : [];

        /**
         * Handling Data Input in Numeric For Search by Criteria purposes.
         * It handles Amount Ranges and Date Ranges searches.
         * */

        if (questionObject && questionObject.isNumericAnswerRequired) {
            let answer = requestData.answer;
            //If it is Date Input then convert input value into epoch and then set it on "numericValue" key.
            if (Utils.isDateInputRequired(questionObject.questionId)) {
                //Generate epoch from input date string
                if (requestData && requestData.answer && requestData.answer.value)
                    answer = this.pushDataOnNumericValueKey(requestData.answer, Utils.getEpochFromTimeString(requestData.answer.value));
            } else
                answer = this.pushDataOnNumericValueKey(requestData.answer, Utils.extractNumericValueFromString(requestData.answer));
            requestData = {...requestData, answer}
        }
        //Pushing modifiedBy user info
        let {userDetails} = this.props.user;

        requestData['modifiedBy'] = {
            name: userDetails.firstName || "",
            userId: userDetails.userID || "",
            email: userDetails.emailID || "",
        };

        let [error, questionUpdateResponse] = await Utils.parseResponse(RecordService.updateQuestion(requestData));
        if (error || !questionUpdateResponse) {
            Utils.consoleLogger("submitAnswer error for question : " + questionObject.question, error);
            Utils.apiFailureToast("Unable to submit answer!");
            return;
        }
        this.updateState("isSaveButtonVisible", false);
        this.setState({
            answer: questionUpdateResponse.answer,
            questionObject: questionUpdateResponse,
            subQuestions: []
        });
        let nestedQuestionArray = await this.extractNestedQuestionOnOptionSelection(this.state.questionObject, this.state.answer.id);
        await this.props.getSectionDetails(questionObject.recordId, questionObject.sectionId).catch((error) => Utils.consoleLogger("error : submitAnswer : getSectionDetails", error));
        await this.setState({subQuestions: nestedQuestionArray});
    };

    pushDataOnNumericValueKey = (answerObject, value) => {
        return {...answerObject, numericValue: value ? parseFloat(value) : 0};
    };


    highLightQuestion = async (questionObject) => {
        let requestData = {...this.getRequestObjectForUpdateQuestion()};
        requestData['isHighlighted'] = !questionObject.isHighlighted;
        await this.updateQuestionObject(requestData);
    };
    toggleNoteDialog = () => {
        this.setState({isNoteDialogOpen: !this.state.isNoteDialogOpen});

    };

    addQuestionNotes = async (noteObject) => {
        if (!noteObject || Object.keys(noteObject).length < 1)
            return;
        let requestData = {...this.getRequestObjectForUpdateQuestion()};
        requestData['notes'] = noteObject;

        await this.updateQuestionObject(requestData);
        this.toggleNoteDialog();

    };
    getRequestObjectForUpdateQuestion = () => {
        let requestData = {};
        let {questionObject} = this.state;
        requestData['recordId'] = questionObject.recordId;
        requestData['questionId'] = questionObject.questionId;
        requestData['sectionId'] = questionObject.sectionId;
        requestData['answer'] = questionObject.answer;
        requestData['isDocumentUploaded'] = questionObject.isDocumentUploaded || false;
        requestData['isHighlighted'] = questionObject.isHighlighted;
        requestData['parentQuestionId'] = questionObject && questionObject.parentQuestionId ? questionObject.parentQuestionId : "";
        return requestData;
    };
    updateIsDocumentUploaded = async () => {
        let requestData = {...this.getRequestObjectForUpdateQuestion()};
        requestData['isDocumentUploaded'] = true;
        await this.updateQuestionObject(requestData);
    };

    updateQuestionObject = async (requestData) => {
        if (!requestData || Object.keys(requestData).length < 1) {
            Utils.apiFailureToast("Unable to update question object due to empty requestData!");
            return;
        }
        let [error, questionUpdateResponse] = await Utils.parseResponse(RecordService.updateQuestion(requestData));
        if (error || !questionUpdateResponse) {
            Utils.consoleLogger("update question object error for question : " + requestData, error);
            Utils.apiFailureToast("Unable to update question object!");
            return;
        }
        this.setState({
            questionObject: questionUpdateResponse,
            subQuestions: []
        });
    };

    renderTypeBasedOptions = (props) => {
        let {optionObj, isSaveButtonVisible, isDisabled, index} = props;
        let questionObject = this.state.questionObject;

        switch (optionObj.type) {

            default:
            case genericConstants.ANSWER_TYPE.CHECKBOX :
                return (
                    <div key={index}>
                        <Row vertical="center" className="m-2">
                            <input type="checkbox" name="answer"
                                   onClick={() => this.handleCheckboxSelection(optionObj)}
                                   checked={this.state.answer && this.state.answer.id && (this.state.answer.id === optionObj.id)}/>
                            <div className="mx-2">{optionObj.value}</div>
                        </Row>
                    </div>
                );
            case genericConstants.ANSWER_TYPE.DESCRIPTION :
                return (
                    <Column key={index} vertical="start" horizontal="start" className="w-50 flex-wrap">
                        <div className="mx-2">{optionObj.value}</div>
                        {isDisabled ?
                            <div>{this.state.answer && this.state.answer.value ? this.state.answer.value : ""}</div> :
                            <textarea id="userInput" rows="2" className="outline-none input-border p-2 w-85-percent"
                                      onChange={this.handleUserInput}
                                      value={this.state.answer && this.state.answer.value ? this.state.answer.value : ""}
                                      onFocus={() => this.updateState("isSaveButtonVisible", true)}
                                      onBlur={() => this.submitTextInputAnswer(questionObject)}
                            />}
                        {isSaveButtonVisible ? <Row className="display-flex align-content-end my-2" horizontal={"end"}>
                            <div>
                                <Button onClick={() => this.updateState("isSaveButtonVisible", false)}
                                        className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-pale-grey fc-brownish-grey mx-1 fw-500 px-3 cursor-pointer">
                                    Cancel
                                </Button>
                                <Button //onClick={() => this.submitTextInputAnswer(questionObject)}
                                    className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                                    Save
                                </Button>
                            </div>
                        </Row> : null}
                    </Column>
                );
            case genericConstants.ANSWER_TYPE.DESCRIPTIVE_CHECKBOX :
                return (<OptionsComponent optionObj={optionObj} key={index}
                                          questionObject={questionObject}
                                          answer={this.state.answer}
                                          isSaveButtonVisible={isSaveButtonVisible}
                                          submitAnswer={this.submitAnswer}
                                          isDisabled={isDisabled}/>);
            case genericConstants.ANSWER_TYPE.INFO :
                return (
                    <div>

                    </div>
                );

        }
    };

    AnswersComponent = (questionObject, isSaveButtonVisible, isDisabled) => {
        switch (questionObject.type) {
            case genericConstants.QUESTION_TYPE.DESCRIPTIVE_CHECKBOX:
            case genericConstants.QUESTION_TYPE.CHECKBOX:
                return (
                    <Row vertical="start" horizontal="start" className="w-40-per">
                        <div className="flex-wrap display-flex flex-direction-row align-items-start">
                            {questionObject && questionObject.optionArray ?
                                questionObject.optionArray.map((optionObj, index) => {
                                        if (!optionObj)
                                            return;

                                        return this.renderTypeBasedOptions({
                                            optionObj,
                                            questionObject,
                                            isSaveButtonVisible,
                                            isDisabled,
                                            index
                                        });

                                        /*return <Row vertical="center" className="m-2">
                                            <input type="checkbox" name="answer"
                                                   onClick={() => this.updateState("userInput", optionObj._id)}
                                                   checked={this.state.userInput === optionObj._id}/>
                                            <div className="mx-2">{optionObj.value}</div>
                                        </Row>*/
                                        // return OptionComponent({optionObj, questionObject, isSaveButtonVisible, isDisabled});
                                    }
                                ) : null}
                        </div>
                    </Row>
                );

            case genericConstants.QUESTION_TYPE.DESCRIPTION:
                return (
                    <Column vertical="start" horizontal="start" className="w-40-per mr-2 min-h-250">
                        {isDisabled ?
                            <div>{this.state.answer && this.state.answer.value ? this.state.answer.value : "-"}</div> :
                            <Row className="w-100-per h-100">
                                {!Utils.isDateInputRequired(questionObject.questionId) ?
                                    <textarea id="userInput" rows="2"
                                              className="outline-none input-border p-2 w-95-percent h-70-percent min-h-250 textarea-resize-both"
                                              onChange={this.handleAnswerInput}
                                              value={this.state.answer && this.state.answer.value ? this.state.answer.value : ""}
                                              onFocus={() => this.updateState("isSaveButtonVisible", true)}
                                              onBlur={() => this.submitTextInputAnswer(questionObject)}
                                    />
                                    : <DatePickerComponent
                                        value={this.state.answer && this.state.answer.value ? this.state.answer.value : ""}
                                        questionObject={questionObject}
                                        onChange={this.handleDataChange}
                                    />
                                }
                            </Row>
                        }
                        {isSaveButtonVisible ? <Row className="display-flex align-content-end my-2" horizontal={"end"}>
                            <div>
                                <Button onClick={() => this.updateState("isSaveButtonVisible", false)}
                                        className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-pale-grey fc-brownish-grey mx-1 fw-500 px-3 cursor-pointer">
                                    Cancel
                                </Button>
                                <Button //onClick={() => this.submitTextInputAnswer(questionObject)}
                                    className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                                    Save
                                </Button>
                            </div>
                        </Row> : null}
                    </Column>
                );
            default:
                return <div/>
        }
    };


    onFileCancel = () => {
        this.setState({
            files: []
        });
    };

    onFileDrop = async (files) => {
        if (!files || files.length < 1) {
            Utils.apiFailureToast(apiFailureConstants.UPLOAD_DOCUMENT);
            return;
        }
        let file = files[0];
        let fileDetail = Utils.getFileName(file.name);
        let keyName = Utils.getNewFileName('quesDoc', fileDetail.extension);
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let resFromS3 = await uploadFileToS3(file, `documents/${keyName}`, file.type).catch(err => {
            Utils.apiFailureToast(apiFailureConstants.UPLOAD_DOCUMENT);
            this.props.dispatchAction(eventConstants.HIDE_LOADER);
            return false;
        });
        if (!resFromS3) {
            Utils.apiFailureToast(apiFailureConstants.UPLOAD_DOCUMENT);
            this.props.dispatchAction(eventConstants.HIDE_LOADER);
            return;
        }

        let requestData = this.getDocumentsData(file.name, keyName);
        if (!requestData || requestData.length < 1) {
            this.props.dispatchAction(eventConstants.HIDE_LOADER);
            return;
        }
        await this.saveDocumentsInLDR(requestData);
        this.updateIsDocumentUploaded();
    };


    getDocumentsData = (documentName, keyName) => {
        if (!documentName || !keyName)
            return;
        let documentInfo = {
            name: documentName,
            key: keyName,
            addedBy: this.getAddedByInformation(),
            recordId: this.state.questionObject.recordId || '',
            sectionId: this.state.questionObject.sectionId || '',
            questionId: this.state.questionObject.questionId || '',
        };
        return [documentInfo];
    };

    getAddedByInformation() {
        let {company} = this.props;
        let {userDetails} = this.props.user;
        return {
            "companyId": company.companyId || '',
            "userId": userDetails.userID || '',
            "email": userDetails.emailID || '',
            "firstName": userDetails.firstName || '',
            "role": userDetails.role && userDetails.role.length && userDetails.role[0] ? userDetails.role[0] : {}
        }
    }

    saveDocumentsInLDR = async (documentList) => {
        this.props.dispatchAction(eventConstants.SHOW_LOADER);

        let [error, documentResponse] = await Utils.parseResponse(DocumentService.addDocuments(documentList));
        if (error) {
            this.props.dispatchAction(eventConstants.HIDE_LOADER);
            Utils.apiFailureToast("Unable to Add document");
            return;
        }
        this.props.dispatchAction(eventConstants.HIDE_LOADER);

    };
    ViewDocument = async (questionObject) => {
        if (!questionObject || Object.keys(questionObject).length < 1 || !questionObject.recordId) {
            Utils.apiFailureToast(apiFailureConstants.PARAMETER_VIEW_DOCUMENT);
            return;
        }
        let request = {
            recordId: questionObject.recordId || '',
            sectionId: questionObject.sectionId || '',
            questionId: questionObject.questionId || '',
            isGetAssociatedDocumentsList: false
        };
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let documentObj = await this.getDocument(request);
        if (!documentObj || Object.keys(documentObj).length < 1 || !documentObj.key) {
            this.props.dispatchAction(eventConstants.HIDE_LOADER);
            return;
        }
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        window.open(await getSignedURL(`documents/${documentObj.key}`), '_blank');
        return;

    };
    getDocument = async (request) => {
        if (!request || Object.keys(request).length < 1 || !request.recordId) {
            Utils.apiFailureToast(apiFailureConstants.PARAMETER_VIEW_DOCUMENT);
            return false;
        }

        let [error, documentResponse] = await Utils.parseResponse(DocumentService.getDocuments(request));
        if (error) {
            Utils.apiFailureToast(apiFailureConstants.GET_DOCUMENT);
            return false;
        }
        if (!documentResponse || documentResponse.length < 1) {
            Utils.apiFailureToast(apiFailureConstants.NO_DOCUMENT);
            return false;
        }
        return documentResponse[0];
    };


    render() {
        let questionObject = this.state.questionObject;
        let isSaveButtonVisible = this.state.isSaveButtonVisible;
        let isDisabled = this.props.isDisabled;
        let acceptanceString = genericConstants.ACCEPTED_FILES_FORMATS;
        let styleSelectionForQuestionAccess = isDisabled ? " pointer-event-none " :
            questionObject.isHighlighted ? " select-question-css cursor-pointer " :
                this.props.inCompleteQuestionIdsList && this.props.inCompleteQuestionIdsList.length > 1 &&
                this.props.inCompleteQuestionIdsList.indexOf(questionObject.questionId) > -1 ? " incomplete-question-css cursor-pointer " : " cursor-pointer ";
        if (this.props.inCompleteQuestionIdsList && this.props.inCompleteQuestionIdsList.length === 1 && this.props.inCompleteQuestionIdsList.indexOf(questionObject.questionId) > -1) {
            styleSelectionForQuestionAccess = styleSelectionForQuestionAccess + ' incomplete-question-css cursor-pointer ';

        }

        let isUnderlined = (questionObject && underlinedQuestionList.hasOwnProperty(questionObject.questionId));

        /**
         * Implementation : only for question which is having only one text to be underlined
         * and having single occurrence in question Text.
         * Returns : UnderLined Question
         */
        function renderUnderLinedQuestion() {
            let question = questionObject.question;
            let questionParts = question.split(underlinedQuestionList[questionObject.questionId]);
            return (
                <div>{questionParts[0]}<span
                    className="underlineText">{underlinedQuestionList[questionObject.questionId]}</span>{questionParts[1]}
                </div>
            )
        }

        return (
            <div className="w-100 display-flex align-items-center flex-column p-2 my-2 fc-dark-slate-blue ">
                <div className={"w-100 display-flex flex-row parent_div" + styleSelectionForQuestionAccess}>
                    <Row
                        className={"px-2 " + (questionObject.type === genericConstants.QUESTION_TYPE.INFO || questionObject.isQuestionTextBold ? "font-weight-bold w-100 " : "fw-500 w-40-per ")}
                        vertical={"start"}>

                        {questionObject.type === genericConstants.QUESTION_TYPE.QUESTION_CHECKBOX ?
                            <input type="checkbox" name="answer" className="p-1 m-1"
                                   onClick={() => this.handleQuestionCheckboxSelection(questionObject)}
                                   checked={this.state.answer && this.state.answer.id && (this.state.answer.id === questionObject.optionArray[0].id)}/> : null}

                        {questionObject ? isUnderlined ? renderUnderLinedQuestion() : questionObject.question : "-"}
                    </Row>

                    {this.AnswersComponent(questionObject, isSaveButtonVisible, isDisabled)}
                    {questionObject.type === genericConstants.QUESTION_TYPE.INFO ? "" :
                        <Row className="display-flex flex-end vertical-align-middle align-items-center child_div">
                            <Column className="cursor-pointer"><img
                                src={questionObject.isHighlighted ? "/images/highlight-icon-blue.svg" : "/images/highlight-icon.svg"}
                                className="w-20px"
                                onClick={() => this.highLightQuestion(questionObject)}/></Column>
                            <Column className="pl-2 pr-2 cursor-pointer"><img
                                src={questionObject.notes && questionObject.notes.length > 0 ? "/images/note-icon-blue.svg" : "/images/add-note-icon.svg"}
                                onClick={() => this.toggleNoteDialog()}
                                className="w-20px"/></Column>
                            <Column className="cursor-pointer">
                                {questionObject.isDocumentUploaded ?
                                    <img
                                        src={questionObject.isDocumentUploaded ? "/images/upload-icon-blue.svg" : "/images/upload-icon.svg"}
                                        className="w-20px"
                                        onClick={() => this.ViewDocument(questionObject)}/>
                                    :
                                    <Dropzone
                                        multiple={false}
                                        accept={acceptanceString}
                                        onDrop={(files) => {
                                            this.onFileDrop(files)
                                        }}
                                        noClick={true}
                                        onFileDialogCancel={() => this.onFileCancel()}>
                                        {({getRootProps, getInputProps, open}) => (
                                            <div {...getRootProps()} className="outline-none">
                                                <input {...getInputProps()} />
                                                <Column className="">
                                                    <img
                                                        src={questionObject.isDocumentUploaded ? "/images/upload-icon-blue.svg" : "/images/upload-icon.svg"}
                                                        className="w-20px"
                                                        onClick={() => open()}/>
                                                </Column>
                                            </div>
                                        )}
                                    </Dropzone>
                                }

                            </Column>
                        </Row>
                    }

                </div>

                <div className="w-95">
                    {this.state.subQuestions ? this.state.subQuestions.map((questionObject) => {
                        return <QuestionCell props={this.props} getSectionDetails={this.props.getSectionDetails}
                                             questionObject={questionObject}
                                             inCompleteQuestionIdsList={this.props.inCompleteQuestionIdsList}
                                             isDisabled={this.props.isDisabled}
                                             removeQuestionInCompleteQuestionIdsList={this.props.removeQuestionInCompleteQuestionIdsList}
                                             questionsList={this.props.questionsList}/>
                    }) : null}
                </div>

                {this.state.isNoteDialogOpen ?
                    <NoteDialogComponent
                        isNoteDialogOpen={this.state.isNoteDialogOpen}
                        questionObject={this.state.questionObject}
                        userDetails={this.props.user.userDetails}
                        toggleNoteDialog={this.toggleNoteDialog}
                        addQuestionNotes={this.addQuestionNotes}
                        headerMessage={questionObject.notes && questionObject.notes.length > 0 ? 'View Added Note' : 'Add New Note'}
                    /> : ""}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, company: state.company}
};

export default connect(mapStateToProps, {dispatchAction})(QuestionComponent);
