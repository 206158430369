import React from "react";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {Column, Row} from "simple-flexbox";
import {Button} from "@material-ui/core";
import utility from "../../utility";
import {stringConstants} from "../../constants";

const ConfirmationPopup = (props) => {
    function closePopup() {
        props.closePopup();
        return;
    }

    function actionOnClicked() {
        props.onYesClicked();
        props.closePopup();
        return;
    }

    return (
        <Dialog open={props.isConfirmationPopUpOpen} fullWidth={true}>
            <Column style={{width: '90%', margin: 'auto'}}>
                <Row>
                    <div
                        className="fs-20 fw-500 pt-4 pb-4">{props.headerMessage}
                    </div>
                </Row>


                <Row horizontal={"end"} className="mb-5 pt-3">
                    <button
                        className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two fc-blue mx-1 fw-500 px-3 cursor-pointer"
                        onClick={closePopup}>
                        No
                    </button>
                    <button
                        className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer"
                        onClick={actionOnClicked}>
                        Yes
                    </button>
                </Row>

            </Column>
        </Dialog>
    )
};

export default ConfirmationPopup;