/**
 * Created by Ayush Kulshrestha on 18/09/2019.
 */

import {httpConstants} from "../constants";
import {httpService} from "../managers/httpService";

export default {
    inviteUser
}

async function inviteUser(requestData) {
    let url = process.env.REACT_APP_INVITE_SERVICE_URL + httpConstants.API_END_POINT.INVITE_USER;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                console.log('inviteUser', response);
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });

}

//
// function getHeader() {
//     return {
//         'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON,
//         'device-type': httpConstants.DEVICE_TYPE.WEB,
//         'device-id': sessionManager.getDataFromCookies(genericConstants.COOKIES_KEY.DEVICE_ID) === undefined ? "" : sessionManager.getDataFromCookies(genericConstants.COOKIES_KEY.DEVICE_ID),
//         'session-token': sessionManager.getDataFromCookies(genericConstants.COOKIES_KEY.SESSION_TOKEN) === undefined ? "" : sessionManager.getDataFromCookies(genericConstants.COOKIES_KEY.SESSION_TOKEN),
//     };
// }

