import React from "react";
import Utility from "../../utility";
import {Column, Row} from "simple-flexbox";
import CustomInput from "./CustomInput";

class AddressForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addressObj: {
                streetAddress: "",
                city: "",
                state: "",
                country: "",
                lat: "",
                lng: "",
            }
        };
    }

    componentDidMount() {
        this.updateAutoCompleteListeners();
    }

    updateAutoCompleteListeners = () => {
        let {requestFor} = this.props;
        const google = window.google = window.google ? window.google : {};
        this.addressLineAutocomplete = new google.maps.places.Autocomplete(document.getElementById(`${requestFor}.streetAddress`), {})
        this.addressLineAutocomplete.addListener("place_changed", this.handleAddressSelect);

        //Uncomment if address completion with city field required.
        /*this.cityAutocomplete = new google.maps.places.Autocomplete(document.getElementById('city'), {
            types: ['(cities)']
        });
        this.cityAutocomplete.addListener("place_changed", this.handlePlaceSelect);*/
    };

    handleAddressSelect = () => {
        let {updateAddressInfo} = this.props;

        let goggleAddressObject = this.addressLineAutocomplete.getPlace();
        if (!goggleAddressObject || !goggleAddressObject.address_components)
            return;
        let addressArray = goggleAddressObject.address_components;
        let locationObject = goggleAddressObject && goggleAddressObject.geometry && goggleAddressObject.geometry.location || {};
        let formattedAddressObj = this.getFormattedAddressObj(addressArray);
        let {addressObj} = this.state;
        addressObj['streetAddress'] = formattedAddressObj.streetAddress || "";
        addressObj['city'] = formattedAddressObj.city || "";
        addressObj['state'] = formattedAddressObj.state || "";
        addressObj['country'] = formattedAddressObj.country || "";
        addressObj['lat'] = locationObject.lat() || 0;
        addressObj['lng'] = locationObject.lng() || 0;
        updateAddressInfo(addressObj);
    };

    /*handlePlaceSelect = () => {
        let {updateAddressInfo} = this.props;
        let goggleAddressObject = this.cityAutocomplete.getPlace();
        if (!goggleAddressObject || !goggleAddressObject.address_components)
            return;
        let addressArray = goggleAddressObject.address_components;
        let locationObject = goggleAddressObject && goggleAddressObject.geometry && goggleAddressObject.geometry.location || {};
        let formattedAddressObj = this.getFormattedAddressObj(addressArray);
        let {addressObj} = this.state;
        addressObj['city'] = formattedAddressObj.city || "";
        addressObj['state'] = formattedAddressObj.state || "";
        addressObj['country'] = formattedAddressObj.country || "";
        addressObj['lat'] = locationObject.lat() || 0;
        addressObj['lng'] = locationObject.lng() || 0;
        updateAddressInfo(addressObj);
    };*/

    getFormattedAddressObj = (addressArray) => {
        let addressObj = {
            streetAddress: "",
            city: "",
            state: "",
            country: "",
        };
        let subLocality = "";
        for (let addressIndex in addressArray) {
            for (let typeIndex in addressArray[addressIndex].types) {
                switch (addressArray[addressIndex].types[typeIndex]) {
                    case 'country':
                        addressObj.country = addressArray[addressIndex].short_name;
                        break;
                    case 'locality':
                        addressObj.city = addressArray[addressIndex].long_name;
                        break;
                    case 'administrative_area_level_1':
                        addressObj.state = addressArray[addressIndex].short_name;
                        break;
                    case 'street_number':
                    case 'route':
                        addressObj.streetAddress += addressArray[addressIndex].long_name + ' ';
                        break;
                    case 'sublocality':
                        subLocality += addressArray[addressIndex].long_name + ' ';
                        break;

                }
            }
        }
        if (Utility.isEmpty(addressObj.streetAddress))
            addressObj.streetAddress += subLocality + ' ';
        return addressObj;
    };

    render() {
        let {companyInfo, handleInputChange, requestFor, errorInfo} = this.props;
        let headerText = requestFor && requestFor === "propertyAddress" ? "Property Address" : "Street Address";
        return (
            <div>
                <div className="fc-blue-grey fw-500 mt-2">{headerText}<label className="fc-red">*</label></div>
                <CustomInput id={`${requestFor}.streetAddress`} className=" p-2" onChange={handleInputChange} placeholder=""
                             value={companyInfo.streetAddress} error={errorInfo && errorInfo.streetAddress || companyInfo.streetAddressError}
                             autoComplete="off"/>

                {requestFor && requestFor === "propertyAddress" ? "" :
                    <div>
                        <div className="fc-blue-grey fw-500 mt-2">Suite/Unit</div>
                        <CustomInput id = {`${requestFor}.suite`} className="p-2" onChange={handleInputChange} placeholder=""
                        value={companyInfo.suite} error={errorInfo && errorInfo.suite || companyInfo.suiteError} autoComplete="off"/>
                    </div>
                }
                <Row className="mt-2">
                    <div className="mr-1">
                        <div className="fc-blue-grey fw-500">City<label
                            className="fc-red">*</label></div>
                        <CustomInput id={`${requestFor}.city`} className="p-2" type="select" onChange={handleInputChange} placeholder=""
                                     value={companyInfo.city} error={errorInfo && errorInfo.city || companyInfo.cityError}
                                     autoComplete="off"/>
                    </div>
                    <div className="mx-1">
                        <div className=" fc-blue-grey fw-500">State<label
                            className="fc-red">*</label></div>
                        <CustomInput id={`${requestFor}.state`} className=" p-2" type="select" onChange={handleInputChange}
                                     placeholder=""
                                     value={companyInfo.state} error={errorInfo && errorInfo.state || companyInfo.stateError}
                                     autoComplete="off"/>
                    </div>
                    <div className="ml-1">
                        <div className=" fc-blue-grey fw-500">Country<label
                            className="fc-red">*</label></div>
                        <CustomInput id={`${requestFor}.country`} className=" p-2" type="select" onChange={handleInputChange}
                                     placeholder=""
                                     value={companyInfo.country} error={errorInfo && errorInfo.country || companyInfo.countryError}
                                     autoComplete="off"/>
                    </div>
                </Row>
            </div>

        )
    }
}

export default AddressForm;
