import React from "react";
import BaseComponent from '../../baseComponent'
import DashboardComponent from './dashboardComponent'
import {genericConstants, pathConstants} from "../../../constants";
import {history} from "../../../managers/history";
import connect from "react-redux/es/connect/connect";
import {dispatchAction} from "../../../utility";
import Utility from "../../../utility";

class Dashboard extends BaseComponent {
    constructor(props) {
        super(props);
        let url = [];
        this.state = {
            activeMenu: pathConstants.DASHBOARD_MENU.PUBLISH_REQUESTS,
        };

        if (history.location.pathname)
            url = history.location.pathname.split('/');

        if (url && url.length === 2)
            history.replace(pathConstants.DASHBOARD_MENU.PUBLISH_REQUESTS);

    }

    componentDidMount() {
        let userRole = this.props && this.props.user && this.props.user.userDetails ? Utility.getUserRole(this.props.user.userDetails) : null;
        if (!userRole)
            return;
        if (userRole !== genericConstants.ROLES.SUPER_ADMIN)
            this.onMenuClick(pathConstants.DASHBOARD_MENU.PUBLISH_REQUESTS);
    }

    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };

    onMenuClick = (activeMenu) => {
        this.setState({activeMenu: activeMenu});
        history.push({pathname: activeMenu});
    };


    render() {
        return (
            <DashboardComponent state={this.state}
                                userDetails={this.props.user ? this.props.user.userDetails : null}
                                companyDetails={this.props.company}
                                onMenuClick={this.onMenuClick}
                                onChangeEvent={this.onChangeEvent}/>
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, company: state.company}
};

export default connect(mapStateToProps, {dispatchAction})(Dashboard);
