import React from "react";
import {Button} from "@material-ui/core";

const CustomButton = (props) => {
    return (
        <Button type='submit' className={props.class} onClick={props.onClickHandler}>
            {props.buttonText}
        </Button>
    )
};

export default CustomButton;