import {Column, Row} from "simple-flexbox";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {Button, LinearProgress, Select, withStyles} from "@material-ui/core";
import CustomSelectInput from "../../../common/components/CustomSelectInput";
import MenuItem from "@material-ui/core/MenuItem";
import Dropzone from 'react-dropzone';
import CustomInput from "../../../common/components/CustomInput";
import {genericConstants} from "../../../constants";

const BorderLinearProgress = withStyles({
    root: {
        height: 6,
        backgroundColor: '#dfe2e8',
        borderRadius: '3px',
        width: '100%'
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#0052cc',
    },
})(LinearProgress);

const addDocumentComponent = (props) => {
    let {onChangeEvent, toggleAddDocumentDialog, state, onFileDrop, onFileCancel, deleteFile, saveDocumentsInLDR} = props;
    return (
        <Dialog maxWidth={"sm"} open={state.isAddDocumentDialogOpen} fullWidth={true}>
            <form className='overflow-hidden'>
                <Column style={{width: '90%', margin: 'auto'}}>
                    <Row>
                        <div
                            className="fs-20 fw-500 pt-4 pb-4">Add Document
                        </div>
                    </Row>
                    <div>
                        <label className="input-label">Select Category</label>
                        <Row className="br-2">
                            <Select value={state.selectedCategory} name="selectedCategory"
                                    className="mb-2 counsel-entities input-border form-control p-0"
                                    onChange={onChangeEvent}
                                    input={<CustomSelectInput name="selectedCategory" id="pools-select"/>}
                            >
                                <MenuItem className="p-2 cursor-pointer outline-none" value={state.selectedCategory}>
                                    Select Values
                                </MenuItem>
                                {state && state.categoryList && Object.keys(state.categoryList).length > 0 ?
                                    Object.keys(state.categoryList).map((key, index) => {
                                        return getList(key, index)
                                    }) : ""}
                            </Select>
                        </Row>
                        {state.selectedCategoryError ?
                            <div className="display-flex fc-red fs-14 py-1">{state.selectedCategoryError}</div>
                            : ""}
                    </div>

                    {/*<div>*/}
                    {/*    <label className="input-label">Select Type</label>*/}
                    {/*    <Row className="br-2">*/}
                    {/*        <Select value={state.selectedType} name="selectedType"*/}
                    {/*                className="mb-2 counsel-entities input-border form-control p-0"*/}
                    {/*                onChange={onChangeEvent}*/}
                    {/*                input={<CustomSelectInput name="selectedType" id="pools-select"/>}*/}
                    {/*        >*/}
                    {/*            {state && state.selectedCategory && state.categoryList && state.categoryList[state.selectedCategory] && state.categoryList[state.selectedCategory].length > 0 ?*/}
                    {/*                state.categoryList[state.selectedCategory].map((key, index) => {*/}
                    {/*                    return getList(key, index)*/}
                    {/*                }) : ""}*/}
                    {/*        </Select>*/}
                    {/*    </Row>*/}
                    {/*    {state.selectedTypeError ?*/}
                    {/*        <div className="display-flex fc-red fs-14 py-1">{state.selectedTypeError}</div>*/}
                    {/*        : ""}*/}
                    {/*</div>*/}
                    <div>
                        <div style={{maxHeight: "255px", boxShadow: "none"}}
                             className=" card border-radius-none h-180px border-style-dashed">
                            <Dropzone accept={genericConstants.ACCEPTED_FILES_FORMATS}
                                      multiple={true}
                                      onDrop={files => onFileDrop(files)}
                                      disableClick
                                      noClick={true}
                                      onFileDialogCancel={onFileCancel}>
                                {({getRootProps, getInputProps, open}) => (
                                    <div {...getRootProps()}
                                         className="fs-14  text-center display-flex h-100 align-items-center"
                                         id={'selectFileId'}>
                                        <div
                                            className="w-100-per fc-dark-grey">
                                            <div>
                                                <img src="/images/upload-file.svg"/>
                                            </div>
                                            <div>
                                                Drag & Drop files here
                                            </div>
                                            <div>
                                                or
                                            </div>
                                            <div className="pt-2 w-100px m-auto">
                                                <input {...getInputProps()} />
                                                <div onClick={() => open()} className="text-transform-capitalize p-2
                     b-1-blue fs-15  fc-blue mx-1 fw-500 px-3 cursor-pointer br-4 ">
                                                    Select
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                            </Dropzone>

                        </div>
                    </div>
                    {
                        state && state.files && state.files.length > 0 ? state.files.map((file, index) => {
                            return documentProcessingModule(file, index, deleteFile)
                        }) : ""
                    }


                    <Row horizontal={"end"} className="mb-5 pt-3">
                        <Button onClick={toggleAddDocumentDialog}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two fc-blue mx-1 fw-500 px-3 cursor-pointer">Cancel
                        </Button>
                        <Button onClick={saveDocumentsInLDR}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                            Done
                        </Button>
                    </Row>

                </Column>
            </form>
        </Dialog>
    )
};


const addInvoiceComponent = (props) => {
    let {uploadInvoiceForCounsel, onInvoiceFileDrop, onInputChangeEvent, toggleAddDocumentDialog, state, onFileDrop, onFileCancel, deleteFile} = props;

    return (
        <Dialog maxWidth={"sm"} open={state.isInvoiceUploadFlow} fullWidth={true}>
            <form className='overflow-hidden'>
                <Column style={{width: '90%', margin: 'auto'}}>
                    <Row>
                        <div
                            className="fs-20 fw-500 pt-4 pb-4">Upload Invoice
                        </div>
                    </Row>
                    <div>
                        <div className="mt-lg-4">
                            <div className="input-label">Invoice number</div>
                            <CustomInput id="invoiceNumber" type="text" placeholder={''}
                                         value={state.invoiceNumber}
                                         onChange={onInputChangeEvent}
                                         className="fc-dark-slate-blue fs-16 p-2 h-50px"
                            />
                            {state.invoiceNumberError ?
                                <div className="display-flex fc-red fs-14 py-1">{state.invoiceNumberError}</div>
                                : ""}
                        </div>
                    </div>
                    <div>
                        <div className="mt-lg-4">
                            <div className="input-label">Amount($)</div>
                            <CustomInput id="invoiceAmount" type="number" placeholder={''}
                                         value={state.invoiceAmount}
                                         onChange={onInputChangeEvent}
                                         className="fc-dark-slate-blue fs-16 p-2 h-50px"
                            />
                            {state.invoiceAmountError ?
                                <div className="display-flex fc-red fs-14 py-1">{state.invoiceAmountError}</div>
                                : ""}
                        </div>
                    </div>
                    <div>
                        <div style={{maxHeight: "255px", boxShadow: "none"}}
                             className=" card border-radius-none h-180px border-style-dashed">
                            <Dropzone accept=".doc, .docx, .pdf"
                                      multiple={false}
                                      onDrop={files => onInvoiceFileDrop(files)}
                                      disableClick
                                      noClick={true}
                                      onFileDialogCancel={onFileCancel}>
                                {({getRootProps, getInputProps, open}) => (
                                    <div {...getRootProps()}
                                         className="fs-14  text-center display-flex h-100 align-items-center"
                                         id={'selectFileId'}>
                                        <div
                                            className="w-100-per fc-dark-grey">
                                            <div>
                                                <img src="/images/upload-file.svg"/>
                                            </div>
                                            <div>
                                                Drag & Drop files here
                                            </div>
                                            <div>
                                                or
                                            </div>
                                            <div className="pt-2 w-100px m-auto">
                                                <input {...getInputProps()} />
                                                <div onClick={() => open()} className="text-transform-capitalize p-2
                     b-1-blue fs-15  fc-blue mx-1 fw-500 px-3 cursor-pointer br-4 ">
                                                    Select
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                            </Dropzone>
                        </div>
                    </div>
                    {state && state.invoices && state.invoices.length > 0 ? state.invoices.map((file, index) => {
                        return documentProcessingModule(file, index, deleteFile)
                    }) : ""
                    }
                    <Row horizontal={"end"} className="mb-5 pt-3">
                        <Button onClick={toggleAddDocumentDialog}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two fc-blue mx-1 fw-500 px-3 cursor-pointer">Cancel
                        </Button>
                        <Button onClick={uploadInvoiceForCounsel}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                            Upload
                        </Button>
                    </Row>
                </Column>
            </form>
        </Dialog>
    )
};

const getList = (value, index) => {
    return <MenuItem className="p-2 cursor-pointer outline-none" value={value} key={index}>{value.toUpperCase()}</MenuItem>
};

const documentProcessingModule = (file, index, deleteFile) => {
    return <Row className="justify-content-between  pt-2 pb-2" key={index}>
        <Column className="fw-500 fs-14"
                flex={20}>{file && Object.keys(file).length > 0 && file.name ? file.name : ""}</Column>
        <Column className="fc-dark-slate-blue pt-2 pl-2 w-100" flex={60}>
            <BorderLinearProgress
                variant="determinate"
                color="secondary"
                value={100}
            />
        </Column>
        <Column className="cursor-pointer" flex={20} horizontal={"end"}>
            <img src="/images/cross-icon.svg" className="w-15" onClick={() => deleteFile(index)}/>
        </Column>
    </Row>
};

const uploadDocumentsComponent = (props) => {
    return (
        <Column className="min-vh-100 p-4 fc-dark-slate-blue" style={{maxHeight: props.height}}>
            {props.isInvoiceUploadFlow ? addInvoiceComponent(props) : addDocumentComponent(props)}
        </Column>
    )
};
export default uploadDocumentsComponent;