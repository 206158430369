import {Column, Row} from "simple-flexbox";
import React from "react";
import headerComponent from "../../../common/components/CustomHeader";
import {Button, Select, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import CustomSelectInput from "../../../common/components/CustomSelectInput";
import MenuItem from "@material-ui/core/MenuItem";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import Dialog from "@material-ui/core/Dialog";
import {genericConstants, stringConstants} from "../../../constants";
import {getSignedURL} from "../../../managers/awsService";
import Utility from "../../../utility";
import CustomInput from "../../../common/components/CustomInput";
import inviteUser from "../../../services/invite";
import SortingComponent from "../../../common/components/SortingComponent";

const SearchFilterComponent = (props) => {
    const {state} = props;

    function searchInList(event) {
        props.counselFilteredListSetValues(event.target.id, event.target.value);
    }

    return (
        <Column>
            <Row className="bg-color-white fs-12 mt-4" vertical="center">
                <Row className="br-2 bg-pale-grey p-2 b-1-pale-grey-three">
                    <input placeholder={'Search Counsel'} id={'search'}
                           onChange={searchInList}
                           className="outline-none b-none fc-bluey-grey bg-pale-grey fw-500"/>
                    <img className="mx-2" src="/images/search-icon.svg" alt="search"/>
                </Row>
                <Select value={props.state.locationFilter} className="br-2 mx-3 fc-warm-grey"
                        style={{borderBottom: '0px', transition: 'none'}}
                        onChange={props.onFilterChanged}
                        input={<CustomSelectInput name="locationFilter"/>}>
                    <MenuItem className="p-2 cursor-pointer outline-none"
                              value={genericConstants.INITIAL_FILTER_VALUES.LOCATION}>
                        {genericConstants.INITIAL_FILTER_VALUES.LOCATION}
                    </MenuItem>
                    {state && state.dataFilter && Object.keys(state.dataFilter).length > 0 && state.dataFilter.state && Object.keys(state.dataFilter.state).length > 0 ?
                        (state.dataFilter.state).map((key, index) => {
                            return renderMenuItem(key, index)
                        }) : ""}
                </Select>
                <Select value={props.state.statusFilter} className="br-2 mx-3 fc-warm-grey"
                        style={{borderBottom: '0px', transition: 'none'}}
                        onChange={props.onFilterChanged}
                        input={<CustomSelectInput name="statusFilter"/>}>
                    <MenuItem className="p-2 cursor-pointer outline-none"
                              value={genericConstants.INITIAL_FILTER_VALUES.STATUS}>
                        {genericConstants.INITIAL_FILTER_VALUES.STATUS}
                    </MenuItem>
                    {state && state.dataFilter && Object.keys(state.dataFilter).length > 0 && state.dataFilter.status && Object.keys(state.dataFilter.status).length > 0 ?
                        Object.keys(state.dataFilter.status).map((key, index) => {
                            return renderMenuItem(state.dataFilter.status[key], index)
                        }) : ""}
                </Select>
            </Row>
        </Column>
    )
};

const RecordsTableComponent = (props) => {
    const {handlePageChange, state, roleType} = props;
    const {filteredList: counselList} = state;

    let [sortingState, updateSortingState] = React.useState({
        name: null,
        invitationStatus: null,
        "address.city": null
    });

    return (
        <div className="my-2 overflow-x-auto">
            {state.data && state.data.length > 0 ?
                <Column className="bg-white h-100-per fs-15">
                    <Table>
                        {getRoleBasedRecordTableHeader(roleType, props, sortingState, updateSortingState)}
                        <TableBody>
                            {counselList && counselList.length !== 0 ? counselList.map((counsels, index) => (
                                getRoleBasedRecordTableBody(roleType, props, counsels)
                            )) : null
                            }
                        </TableBody>
                    </Table>

                </Column>
                : <div className="p-5 mx-4 mb-4 jc-center flex-container bg-color-white">
                    {getNoRecordText(roleType)}
                </div>}
            {state.filteredList && state.filteredList.length > 0 ?
                <div>
                    <Pagination style={{marginTop: 20}}
                                onChange={(pn) => handlePageChange(pn)}
                                current={state.activePage}
                                total={state.filteredList ? state.filteredList.length : 0}
                                pageSize={10}
                    />
                </div>
                :
                ""}
        </div>
    );
};


const InviteOCComponent = (props) => {
    let {onChangeEvent, toggleInviteOCDialog, state, inviteCounsel, hideSuggestionBox, onInviteCounselButtonClicked, deleteCounsel} = props;
    let [email, handleChange] = React.useState('');
    let [emailError, handleChangeErrorMsg] = React.useState('');

    function handleInputChange(event) {
        handleChange(event.target.value);
        handleChangeErrorMsg('');
    }


    async function inviteUser() {
        if (!email || !Utility.validateEmail(email)) {
            handleChangeErrorMsg('Please  enter correct email address');
            return;
        }
        toggleInviteOCDialog();
        inviteCounsel(email);

    }

    return (
        <Dialog maxWidth={"sm"} open={state.isInviteOCDialogOpen} fullWidth={true}>
            <form className='overflow-hidden'>
                <Column style={{width: '90%', margin: 'auto'}}>
                    <Row>
                        <div
                            className="fs-20 fw-500 pt-4">Invite Counsel
                        </div>
                    </Row>
                    <Row className='p-t-b-18px'>
                        <CustomInput type="text" className="input-border counsel-entities form-control"
                                     name='EmailAddress'
                                     onChange={handleInputChange}
                                     value={email}
                                     placeholder="Enter email Address"
                        />
                    </Row>
                    <Row className="display-flex fc-red fs-14 py-1">{emailError}</Row>
                    <Row horizontal={"end"} className="mb-5">
                        <Button onClick={toggleInviteOCDialog}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two fc-blue mx-1 fw-500 px-3 cursor-pointer">Cancel
                        </Button>
                        <Button onClick={() => inviteUser()}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                            Invite
                        </Button>
                    </Row>

                </Column>
            </form>
        </Dialog>
    )
};

const getComponentName = (role) => {
    switch (role) {
        case genericConstants.ROLES.LENDER:
            return 'Lenders';
        case genericConstants.ROLES.ORIGINATION_COUNSEL:
            return 'Loan Origination Counsel';
        case genericConstants.ROLES.SELLER_COUNSEL:
            return 'Loan Seller Counsel';
        default:
            return 'Loan Seller Counsel';
    }
};
const getRoleBasedRecordTableHeader = (role, props, sortingState, updateSortingState) => {
    const {state, onSortClicked} = props;

    function onDescendingSort(key) {
        onSortClicked(key, genericConstants.SORTING_ORDER.DESCENDING);
        updateSortingState({[key]: genericConstants.SORTING_ORDER.DESCENDING});
    }

    function onAscendingSort(key) {
        onSortClicked(key, genericConstants.SORTING_ORDER.ASCENDING);
        updateSortingState({[key]: genericConstants.SORTING_ORDER.ASCENDING});
    }

    switch (role) {
        case genericConstants.ROLES.LENDER:
            return (<TableHead>
                <TableRow style={{padding: 20}}>
                    <TableCell className='fs-14'>Logo</TableCell>
                    <TableCell className='fs-14'>
                        <Row vertical="center">
                            {getCompanyNameText(role)}
                            <SortingComponent
                                state={sortingState.name}
                                onDescendingSort={() => onDescendingSort("name")}
                                onAscendingSort={() => onAscendingSort("name")}
                            />
                        </Row>

                    </TableCell>
                    <TableCell className='fs-14'>
                        <Row>
                            Email
                        </Row>

                    </TableCell>
                    <TableCell className='fs-14'>
                        <Row vertical="center">
                            Location
                            <SortingComponent
                                state={sortingState["address.city"]}
                                onDescendingSort={() => onDescendingSort("address.city")}
                                onAscendingSort={() => onAscendingSort("address.city")}
                            />
                        </Row>

                    </TableCell>

                    <TableCell className='fs-14'>
                        <Row>
                            Total Records
                        </Row>

                    </TableCell>
                    <TableCell className='fs-14'>
                        <Row>
                            Records Published
                        </Row>

                    </TableCell>
                    <TableCell className='fs-14'>
                        <Row>
                            Records in Progress
                        </Row>

                    </TableCell>
                    <TableCell className='fs-14'>
                        <Row vertical="center">
                            Status
                            <SortingComponent
                                state={sortingState["address.city"]}
                                onDescendingSort={() => onDescendingSort("address.city")}
                                onAscendingSort={() => onAscendingSort("address.city")}
                            />
                        </Row>
                    </TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>);
        default:
            return (<TableHead>
                <TableRow style={{padding: 20}}>
                    <TableCell className='fs-14'>Logo</TableCell>
                    <TableCell className='fs-14'>
                        <Row vertical="center">
                            {getCompanyNameText(role)}
                            <SortingComponent
                                state={sortingState.name}
                                onDescendingSort={() => onDescendingSort("name")}
                                onAscendingSort={() => onAscendingSort("name")}
                            />
                        </Row>

                    </TableCell>
                    <TableCell className='fs-14'>
                        <Row>
                            Subscription Plan
                        </Row>

                    </TableCell>
                    <TableCell className='fs-14' onClick={() => props.sorting(state.counselList, 'address')}>
                        <Row vertical="center">
                            Location
                            <SortingComponent
                                state={sortingState["address.city"]}
                                onDescendingSort={() => onDescendingSort("address.city")}
                                onAscendingSort={() => onAscendingSort("address.city")}
                            />
                        </Row>

                    </TableCell>
                    <TableCell className='fs-14' onClick={() => props.sorting(state.counselList, 'status')}
                    >
                        <Row vertical="center">
                            Status
                            <SortingComponent
                                state={sortingState.status}
                                onDescendingSort={() => onDescendingSort("status")}
                                onAscendingSort={() => onAscendingSort("status")}
                            />
                        </Row>
                    </TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>);
    }
};
const getRoleBasedRecordTableBody = (role, props, counsels) => {
    const {onViewDetailsClick} = props;
    switch (role) {
        case genericConstants.ROLES.LENDER:
            return (<TableRow className='h-60px'>
                <TableCell component="th" scope="row">
                    <img
                        src={counsels && counsels.logo ? getSignedURL(counsels.logo, '/images/user-placeholder.svg') : "/images/user-placeholder.svg"}
                        className="w-60" alt="counsel-logo"/>
                </TableCell>
                <TableCell className='fs-15 fc-blue-grey'>{counsels.name}</TableCell>
                <TableCell className='fs-15 fc-blue-grey'>{counsels.contactEmail}</TableCell>
                <TableCell
                    className='fs-15 fc-blue-grey'>{counsels.address.city + ", " + counsels.address.state}</TableCell>
                <TableCell className='fs-15 fc-blue-grey'>{counsels.totalRecord}</TableCell>
                <TableCell className='fs-15 fc-blue-grey'>{counsels.publishedRecord}</TableCell>
                <TableCell className='fs-15 fc-blue-grey'>{counsels.totalRecord - counsels.publishedRecord}</TableCell>
                <TableCell
                    className='fs-15 fc-blue-grey text-capitalize'>{counsels.status.toLowerCase()}</TableCell>
                <TableCell>
                    <Row className="cursor-pointer fc-blue fs-15"
                         onClick={() => onViewDetailsClick(counsels)}
                        //  onClick={() => Utility.showUnderDevelopment()}
                    >
                        View Details
                    </Row>

                </TableCell>
            </TableRow>);
        default:
            return (<TableRow className='h-60px'>
                <TableCell component="th" scope="row">
                    <img
                        src={counsels && counsels.logo ? getSignedURL(counsels.logo, '/images/user-placeholder.svg') : "/images/user-placeholder.svg"}
                        className="w-60" alt="counsel-logo"/>
                </TableCell>
                <TableCell className='fs-15 fc-blue-grey'>{counsels.name}</TableCell>
                <TableCell className='fs-15 fc-blue-grey'>{"N/A"}</TableCell>
                <TableCell
                    className='fs-15 fc-blue-grey'>{counsels.address.city + ", " + counsels.address.state}</TableCell>
                <TableCell
                    className='fs-15 fc-blue-grey text-capitalize'>{counsels.status.toLowerCase()}</TableCell>
                <TableCell>
                    <Row className="cursor-pointer fc-blue fs-15"
                         onClick={() => onViewDetailsClick(counsels)}
                        //  onClick={() => Utility.showUnderDevelopment()}
                    >
                        View Details
                    </Row>

                </TableCell>
            </TableRow>);
    }
};
const getNoRecordText = (role) => {
    switch (role) {
        case genericConstants.ROLES.LENDER:
            return 'No Lender Found';
        case genericConstants.ROLES.ORIGINATION_COUNSEL:
            return 'No Origination Counsel Found';
        case genericConstants.ROLES.SELLER_COUNSEL:
            return 'No Seller Counsel Found';
        default:
            return 'No Counsel Found';
    }
};
const getInviteButtonName = (role) => {
    switch (role) {
        case genericConstants.ROLES.LENDER:
            return 'Invite Lender';
        default:
            return 'Invite Counsel';
    }
};
const getCompanyNameText = (role) => {
    switch (role) {
        case genericConstants.ROLES.LENDER:
            return 'User Name';
        default:
            return 'Counsel Name';
    }
};

const renderMenuItem = (value, index) => {
    if (!value)
        return "";
    return <MenuItem className="p-2 cursor-pointer outline-none text-capitalize" value={value}
                     key={index}>{value}</MenuItem>
};

const counselComponent = (props) => {
    let updatedProps = {
        componentName: getComponentName(props.roleType),
        buttonName: getInviteButtonName(props.roleType),
        invitationButtonClicked: props.toggleInviteOCDialog
        // invitationButtonClicked: Utility.showUnderDevelopment
    };
    return (
        <Column className="min-vh-100 p-4 fc-dark-slate-blue" style={{maxHeight: props.height}}>
            {headerComponent(updatedProps)}
            {SearchFilterComponent(props)}
            {RecordsTableComponent(props)}
            {InviteOCComponent(props)}
        </Column>
    )
};
export default counselComponent;