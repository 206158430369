import {Column, Row} from "simple-flexbox";
import React from "react";
import CustomInput from "../../common/components/CustomInput";
import Button from "@material-ui/core/Button/Button";
import Divider from "@material-ui/core/Divider/Divider";
import Utils from "../../utility";
import {stringConstants} from "../../constants";
import AddressForm from "../../common/components/addressForm";


function OrderSummaryComponent(props) {
    return (
        <div className="fc-dark-slate-blue mx-5 w-320">
            {/*<div className="font-weight-bold fs-20">Have a discount coupon?</div>*/}
            {/*<Row className="br-4 br-dark-grey bg-blue my-3">*/}
            {/*<input className="outline-none p-2 border-0 w-75 bg-white"/>*/}
            {/*<Button className="outline-none text-capitalize text-center fc-white bg-blue">Apply</Button>*/}
            {/*</Row>*/}
            <div className="font-weight-bold fs-20">Order Summary</div>
            <div className="br-greyish br-4 fs-15">
                <Row className="justify-content-between px-3 pt-3 my-1">
                    <div className="fc-brownish-grey">Plan</div>
                    <div className="font-weight-bold">{props.selectedPlan.planName}</div>
                </Row>
                <Row className="justify-content-between my-1 pb-3 mt-3 px-3 ">
                    <div className="fc-brownish-grey">Payment Schedule</div>
                    <div
                        className="font-weight-bold">{Utils.getSubscriptionPlanText(props.selectedPlan.subscriptionType)}</div>
                </Row>
                <Divider/>
                <Row className="justify-content-between my-1 px-3 py-2">
                    <div className=" fc-brownish-grey">Today’s Charges</div>
                    <Column className="text-right">
                        <div className="fs-22 font-weight-bold">
                            {`$${props.selectedPlan.price}`}
                        </div>
                        <div className="fc-brownish-grey fs-14">
                            without taxes
                        </div>
                    </Column>
                </Row>
                <Divider/>
                <Row className="justify-content-between my-1 px-3 py-2">
                    <Column>
                        <div className="fs-15 fc-brownish-grey">
                            Recurring Charges
                        </div>
                        <div className="fc-brownish-grey fs-14">
                            Start next year
                        </div>
                    </Column>
                    <Column className="text-right">
                        <div className="fs-16 font-weight-bold">
                            {`$${props.selectedPlan.price}`}
                        </div>
                        <div className="fc-brownish-grey fs-14">
                            without taxes
                        </div>
                    </Column>
                </Row>
                <div className="fs-14 fc-brownish-grey text-center p-4 mt-4">
                    (In case of wire transfer, you need to send a
                    wire transfer before #date to continue
                    using this service)
                </div>
            </div>
        </div>
    );
}

function CompanyInfoForm(props) {
    let {companyInfo, updateCompanyInfo} = props;

    function handleInputChange(event) {
        let targetElement = (event.target.id).split('.')[1] || event.target.id;
        updateCompanyInfo({...companyInfo, [targetElement]: event.target.value, [`${targetElement}Error`]: ''})
    }

    function updateAddressInfo(addressObject) {
        updateCompanyInfo({...companyInfo, ...addressObject});
    }

    return (

        <div>
            <div className="fc-blue-grey fw-500">Company Name<label className="fc-red">*</label></div>
            <CustomInput id="companyName" className=" p-2" onChange={handleInputChange}
                         value={companyInfo.companyName} error={companyInfo.companyNameError} placeholder=""/>
            <AddressForm companyInfo={companyInfo} handleInputChange={handleInputChange}
                         updateAddressInfo={updateAddressInfo} requestFor={"CompanyInfoForm"}/>
        </div>
    );
}

function BillingInfoForm(props) {
    let {billingInfo, updateBillingInfo} = props;

    function handleInputChange(event) {
        let targetElement = (event.target.id).split('.')[1];
        updateBillingInfo({...billingInfo, [targetElement]: event.target.value})
    }

    function updateAddressInfo(addressObject) {
        updateBillingInfo({...billingInfo, ...addressObject});
    }

    return (
        <div>
            <div>
                <AddressForm companyInfo={billingInfo} handleInputChange={handleInputChange}
                             updateAddressInfo={updateAddressInfo} requestFor={"billingInfo"}/>
            </div>
        </div>
    );
}

function CompanyInfoComponent(props) {

    let [isBillingAddressVisible, toggleBillingAddress] = React.useState(true);
    let [companyInfo, updateCompanyInfo] = React.useState({});
    let [billingInfo, updateBillingInfo] = React.useState({});

    function validation() {
        let companyNameError = Utils.isEmpty(companyInfo.companyName) ? stringConstants.EMPTY_COMPANY_NAME : '';
        let streetAddressError = Utils.isEmpty(companyInfo.streetAddress) ? stringConstants.EMPTY_STREET_ADDRESS : '';
        // let suiteError = Utils.isEmpty(companyInfo.suite) ? stringConstants.EMPTY_SUITE : '';
        let cityError = Utils.isEmpty(companyInfo.city) ? stringConstants.EMPTY_CITY : '';
        let stateError = Utils.isEmpty(companyInfo.state) ? stringConstants.EMPTY_STATE : '';
        let countryError = Utils.isEmpty(companyInfo.country) ? stringConstants.EMPTY_COUNTRY : '';
        updateCompanyInfo({
            ...companyInfo,
            companyNameError: companyNameError,
            streetAddressError: streetAddressError,
            cityError: cityError,
            stateError: stateError,
            countryError: countryError
        });

        return !!(companyNameError || streetAddressError || cityError || stateError || countryError);
    }

    function onFormSubmit(event) {
        event.preventDefault();
        if (validation())
            return;
        let requestData = {};
        requestData['name'] = companyInfo.companyName;
        requestData['streetAddress'] = companyInfo.streetAddress;
        requestData['suite'] = companyInfo.suite;
        requestData['city'] = companyInfo.city;
        requestData['state'] = companyInfo.state;
        requestData['country'] = companyInfo.country;
        requestData['lat'] = companyInfo.lat;
        requestData['lng'] = companyInfo.lng;
        let role = Utils.getUserRole(props.user.userDetails);
        requestData['type'] = Utils.getCompanyType(role);
        props.onAddCompanyInfo({company: requestData, billingAddress: requestData})
    }


    return (
        <div className="fc-dark-slate-blue w-545 mx-5">
            <div className="font-weight-bold fs-20">Add Company Information</div>
            <form className="my-3 fs-15" onSubmit={onFormSubmit} autoComplete="off">
                {<CompanyInfoForm companyInfo={companyInfo} updateCompanyInfo={updateCompanyInfo}/>}
                <Row vertical="center" className="fs-15 my-3">
                    <input type="checkbox" checked={isBillingAddressVisible}
                           onChange={() => toggleBillingAddress(!isBillingAddressVisible)}/>
                    <div className="mx-2">Billing information same as company information</div>
                </Row>
                {isBillingAddressVisible ? null :
                    <BillingInfoForm billingInfo={billingInfo} updateBillingInfo={updateBillingInfo}/>}
                <Divider className="my-4"/>
                <div className="font-weight-bold fs-20 mt-5">Wire transfer details</div>
                <div className="fs-16 fc-greyish-brown my-4">To send a wire transfer, you need to visit your local
                    branch and
                    provide
                    the following information
                </div>
                <p className="fc-warm-grey-two fs-16 font-italic line-height-1-69">
                    Account holder name<br/>
                    Full addressAccount number<br/>
                    Branch number and full address<br/>
                    Institution number<br/>
                    Swift Code / BIC / IBAN code<br/>
                    Routing Number (international)
                </p>
                <Button type='submit' className="bg-blue outline-none text-capitalize
                fc-white w-100 py-2 fs-17 mt-4 cursor-pointer">
                    Continue
                </Button>
            </form>
        </div>
    );
}

function CompanySetupComponent(props) {
    return (
        <Row>
            <div>
                {CompanyInfoComponent(props)}
            </div>
            <div>
                {props.selectedPlan && Object.keys(props.selectedPlan).length > 0 ?
                    OrderSummaryComponent(props) : ''}
            </div>

        </Row>
    );
}

export default function AddCompanyInfoComponent(props) {
    return (
        <div className="w-100 p-3 min-vh-100">
            <img src="/images/limb-logo.svg" alt='limb-logo' className="w-150"/>
            <Column horizontal={'center'} className="mb-5 mt-5 jc-space-between">
                {CompanySetupComponent(props)}
            </Column>
        </div>
    );
};