/**
 * Created by Ayush Kulshrestha on 18/09/2019.
 */
//export all services from index file -

// export * from './user'
import {httpConstants} from "../constants";
import {httpService} from "../managers/httpService";

export default {
    addCompanyService,
    updateCompanyService,
    getCompanyDetail,
    getCompanyDetailUsingLimbId,
    getAssociateList,
    getTypeBasedCompany,
    inviteAssociate,
    updateInvitation,
    updateAssociate,
    inviteCounsel,
    getStatusBasedCompanies
}

async function getTypeBasedCompany(requestData) {
    let url = process.env.REACT_APP_COMPANY_SERVICE_URL + httpConstants.API_END_POINT.GET_COMPANY_BASED_ON_ITS_TYPE;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });

}

async function getStatusBasedCompanies(requestData) {
    let url = process.env.REACT_APP_COMPANY_SERVICE_URL + httpConstants.API_END_POINT.GET_COMPANY_BASED_ON_ITS_STATUS;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });

}

async function getAssociateList(requestData) {
    let url = process.env.REACT_APP_COMPANY_SERVICE_URL + httpConstants.API_END_POINT.GET_ASSOCIATE;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function inviteAssociate(requestData) {
    let url = process.env.REACT_APP_COMPANY_SERVICE_URL + httpConstants.API_END_POINT.INVITE_ASSOCIATE;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function updateAssociate(requestData) {
    let url = process.env.REACT_APP_COMPANY_SERVICE_URL + httpConstants.API_END_POINT.INVITE_ASSOCIATE;
    return httpService(httpConstants.METHOD_TYPE.PUT, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function updateInvitation(requestData) {
    let url = process.env.REACT_APP_COMPANY_SERVICE_URL + httpConstants.API_END_POINT.INVITE_ASSOCIATE;
    return httpService(httpConstants.METHOD_TYPE.PUT, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function addCompanyService(requestData) {
    let url = process.env.REACT_APP_COMPANY_SERVICE_URL + httpConstants.API_END_POINT.ADD_COMPANY_DETAILS;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function updateCompanyService(requestData) {
    let url = process.env.REACT_APP_COMPANY_SERVICE_URL + httpConstants.API_END_POINT.COMPANY_DETAILS;
    return httpService(httpConstants.METHOD_TYPE.PUT, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getCompanyDetail(companyId) {
    let url = process.env.REACT_APP_COMPANY_SERVICE_URL + httpConstants.API_END_POINT.COMPANY_DETAILS + '/' + companyId;
    return httpService(httpConstants.METHOD_TYPE.GET, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, null, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getCompanyDetailUsingLimbId(limbId) {
    let url = process.env.REACT_APP_COMPANY_SERVICE_URL + httpConstants.API_END_POINT.COMPANY_DETAILS_USING_LIMB_ID + '/' + limbId;
    return httpService(httpConstants.METHOD_TYPE.GET, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, null, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function inviteCounsel(requestData){
    let url = process.env.REACT_APP_COMPANY_SERVICE_URL + httpConstants.API_END_POINT.INVITE_COUNSEL;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function(err){
            return Promise.reject(err);
        });
}
//
// function getHeader() {
//     return {
//         'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON,
//         'device-type': httpConstants.DEVICE_TYPE.WEB,
//         'device-id': sessionManager.getDataFromCookies(genericConstants.COOKIES_KEY.DEVICE_ID) === undefined ? "" : sessionManager.getDataFromCookies(genericConstants.COOKIES_KEY.DEVICE_ID),
//         'session-token': sessionManager.getDataFromCookies(genericConstants.COOKIES_KEY.SESSION_TOKEN) === undefined ? "" : sessionManager.getDataFromCookies(genericConstants.COOKIES_KEY.SESSION_TOKEN),
//     };
// }

