import {Column, Row} from "simple-flexbox";
import React from "react";
import {
    Button,
    Dialog,
    Tabs,
    Tab,
    LinearProgress, withStyles, Select, Table, TableRow, TableCell, TableHead, TableBody
} from "@material-ui/core";
import {history} from '../../../managers/history'
import Utility from "../../../utility";
import AvatarView from "../../../common/components/AvatarView";
import UploadDocument from "../../document/uploadDocuments";
import CounselDetailsComponent from "./index";
import moment from "moment";
import Utils from "../../../utility";

const HeaderComponent = (props) => {
    let {state} = props;
    return (
        <Column>
            <Row className="fs-24 font-weight-bold"><img onClick={() => history.goBack()}
                                                         className="cursor-pointer mr-3"
                                                         src="/images/back-icon.svg"/></Row>
            <Row>
                <Column className="justify-content-center w-150 h-135px">
                    <AvatarView
                        source={state.company.logoURL ? state.company.logoURL : '/images/user-placeholder.svg'}
                        alt='limb-logo' class="max-h-130 w-130 h-100 width-fit-content"/>
                </Column>
                <Column
                    className="fc-dark-slate-blue fs-20 fw-500 justify-content-center">{state.company.name}</Column>
            </Row>
        </Column>
    )
};
const InformationComponent = (props) => {
    const [value, setValue] = React.useState(1);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Row className="w-100 p-3 px-5 m-5 h-100 max-w-70-per" wrap={true} horizontal={"start"}>
            <div style={{flexGrow: 1}}>
                <div className="tab-shadow ">
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        aria-label="disabled tabs example"
                        variant="scrollable"
                        className="pl-0"
                    >
                        <Tab label="Contact Details" value={1} className="fs-15 custom text-transform-none"/>
                        <Tab label="Billing Details" value={2} className="fs-15 custom text-transform-none"/>
                        <Tab label="Invoices" value={3} className="fs-15 custom text-transform-none"/>
                        <hr/>
                    </Tabs>
                </div>
                {getAllViews(value, props)}
            </div>
        </Row>
    );
};
const getAllViews = (value, props) => {
    switch (value) {
        case 1:
            return getContactDetailView(props);
        case 2:
            return getBillingDetailView(props);
        case 3:
            return getInvoiceView(props);

    }

};
const getContactDetailView = (props) => {
    let {state} = props;
    return (<div className="pt-4 pl-5">
        <Row className="fc-blue-grey mt-4 fs-15" horizontal={"start"}>Address</Row>
        <Row className="" horizontal={"start"}>{state.company.address}</Row>
        <Row className="fc-blue-grey mt-4 fs-15" horizontal={"start"}>Contact Person</Row>
        <Row className="" horizontal={"start"}>{state.company.contactName}</Row>
        <Row className="fc-blue-grey mt-4 fs-15" horizontal={"start"}>Email</Row>
        <Row className="" horizontal={"start"}>{state.company.contactEmail}</Row>
        <Row className="fc-blue-grey mt-4 fs-15" horizontal={"start"}>Website</Row>
        <Row className="" horizontal={"start"}>{state.company.websiteUrl}</Row>
    </div>);

};
const getBillingDetailView = (props) => {
    let {state} = props;
    Utility.consoleLogger("subscriptionData", state.subscriptionData);
    return (<div className="pt-4 pl-5">
        <Row className="fs-15" horizontal={"start"}>Subscription Details</Row>
        <div className="mt-3 b-1-white-two br-4  pb-3">
            <Row className="justify-content-between fc-blue-grey mt-3 fs-15" horizontal={"start"}>
                <Column className="pl-3">
                    <div>Current Plan</div>
                    <div
                        className="fs-18 fc-black">{state.subscriptionData && state.subscriptionData.planName ? state.subscriptionData.planName : "N/A"}</div>
                </Column>
                <Column className="cursor-pointer fc-red pr-4" onClick={() => Utility.showUnderDevelopment()}>Deactivate
                    Account</Column>
            </Row>
            <Row horizontal={"start"} className="fc-blue-grey fs-15 pt-5">
                <Column className="pl-3 ml-2">
                    <div>Payment mode</div>
                    <div
                        className="fc-black">{state.subscriptionData && state.subscriptionData.PaymentType ? state.subscriptionData.PaymentType : "N/A"}</div>
                </Column>
                <Column className="pl-5 ml-2">
                    <div>Account created on</div>
                    <div className="fc-black">{state.company.addedOn}</div>
                </Column>
                <Column className="pl-5 ml-2">
                    <div>Next Payment due on</div>
                    <div
                        className="fc-black">{state.subscriptionData && state.subscriptionData.expiryDate ? Utils.getDateString(state.subscriptionData.expiryDate) : "N/A"}</div>
                </Column>
            </Row>

        </div>
    </div>);


};

const getInvoiceView = (props) => {
    Utility.consoleLogger("getInvoiceView", props.state.invoiceList);
    let {state} = props;

    return (<div className="pt-4 pb-3 pl-5">
        <Row className="fs-15 justify-content-between" horizontal={"start"}>
            <Column>Invoices</Column>
            <Column>
                <Button className="outline-none text-transform-capitalize p-2
                    br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer"
                        onClick={() => props.toggleAddDocumentDialogOpen()}>
                    + Upload a New Invoice
                </Button>
            </Column>
        </Row>
        {AddInvoiceComponent(props)}
        <Table>
            {InvoiceTableHead(props)}
            <TableBody>
                {state && state.invoiceList && state.invoiceList.length !== 0 ? state.invoiceList.map((invoiceData, index) => (
                    RenderInvoiceCell(props, invoiceData)
                )) : <Row className='fs-14' justifyContent={"center"}>No Invoices Found!</Row>
                }
            </TableBody>
        </Table>
    </div>);


};

const InvoiceTableHead = (props) => {
    return (
        <TableHead>
            <TableRow style={{padding: 20}}>
                <TableCell className='fs-14 pl-0'>Invoice Number</TableCell>
                <TableCell className='fs-14 pl-0'>Issue Date</TableCell>
                <TableCell className='fs-14 pl-0'>Amount</TableCell>
                <TableCell className='fs-14'></TableCell>
                <TableCell className='fs-14'></TableCell>
            </TableRow>
        </TableHead>
    )
};

const RenderInvoiceCell = (props, invoiceData) => {
    return (
        <TableRow style={{padding: 20}}>
            <TableCell className='fs-14 pl-0'>{invoiceData.invoiceNumber}</TableCell>
            <TableCell className='fs-14 pl-0'>{Utils.getDateString(invoiceData.issueDate)}</TableCell>
            <TableCell className='fs-14 pl-0'>{invoiceData.price}</TableCell>
            <TableCell className='fs-14'></TableCell>
            <TableCell className='fs-14 pl-0'>
                <Row className="cursor-pointer fc-blue"
                     onClick={() => props.viewInvoice(invoiceData)}>View Receipt</Row>
            </TableCell>

        </TableRow>
    )
};

const AddInvoiceComponent = (props) => {
    let {state, toggleAddDocumentDialogOpen, userDetails, company} = props;
    return (
        <Column className="my-4 w-350" vertical="start">
            {state.isAddDocumentDialogOpen ?
                <UploadDocument
                    isInvoiceUploadFlow={true}
                    isAddDocumentDialogOpen={state.isAddDocumentDialogOpen}
                    recordDetails={state.recordDetails}
                    userDetails={userDetails}
                    company={company}
                    toggleAddDocumentDialogOpen={toggleAddDocumentDialogOpen}
                /> : ""}
        </Column>);
};

const counselDetailsComponent = (props) => {
    return (
        <Column className="min-vh-100 p-4 fc-dark-slate-blue" style={{maxHeight: props.height}}>
            {HeaderComponent(props)}
            <Row className="justify-content-between">
                {InformationComponent(props)}
            </Row>
        </Column>
    )
};
export default counselDetailsComponent;
