import {httpConstants} from "../constants";
import {httpService} from "../managers/httpService";

/**
 * Created by Ratnesh Chandra on 11/02/2020.
 */

export default {
    publishRecord,
    publishRequest,
    getPublishRequestList,
    getPublishHistory,
    getPublishRequestListByCompany,
    getPublishRequestDetails,
    deletePublishRequest
}

async function publishRecord(requestData) {
    let url = process.env.REACT_APP_PUBLISH_RECORD_SERVICE_URL + httpConstants.API_END_POINT.PUBLISH;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getPublishRequestList(requestData) {
    let url = process.env.REACT_APP_PUBLISH_RECORD_SERVICE_URL + httpConstants.API_END_POINT.PUBLISH_REQUEST_LIST;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getPublishRequestListByCompany(requestData) {
    let url = process.env.REACT_APP_PUBLISH_RECORD_SERVICE_URL + httpConstants.API_END_POINT.PUBLISH_REQUEST_LIST_BY_COMPANY;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function publishRequest(requestData) {
    let url = process.env.REACT_APP_PUBLISH_RECORD_SERVICE_URL + httpConstants.API_END_POINT.PUBLISH_REQUEST;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getPublishRequestDetails(requestData) {
    let url = process.env.REACT_APP_PUBLISH_RECORD_SERVICE_URL + httpConstants.API_END_POINT.PUBLISH_REQUEST+"/" +requestData.publishRequestId;
    return httpService(httpConstants.METHOD_TYPE.GET, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function deletePublishRequest(requestData) {
    let url = process.env.REACT_APP_PUBLISH_RECORD_SERVICE_URL + httpConstants.API_END_POINT.PUBLISH_REQUEST+"/" +requestData.publishRequestId;
    return httpService(httpConstants.METHOD_TYPE.DELETE, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getPublishHistory(requestData) {
    let url = process.env.REACT_APP_PUBLISH_RECORD_SERVICE_URL + httpConstants.API_END_POINT.STATE_PUBLISH;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
