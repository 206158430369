import React from "react";
const SectionCellComponent = (props) => {
    let sectionData = {
        "name": "I. General Information",
        "assignedTo": "I. General Information",
        "status": "In-Progress",
    };
    return (
        <div className='display-flex flex-direction-row justify-content-between h-50px'>
            <div className="flex-3 display-flex flex-row">{sectionData.name}</div>
            <div className="flex-2 display-flex flex-row">{sectionData.assignedTo}</div>
            <div className="flex-2 display-flex flex-row">{sectionData.status}</div>
        </div>
    )
};

export default SectionCellComponent;