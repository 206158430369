import React from "react";
import BaseComponent from '../baseComponent'
import RecoverPasswordComponent from './recoverPasswordComponent'
import Utils, {dispatchAction} from "../../utility";
import {eventConstants, pathConstants} from "../../constants";
import {UserService} from "../../services";
import {history} from "../../managers/history";
import connect from "react-redux/es/connect/connect";

class RecoverPassword extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            emailError: "",
            isRecoveryEmailSent: false,
        }
    }

    componentDidMount() {

    }

    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value});

        if (!Utils.isEmpty(this.state.emailError))
            this.validateRecoveryForm();
    };

    togglePassword = (event) => {
        this.setState({isPasswordVisible: !this.state.isPasswordVisible});
    };


    validateRecoveryForm = () => {
        this.setState({
            emailError: Utils.validateEmail(this.state.email) ? "" : (Utils.isEmpty(this.state.email) ? "Please enter email address" : "Invalid email address")
        });
        return Utils.validateEmail(this.state.email);
    };

    requestPassword = async (event) => {
        event.preventDefault();

        if (!this.validateRecoveryForm())
            return;

        this.props.dispatchAction(eventConstants.SHOW_LOADER);

        let requestData = {};
        requestData.emailID = this.state.email;
        requestData.roleTitle = "FORGOT_PASSWORD";
        let [error, response] = await Utils.parseResponse(UserService.recoverPassword(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);

        if (error) {
            Utils.consoleLogger("error", error);
            Utils.apiFailureToast(error && error.message && error.message.length !== 0 && error.message[0] && error.message[0].msg ? error.message[0].msg : "Unable to login")
            return
        } else
            this.setState({isRecoveryEmailSent: true});

    };

    render() {
        return (
            <RecoverPasswordComponent state={this.state}
                                      onChangeEvent={this.onChangeEvent}
                                      togglePassword={this.togglePassword}
                                      requestPassword={this.requestPassword}/>
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user}
};

export default connect(mapStateToProps, {dispatchAction})(RecoverPassword);
