import React from 'react'
import {Column, Row} from "simple-flexbox";
import {stringConstants} from "../../constants";


function ActivationStatus(props) {
    return (
        <Column className="w-600 pt-4 justify-content-center align-items-center text-center" horizontal={'center'}>
            <Column className="w-100">
                <img src="/images/activation_pending.svg" alt='pending-logo' className="fw-500 m-2 fc-dark-slate-blue"/>
                <Row className="m-3 fs-28 fc-dark-slate-blue fw-500 justify-content-center">
                    {stringConstants.ACTIVATION_PENDING_HEADER}
                </Row>
                <Row className="fs-18 mx-4 justify-content-center my-4 fc-chambray">
                    {stringConstants.ACTIVATION_PENDING_DETAIL}
                </Row>
                <Row className="fs-18 justify-content-center fc-chambray ">
                    You can contact&nbsp;{<p className="fc-black">{stringConstants.SUPPORT_EMAIL}</p>}&nbsp;in case you
                    have any questions.
                </Row>

            </Column>
        </Column>
    );
}

function DownloadPrivateKey(props) {
    return (
        <Column className="w-600 pt-5 justify-content-center align-items-center text-center" horizontal={'center'}>
            <Column className="w-100">
                <Row className="m-3 fs-28 fc-dark-slate-blue fw-500 justify-content-center">
                    {stringConstants.DOWNLOAD_PRIVATE_KEY}
                </Row>

            </Column>
        </Column>
    );
}

export default function ActivationStatusComponent(props) {
    return (
        <div className="w-100 p-3 min-vh-100 ">
            <img src="/images/limb-logo.svg" alt='limb-logo' className="w-150"/>
            <Column horizontal={'center'} vertical={'center'} className="mt-5 jc-space-between">
                {(!props.isActivated) ? ActivationStatus(props) : DownloadPrivateKey(props)}
            </Column>
        </div>
    );
};