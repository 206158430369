import {cookiesConstants, eventConstants} from "../constants";
import sessionManager from "../managers/sessionManager";
import Utils from "../utility";


const requestData = sessionManager.getDataFromCookies(cookiesConstants.PUBLISH_REQUEST);

export default function user(state = requestData || {}, action) {
    switch (action.type) {
        case eventConstants.REVIEW_PUBLISH_REQUEST:
            sessionManager.setDataInCookies(action.data, cookiesConstants.PUBLISH_REQUEST);
            return action.data;
        case eventConstants.LOGOUT_SUCCESS:
            sessionManager.setDataInCookies(null, cookiesConstants.PUBLISH_REQUEST);
            return null;
        default:
            return state;
    }
}