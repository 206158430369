import {Column, Row} from "simple-flexbox";
import React from "react";
import {Button, Select, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import CustomSelectInput from "../../../common/components/CustomSelectInput";
import MenuItem from "@material-ui/core/MenuItem";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import {genericConstants, pathConstants, stringConstants} from "../../../constants";
import Utility from "../../../utility";
import Notification from "../../notification";
import {history} from "../../../managers/history";

const SearchFilterComponent = (props) => {
    return (
        <Column>
            <Row className="bg-color-white fs-12 mt-4" vertical="center">
                <Row className="br-2 bg-pale-grey p-2 b-1-pale-grey-three">
                    <input placeholder={'Search Request'} id="searchText" value={props.state.searchText}
                           onChange={e => props.onSearchTextChanged(e)}
                           className="outline-none b-none fc-bluey-grey bg-pale-grey fw-500"/>
                    <img className="mx-2" src="/images/search-icon.svg" alt="search"/>
                </Row>
            </Row>
        </Column>
    )
};

const PublishRequestTableComponent = (props) => {
    const {handlePageChange, state, roleType} = props;
    const {filteredList: counselList} = state
    return (
        <div className="my-2 overflow-x-auto">
            {state.data && state.data.length > 0 ?
                <Column className="bg-white h-100-per fs-15">
                    <Table>
                        {getRoleBasedRecordTableHeader(roleType, props)}
                        <TableBody>
                            {counselList.length !== 0 ? counselList.map((counsels, index) => (

                                getRoleBasedRecordTableBody(roleType, props, counsels, index)
                            )) : null
                            }
                        </TableBody>
                    </Table>

                </Column>
                : <div className="p-5 mx-4 mb-4 jc-center flex-container bg-color-white">
                    {'No Request Found'}
                </div>}
            {state.filteredList && state.filteredList.length > 0 ?
                <div>
                    <Pagination style={{marginTop: 20}}
                                onChange={(pn) => handlePageChange(pn)}
                                current={state.activePage}
                                total={state.filteredList ? state.filteredList.length : 0}
                                pageSize={10}
                    />
                </div>
                :
                ""}
        </div>
    );
};


const getRoleBasedRecordTableHeader = (role, props) => {
    const {state} = props;
    return (<TableHead>
        <TableRow style={{padding: 20}}>

            <TableCell className='fs-14'>
                <Row>
                    File Number
                </Row>

            </TableCell>
            <TableCell className='fs-14'>
                <Row>
                    Loan Tag
                </Row>

            </TableCell>
            <TableCell className='fs-14'
                // onClick={() => props.sorting(state.counselList, 'invitationStatus')}
            >
                <Row>
                    Location
                </Row>
            </TableCell>
            <TableCell className='fs-14'>
                <Row>
                    Request Date
                </Row>
            </TableCell>
            <TableCell className='fs-14'>
                <Row>
                    Status
                </Row>
            </TableCell>
            <TableCell></TableCell>
        </TableRow>
    </TableHead>);
};
const getRoleBasedRecordTableBody = (role, props, counsels, index) => {
    const {onReviewClick, onClearButtonClick} = props;
    return (<TableRow className='h-60px' key={index}>
        <TableCell className='fs-15 fc-blue-grey'>{counsels.fileNo}</TableCell>
        <TableCell className='fs-15 fc-blue-grey'>{counsels.loanTag}</TableCell>
        <TableCell
            className='fs-15 fc-blue-grey'>{counsels.address.city + ", " + counsels.address.state}</TableCell>
        <TableCell
            className='fs-15 fc-blue-grey'>{Utility.epochToDate(counsels.addedOn, "MM-DD-YYYY")}</TableCell>
        <TableCell
            className='fs-15 fc-blue-grey'>
            {counsels.status === genericConstants.RECORD_STATUS.PUBLISHED ?
                <div
                    className="text-transform-capitalize  outline-none fs-15 fc-green mx-1 fw-500 ">
                    Published
                </div> : (counsels.status === genericConstants.RECORD_STATUS.REJECTED) ?
                    <div
                        className=" text-transform-capitalize   outline-none fs-15 fc-red mx-1 fw-500 ">
                        Rejected
                    </div> :
                    <div
                        className=" text-transform-capitalize   outline-none fs-15  fc-blue mx-1 fw-500 ">
                        Pending
                    </div>}
        </TableCell>
        <TableCell>
            {counsels.status !== genericConstants.RECORD_STATUS.PUBLISHED ?
                <Button
                    className="text-transform-capitalize p-2 b-1-red outline-none fs-15 br-4 mx-1 fw-500 px-3 cursor-pointer fc-red"
                    onClick={() => onClearButtonClick(counsels)}> Clear
                </Button> : null}
            {counsels.status === genericConstants.RECORD_STATUS.REJECTED ?
                <Button
                    className="text-transform-capitalize p-2 b-1-blue outline-none fs-15 br-4  fc-blue mx-1 fw-500 px-3 cursor-pointer"
                    onClick={() => onReviewClick(counsels)}> Detail
                </Button> : null}
        </TableCell>
    </TableRow>);
};
const headerComponent = (props) => {

    return (
        <Row className='justify-content-between'>
            <div className="fs-24 font-weight-bold display-flex flex-row">{'Blockchain Publish Requests'}</div>
            <Row className="justify-content-between">
                <Row>
                    <Notification/>
                </Row>
            </Row>
        </Row>
    )
};

const publishRequestComponent = (props) => {

    return (
        <Column className="min-vh-100 p-4 fc-dark-slate-blue" style={{maxHeight: props.height}}>
            {headerComponent(props)}
            {SearchFilterComponent(props)}
            {PublishRequestTableComponent(props)}
        </Column>
    )
};
export default publishRequestComponent;