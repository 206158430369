import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Utility from "../../utility";

const RangeSelector = (props) => {
    return (
        <div className="w-100-per">
            <Slider defaultValue={props.value} min={props.minValue} max={props.maxValue}
                   onChange={props.onChange} allowCross={props.allowCross} pushable={props.pushable}
                   step={props.step} dots={props.dots} marks={props.marks}
            />
        </div>

    );
};

export default RangeSelector;
