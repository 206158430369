import React from "react";
import BaseComponent from '../baseComponent'
import LoginComponent from './loginComponent'
import Utils, {dispatchAction} from "../../utility";
import {eventConstants, pathConstants} from "../../constants";
import {UserService} from "../../services";
import {history} from "../../managers/history";
import connect from "react-redux/es/connect/connect";

class Login extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            emailError: "",
            password: "",
            passwordError: "",
            isPasswordVisible: false,
        }
    }

    componentDidMount() {

    }

    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };

    togglePassword = (event) => {
        this.setState({isPasswordVisible: !this.state.isPasswordVisible});
    };


    validateLoginForm = () => {
        this.setState({
            emailError: Utils.validateEmail(this.state.email) ? "" : "Invalid email address",
            passwordError: Utils.isPasswordValid(this.state.password) ? "" : "Password should contain 1 special character"
        });
        return Utils.validateEmail(this.state.email) && Utils.isPasswordValid(this.state.password);
    };

    onLoginClicked = async (event) => {
        event.preventDefault();

        if (!this.validateLoginForm())
            return;

        this.props.dispatchAction(eventConstants.SHOW_LOADER);

        let requestData = {};
        requestData.emailID = this.state.email;
        requestData.password = this.state.password;
        requestData.deviceID = this.props.user.deviceID;
        let [error, response] = await Utils.parseResponse(UserService.loginUser(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);

        if (error) {
            Utils.consoleLogger("error", error);
            Utils.apiFailureToast(error && error.message && error.message.length !== 0 && error.message[0] && error.message[0].msg ? error.message[0].msg : "Unable to login")
            return
        }
        this.props.dispatchAction(eventConstants.SIGN_UP_SUCCESS, response);
        await this.getUserPermissions(response);
        history.replace(pathConstants.COMPANY);
    };

    getUserPermissions = async (userData) => {

        if (!userData || Object.keys(userData).length < 1 || !userData.userDetails || Object.keys(userData.userDetails).length < 1)
            return;
        let roleTittle = Utils.getUserRole(userData.userDetails);
        let roleLevel = Utils.getUserRoleLevel(userData.userDetails);

        if (!roleTittle || !roleLevel)
            return;

        let roleID = Utils.getUserRoleID(roleTittle, roleLevel);
        let requestData = {
            roleID: roleID
        };
        let [error, response] = await Utils.parseResponse(UserService.getUserPermissions(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error) {
            Utils.consoleLogger("error", error);
            Utils.apiFailureToast(error && error.message && error.message.length !== 0 && error.message[0] && error.message[0].msg ? error.message[0].msg : "Unable to login")
            return
        }
        console.log("response in getUserPermissions", response);
        this.props.dispatchAction(eventConstants.GET_USER_PERMISSION_SUCCESS, response);
    };

    render() {
        return (
            <LoginComponent
                state={this.state}
                onChangeEvent={this.onChangeEvent}
                togglePassword={this.togglePassword}
                onLoginClicked={this.onLoginClicked}/>
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user}
};

export default connect(mapStateToProps, {dispatchAction})(Login);
