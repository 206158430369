import React from "react";
import Avatar from '@material-ui/core/Avatar';

const AvatarView = (props) => {
    return (
        <Avatar alt="avatar" src={props.source} className={props.class}/>
    )
};

export default AvatarView;
