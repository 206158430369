/**
 * Created by rahul on 1/23/19.
 */
import AWS from "aws-sdk";
import Utils from '../utility';

const bucketName = process.env.REACT_APP_S3_BUCKET_NAME;
const awsExpiryTime = typeof process.env.REACT_APP_AWS_EXPIRY_TIME === 'string'? parseInt(process.env.REACT_APP_AWS_EXPIRY_TIME) :process.env.REACT_APP_AWS_EXPIRY_TIME;
let accessKey = Utils.decodeBase64Key(process.env.REACT_APP_ACCESS_KEY);
let secretAccessKey = Utils.decodeBase64Key(process.env.REACT_APP_SECRET_KEY);

export const uploadFileToS3 = (fileObject, fileName, mimeType, isPublic = false, content = null) => {
    AWS.config.update({
        accessKeyId: accessKey,
        secretAccessKey: secretAccessKey
    });

    // create S3 instance
    const S3 = new AWS.S3();

    const params = {
        ServerSideEncryption: "AES256",
        Body: fileObject,
        Bucket: bucketName,
        ContentType: mimeType,
        Key: fileName
    };

    if (isPublic)
        params.ACL = 'public-read';

    if (content) {
        params.ContentEncoding = 'base64';
        params.Body = new Buffer(params.Body.replace(/^data:image\/\w+;base64,/, ""), 'base64')
    }
    return new Promise(function (resolve, reject) {
        S3.upload(params, function (err, res) {
            let versionId = '';
            if (err){
                reject({code:err.code});
            }
            let params = {
                Bucket: bucketName,
                Prefix: fileName,
                MaxKeys: 1
            };
            S3.listObjectVersions(params, function (err, data) {
                if (err) {
                    Utils.consoleLogger("S3 Error ",err);
                    reject({code:err.code});
                }
                // an error occurred
                else {
                    if (data && data.Versions && data.Versions.length && data.Versions[0].VersionId)
                        versionId = data.Versions[0].VersionId;
                }
                let response = {
                    ...res,
                    versionId: versionId
                    //url: getSignedURL(res.Key)
                };
                resolve(response);

            })
        });
    });
};

export const getSignedURL = (key, placeholderPath, expiryTime = awsExpiryTime) => {
    if (Utils.isEmpty(key))
        return placeholderPath;
    AWS.config.update({
        accessKeyId: accessKey,
        secretAccessKey: secretAccessKey
    });

    // create S3 instance
    const S3 = new AWS.S3({logger: console, "signatureVersion": "v4"});
    const params = {
        Bucket: bucketName,
        Key: key
    };
    
    try {
        // S3.headObject(params).promise();
        return S3.getSignedUrl('getObject', {...params,Expires : expiryTime});
    }
    catch (e) {
        return placeholderPath;
    }

};

export const getImageBuffer = async (key) => {
    AWS.config.update({
        accessKeyId: accessKey,
        secretAccessKey: secretAccessKey
    });

    // create S3 instance
    const S3 = new AWS.S3({logger: console});
    const params = {
        Bucket: bucketName,
        Key: key
    };
    return new Promise(function (resolve, reject) {

        S3.getObject(params, function (err, data) {
            if (err)
                reject(err);
            let file = new Buffer(data.Body, 'binary');
            let imageBuffer = file.toString('base64');
            resolve(imageBuffer);
        });
    });
}
