import React from "react";
import {Column} from "simple-flexbox";
import {genericConstants} from "../../constants";

const sortingComponent = (props) => {
    let {id, state, onDescendingSort, onAscendingSort} = props;

    return (
        <Column className="p-2">
            <img onClick={onAscendingSort} className="cursor-pointer"
                 src={state === genericConstants.SORTING_ORDER.ASCENDING ? '/images/sort-arrow-up-active.svg' : '/images/sort-arrow-up-inactive.svg'}/>
            <img onClick={onDescendingSort} className="cursor-pointer"
                 src={state === genericConstants.SORTING_ORDER.DESCENDING ? '/images/sort-arrow-down-active.svg' : '/images/sort-arrow-down-inactive.svg'}/>

        </Column>)
};

export default sortingComponent;