import React from "react";
import BaseComponent from '../../baseComponent'
import RejectedRecordComponent from './RejectedRecordComponent'
import Utils, {dispatchAction} from "../../../utility";
import {PublishRecordService} from "../../../services";
import {eventConstants} from "../../../constants";
import {connect} from "react-redux";

class RejectedRecord extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            token: null,
            password: null,
            recordDetails: null,
            isHistoryRequested: false,
            recordId: null
        }
    }

    componentDidMount() {
        let pathName = window.location.pathname;
        let pathArray = pathName.split('/');
        if (pathArray && pathArray.length === 4) {
            this.getPublishRequestDetails(pathArray[pathArray.length - 1]);
        }
    };

    getPublishRequestDetails = async (publishRequestId) => {
        if (!publishRequestId)
            return Utils.apiFailureToast("Unable to get rejection details");
        let requestObj = {
            publishRequestId: publishRequestId
        };
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, publishDetails] = await Utils.parseResponse(PublishRecordService.getPublishRequestDetails(requestObj));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);

        if (error) {
            Utils.apiFailureToast("Unable to get rejection details");
            return;
        }
        this.setState({recordDetails: publishDetails});
    };


    render() {
        return (
            <RejectedRecordComponent state={this.state} recordDetails={this.state.recordDetails}/>
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, company: state.company}
};

export default connect(mapStateToProps, {dispatchAction})(RejectedRecord);