import {httpConstants} from "../constants";

/**
 * Created by Sunny Kumar on 17/01/2020.
 */

export default {
    createReport,

}

async function createReport(requestData) {
    let url = process.env.REACT_APP_REPORT_SERVICE_URL + httpConstants.API_END_POINT.CREATE_REPORT + `/${requestData.type}` + `/${requestData.recordId}`;
    try {
        return downloadURI(url, `${requestData.fileName}.pdf`);
    }
    catch (err) {
       return Promise.reject(err);

    }

}
function downloadURI (url, name) {
    let link = document.createElement('a');
    link.download = name;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}