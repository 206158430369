import React from "react";
import {Column, Row} from "simple-flexbox";
import Utils from "../../utility";

const NotificationCellComponent = (notificationObj, index) => {
    return (
        <Column key={index} className="p-3 w-100 border-bottom-pale-grey-four">
            <Column flex="3" className="fs-13 fc-brownish-grey" vertical="start">
                {Utils.epocToPrettyTime(notificationObj.addedOn)}
            </Column>
            <Column flex="7" className={(notificationObj.isRead ? "fc-brownish-grey" : "fw-500") + " py-1 fs-15"} vertical="center"
                    style={{wordBreak: 'break-word'}}>
                {notificationObj.title}
            </Column>
        </Column>
    );
};

function NotificationListComponent(props) {
    let {state, notificationList} = props;

    return (
        <div className="p-4 m-2 h-100 overflow-y-auto">
            <Row className="fc-dark-slate-blue fs-24 mb-4 font-weight-bold">All Notifications</Row>
            {notificationList && notificationList.length > 0 ? notificationList.map((notificationObj, index) => {
                    return index >= 5 ? null : NotificationCellComponent(notificationObj, index)
                })
                : <div className="px-3 py-5 justify-content-center display-flex">
                    {state && state.isLoading ? "Loading..." : "No new Notification"}
                </div>
            }
        </div>
    );
}

export default NotificationListComponent;
