import React from "react";
import BaseComponent from '../baseComponent'
import RegisterSubUserComponent from './registerSubUserComponent'
import Utils, {dispatchAction} from "../../utility";
import {CompanyService, UserService} from "../../services";
import connect from "react-redux/es/connect/connect";
import {eventConstants, pathConstants, stringConstants} from "../../constants";
import {history} from "../../managers/history";

const base64 = require('base-64');

class RegisterSubUser extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            token: this.getSecurityToken(),
            firstName: "",
            newPassword: "",
            newPasswordError: "",
            confirmPassword: "",
            confirmPasswordError: "",
            selectedRole: {},
            rolesList: []
        }
    }

    getSecurityToken = () => {
        const urlParams = window.location.search;
        let payload = urlParams.substring(7);
        if (!payload) {
            history.replace(pathConstants.LOGIN);
            return;
        }
        try {
            payload = base64.decode(payload);
            return payload;
        } catch (error) {
            Utils.consoleLogger('Class: RegisterSubUser, Function: getSecurityToken ==', error);
            return null;
        }
    };

    async componentDidMount() {
        this.props.dispatchAction(eventConstants.SHOW_LOADER);

        let requestObj = {};
        requestObj.queryObj = JSON.stringify({'security.token': this.state.token});
        requestObj.skip = 0;
        requestObj.limit = 1;

        let [error, userList] = await Utils.parseResponse(UserService.getUserListByQueryObj(requestObj));

        this.props.dispatchAction(eventConstants.HIDE_LOADER);

        if (error || !userList || userList.length === 0 || !userList[0] || !userList[0].security || userList[0].security.isExpire) {
            Utils.apiFailureToast(stringConstants.INVITATION_LINK_EXPIRED);
            history.replace('/');
            return;
        }
        this.setState({firstName: userList[0].firstName});
    }

    resetPassword = async () => {
        this.props.dispatchAction(eventConstants.SHOW_LOADER);

        let requestData = {};
        requestData['userToken'] = this.state.token;
        requestData['newPassword'] = this.state.newPassword;

        let [error, userDetails] = await Utils.parseResponse(UserService.resetPassword(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        history.replace('/');
        if (error || !userDetails || userDetails.length === 0) {
            Utils.apiFailureToast(stringConstants.INVITATION_LINK_EXPIRED);
            return;
        }
        Utils.apiSuccessToast(stringConstants.ACCOUNT_CREATED_SUCCESSFULLY);
    };

    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value, [`${event.target.id}Error`]: ''});
    };

    validateForm = () => {
        this.setState({
            newPasswordError: Utils.isEmpty(this.state.newPassword) ? stringConstants.EMPTY_PASSWORD :
                (Utils.isPasswordValid(this.state.newPassword) ? "" : stringConstants.PASSWORD_VALIDATION),
            confirmPasswordError: Utils.isEmpty(this.state.confirmPassword) ? stringConstants.EMPTY_PASSWORD :
                (Utils.isPasswordValid(this.state.confirmPassword) ? (this.state.newPassword === this.state.confirmPassword ? "" : stringConstants.PASSWORD_DO_NOT_MATCH) :
                    stringConstants.PASSWORD_VALIDATION)
        });
        return Utils.isPasswordValid(this.state.newPassword)
            && Utils.isPasswordValid(this.state.confirmPassword) && this.state.newPassword === this.state.confirmPassword;
    };

    handlePlanSelection = (selectedPLan) => {
        this.setState({selectedRole: selectedPLan})
    };

    onCreateAccountClicked = async (event) => {
        event.preventDefault();

        if (!this.validateForm())
            return;

        await this.resetPassword();
    };

    render() {
        return (
            <RegisterSubUserComponent state={this.state}
                                      onChangeEvent={this.onChangeEvent}
                                      handlePlanSelection={this.handlePlanSelection}
                                      onCreateAccountClicked={this.onCreateAccountClicked}/>
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user}
};

export default connect(mapStateToProps, {dispatchAction})(RegisterSubUser);
