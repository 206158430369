import {Column, Row} from 'simple-flexbox';
import React, {useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import CustomSelectInput from "../../common/components/CustomSelectInput";
import headerComponent from "../../common/components/CustomHeader";
import ConfirmationPopUp from "../../common/components/ConfirmationPopUp";
import SortingComponent from "../../common/components/SortingComponent";
import CustomInput from "../../common/components/CustomInput";
import {genericConstants} from "../../constants";
import {stringConstants} from "../../constants";
import Utility from "../../utility";
import NoteDialogComponent from "../../common/components/NoteDialogComponent";

const SearchFilterComponent = (props) => {
    return (
        <Row className="bg-color-white fs-15 mt-4" vertical="center">
            <Row className="br-2 bg-pale-grey p-2 b-1-pale-grey-three">
                <input placeholder={'Search User'} id={'search'} onChange={props.onSearch}
                       className="outline-none b-none fc-bluey-grey bg-pale-grey fw-500"/>

                <img className="mx-2" src="/images/search-icon.svg" alt="search"/>
            </Row>
            <Select value={props.state.roleFilter} className="br-2 mx-2"
                    id="roleFilter" input={<CustomSelectInput name="roleFilter"/>}
                    onChange={props.onFilterChanged}>

                <option className="p-2 cursor-pointer outline-none" value="All">
                    All Roles
                </option>
                <option className="p-2 cursor-pointer outline-none" value={10}>Level 1</option>
                <option className="p-2 cursor-pointer outline-none" value={20}>Level 2</option>
                <option className="p-2 cursor-pointer outline-none" value={30}>Level 3</option>
            </Select>
            <Select className="br-2 mx-2" placeholder="Select Values" value={props.state.statusFilter}
                    id="statusFilter" input={<CustomSelectInput name="statusFilter"/>}
                    onChange={props.onFilterChanged}>
                <option className="p-2 cursor-pointer outline-none" value={"All"}>
                    All Status
                </option>
                <option className="p-2 cursor-pointer outline-none" value={0}>Active</option>
                <option className="p-2 cursor-pointer outline-none" value={1}>Inactive</option>
            </Select>
        </Row>
    )
};

function getUserRole(userDetails) {
    let userRole = Utility.getUserRole(userDetails);
    switch (userRole) {
        case genericConstants.ROLES.LENDER:
            return genericConstants.ROLES.SUB_LENDER;
        case genericConstants.ROLES.ORIGINATION_COUNSEL:
            return genericConstants.ROLES.SUB_ORIGINATION_COUNSEL;
        case genericConstants.ROLES.SELLER_COUNSEL:
            return genericConstants.ROLES.SUB_SELLER_COUNSEL;
        default:
            return false;
    }
}

function getRequestData(userDetails, subUserObject) {
    let requestData = {};
    requestData.firstName = subUserObject.firstName;
    requestData.emailID = [];
    requestData.emailID.push(subUserObject.emailID);
    requestData.role = getUserRole(userDetails);
    requestData.invitedBy = JSON.stringify(Utility.getMiniUserModel(userDetails));
    requestData.miscellaneous = {};
    requestData.miscellaneous.company = userDetails.miscellaneous.company;
    requestData.miscellaneous.roleLevel = subUserObject.miscellaneous && subUserObject.miscellaneous.roleLevel ?
        subUserObject.miscellaneous.roleLevel : subUserObject.roleLevel;
    requestData.miscellaneous.address = subUserObject.location ? subUserObject.location : "";

    requestData.sentFromEmail = userDetails.emailID;
    requestData.sentFromName = userDetails.miscellaneous.company ? userDetails.miscellaneous.company.name : userDetails.firstName;
    requestData.subject = (userDetails.miscellaneous.company ? userDetails.miscellaneous.company.name : "Company") + " invited you to join LIMBCRE";
    requestData.message = (userDetails.miscellaneous.company ? userDetails.miscellaneous.company.name : "Company") + " invited you to join LIMBCRE";

    return requestData;
}

function getRequestDataForUpdate(userDetails, subUserObject) {
    let requestData = {...userDetails};
    requestData.firstName = subUserObject.firstName;
    requestData.emailID = subUserObject.emailID;
    requestData.miscellaneous.address = subUserObject.location ? subUserObject.location : "";
    requestData.miscellaneous.roleLevel = subUserObject.roleLevel ? subUserObject.roleLevel : "";

    return requestData;
}

const AddSubUserComponent = (props) => {
    let {toggleAddUserDialog, onCreateUserClicked, onUpdateUserClicked, state, userDetails} = props;
    let [subUserObject, updateSubUser] = React.useState(state.subUserObjectForEdit && Object.keys(state.subUserObjectForEdit).length > 0 ? state.subUserObjectForEdit : {
        name: '',
        email: '',
        roleLevel: ''
    });
    let [errorSubUserObject, errorUpdateSubUser] = React.useState({
        name: '',
        email: '',
        roleLevel: ''
    });
    React.useEffect(() => {
        updateSubUser(
            {
                emailID: state.subUserObjectForEdit.emailID ? state.subUserObjectForEdit.emailID : "",
                firstName: state.subUserObjectForEdit.firstName ? state.subUserObjectForEdit.firstName : "",
                location: state.subUserObjectForEdit.miscellaneous && state.subUserObjectForEdit.miscellaneous.address ? state.subUserObjectForEdit.miscellaneous.address : "",
                roleLevel: state.subUserObjectForEdit.miscellaneous && state.subUserObjectForEdit.miscellaneous.roleLevel ? state.subUserObjectForEdit.miscellaneous.roleLevel : ""
            })
    }, [state.subUserObjectForEdit]);

    function onChangeEvent(event) {
        updateSubUser({
            ...subUserObject,
            [event.target.name]: event.target.value
        });
        errorUpdateSubUser({
            ...errorSubUserObject,
            [event.target.name]: ''
        })
    }

    function validation() {
        let email = Utility.isEmpty(subUserObject.emailID) ? stringConstants.EMPTY_EMAIL :
            (!Utility.validateEmail(subUserObject.emailID) ? stringConstants.INVALID_EMAIL : '');
        let roleLevel = Utility.isEmpty(subUserObject.roleLevel) ? stringConstants.EMPTY_ROLE_LEVEL : '';
        let name = Utility.isEmpty(subUserObject.firstName) ? stringConstants.EMPTY_NAME : '';
        let location = Utility.isEmpty(subUserObject.location) ? stringConstants.EMPTY_LOCATION : '';
        errorUpdateSubUser({
            ...errorSubUserObject,
            firstName: name,
            emailID: email,
            roleLevel: roleLevel,
            location: location
        });

        return !!(name || email || roleLevel || location);
    }

    function onSubmitEvent(event) {
        event.preventDefault();
        if (validation())
            return;

        let requestData;
        if (state.subUserObjectForEdit && Object.keys(state.subUserObjectForEdit).length > 0) {
            requestData = getRequestDataForUpdate(state.subUserObjectForEdit, subUserObject);
            onUpdateUserClicked(requestData);
        } else if (getUserRole(userDetails)) {
            requestData = getRequestData(userDetails, subUserObject);
            onCreateUserClicked(requestData);
        } else Utility.apiFailureToast("Unable to invite user")

    }

    let dialogText = (state.subUserObjectForEdit && Object.keys(state.subUserObjectForEdit).length > 0) ? "Edit Counsel" : "Add Counsel";

    return (
        <div>
            <Dialog maxWidth={"sm"} open={state.isAddUserDialogOpen} fullWidth={true}>
                <form onSubmit={onSubmitEvent}>
                    <Column style={{width: '90%', margin: 'auto'}}>
                        <Row>
                            <div className="fs-20 fw-500 pt-4">{dialogText}</div>
                        </Row>
                        <Column style={{margin: '50px 0px'}}>
                            <label className="input-label">Full Name</label>
                            <CustomInput name='firstName' id='firstName' type="text" className="fs-15 p-2 my-1 mb-2"
                                         value={subUserObject.firstName}
                                         error={errorSubUserObject.firstName}
                                         onChange={onChangeEvent}/>
                            <label className="input-label">User Email</label>
                            <CustomInput name='emailID' id='emailID' type="text" className="fs-15 p-2 my-1 mb-2"
                                         value={subUserObject.emailID}
                                         error={errorSubUserObject.emailID}
                                         disabled={state.subUserObjectForEdit && Object.keys(state.subUserObjectForEdit).length > 0}
                                         onChange={onChangeEvent}/>
                            <label className="input-label">Location</label>
                            <CustomInput name='location' id='location' type="area" className="fs-15 p-2 my-1 mb-2"
                                         value={subUserObject.location}
                                         error={errorSubUserObject.location}
                                         onChange={onChangeEvent}/>
                            <label className="input-label">User Role</label>
                            <Select value={subUserObject.roleLevel} className="my-1 br-2 input-border" id="roleLevel"
                                    input={<CustomSelectInput name="roleLevel" id="roleLevel"/>}
                                    onChange={onChangeEvent}>
                                <MenuItem value={genericConstants.LEVEL_TYPE.LEVEL_1}>Level 1</MenuItem>
                                <MenuItem value={genericConstants.LEVEL_TYPE.LEVEL_2}>Level 2</MenuItem>
                                {Utility.getUserRole(userDetails) === genericConstants.ROLES.ORIGINATION_COUNSEL ?
                                    <MenuItem value={genericConstants.LEVEL_TYPE.LEVEL_3}>Level 3</MenuItem>
                                    : ""}
                            </Select>
                            <div className="display-flex fc-red fs-14 p-1">{errorSubUserObject.roleLevel}</div>
                        </Column>
                        <Row horizontal={"end"} className="mb-5">
                            <Button onClick={toggleAddUserDialog}
                                    className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two fc-blue mx-1 fw-500 px-3 cursor-pointer">Cancel
                            </Button>
                            <Button type={"submit"}
                                    className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                                {state.subUserObjectForEdit && Object.keys(state.subUserObjectForEdit).length > 0 ? "Edit" : "Create"}
                            </Button>
                        </Row>
                    </Column>
                </form>
            </Dialog>
        </div>
    )
};


const SubUserTableComponent = (props) => {
    let {state, onSortClicked, onResendClicked, userDetails, deleteSubUser, toggleAddSubUserDialog, toggleConfirmationPopUp} = props;
    let {filteredList: usersList} = state;
    let [sortingState, updateSortingState] = React.useState({fistName: null, isInactive: null});

    function onDescendingSort(key) {
        onSortClicked(key, genericConstants.SORTING_ORDER.DESCENDING);
        updateSortingState({[key]: genericConstants.SORTING_ORDER.DESCENDING});
    }

    function onAscendingSort(key) {
        onSortClicked(key, genericConstants.SORTING_ORDER.ASCENDING);
        updateSortingState({[key]: genericConstants.SORTING_ORDER.ASCENDING});
    }

    function onResendMailClicked(subUserObject) {
        let requestData = getRequestData(userDetails, subUserObject);
        onResendClicked(requestData);
    }

    return (
        <div className="my-4 overflow-x-auto">
            {usersList ?
                <Column className="bg-white h-100-per fs-15">
                    <Table size="small" className='br-1 p-1'>
                        <TableHead className="data-table fw-500">
                            <TableRow>
                                <TableCell component="th" className={'p-none'}>
                                    <Row vertical={"center"}>Counsel Name
                                        <SortingComponent state={sortingState.firstName}
                                                          onDescendingSort={() => onDescendingSort("firstName")}
                                                          onAscendingSort={() => onAscendingSort("firstName")}/>
                                    </Row>
                                </TableCell>
                                <TableCell component="th" className={'p-none'}>
                                    <div>Email</div>
                                </TableCell>
                                <TableCell component="th" className={'p-none'}>
                                    <div>Location</div>
                                </TableCell>
                                <TableCell component="th" className={'p-none'}>
                                    <div>Roles</div>
                                </TableCell>
                                <TableCell component="th" className={'p-none'}>
                                    <Row vertical={"center"}>Status
                                        <SortingComponent state={sortingState.isInactive}
                                                          onDescendingSort={() => onDescendingSort("isInactive")}
                                                          onAscendingSort={() => onAscendingSort("isInactive")}/> </Row>
                                </TableCell>
                                <TableCell component="th" className={'p-none'}>
                                    <div/>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='data-table'>
                            {usersList.map((userObject, index) => {
                                const isItemSelected = true;
                                const labelId = `roles-row-${index}`;
                                return (
                                    <TableRow role="checkbox" aria-checked={isItemSelected} tabIndex={-1}
                                              selected={false}
                                              className={"cursor-pointer " + userObject && userObject.isInactive ? "fc-dark-slate-blue" : " fc-blue-grey"}>
                                        <TableCell component="tr" id={labelId} scope="row"
                                                   padding="default">
                                            {userObject && userObject.firstName ? userObject.firstName : "-"}
                                        </TableCell>
                                        <TableCell component="tr" id={labelId} scope="row"
                                                   padding="default">
                                            {userObject && userObject.emailID ? userObject.emailID : "-"}
                                        </TableCell>
                                        <TableCell component="tr" id={labelId} scope="row"
                                                   padding="default">
                                            {userObject && userObject.miscellaneous && userObject.miscellaneous.address ? userObject.miscellaneous.address : "Not Available"}
                                        </TableCell>
                                        <TableCell component="tr" id={labelId} scope="row"
                                                   padding="default">
                                            {userObject && userObject.miscellaneous && userObject.miscellaneous.roleLevel ? Utility.viewRoleLevel(userObject.miscellaneous.roleLevel) : ""}
                                        </TableCell>
                                        <TableCell component="tr" id={labelId} scope="row"
                                                   padding="default">
                                            {userObject && userObject.isInactive ? 'Pending' : "Active"}
                                        </TableCell>
                                        <TableCell component="tr" id={labelId} scope="row"
                                                   padding="default">
                                            {userObject && userObject.isInactive ?
                                                <div>
                                                    <div
                                                        className="fc-red fs-14 fw-500 br-4 b-1-red p-1 display-inline cursor-pointer"
                                                        onClick={() => toggleConfirmationPopUp(userObject, stringConstants.RESEND, stringConstants.CONFIRM_RESEND_INVITATION)}>
                                                        Resend
                                                    </div>
                                                    <img className="cursor-pointer mx-3"
                                                         src="/images/delete-icon.svg"
                                                         onClick={() => toggleConfirmationPopUp(userObject, stringConstants.DELETE, stringConstants.CONFIRM_DELETE_INVITATION)}/>
                                                </div> :
                                                <div className="flex-column cursor-pointer justify-content-between">
                                                    <img src="/images/edit-icon.svg"
                                                         onClick={() => toggleAddSubUserDialog(userObject)}/>
                                                    <img className="cursor-pointer mx-3"
                                                         src="/images/delete-icon.svg"
                                                         onClick={() => toggleConfirmationPopUp(userObject, stringConstants.DELETE, stringConstants.CONFIRM_DELETE_INVITATION)}/>
                                                </div>}
                                        </TableCell>

                                    </TableRow>)
                            })}
                        </TableBody>
                    </Table>
                </Column>
                : <Row className="p-5 mx-4 my-4 justify-content-center bg-color-white">
                    No Record found !
                </Row>}


        </div>
    );
};

const subCounselComponent = (props) => {
    let {state, deleteSubUser, toggleConfirmationPopUp, onResendClicked} = props;
    let updatedProps = {
        componentName: 'Counsel Sub-Users',
        buttonName: 'Add Counsel',
        invitationButtonClicked: props.toggleAddUserDialog
    };

    function closePopup() {
        toggleConfirmationPopUp();
    }

    function deleteUser() {
        deleteSubUser(state.userObject)
    }

    function resendMail() {
        onResendClicked(state.userObject);
    }

    return (
        <Column className="min-vh-100 p-4 fc-dark-slate-blue" style={{maxHeight: props.height}}>
            {headerComponent(updatedProps)}
            {AddSubUserComponent(props)}
            {SearchFilterComponent(props)}
            {SubUserTableComponent(props)}
            {state.isConfirmationPopUpOpen ?
                <ConfirmationPopUp
                    isConfirmationPopUpOpen={state.isConfirmationPopUpOpen}
                    headerMessage={state.headerMessage}
                    closePopup={closePopup}
                    onYesClicked={state.functionality === stringConstants.DELETE ? deleteUser : resendMail}
                /> : ""}
        </Column>
    )
};

export default subCounselComponent;