import React from 'react'
import {Column, Row} from "simple-flexbox";
import {Button} from "@material-ui/core";
import {genericConstants, stringConstants} from "../../constants";
import CustomInput from "../../common/components/CustomInput";
import Dropzone from "react-dropzone";
import ProcessingLoader from "../../common/components/processingLoader";
import Utils from "../../utility";
import AvatarView from "../../common/components/AvatarView";
import AddressForm from "../../common/components/addressForm";
import Notification from "../notification";

const UpdateAddressComponent = (props) => {
    let {state, toggleChangeAddress, updateLatestCompanyAddress} = props;
    let [companyInfo, updateCompanyInfo] = React.useState({
        streetAddress: '',
        suite: '',
        city: '',
        state: '',
        country: ''
    });
    React.useEffect(() => {
        updateCompanyInfo(
            {
                streetAddress: state.companyData.address.streetAddress || "",
                suite: state.companyData.address.suite || "",
                city: state.companyData.address.city || "",
                state: state.companyData.address.state || "",
                country: state.companyData.address.country || "",
            })
    }, [state.companyData.address]);
    let [errorCompanyInfo, updateErrorCompanyInfo] = React.useState({
        streetAddress: '',
        suite: '',
        city: '',
        state: '',
        country: ''
    });

    function handleInputChange(event) {
        let targetElement = (event.target.id).split('.')[1] || event.target.id;
        updateCompanyInfo({...companyInfo, [targetElement]: event.target.value});
        updateErrorCompanyInfo({...errorCompanyInfo, [targetElement]: ''})
    }

    function validateAddressField() {
        let isAddressFieldValid = true;
        if (!companyInfo || Object.keys(companyInfo).length < 1) {
            isAddressFieldValid = false;
            updateErrorCompanyInfo({
                streetAddress: stringConstants.PLEASE_FILL_THIS_FIELD,
                suite: stringConstants.PLEASE_FILL_THIS_FIELD,
                city: stringConstants.PLEASE_FILL_THIS_FIELD,
                state: stringConstants.PLEASE_FILL_THIS_FIELD,
                country: stringConstants.PLEASE_FILL_THIS_FIELD,
            });
            return isAddressFieldValid;
        }
        let errorCompanyObj = Object.assign({}, errorCompanyInfo);
        for (let key in companyInfo) {
            if (key === 'suite')
                continue;
            if (companyInfo[key]) {
                errorCompanyObj[key] = "";
            } else {
                errorCompanyObj[key] = stringConstants.PLEASE_FILL_THIS_FIELD;
                isAddressFieldValid = false;
            }
        }
        updateErrorCompanyInfo(errorCompanyObj);
        return isAddressFieldValid

    }

    function onUpdateAddress() {
        if (!validateAddressField()) {
            return;
        }
        updateLatestCompanyAddress(companyInfo);
        toggleChangeAddress(false);
    }

    function cancelAddressChange() {
        updateCompanyInfo({
            streetAddress: '',
            suite: '',
            city: '',
            state: '',
            country: ''
        });

        updateErrorCompanyInfo({
            streetAddress: '',
            suite: '',
            city: '',
            state: '',
            country: ''
        });
        toggleChangeAddress(false);

    }

    function updateAddressInfo(addressObject) {
        updateCompanyInfo({...companyInfo, ...addressObject});
    }

    return (<div className="mt-3 mb-3">
        <hr/>
        <AddressForm companyInfo={companyInfo} handleInputChange={handleInputChange}
                     updateAddressInfo={updateAddressInfo} requestFor={"CompanyInfoForm"} errorInfo={errorCompanyInfo}/>
        <Row horizontal={"end"} className="mt-2">
            <Column>
                <Button className="outline-none text-transform-capitalize p-2
                    br-4 b-1-white-two fc-blue mx-1 fw-500 px-3 cursor-pointer"
                        onClick={() => cancelAddressChange()}>
                    Cancel
                </Button>
            </Column>
            <Column>
                <Button className="outline-none text-transform-capitalize p-2
                    br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer"
                        onClick={() => onUpdateAddress()}>
                    Update
                </Button>
            </Column>
        </Row>
        <hr/>
    </div>)
}

const ChangePasswordComponent = (props) => {
    let {toggleChangePassword, changeUserPassword} = props;
    let [passwordInfo, updatePasswordInfo] = React.useState({
        currentPassword: '',
        newPassword: ''
    });
    let [errorPasswordInfo, updateErrorPasswordInfo] = React.useState({
        currentPassword: '',
        newPassword: ''
    });

    function cancelChangePassword() {
        updatePasswordInfo({
            currentPassword: '',
            newPassword: ''
        });
        updateErrorPasswordInfo({
            currentPassword: '',
            newPassword: ''
        });
        toggleChangePassword(false);
    }

    function validatePasswordField() {
        let isPasswordFieldValid = true;
        if (!passwordInfo || Object.keys(passwordInfo).length < 1) {
            isPasswordFieldValid = false;
            updateErrorPasswordInfo({
                currentPassword: '',
                newPassword: ''
            });
            return isPasswordFieldValid;
        }

        let errorPasswordObj = Object.assign({}, errorPasswordInfo);
        for (let key in passwordInfo) {
            if (passwordInfo[key]) {
                if (passwordInfo[key][0] === passwordInfo[key][0].toLowerCase()) {
                    errorPasswordObj[key] = "First character should be capital letter";
                    isPasswordFieldValid = false;
                } else if (passwordInfo[key].length < 8) {
                    errorPasswordObj[key] = "Password should be 8 character long";
                    isPasswordFieldValid = false;
                } else if (!Utils.isPasswordValid(passwordInfo[key])) {
                    errorPasswordObj[key] = "Password should contain 1 special character";
                    isPasswordFieldValid = false;
                } else
                    errorPasswordObj[key] = "";
            } else {
                errorPasswordObj[key] = "Please fill this field";
                isPasswordFieldValid = false;
            }
        }

        updateErrorPasswordInfo(errorPasswordObj);
        return isPasswordFieldValid;
    }

    async function onUpdatePassword() {
        if (!validatePasswordField()) {
            return;
        }
        changeUserPassword(passwordInfo);
        toggleChangePassword(false);

    }

    function handleInputChange(event) {
        updatePasswordInfo({...passwordInfo, [event.target.id]: event.target.value});
        updateErrorPasswordInfo({...errorPasswordInfo, [event.target.id]: ''})
    }

    return (<div className="mt-3 mb-3">
            <hr/>
            <div className="fc-blue-grey fw-500 mt-2">Current Password<label className="fc-red">*</label></div>
            <CustomInput id="currentPassword" className=" p-2"
                         type="password"
                         onChange={handleInputChange} required={true} placeholder=""
                         value={passwordInfo.currentPassword} error={errorPasswordInfo.currentPassword}
                         autoComplete="off"/>

            <div className="fc-blue-grey fw-500 mt-2">New Password</div>
            <CustomInput id="newPassword" className="p-2" type="password" onChange={handleInputChange} placeholder=""
                         value={passwordInfo.newPassword} error={errorPasswordInfo.newPassword} autoComplete="off"/>

            <Row horizontal={"end"} className="mt-2">
                <Column>
                    <Button className="outline-none text-transform-capitalize p-2
                    br-4 b-1-white-two fc-blue mx-1 fw-500 px-3 cursor-pointer"
                            onClick={() => cancelChangePassword()}>
                        Cancel
                    </Button>
                </Column>
                <Column>
                    <Button className="outline-none text-transform-capitalize p-2
                    br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer"
                            onClick={() => onUpdatePassword()}>
                        Change Password
                    </Button>
                </Column>
            </Row>
            <hr/>
        </div>

    )
}

function CompaniesSettingsComponent(props) {
    let [isAbleToChangeAddress, toggleChangeAddress] = React.useState(false);
    let [isAbleToChangeContactNumber, toggleChangeContactNumber] = React.useState(false);
    let [isAbleToChangePassword, toggleChangePassword] = React.useState(false);
    let {state, handleChange, onFileCancel, onFileDrop, updateCompanyAddress, updateCompanyDetailsOnSave, changeUserPassword, toggleSaveButton} = props;

    let acceptanceString = '.jpg, .jpeg, .png, .svg';
    let loader = (state.company && !state.company.logoURL) || state.logoLoading ? <ProcessingLoader/> : null;

    async function updateLatestCompanyAddress(companyInfo) {
        await updateCompanyAddress(companyInfo);

    }

    return (<div>
            <Row horizontal={'center'} className="w-100 p-3 my-3 justify-content-center" wrap={true}>
                <Column flex={30} horizontal={'center'} className="py-3">
                    <Row className="fc-blue-grey fs-15 mt-3 mb-4 fw-500 w-100-per justify-content-center">
                        Company Logo</Row>
                    <Row className="w-100-per justify-content-center w-150 h-135px">
                        {loader ||
                        <AvatarView
                            source={state.company.logoURL ? state.company.logoURL : '/images/company-placeholder.svg'}
                            alt='limb-logo' class="max-h-150 w-150 h-100 width-fit-content"/>
                        }
                    </Row>
                    <Row className="mt-4">
                        <Dropzone
                            multiple={false}
                            accept={acceptanceString}
                            onDrop={(files) => {
                                onFileDrop(files, state, genericConstants.ACTION_TYPE.UPDATE_COMPANY_IMAGE)
                            }}
                            noClick={true}
                            disableClick
                            onFileDialogCancel={() => onFileCancel()}>
                            {({getRootProps, getInputProps, open}) => (
                                <div {...getRootProps()} className="outline-none">
                                    <input {...getInputProps()} />
                                    <Button className="text-transform-capitalize p-2
                     b-1-blue fs-15  fc-blue mx-1 fw-500 px-3 cursor-pointer outline-none"
                                            onClick={() => open()}>
                                        Change
                                    </Button>
                                </div>
                            )}
                        </Dropzone>
                    </Row>
                    <LogoutComponent
                        onLogoutClicked={props.onLogoutClicked}
                    />
                </Column>
                <Column flex={70} className="border-left pr-5">
                    <div className="w-40-per min-w-350 mx-5">
                        <Column className="mb-3">
                            <Row className="fc-dark-slate-blue fs-19 mt-3 fw-500" horizontal={"start"}>Company
                                Information</Row>
                        </Column>
                        <Column className="mb-2">
                            <Row className="fc-blue-grey  mt-3 fw-500" horizontal={"start"}>Company Name</Row>
                            <CustomInput id="name" type="text" placeholder={''}
                                         onChange={handleChange}
                                         value={state.company.name} className="fc-dark-slate-blue fs-13 p-2"/>
                        </Column>
                        <Column className="mb-2">
                            <Row className="fc-blue-grey mt-3 fw-500" horizontal={"start"}>Company Address</Row>
                            {isAbleToChangeAddress ?
                                <UpdateAddressComponent
                                    toggleChangeAddress={toggleChangeAddress}
                                    state={state}
                                    updateLatestCompanyAddress={updateLatestCompanyAddress}
                                />
                                :
                                <div className="position-relative">
                                    <CustomInput id="companyAddress" type="text" placeholder={''}
                                                 value={state.company.address}
                                                 className="fc-dark-slate-blue fs-13 p-2"
                                                 readonly="readonly"
                                    />
                                    <Button className="text-transform-capitalize pl-1 outline-none
                      fs-15  fc-blue mx-1 fw-500 px-3 cursor-pointer position-absolute top-0 right-0"
                                            onClick={() => toggleChangeAddress(true)}>
                                        Change
                                    </Button>
                                </div>
                            }

                        </Column>
                        <Column className="mb-2">
                            <Row className="fc-blue-grey  mt-3 fw-500" horizontal={"start"}>Company Website</Row>
                            <CustomInput id="websiteUrl" type="text" placeholder={''}
                                         value={state.company.websiteUrl}
                                         onChange={handleChange}
                                         className="fc-dark-slate-blue fs-13 p-2"/>
                        </Column>
                        <Column className="mb-2">
                            <Row className="fc-blue-grey  mt-3 fw-500" horizontal={"start"}>Primary Contact</Row>
                            <CustomInput id="contactName" type="text" placeholder={''}
                                         value={state.company.contactName}
                                         onChange={handleChange}
                                         className="fc-dark-slate-blue fs-13 p-2"/>
                        </Column>
                        <Column className="mb-2">
                            <Row className="fc-blue-grey  mt-3 fw-500" horizontal={"start"}>Contact Email</Row>
                            <CustomInput id="contactEmail" type="text" placeholder={''}
                                         onChange={handleChange}
                                         value={state.company.contactEmail}
                                         className="fc-dark-slate-blue fs-13 p-2"/>
                        </Column>
                        <Column className="mb-3">
                            <Row className="fc-blue-grey mt-3 fw-500" horizontal={"start"}>Phone (Optional)</Row>
                            {isAbleToChangeContactNumber ?
                                <div>
                                    <CustomInput id="contactNumber" type="text" placeholder={''}
                                                 value={state.company.contactNumber}
                                                 name={"contactNumber"}
                                                 onChange={handleChange}
                                                 className="fc-dark-slate-blue fs-13 p-2"
                                    />
                                </div> :
                                <div className="position-relative">
                                    <CustomInput id="contactNumber" type="text" placeholder={''}
                                                 value={state.company.contactNumber}
                                                 name={"contactNumber"}
                                                 className="fc-dark-slate-blue fs-13 p-2"
                                                 readonly="readonly"
                                    />
                                    <Button className="text-transform-capitalize pl-1 outline-none
                      fs-15  fc-blue mx-1 fw-500 px-3 cursor-pointer position-absolute top-0 right-0"
                                            onClick={() => toggleChangeContactNumber(true)}>
                                        {state && state.company && state.company.contactNumber ? "Change" : "Add"}
                                    </Button>
                                </div>
                            }

                        </Column>

                        <Column className="mb-3">
                            <Row className="fc-dark-slate-blue fs-19 mt-3 fw-500" horizontal={"start"}>Account
                                Settings</Row>
                        </Column>

                        <Column className="mb-2">
                            <Row className="fc-blue-grey  mt-3 fw-500" horizontal={"start"}>Password</Row>
                            {isAbleToChangePassword ?
                                <ChangePasswordComponent
                                    state={state}
                                    toggleChangePassword={toggleChangePassword}
                                    changeUserPassword={changeUserPassword}
                                /> :
                                <div className="position-relative">
                                    <CustomInput id="password" type="password" placeholder={''}
                                                 value={"*********"} className="fc-dark-slate-blue fs-16 p-2"/>
                                    <Button className="text-transform-capitalize pl-1 outline-none
                      fs-15  fc-blue mx-1 fw-500 px-3 cursor-pointer position-absolute top-0 right-0"
                                            onClick={() => toggleChangePassword(true)}>
                                        Change
                                    </Button>
                                </div>
                            }
                        </Column>
                        <Column className="mb-2">
                            <Row className="fc-blue-grey mt-3 fw-500" horizontal={"start"}>Notifications</Row>
                        </Column>
                        <Row className="mb-2 justify-content-between mt-3 fw-500 fc-dark-slate-blue">
                            <Column>Email Notifications</Column>
                            <Column><input id="Notifications" type="checkbox" placeholder={''}
                                           value={""}
                                           className="fc-dark-slate-blue fs-15 p-2"/></Column>
                        </Row>
                        {state.showSaveButton ?
                            <Row horizontal={"end"}>
                                <Column>
                                    <Button className="outline-none text-transform-capitalize p-2
                    br-4 b-1-white-two fc-blue mx-1 fw-500 px-3 cursor-pointer"
                                            onClick={toggleSaveButton}
                                    >
                                        Cancel
                                    </Button>
                                </Column>
                                <Column>
                                    <Button className="outline-none text-transform-capitalize p-2
                    br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer"
                                            onClick={() => updateCompanyDetailsOnSave()}>
                                        Save
                                    </Button>
                                </Column>
                            </Row>
                            : ""}
                    </div>

                </Column>

            </Row>
        </div>
    );
}


function ProfileSettingsComponent(props) {
    let [isAbleToChangePassword, toggleChangePassword] = React.useState(false);
    let {state, user, onFileCancel, onFileDrop, updateCompanyDetailsOnSave, changeUserPassword, onChangeEvent, onLogoutClicked} = props;

    let acceptanceString = '.jpg, .jpeg, .png, .svg';
    let loader = user && user.userDetails && user.userDetails.photo && user.userDetails.photo.fileName || state.logoLoading ?
        <ProcessingLoader/> : null;
    let profilePhoto = user && user.userDetails && user.userDetails.photo && user.userDetails.photo.fileName;
    return (
        <Row className="w-100 p-3 px-5 justify-content-start m-5 h-100" wrap={true}>
            <Column className="display-flex flex-1 flex-direction-column align-items-center" flex="25">
                <Row className="fc-blue-grey fs-15 mt-3 mb-4 fw-500">Profile Picture</Row>
                <Row className="justify-content-center w-150 h-135px">
                    {loader ? loader :
                        <AvatarView
                            source={state.company.logoURL ? state.company.logoURL : '/images/user-placeholder.svg'}
                            alt='limb-logo' class="max-h-130 w-130 h-100 width-fit-content"/>
                    }
                </Row>
                <Row className="mt-4">
                    <Dropzone
                        multiple={false}
                        accept={acceptanceString}
                        onDrop={(files) => {
                            onFileDrop(files, user, genericConstants.ACTION_TYPE.UPDATE_PROFILE_IMAGE)
                        }}
                        noClick={true}
                        disableClick
                        onFileDialogCancel={() => onFileCancel()}>
                        {({getRootProps, getInputProps, open}) => (
                            <div {...getRootProps()} className="outline-none">
                                <input {...getInputProps()} />
                                <Button
                                    className="text-transform-capitalize p-2 b-1-blue outline-none fs-15  fc-blue mx-1 fw-500 px-3 cursor-pointer"
                                    onClick={() => open()}>
                                    Change
                                </Button>
                            </div>
                        )}
                    </Dropzone>

                </Row>
            </Column>
            <div className="b-1-pale-grey-three m-h-30p h-auto"/>
            <Column flex={75}>
                <div className="w-40-per min-w-350">
                    {state && state.firstName ? <div className="mb-2">
                        <Row className="fc-blue-grey  mt-3 fw-500" horizontal={"start"}>Full Name</Row>
                        <CustomInput id="firstName" type="text" placeholder={''}
                                     onChange={onChangeEvent}
                                     value={state.firstName} className="fc-dark-slate-blue fs-13 p-2"
                        />
                    </div> : ""}
                    {user && user.userDetails && user.userDetails.emailID ? <div className="mb-2">
                        <Row className="fc-blue-grey  mt-3 fw-500" horizontal={"start"}>Email</Row>
                        <div className="fc-dark-slate-blue p-2 bg-pale-grey-two">
                            {user.userDetails && user.userDetails.emailID}
                        </div>
                    </div> : ""}
                    {user && user.userDetails && user.userDetails ? <div className="mb-2">
                        <Row className="fc-blue-grey  mt-3 fw-500" horizontal={"start"}>User Role</Row>
                        <div className="fc-dark-slate-blue p-2 bg-pale-grey-two">
                            {Utils.viewRoleLevel(Utils.getUserRoleLevel(user.userDetails))}
                        </div>
                    </div> : ""}

                    <div className="mb-2">
                        <Row className="fc-blue-grey  mt-3 fw-500" horizontal={"start"}>Password</Row>
                        {isAbleToChangePassword ?
                            <ChangePasswordComponent
                                state={state}
                                toggleChangePassword={toggleChangePassword}
                                changeUserPassword={changeUserPassword}
                            /> :
                            <div className="position-relative">
                                <CustomInput id="password" type="password" placeholder={''}
                                             value={"12345567"} className="fc-dark-slate-blue fs-13 p-2"/>
                                <Button className="text-transform-capitalize pl-1 outline-none
                      fs-15  fc-blue mx-1 fw-500 px-3 cursor-pointer position-absolute top-0 right-0"
                                        onClick={() => toggleChangePassword(true)}>
                                    Change
                                </Button>
                            </div>
                        }
                    </div>
                    <Row className="mt-4 mb-2 fc-blue-grey mt-3 fw-500" horizontal={"start"}>Notifications</Row>
                    <Row className="mb-2 justify-content-between fw-500 fc-dark-slate-blue">
                        <div className="p-2">Email Notifications</div>
                        <div><input id="Notifications" type="checkbox" placeholder={''}
                                    value={""}
                                    className="fc-dark-slate-blue fs-15 p-2"/></div>
                    </Row>
                    {state.showSaveButton ?
                        <Row horizontal={"end"}>
                            <div>
                                <Button className="outline-none text-transform-capitalize p-2
                    br-4 b-1-white-two fc-blue mx-1 fw-500 px-3 cursor-pointer outline-none "
                                        onClick={() => onLogoutClicked()}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <Column>
                                <Button className="outline-none text-transform-capitalize p-2
                    br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer outline-none "
                                        onClick={() => updateCompanyDetailsOnSave()}>
                                    Save
                                </Button>
                            </Column>
                        </Row>
                        : ""}
                </div>

            </Column>

        </Row>
    );
}

function LogoutComponent(props) {
    let {onLogoutClicked} = props;
    return (<Button className="outline-none text-transform-capitalize p-2 m-5
                br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer"
                    onClick={() => onLogoutClicked()}>
        Logout
    </Button>);

}

function HeaderComponent(props) {

    return (
        <Row className='justify-content-between'>
            <div className="fs-24 font-weight-bold display-flex flex-row">{'Settings'}</div>
            <Row className="justify-content-between">
                <Row>
                    <Notification/>
                    <img src="/images/more-icon.svg" className='p-2'/>
                </Row>
            </Row>
        </Row>
    )
};

function SettingsComponent(props) {
    let {state, toggleSaveButton, user, handleChange, onChangeEvent, onFileCancel, onFileDrop, updateCompanyAddress, updateCompanyDetailsOnSave, changeUserPassword, onLogoutClicked} = props;

    return (<div className="p-4 m-2 h-100">
        {<HeaderComponent
            props={props}/>}
        {state.userRole === genericConstants.ROLES.LENDER || state.userRole === genericConstants.ROLES.ORIGINATION_COUNSEL || state.userRole === genericConstants.ROLES.SELLER_COUNSEL ?
            <CompaniesSettingsComponent
                state={state}
                handleChange={handleChange}
                onFileCancel={onFileCancel}
                onFileDrop={onFileDrop}
                onLogoutClicked={onLogoutClicked}
                toggleSaveButton={toggleSaveButton}
                updateCompanyAddress={updateCompanyAddress}
                updateCompanyDetailsOnSave={updateCompanyDetailsOnSave}
                changeUserPassword={changeUserPassword}
            />
            : <ProfileSettingsComponent
                onLogoutClicked={onLogoutClicked}
                state={state}
                user={user}
                onChangeEvent={onChangeEvent}
                onFileCancel={onFileCancel}
                onFileDrop={onFileDrop}
                updateCompanyAddress={updateCompanyAddress}
                updateCompanyDetailsOnSave={updateCompanyDetailsOnSave}
                changeUserPassword={changeUserPassword}
            />}

    </div>)

}

export default SettingsComponent;
