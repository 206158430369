import React from 'react'
import {Row} from "simple-flexbox";
import {Button} from "@material-ui/core";
import {stringConstants} from "../../../constants";
import CustomInput from "../../../common/components/CustomInput";
import ConfirmationPopUp from "../../../common/components/ConfirmationPopUp";
import Utils from "../../../utility";

const ChangingPasswordComponent = (props) => {
    let {changeUserPassword} = props;
    let [oldPassword, handleChangeOld] = React.useState('');
    let [newPassword, handleChangeNew] = React.useState('');
    let [confirmPassword, handleChangeConfirm] = React.useState('');
    let [newPError, handleNewErrorMsg] = React.useState('');
    let [confirmPError, handleConfirmErrorMsg] = React.useState('');
    let [matchError, handleMatchErrorMsg] = React.useState('');
    let passwordInfo = {
        "currentPassword": oldPassword,
        "newPassword": newPassword
    };

    function handleOldPasswordChange(event) {
        handleChangeOld(event.target.value);
        handleNewErrorMsg('');
        handleConfirmErrorMsg("");
        handleMatchErrorMsg("");
    };

    function handleNewPasswordChange(event) {
        handleChangeNew(event.target.value);
        handleNewErrorMsg('');
        handleConfirmErrorMsg("");
        handleMatchErrorMsg("");
    }

    function handleConfirmPasswordChange(event) {
        handleChangeConfirm(event.target.value);
        handleNewErrorMsg('');
        handleConfirmErrorMsg("");
        handleMatchErrorMsg("");
    }

    async function changePassword() {
        if (!newPassword || !Utils.isPasswordValid(newPassword) || !confirmPassword || !Utils.isPasswordValid(confirmPassword)) {

            if (!newPassword) {
                handleNewErrorMsg(stringConstants.EMPTY_FIELD);
            }
            if (!Utils.isPasswordValid(newPassword) && newPassword) {
                handleNewErrorMsg(stringConstants.PASSWORD_VALIDATION);
            }
            if (!confirmPassword) {
                handleConfirmErrorMsg(stringConstants.EMPTY_FIELD);
            }
            if (!Utils.isPasswordValid(confirmPassword) && confirmPassword) {
                handleConfirmErrorMsg(stringConstants.PASSWORD_VALIDATION);
            }
            if (!newPassword) {
                handleNewErrorMsg(stringConstants.EMPTY_FIELD);
            }
            if (!confirmPassword && !Utils.isPasswordValid(confirmPassword)) {
                handleConfirmErrorMsg(stringConstants.EMPTY_FIELD);
            }
            return;
        }
        if (newPassword !== confirmPassword) {
            handleMatchErrorMsg(stringConstants.PASSWORD_DO_NOT_MATCH);
            return;
        } else {
            handleMatchErrorMsg("");
        }
        if (oldPassword === newPassword) {
            handleMatchErrorMsg("Old password and New password are same.");
            return;
        }
        changeUserPassword(passwordInfo);
    }

    return (
        <div className="p-50px">
            <div className=" mt-2 fs-16 p-t-b-10px"> Change Password</div>
            <div className="p-t-b-10px max-w-480">
                <div className="fc-dark-slate-blue mt-2 fs-15">Old Password</div>
                <CustomInput className="p-8h" type="password" name="OldPassword" onChange={handleOldPasswordChange}
                             value={oldPassword} placeholder="Old Password"/>
            </div>
            <div className="p-t-b-10px max-w-480">
                <div className="fc-dark-slate-blue mt-2 fs-15">New Password</div>
                <CustomInput className="p-8h" type="password" name="NewPassword" onChange={handleNewPasswordChange}
                             value={newPassword} placeholder="New Password"/>
                <div className="display-flex fc-red fs-14 py-1">{newPError}</div>
            </div>
            <div className="p-t-b-10px max-w-480">
                <div className="fc-dark-slate-blue mt-2 fs-15">Confirm New Password</div>
                <CustomInput className="p-8h" type="password" name="ConfirmPassword"
                             onChange={handleConfirmPasswordChange} value={confirmPassword} placeholder="Confirm New Password"/>
                <div className="display-flex fc-red fs-14 py-1">{confirmPError}</div>
            </div>
            <div className="display-flex fc-red fs-14 py-1">{matchError}</div>
            <div className="p-t-b-10px max-w-480">
                <Button onClick={() => changePassword()}
                        className="w-100 text-transform-capitalize bg-blue fc-white fs-16">Continue</Button>
            </div>
        </div>
    )
}

function LogoutComponent(props) {
    let {toggleConfirmationPopUp} = props;
    return (<Button className="outline-none text-transform-capitalize p-2 m-4 fs-16
                br-4 b-1-blue fc-blue mx-1 fw-500 px-3 cursor-pointer"
                    onClick={() => toggleConfirmationPopUp({}, stringConstants.LOGOUT, stringConstants.CONFIRM_LOGOUT)}>
        Logout
    </Button>);
}

function SettingsComponent(props) {
    let {state, changeUserPassword, onLogoutClicked, toggleConfirmationPopUp} = props;

    function closePopup(){
        toggleConfirmationPopUp();
    }

    function logout(){
        onLogoutClicked();
    }

    return (<div className="p-4 m-2 h-100 w-60-per">
        <Row className="fc-dark-slate-blue fs-24 font-weight-bold">Settings</Row>
        <div>
            <ChangingPasswordComponent
                changeUserPassword={(passwordInfo) => changeUserPassword(passwordInfo)}
            />
            <hr/>
            <LogoutComponent
                onLogoutClicked={onLogoutClicked}
                toggleConfirmationPopUp={toggleConfirmationPopUp}
            />
        </div>

        {state.isConfirmationPopUpOpen ?
            <ConfirmationPopUp
                isConfirmationPopUpOpen={state.isConfirmationPopUpOpen}
                headerMessage={state.headerMessage}
                closePopup={closePopup}
                onYesClicked={logout}
            /> : ""}

        </div>)
}

export default SettingsComponent;
