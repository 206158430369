import React from 'react';

import TextField from '@material-ui/core/TextField';


const DatePickerComponent = (props) => {
    const [selectedDate, setSelectedDate] = React.useState("");
    React.useEffect(() => {
        setSelectedDate(props.value)
    }, [props.value]);

    const handleDateChange = event => {
        setSelectedDate(event.target.value);
        if (props.questionObject)
            props.onChange(event, props.questionObject); //For Question Answer Usage
        else
            props.onChange(event); //For Generic Usage
    };
    return (
        <div>
            <TextField
                id={props.id ? props.id : "date"}
                label="Date"
                type="date"
                classes={'date-func'}
                onChange={handleDateChange}
                value={selectedDate}
                defaultValue="2017-05-24"
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </div>
    );
};

export default DatePickerComponent;