import {Column, Row} from "simple-flexbox";
import React, {useState} from "react";
import {
    Button,
    Dialog,
    LinearProgress, Popover, Select, withStyles
} from "@material-ui/core";
import {history} from '../../managers/history'
import PartsTableComponent from "./partsComponent";
import UploadDocument from "../document/uploadDocuments";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Utility from "../../utility";
import {actionTypeConstants, genericConstants, pathConstants, stringConstants} from "../../constants";
import {getSignedURL} from "../../managers/awsService";
import CustomInputWithAddUser from "../../common/components/CustomInputWithAddUser";
import DocumentManagement from "../document/documentManagement";
import CounselFieldComponent from "../../common/components/CounselField";
import EditRecordComponent from "./editRecordComponent";
import Notification from "../notification";
import NoteDialogComponent from "../../common/components/NoteDialogComponent";
import CustomSelectInput from "../../common/components/CustomSelectInput";
import MenuItem from "@material-ui/core/MenuItem";

const BorderLinearProgress = withStyles({
    root: {
        height: 6,
        backgroundColor: '#dfe2e8',
        borderRadius: '3px',
        width: '100%'
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#0052cc',
    },
})(LinearProgress);

const HeaderComponent = (props) => {
    let {toggleNoteDialog, togglePublishTagSelectionDialogState} = props;
    let userRole = Utility.getUserRole(props.userDetails);

    function goBack() {
        if (userRole === genericConstants.ROLES.SUPER_ADMIN)
            history.replace(pathConstants.DASHBOARD_MENU.PUBLISH_REQUESTS);
        else
            history.replace(pathConstants.DASHBOARD_MENU.INFORMATION_RECORDS);
        return;

    }

    return (
        <Column>
            <Row className="justify-content-between" style={{maxHeight: props.height}}>
                <div className="fs-24 font-weight-bold"><img
                    onClick={() => goBack()}
                    className="cursor-pointer mr-3"
                    src="/images/back-icon.svg"/>Records
                    Details
                </div>
                {userRole === genericConstants.ROLES.SUPER_ADMIN ?
                    <Row vertical="center">
                        <Row>
                            <button
                                className="text-transform-capitalize p-2 b-1-green outline-none fs-15 br-4  fc-white mx-1 fw-500 px-3 cursor-pointer bg-green"
                                onClick={() => togglePublishTagSelectionDialogState()}>Publish
                            </button>
                            <button
                                className="text-transform-capitalize p-2 b-1-red outline-none fs-15 br-4 fc-red bg-white mx-1 fw-500 px-3 cursor-pointer"
                                onClick={() => toggleNoteDialog()}>Reject
                            </button>
                        </Row>
                        <Column>
                            <Notification/>
                        </Column>
                    </Row> :
                    <Row vertical="center">
                        {props && props.recordDetails && props.recordDetails.publishInfo && props.recordDetails.publishInfo.length > 0 ?
                            <div className="p-9px fw-500 br-4 b-1-white-two bg-white-two mx-1 cursor-pointer"
                                 onClick={() => props.onViewRecordClicked()}>View Blockchain Record</div> : ""}
                        <Notification/>
                        {MoreMenuPopOverComponent(props)}
                    </Row>
                }
            </Row>
        </Column>
    )
};

function MoreMenuPopOverComponent(props) {
    const [moreOptionsMenuAnchorEl, setOptionAnchorEl] = React.useState(null);
    const isMoreOptionMenuOpen = Boolean(moreOptionsMenuAnchorEl);


    function handleMoreOptionMenuOpen(event) {
        setOptionAnchorEl(event.currentTarget);
    }

    function handleMoreOptionMenuClose() {
        setOptionAnchorEl(null);
    }

    let userRole = Utility.getUserRole(props.userDetails);

    return (
        <Column className="fc-dark-slate-blue">
            <img src="/images/more-icon.svg" className="p-1 mx-1 cursor-pointer" onClick={handleMoreOptionMenuOpen}/>
            <Popover
                className="subscription-plan-card-shadow mt-2 br-10"
                anchorEl={moreOptionsMenuAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isMoreOptionMenuOpen}
                onClose={handleMoreOptionMenuClose}
            >
                <Column style={{width: '200px', maxHeight: '200px'}} className="display-flex overflow-y-auto">
                    <Column style={{width: '200px'}} className="overflow-y-auto p-2">
                        {userRole === genericConstants.ROLES.LENDER ?
                            <div>
                                <div
                                    onClick={() => props.onMenuOptionClicked(genericConstants.RECORD_DETAILS_MENU_OPTION.PUBLISH)}
                                    className="p-2 w-100 cursor-pointer fw-500 fs-16">Publish to Blockchain
                                </div>
                                {props && props.recordDetails && props.recordDetails.status === genericConstants.RECORD_STATUS.PUBLISHED ?
                                    <div
                                        onClick={() => props.onMenuOptionClicked(genericConstants.RECORD_DETAILS_MENU_OPTION.TRANSFER)}
                                        className="p-2 w-100  cursor-pointer fw-500 fs-16">Transfer
                                    </div> : ""}
                            </div> : ""}
                        {/*
                        <div onClick={() => Utility.showUnderDevelopment()}
                             className="p-2 w-100 cursor-pointer fw-500 fs-16">Export as PDF
                        </div>*/}
                    </Column>
                </Column>
            </Popover>
        </Column>
    );
}

const SharedWithCell = (userObject, role) => {
    if (!userObject)
        return;
    return (
        <Row className="w-100 my-1 justify-content-between" vertical={"center"} key={userObject._id}>
            <Row className="justify-content-between" vertical={"center"}>
                {(role === "Sub-Lender" || role === "Sub-Counsel") ? <Avatar
                    src={userObject && userObject.photo && typeof userObject.photo === 'string' ? getSignedURL(userObject.photo, "/images/user-placeholder.svg") : "/images/user-placeholder.svg"}
                    className="w-30"/> : <Avatar
                    src={userObject && userObject.logo && typeof userObject.logo === 'string' ? getSignedURL(userObject.logo, "/images/company-placeholder.svg") : "/images/company-placeholder.svg"}
                    className="w-30"/>}
                <div className="fc-dark-slate-blue mx-2 fs-15">{userObject ? userObject.name : "-"}</div>
            </Row>
            <div className="fc-steel-grey fs-13 white-space-no-wrap">{role}</div>
        </Row>
    );
};

function getSubUserCells(recordDetails, userRole) {
    if (!recordDetails || !userRole)
        return;
    switch (userRole) {
        case genericConstants.ROLES.LENDER:
        case genericConstants.ROLES.SUB_LENDER:
            return (
                <div>
                    {recordDetails.subLender ? recordDetails.subLender.map((subUser) => SharedWithCell(subUser, "Sub-Lender")) : null}
                </div>
            );
        case genericConstants.ROLES.ORIGINATION_COUNSEL:
        case genericConstants.ROLES.SUB_ORIGINATION_COUNSEL:
            return (
                <div>
                    {recordDetails.subOriginationCounsel ? recordDetails.subOriginationCounsel.map((subUser) => SharedWithCell(subUser, "Sub-Counsel")) : null}
                </div>
            );
        case genericConstants.ROLES.SELLER_COUNSEL:
        case genericConstants.ROLES.SUB_SELLER_COUNSEL:
            return (
                <div>
                    {recordDetails.subSellerCounsel ? recordDetails.subSellerCounsel.map((subUser) => SharedWithCell(subUser, "Sub-Counsel")) : null}
                </div>
            )
    }
}

const SharedWithComponent = (props) => {
    let {recordDetails, onManageUsersClicked, userDetails} = props;
    let userRole = Utility.getUserRole(userDetails);
    return (
        <div className="w-100">
            <Row className="justify-content-between mb-2">
                <div className="font-weight-bold fs-16">Shared with</div>
                {Utility.isSubUser(userRole)
                    ? null : <div className="fc-blue fs-14 cursor-pointer" onClick={onManageUsersClicked}>Manage</div>
                }
            </Row>
            {userRole === genericConstants.ROLES.LENDER || userRole === genericConstants.ROLES.SUB_LENDER ? null : SharedWithCell(recordDetails.lender, "Lender")}
            {getSubUserCells(recordDetails, userRole)}
            {userRole === genericConstants.ROLES.LENDER || userRole === genericConstants.ROLES.SUB_LENDER ? SharedWithCell(recordDetails.originationCounsel, "Origination Counsel") : null}
            {userRole === genericConstants.ROLES.LENDER || userRole === genericConstants.ROLES.SUB_LENDER ? SharedWithCell(recordDetails.sellerCounsel, "Seller Counsel") : null}
        </div>
    );
};

const DocumentsAddedComponent = (props) => {
    let {toggleAddDocumentDialogOpen} = props;
    return (
        <div className="w-100 my-5">
            <Row className="justify-content-between mb-2">
                <div className="font-weight-bold fs-16">Document</div>
                <div className="fc-blue fs-14 cursor-pointer" onClick={toggleAddDocumentDialogOpen}>+ Add</div>
            </Row>
            <div className="bg-pale-grey  display-flex
                justify-content-center flex-direction-column align-items-center">
                {props.state.documentList && Object.keys(props.state.documentList).length > 0 ?
                    <DocumentManagement
                        documentList={props.state.documentList}
                        getDocumentList={props.getDocumentList}
                    /> :
                    <div className="py-5 text-center">
                        <div><img src="/images/no-document-placeholder.svg"/></div>
                        <div className="fc-silver fs-16">No Document added</div>
                    </div>
                }

            </div>
        </div>
    );
};


const SharingAndDocumentComponent = (props) => {
    let {state, toggleAddDocumentDialogOpen, userDetails, company} = props;
    let userRole = Utility.getUserRole(props.userDetails);
    return (
        <Column className="my-4 w-350" vertical="start">
            {userRole === genericConstants.ROLES.SUPER_ADMIN ? "" : SharedWithComponent(props)}
            {DocumentsAddedComponent(props)}
            {state.isAddDocumentDialogOpen ?
                <UploadDocument
                    isAddDocumentDialogOpen={state.isAddDocumentDialogOpen}
                    recordDetails={state.recordDetails}
                    userDetails={userDetails}
                    company={company}
                    toggleAddDocumentDialogOpen={toggleAddDocumentDialogOpen}
                /> : ""}
        </Column>);
};

const RecordFileDetailsComponent = (props) => {
    let {recordDetails, userDetails} = props;
    let userRole = Utility.getUserRole(userDetails);

    if (!recordDetails)
        return;
    let imageURL = getSignedURL(recordDetails.propertyPhoto, '/images/recent-companies.svg');
    return (
        <Row className="my-4" vertical="start">
            <Column className="max-w-225 w-100-per">
                <img src={imageURL} className="max-w-225 max-h-130 w-100-per"/>
                {userRole === genericConstants.ROLES.LENDER ?
                    <Button className="outline-none text-transform-capitalize p-2 br-4
                    b-1-white-two bg-blue fc-white m-4 fw-500 cursor-pointer" onClick={props.onEditRecordClicked}>
                        Edit Record
                    </Button> : null}
            </Column>
            <div className="text-left line-height-2-27 w-450 fw-500 fs-15">
                <Row className="mx-3 justify-content-between">
                    <div className="fc-blue-grey mx-3">File Number</div>
                    <div className="fc-dark-slate-blue mx-3">
                        {recordDetails && recordDetails.fileNo ? recordDetails.fileNo : ""}</div>
                </Row>
                <Row className="mx-3 justify-content-between">
                    <div className="fc-blue-grey mx-3">Loan Tag</div>
                    <div className="fc-dark-slate-blue mx-3">
                        {recordDetails && recordDetails.loanTag ? recordDetails.loanTag : ""}</div>
                </Row>
                <Row className="mx-3 justify-content-between">
                    <div className="fc-blue-grey mx-3">Location</div>
                    <div className="fc-dark-slate-blue mx-3">
                        {recordDetails && recordDetails.address && recordDetails.address.city ? recordDetails.address.city : ""}
                        {recordDetails && recordDetails.address && recordDetails.address.city && recordDetails.address.state ? "," : ""}
                        {recordDetails && recordDetails.address && recordDetails.address.state ? " " + recordDetails.address.state : ""}
                    </div>
                </Row>
                <Row className="mx-3 justify-content-between">
                    <div className="fc-blue-grey mx-3">Type</div>
                    <div className="fc-dark-slate-blue mx-3">
                        {recordDetails && recordDetails.propertyType ? recordDetails.propertyType : ""}
                        {recordDetails && recordDetails.propertyType && recordDetails.propertySubType ? "," : ""}
                        {recordDetails && recordDetails.propertySubType ? " " + recordDetails.propertySubType : ""}
                    </div>
                </Row>
                <Row className="mx-3 justify-content-between">
                    <div className="fc-blue-grey mx-3">Status</div>
                    <div className="fc-dark-slate-blue mx-3">
                        {recordDetails && recordDetails.status ? recordDetails.status : ""}
                    </div>
                </Row>
                <Row className="mx-3 justify-content-between">
                    <div className="fc-blue-grey mx-3">Completion</div>
                    <Row vertical="center" className="fc-dark-slate-blue mx-3 w-100">
                        <BorderLinearProgress
                            variant="determinate"
                            color="secondary"
                            value={recordDetails && Object.keys(recordDetails).length > 0 && recordDetails.progress ? parseInt(recordDetails.progress) : 0}
                        />
                    </Row>
                </Row>
            </div>

        </Row>
    )
};

function getTotalCountAndCompletedCountList(sectionList) {
    let response = {totalCount: 0, totalCompletedCount: 0};
    if (!sectionList || sectionList.length < 1)
        return response;
    response['totalCount'] = sectionList.length;
    sectionList.forEach(section => {
        if (section.status === genericConstants.SECTION_STATUS.COMPLETED || section.status === genericConstants.SECTION_STATUS.APPROVED
            || section.status === genericConstants.SECTION_STATUS.IN_REVIEW || section.status === genericConstants.SECTION_STATUS.REJECTED
            || section.progress === 100)
            response['totalCompletedCount'] = response['totalCompletedCount'] + 1;
    });

    return response;
};

const calculatePartCompletion = (sectionList) => {
    let response = getTotalCountAndCompletedCountList(sectionList);
    let completionPercentage = 0;
    if (!response || Object.keys(response).length < 1 || response.totalCount < 1)
        return completionPercentage;
    if (response.totalCompletedCount > 0)
        completionPercentage = (response.totalCompletedCount * 100) / response.totalCount;
    return completionPercentage;
};

const PartsComponent = (props) => {
    let {userDetails, state, recordDetails, userPermission} = props;
    let userRole = Utility.getUserRole(userDetails);
    let [partsVisibility, updatePartsVisibility] = React.useState({
        A: userRole === genericConstants.ROLES.ORIGINATION_COUNSEL || userRole === genericConstants.ROLES.SUB_ORIGINATION_COUNSEL,
        B: userRole === genericConstants.ROLES.LENDER || userRole === genericConstants.ROLES.SUB_LENDER,
        C1: userRole === genericConstants.ROLES.SELLER_COUNSEL || userRole === genericConstants.ROLES.SUB_SELLER_COUNSEL,
        C2: userRole === genericConstants.ROLES.SELLER_COUNSEL || userRole === genericConstants.ROLES.SUB_SELLER_COUNSEL,
    });

    let toggleSection = (key) => {
        updatePartsVisibility({...partsVisibility, [key]: !partsVisibility[key]})
    };
    switch (userRole) {
        case genericConstants.ROLES.ORIGINATION_COUNSEL:
        case  genericConstants.ROLES.SUB_ORIGINATION_COUNSEL:
            return (
                <PartsTableComponent props={props} part={genericConstants.RECORD_PART.PART_A}
                                     userPermission={userPermission}
                                     partCompletion={calculatePartCompletion(state.sectionListPartA)}
                                     isSectionVisible={partsVisibility.A} role={userRole} recordDetails={recordDetails}
                                     toggleSection={updatePartsVisibility} sectionList={state.sectionListPartA}/>
            );
            break;
        case genericConstants.ROLES.SELLER_COUNSEL:
        case genericConstants.ROLES.SUB_SELLER_COUNSEL:
            return (
                <div>
                    <PartsTableComponent props={props} part={genericConstants.RECORD_PART.PART_A}
                                         isSectionVisible={partsVisibility.A} role={userRole}
                                         userPermission={userPermission}
                                         recordDetails={recordDetails} isForReviewTextVisible={true}
                                         partCompletion={calculatePartCompletion(state.sectionListPartA)}
                                         toggleSection={toggleSection} sectionList={state.sectionListPartA}/>
                    <PartsTableComponent props={props} part={genericConstants.RECORD_PART.PART_B}
                                         userPermission={userPermission}
                                         isSectionVisible={partsVisibility.B} role={userRole}
                                         isForReviewTextVisible={true}
                                         recordDetails={recordDetails}
                                         partCompletion={calculatePartCompletion(state.sectionListPartB)}
                                         toggleSection={toggleSection} sectionList={state.sectionListPartB}/>
                    <PartsTableComponent props={props} part={genericConstants.RECORD_PART.PART_C1}
                                         userPermission={userPermission}
                                         isSectionVisible={partsVisibility.C1} role={userRole}
                                         recordDetails={recordDetails} isSectionSeparated={true}
                                         partCompletion={calculatePartCompletion(state.sectionListPartC1)}
                                         toggleSection={toggleSection} sectionList={state.sectionListPartC1}/>
                    <PartsTableComponent props={props} part={genericConstants.RECORD_PART.PART_C2}
                                         isSectionVisible={partsVisibility.C2} role={userRole}
                                         userPermission={userPermission}
                                         recordDetails={recordDetails}
                                         partCompletion={calculatePartCompletion(state.sectionListPartC2)}
                                         toggleSection={toggleSection} sectionList={state.sectionListPartC2}/>
                </div>);
            break;

        case genericConstants.ROLES.LENDER :
        case genericConstants.ROLES.SUB_LENDER:
        default:
            return (
                <div>
                    <PartsTableComponent props={props} part={genericConstants.RECORD_PART.PART_B}
                                         isSectionVisible={partsVisibility.B} role={userRole}
                                         recordDetails={recordDetails}
                                         userPermission={userPermission}
                                         partCompletion={calculatePartCompletion(state.sectionListPartB)}
                                         toggleSection={toggleSection} sectionList={state.sectionListPartB}/>

                    <PartsTableComponent props={props} part={genericConstants.RECORD_PART.PART_A}
                                         isSectionVisible={partsVisibility.A} role={userRole}
                                         userPermission={userPermission}
                                         recordDetails={recordDetails}
                                         partCompletion={calculatePartCompletion(state.sectionListPartA)}
                                         toggleSection={toggleSection} sectionList={state.sectionListPartA}/>
                    <PartsTableComponent props={props} part={genericConstants.RECORD_PART.PART_C1}
                                         isSectionVisible={partsVisibility.C1} role={userRole}
                                         userPermission={userPermission}
                                         recordDetails={recordDetails}
                                         partCompletion={calculatePartCompletion(state.sectionListPartC1)}
                                         toggleSection={toggleSection} sectionList={state.sectionListPartC1}/>
                    <PartsTableComponent props={props} part={genericConstants.RECORD_PART.PART_C2}
                                         isSectionVisible={partsVisibility.C2} role={userRole}
                                         recordDetails={recordDetails}
                                         userPermission={userPermission}
                                         partCompletion={calculatePartCompletion(state.sectionListPartC2)}
                                         toggleSection={toggleSection} sectionList={state.sectionListPartC2}/>
                </div>);
            break;
    }


};

const AddNewCounselDialogComponent = (props) => {
    let {handleSelectedCounsel, toggleSelectedCounsel, toggleNewCounselDialog, state, handleAddUser, handleCreateRecord} = props;

    //React hook for temporary data selection of the counsel-
    let [counselList, updateCounselList] = React.useState([]);

    React.useEffect(() => {
        if (state && state.counselList)
            state.counselList.map((counselObj) => {

            });
        updateCounselList(state.counselList)
    }, [state.isAddNewCounselDialogOpen]);

    return (
        <Dialog open={state.isAddNewCounselDialogOpen} className="p-3 fc-blue-grey">

            <Column>
                <form className="m-3">
                    <Column flex={"1"}>
                        <Row className="justify-content-between mb-3">
                            <Column className=" fc-dark-slate-blue fs-16 fw-500">{state.counselDialogHeading}</Column>
                            <img src='/images/cross-icon.svg'
                                 className='w-15 pt-sm-1 cursor-pointer' onClick={toggleNewCounselDialog}
                            />
                        </Row>
                        <Row className="flex-direction-column">
                            {counselList && counselList.length > 0 ?
                                counselList.map((counselObj, index) => {
                                    return <CounselFieldComponent
                                        logo={counselObj && counselObj.photo && counselObj.photo.fileName ? counselObj.photo.fileName : null}
                                        name={counselObj.name}
                                        index={index}
                                        isSelected={counselObj.isSelected}
                                        data={counselObj}
                                        toggleSelectedCounsel={toggleSelectedCounsel}
                                    />
                                })
                                : ""}
                        </Row>
                    </Column>

                    <Row horizontal={"end"} className="mt-3">
                        <Button onClick={() => handleSelectedCounsel(state.counselDialogHeading, counselList)}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">Update
                        </Button>
                    </Row>
                </form>
            </Column>
        </Dialog>
    )
};

const getSubUsers = (userRole, recordDetails) => {
    if (!userRole || !recordDetails)
        return [];
    switch (userRole) {
        case genericConstants.ROLES.LENDER:
            return ["Sub-Lender", recordDetails.subLender];
        case genericConstants.ROLES.ORIGINATION_COUNSEL:
            return ["Sub-Counsel", recordDetails.subOriginationCounsel];
        case genericConstants.ROLES.SELLER_COUNSEL:
            return ["Sub-Counsel", recordDetails.subSellerCounsel];
    }
};

const ManageUsersDialog = (props) => {
    let {handleChangeSelectedSellerCounsel, userDetails, handleChangeSelectedOriginationCounsel, handleChangeSelectedSubLender, onChangeEvent, onManageUsersClicked, state, handleAddUser, handleUpdateRecord} = props;
    let userRole = Utility.getUserRole(userDetails);
    let [subUserText, subUserArray] = getSubUsers(userRole, props.recordDetails);
    // Array.prototype.push.apply(subUserArray, state.selectedSubLender);


    return (
        <Dialog open={state.isManageUserDialogOpen} className="p-3 fc-blue-grey">
            <form className="m-4">
                <Column flex={"1"}>
                    <p className=" fc-dark-slate-blue fs-18 fw-500">Manage Users</p>
                    <Column>
                        <Row className="fs-14 fw-500" horizontal={"start"}>{subUserText}<p
                            className="fc-red m-0">*</p></Row>

                        <CustomInputWithAddUser id="subUsers" type="text" placeholder={''}
                                                onChange={onChangeEvent}
                                                isReadOnly={false}
                                                isAddUserIconDisplay={true}
                                                data={state.selectedSubUsers}
                                                values={state.selectedSubUsers}
                                                handleChangeSelected={(value) => handleChangeSelectedSubLender(value)}
                                                handleAddUser={() => handleAddUser(actionTypeConstants.ADD_SUB_USER)}
                                                className="fs-15 "/>

                    </Column>
                    {userRole !== genericConstants.ROLES.LENDER ?
                        <Column className="pointer-event-none">
                            <Row className="fs-14 mt-3 fw-500" horizontal={"start"}>Lender<p
                                className="fc-red m-0">*</p></Row>
                            <CustomInputWithAddUser id="originationCounsel" type="text" placeholder={''}
                                                    onChange={onChangeEvent}
                                                    isReadOnly={true}
                                                    isAddUserIconDisplay={false}
                                                    data={[state.recordDetails.lender]}
                                                    values={[state.recordDetails.lender]}
                                                    handleChangeSelected={(value) => handleChangeSelectedOriginationCounsel(value)}
                                                    handleAddUser={() => handleAddUser(actionTypeConstants.ADD_ORIGINATION_COUNSEL)}
                                                    className="fs-15"/>

                        </Column> : null}
                    {userRole === genericConstants.ROLES.LENDER ?
                        <Column>
                            <Row className="fs-14 mt-3 fw-500" horizontal={"start"}>Origination Counsel<p
                                className="fc-red m-0">*</p></Row>
                            <CustomInputWithAddUser id="originationCounsel" type="text" placeholder={''}
                                                    onChange={onChangeEvent}
                                                    isReadOnly={false}
                                                    isAddUserIconDisplay={true}
                                                    data={[state.selectedOriginationCounsel]}
                                                    values={[state.selectedOriginationCounsel]}
                                                    handleChangeSelected={(value) => handleChangeSelectedOriginationCounsel(value)}
                                                    handleAddUser={() => handleAddUser(actionTypeConstants.ADD_ORIGINATION_COUNSEL)}
                                                    className="fs-15"/>

                        </Column> : null}
                    {userRole === genericConstants.ROLES.LENDER ?
                        <Column>
                            <Row className="fs-14 mt-3 fw-500" horizontal={"start"}>Seller Counsel<p
                                className="fc-red m-0">*</p></Row>

                            <CustomInputWithAddUser id="sellerCounsel" type="text" placeholder={''}
                                                    onChange={onChangeEvent}
                                                    isReadOnly={false}
                                                    data={[state.selectedSellerCounsel]}
                                                    values={[state.selectedSellerCounsel]}
                                                    isAddUserIconDisplay={true}
                                                    handleAddUser={() => handleAddUser(actionTypeConstants.ADD_SELLER_COUNSEL)}
                                                    handleChangeSelected={(value) => handleChangeSelectedSellerCounsel(value)}
                                                    error={state ? state.sellerCounselError : ""}
                                                    className="fs-15"/>
                        </Column> : null}
                </Column>

                <Row horizontal={"end"} className="mt-5">
                    <Button onClick={onManageUsersClicked} className="outline-none text-transform-capitalize p-2 br-4
                            b-1-white-two fc-blue mx-1 fw-500 w-150 px-3 cursor-pointer bg-transparent">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateRecord} className="outline-none text-transform-capitalize p-2 br-4
                            b-1-white-two bg-blue fc-white mx-1 fw-500 w-150 px-3 cursor-pointer">
                        Update
                    </Button>
                </Row>
            </form>
        </Dialog>
    );
};

const RecordAndPartsComponent = (props) => {
    return (
        <Column className="w-100 mr-4" vertical="start">
            {RecordFileDetailsComponent(props)}
            {PartsComponent(props)}
        </Column>);
};

const getList = (tagObject, index, state) => {
    return <MenuItem className="p-2 cursor-pointer outline-none" value={tagObject}
                     key={index}>{tagObject.tag}</MenuItem>
};

const PublishTagDialog = (props) => {
    let {state, onPublishTagSelectionEvent, handleOnPublishClickByAdmin, togglePublishTagSelectionDialogState} = props;
    return (
        <Dialog maxWidth={"sm"} open={state.isPublishTagSelectionDialogOpen} fullWidth={true}>
            <Column style={{width: '90%', margin: 'auto'}}>
                <Row className="fs-20 fw-500 pt-4 pb-4">
                    Select LDR Publish Tag
                </Row>
                <Column className="br-2">
                    <Select value={state.selectedPublishTag}
                            className="mb-2 form-control p-0 fs-14"
                            onChange={onPublishTagSelectionEvent}
                            input={<CustomSelectInput name="selectedPublishTag"/>}
                    >
                        <MenuItem className="p-2 cursor-pointer outline-none" value={state.selectedPublishTag}>
                            Select Values
                        </MenuItem>
                        {state && state.publishTagList ?
                            (state.publishTagList).map((tagObject, index) => {
                                return getList(tagObject, index, state)
                            }) : ""}
                    </Select>
                    {state.selectedPublishTagError ?
                        <div className="display-flex fc-red fs-14 py-1">{state.selectedPublishTagError}</div>
                        : ""}

                    <Row horizontal={"end"} className="mb-5 pt-3">
                        <Button onClick={() => togglePublishTagSelectionDialogState()}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two fc-blue mx-1 fw-500 px-3 cursor-pointer">Cancel
                        </Button>
                        <Button onClick={() => handleOnPublishClickByAdmin()}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">Publish
                        </Button>
                    </Row>
                </Column>

            </Column>
        </Dialog>
    )
}

const RecordDetailsComponent = (props) => {

    let {state, toggleNoteDialog, rejectPublishRequest} = props;

    return (
        <Column className="min-vh-100 p-4 fc-dark-slate-blue" style={{maxHeight: props.height}}>
            {<HeaderComponent {...props}/>}
            {<PublishTagDialog {...props}/>}
            <Row className="justify-content-between">
                {RecordAndPartsComponent(props)}
                {SharingAndDocumentComponent(props)}
            </Row>
            {props.state.isManageUserDialogOpen ? ManageUsersDialog(props) : ""}
            {AddNewCounselDialogComponent(props)}
            {EditRecordComponent(props)}

            {state.isNoteDialogOpen ?
                <NoteDialogComponent
                    isNoteDialogOpen={state.isNoteDialogOpen}
                    userDetails={props.userDetails}
                    toggleNoteDialog={toggleNoteDialog}
                    addQuestionNotes={(noteObj) => rejectPublishRequest(noteObj)}
                    headerMessage={'Add Reason For Rejection'}
                /> : ""}
        </Column>
    )
};
export default RecordDetailsComponent;
