import {eventConstants} from "../constants";

let initialState = {
    record: null,
    isLoading: false,
};

export default function user(state = initialState, action) {
    switch (action.type) {
        case eventConstants.GET_RECORD_LIST_SUCCESS:
            return {
                ...state,
                record: action.data,
                isLoading: false
            };

        case eventConstants.GET_RECORD_LIST_REQUESTED:
            return {
                ...state,
                record: action.data,
                isLoading: false
            };

        case eventConstants.ADD_RECORD_SUCCESS:
            return {
                ...state,
                record: action.data,
            };
        case eventConstants.GET_RECORD_LIST_FAILURE:
        case eventConstants.ADD_RECORD_FAILURE:
        default:
            return state;
    }
}