import React from "react";
import BaseComponent from '../baseComponent'
import Utils, {dispatchAction} from "../../utility";
import {RecordService} from "../../services";
import connect from "react-redux/es/connect/connect";
import SectionDetailsComponent from "./sectionDetailsComponent";
import {
    eventConstants,
    genericConstants,
    pathConstants,
    stringConstants,
    sectionListToPreventSpecificQuestionInSection
} from "../../constants";
import {history} from "../../managers/history";

class SectionDetails extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            questionList: [],
            statusList: [],
            inCompleteQuestionIdsList: [],
            isAbleToUpdateStatusForView: true,
            sectionDetails: null,
            isConfirmationPopUpOpen: false,
        };
    }

    async componentDidMount() {
        this.getInfoFromPathURL();
    }

    getInfoFromPathURL = () => {
        let pathName = window.location.pathname;
        let pathArray = pathName.split('/');
        let recordId = pathArray.length > 3 && pathArray[3] ? pathArray[3] : null;
        let sectionId = pathArray.length > 4 && pathArray[4] ? pathArray[4] : null;
        if (!recordId || !sectionId) {
            Utils.apiFailureToast("Unable to fetch data");
            return
        }
        this.setState({currentRecordId: recordId, currentSectionId: sectionId});
        this.reLoadComponent(recordId, sectionId);
    };

    reLoadComponent = async (recordId, sectionId) => {
        this.setIfSectionRenderingModificationApplicable(sectionId);
        await this.getRecordDetails(recordId);
        await this.getSectionDetails(recordId, sectionId);
        await this.getQuestionsList(recordId, sectionId);
        await this.getActivityList(recordId, sectionId);
        this.getStatusList();
    };

    setIfSectionRenderingModificationApplicable(sectionId) {
        this.setState({isSectionViewModificationApplicable: sectionListToPreventSpecificQuestionInSection.includes(sectionId)});
    }

    getStatusList = () => {
        let {userDetails} = this.props && this.props.user ? this.props.user : {};
        let roleTittle = userDetails && Object.keys(userDetails).length > 0
        && userDetails.role && userDetails.role.length > 0 && userDetails.role[0]
        && Object.keys(userDetails.role[0]).length > 0 && userDetails.role[0].title ?
            userDetails.role[0].title : "";
        let statusList = [
            {value: genericConstants.SECTION_STATUS_FOR_VIEW.OPEN, color: " bg-greyish-two "},
            {value: genericConstants.SECTION_STATUS_FOR_VIEW.IN_PROGRESS, color: " bg-azure "}
        ];

        if (this.checkSectionReviewPermission()) {
            if (roleTittle === genericConstants.ROLES.SUB_SELLER_COUNSEL || roleTittle === genericConstants.ROLES.SELLER_COUNSEL)
                statusList = [
                    ...statusList,
                    {value: genericConstants.SECTION_STATUS_FOR_VIEW.REVIEW_PENDING, color: " bg-orange "},
                    {value: genericConstants.SECTION_STATUS_FOR_VIEW.APPROVE, color: " bg-green "},
                    {value: genericConstants.SECTION_STATUS_FOR_VIEW.COMPLETED, color: " bg-green "},
                    {value: genericConstants.SECTION_STATUS_FOR_VIEW.REJECT, color: " bg-red "},
                ];
            else
                statusList = [
                    ...statusList,
                    {value: genericConstants.SECTION_STATUS_FOR_VIEW.REVIEW_PENDING, color: " bg-orange "},
                    {value: genericConstants.SECTION_STATUS_FOR_VIEW.APPROVE, color: " bg-green "},
                    {value: genericConstants.SECTION_STATUS_FOR_VIEW.REJECT, color: " bg-red "},
                ];

        } else {
            statusList = [
                ...statusList,
                {value: genericConstants.SECTION_STATUS_FOR_VIEW.SEND_FOR_REVIEW, color: " bg-orange "}
            ];

        }
        this.setState({statusList});

    };


    checkSectionReviewPermission = () => {
        let {userPermission} = this.props && this.props.user ? this.props.user : null;
        if (!userPermission || !userPermission.permissionList || userPermission.permissionList.length < 1 ||
            !this.state.sectionDetails || Object.keys(this.state.sectionDetails).length < 1 || !this.state.sectionDetails.part)
            return false;
        let part = this.state.sectionDetails.part;
        if (this.state.sectionDetails.part === genericConstants.PART_TYPE.PART_C1 || this.state.sectionDetails.part === genericConstants.PART_TYPE.PART_C2)
            part = genericConstants.PART_TYPE.PART_C;

        let filteredPermission = userPermission.permissionList.filter(permissionObj => permissionObj.permission === `REVIEW`
            && permissionObj.miscellaneous && permissionObj.miscellaneous.length > 0 && permissionObj.miscellaneous.includes(part));
        if (filteredPermission.length > 0)
            return true;
        else
            return false;
    };

    getQuestionsList = async (recordId, sectionId) => {

        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, questionsList] = await Utils.parseResponse(RecordService.getQuestionsList(recordId, sectionId));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error || !questionsList || questionsList.length === 0) {
            Utils.apiFailureToast("Unable to get LDR questions");
            return;
        }
        this.setState({questionsList: questionsList});

    };

    getRecordDetails = async (recordId) => {
        if (!recordId) {
            return;
        }
        let [error, recordDetails] = await Utils.parseResponse(RecordService.getRecordDetail(recordId));
        if (error) {
            Utils.apiFailureToast("Unable to get record details");
            return;
        }
        this.setState({recordDetails: recordDetails});
    };

    getSectionDetails = async (recordId, sectionId) => {
        let [error, sectionDetails] = await Utils.parseResponse(RecordService.getSectionDetail(recordId, sectionId));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);

        if (error || !sectionDetails || sectionDetails.length === 0) {
            Utils.apiFailureToast("Unable to get LDR info");
            return;
        }
        this.setState({sectionDetails: sectionDetails});
    };

    getActivityList = async (recordId, sectionId) => {
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, activityList] = await Utils.parseResponse(RecordService.getActivityList(recordId, sectionId));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error) {
            Utils.apiFailureToast("Unable to get Activity information!");
            return;
        }
        this.setState({activityList: activityList});
    };

    handleUpdateSection = async (requestData = {}) => {
        if (!this.state.sectionDetails || !this.state.sectionDetails.sectionId || !this.state.sectionDetails.recordId) {
            Utils.apiFailureToast("Unable to update!");
            return;
        }
        let {} = this.props;
        requestData['sectionId'] = this.state.sectionDetails.sectionId;
        requestData['recordId'] = this.state.sectionDetails.recordId;
        //TODO Make RequestData For section update

        let [error, updatedSectionDetails] = await Utils.parseResponse(RecordService.updateSection(requestData));
        if (error) {
            Utils.apiFailureToast("Unable to update LDR");
            return;
        }
        if (updatedSectionDetails)
            this.setState({sectionDetails: updatedSectionDetails});
    };

    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };
// {
//     "commentId" : {type: String, default: ""},
//     "comment" : {type: String, default: ""},
//     "addedBy": {
//         "userId": "",
//         "name": "",
//         "email": ""
//     },
//     "isDeleted" : {type: Boolean, default: "false"},
//     "addedOn": {type: Number, default: Date.now()},
//     "modifiedOn": {type: Number, default: Date.now()}
// }

    onAddComment = (commentObject) => {
        let requestData = {};
        requestData.comments = commentObject;
        requestData.activities = commentObject;
        this.handleUpdateSection(requestData);
    };

    isSectionCompleted(showIncompleteSessionToast = true) {
        // getting question list which are not completed and isSubQuestion false
        // but if question type INFO and subQuesArray empty then ignore it
        let questionListData = this.state.questionsList;
        let list = this.state.questionsList && this.state.questionsList.length > 0 ?
            this.state.questionsList.filter(obj => obj.status === genericConstants.SECTION_STATUS.IN_COMPLETE && obj.isSubQuestion === false &&
                (obj.type !== genericConstants.QUESTION_TYPE.INFO || obj.type === genericConstants.QUESTION_TYPE.INFO && obj.subQuesArray && Object.keys(obj.subQuesArray).length > 0)) : [];
        if (list.length > 0) {
            this.identifyIncompleteQuestionIdsList(list, questionListData);
            this.setState({isAbleToUpdateStatusForView: false});
            if (showIncompleteSessionToast)
                Utils.apiFailureToast(stringConstants.INCOMPLETE_SECTION);
            return false;
        }
        return true;
    }

    getInCompleteQuestionIds = (questionObject, questionList, idsList) => {
        if (!questionObject || Object.keys(questionObject).length < 1 || questionObject.status === genericConstants.SECTION_STATUS.COMPLETED)
            return;

        if (questionObject.type === genericConstants.QUESTION_TYPE.QUESTION_CHECKBOX && (!questionObject.answer || Object.keys(questionObject.answer).length < 1 || !questionObject.answer.id)) {
            return;
        }
        if (questionObject.type !== genericConstants.QUESTION_TYPE.INFO && (!questionObject.answer || Object.keys(questionObject.answer).length < 1 || !questionObject.answer.id)) {
            idsList.push(questionObject.questionId);
            return;
        }
        if (questionObject.type !== genericConstants.QUESTION_TYPE.INFO && (!questionObject.subQuesArray || Object.keys(questionObject.subQuesArray).length < 1 || !questionObject.subQuesArray[questionObject.answer.id] || questionObject.subQuesArray[questionObject.answer.id].length < 1)) {
            idsList.push(questionObject.questionId);
            return;
        }
        let childList = [];
        if (questionObject.type === genericConstants.QUESTION_TYPE.INFO && questionObject.subQuesArray && Object.keys(questionObject.subQuesArray).length > 0 &&
            questionObject.optionArray && questionObject.optionArray.length > 0 && questionObject.optionArray[0].id) {
            childList = [...childList, ...questionObject.subQuesArray[questionObject.optionArray[0].id]];
        } else {
            childList = [...childList, questionObject.subQuesArray && [...questionObject.subQuesArray[questionObject.answer.id]]];
        }
        for (let index = 0; index < childList.length; index++) {
            let ques = questionList.find((question) => {
                return question.questionId === childList[index]
            });
            this.getInCompleteQuestionIds(ques, questionList, idsList);
        }
    };

    identifyIncompleteQuestionIdsList = (list, questionList) => {
        if (!list || list.length < 1) {
            this.setState({inCompleteQuestionIdsList: []});
            return;
        }
        let idsList = [];
        for (let index = 0; index < list.length; index++)
            this.getInCompleteQuestionIds(list[index], questionList, idsList);
        // console.log("idsList=====", idsList,list.length);
        this.setState({inCompleteQuestionIdsList: idsList});
    };
    updateSectionStatus = async (status) => {
        this.setState({isAbleToUpdateStatusForView: true});
        let sectionStatus = Utils.getSectionStatus(status);
        //Update the question list so that updated status of all question can be loaded.
        await this.getQuestionsList(this.state.currentRecordId, this.state.currentSectionId);
        let requestData = {};
        if (sectionStatus === genericConstants.SECTION_STATUS.COMPLETED || sectionStatus === genericConstants.SECTION_STATUS.APPROVED || sectionStatus === genericConstants.SECTION_STATUS.IN_REVIEW) {
            if (!this.isSectionCompleted())
                return;
        }
        requestData['sectionId'] = this.state.sectionDetails.sectionId;
        requestData['recordId'] = this.state.sectionDetails.recordId;
        requestData['requestType'] = genericConstants.REQUEST_TYPE.SECTION_STATUS_CHANGED;
        requestData['updatedBy'] = this.props && this.props.user && this.props.user.userDetails ? Utils.getMiniSectionStatusUserModel(this.props.user.userDetails) : null;
        requestData['status'] = sectionStatus;

        if (!requestData || Object.keys(requestData).length < 1) {
            Utils.apiFailureToast("Unable to update Status!");
            return;
        }
        let [error, updatedSectionDetails] = await Utils.parseResponse(RecordService.updateStatus(requestData));
        if (error) {
            Utils.apiFailureToast("Unable to update Status!");
            return;
        }
        if (updatedSectionDetails) {
            this.setState({sectionDetails: updatedSectionDetails});
        }
    };

    getNextSectionData = () => {
        let {sectionList} = this.props.user;
        let result = {sectionData: {}, count: -1};
        if (!sectionList || sectionList.length < 1)
            return result;

        for (let index = 0; index < sectionList.length; index++)
            if (sectionList[index].sectionId === this.state.currentSectionId)
                return {sectionData: sectionList[index + 1], count: index + 1};

        return result;
    };

    redirectToNextSection = async () => {
        let userRole = Utils.getUserRole(this.props.user.userDetails);
        let response = this.getNextSectionData();

        if (!response || Object.keys(response).length < 1 || !response.sectionData || Object.keys(response.sectionData).length < 1 || response.count < 0) {
            // history.push('/dashboard/information-records/' + this.state.currentRecordId);
            // return;
            this.reDirectToPreviousSection();
        }

        let {sectionList} = this.props.user;
        if ((response.count === sectionList.length) && response.sectionData) {
            this.setState({currentSectionId: response.sectionData.sectionId});
            this.props.dispatchAction(eventConstants.SECTION_LIST, sectionList);
            // history.push('/dashboard/information-records/' + this.state.currentRecordId);
            // return;
            this.reDirectToPreviousSection();
        }
        if (response.sectionData) {
            this.setState({currentSectionId: response.sectionData.sectionId, questionsList: []});
            this.props.dispatchAction(eventConstants.SET_SECTION, (Utils.romanize(response.count + 1) + '. ' + response.sectionData.name));
            this.props.dispatchAction(eventConstants.SECTION_LIST, sectionList);
            await this.reLoadComponent(this.state.currentRecordId, response.sectionData.sectionId);

            if (userRole === genericConstants.ROLES.SUPER_ADMIN)
                history.push(pathConstants.DASHBOARD_MENU.PUBLISH_REQUESTS + '/' + this.state.currentRecordId + '/' + response.sectionData.sectionId);
            else
                history.push(pathConstants.DASHBOARD_MENU.INFORMATION_RECORDS + '/' + this.state.currentRecordId + '/' + response.sectionData.sectionId);
        }

    };
    reDirectToPreviousSection = () => {
        let userRole = Utils.getUserRole(this.props.user.userDetails);
        if (userRole === genericConstants.ROLES.SUPER_ADMIN)
            history.push(pathConstants.DASHBOARD_MENU.PUBLISH_REQUESTS + '/' + this.state.currentRecordId);
        else
            history.push(pathConstants.DASHBOARD_MENU.INFORMATION_RECORDS + '/' + this.state.currentRecordId);
    };
    reDirectToDashboard = () => {
        let userRole = Utils.getUserRole(this.props.user.userDetails);
        if (userRole === genericConstants.ROLES.SUPER_ADMIN)
            history.push(pathConstants.DASHBOARD_MENU.PUBLISH_REQUESTS);
        else
            history.push(pathConstants.DASHBOARD_MENU.INFORMATION_RECORDS);
    };
    removeQuestionInCompleteQuestionIdsList = (questionId) => {
        if (!questionId)
            return;
        let {inCompleteQuestionIdsList} = this.state;
        for (let index = 0; index < inCompleteQuestionIdsList.length; index++) {
            if (inCompleteQuestionIdsList[index] === questionId) {
                inCompleteQuestionIdsList.splice(index, 1);
            }
        }
        this.setState({inCompleteQuestionIdsList});

    };
    toggleConfirmationPopUp = async (headMessage) => {
        if (this.state.isConfirmationPopUpOpen) {
            this.setState({isConfirmationPopUpOpen: false, headerMessage: ''});
        } else {

            //Update the question list so that updated status of all question can be loaded.
            await this.getQuestionsList(this.state.currentRecordId, this.state.currentSectionId);
            this.isSectionCompleted(false);
            this.setState({isConfirmationPopUpOpen: true, headerMessage: headMessage});
        }
    };

    markAllSessionQuestionsAsComplete = async () => {
        let {currentRecordId, currentSectionId} = this.state;
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        this.toggleConfirmationPopUp();
        let [error, questionsList] = await Utils.parseResponse(RecordService.markSectionAsComplete({
            recordId: currentRecordId,
            sectionId: currentSectionId
        }));
        if (error || !questionsList || questionsList.length < 1) {
            this.props.dispatchAction(eventConstants.HIDE_LOADER);
            Utils.consoleLogger(" mark All Session Questions As Complete error : ", error);
            Utils.apiFailureToast("Unable to mark this section as complete!");
            return;
        }
        this.setState({questionsList: questionsList, inCompleteQuestionIdsList: []});
        await this.getSectionDetails(currentRecordId, currentSectionId);
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
    }

    render() {
        return (
            <SectionDetailsComponent state={this.state} questionsList={this.state.questionsList}
                                     removeQuestionInCompleteQuestionIdsList={this.removeQuestionInCompleteQuestionIdsList}
                                     onAddComment={this.onAddComment}
                                     updateSectionStatus={this.updateSectionStatus}
                                     getSectionDetails={this.getSectionDetails}
                                     redirectToNextSection={this.redirectToNextSection}
                                     reDirectToDashboard={this.reDirectToDashboard}
                                     reDirectToPreviousSection={this.reDirectToPreviousSection}
                                     toggleConfirmationPopUp={this.toggleConfirmationPopUp}
                                     markAllSessionQuestionsAsComplete={this.markAllSessionQuestionsAsComplete}
                                     sectionName={this.props && this.props.user ? this.props.user.currentSection : null}
                                     userDetails={this.props && this.props.user ? this.props.user.userDetails : null}/>
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, company: state.company}
};

export default connect(mapStateToProps, {dispatchAction})(SectionDetails);