import BaseComponent from "../../baseComponent";
import connect from "react-redux/es/connect/connect";
import Utils, {dispatchAction} from "../../../utility/index";
import UploadDocumentsComponent from "./uploadDocumentsComponent";
import React from "react";
import {uploadFileToS3} from "../../../managers/awsService";
import {apiFailureConstants, eventConstants, stringConstants} from "../../../constants/index";
import {DocumentService, SubscriptionService} from "../../../services/index";

class UploadDocument extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            categoryList: {
                "IN-PROCESS/ DRAFTS": [],
                "LEGAL BINDER/ LOAN DOCUMENTS": [],
                "SPLIT LOAN/ ADDIT DEBT-RELATED": [],
                "THIRD PARTY REPORTS": [],
                "LOAN UNDERWRITING": [],
                "ASSET SUMMARY": [],
                "LEGAL DATA RECORD": [],
                "OTHER DOCUMENTS/ DELIVERABLES": [],
                "SERVICING REPORTS/ CONSENTS": [],
                "INTERNAL ONLY/ NOT FOR TRANSFER": [],
                // "Loan Documents": ["Core", "Ancillary", "Special Issue", "Post-Origination Updates", "Other"],
                // "Property Diligence": ["Approved Title", "Survey", "Zoning", "Key Leases", "Material Agreements", "Other"],
                // "Lender Deliverables": ["LDR", "Asset Summary", "Accounting Tape Line", "Abstracts/ Other"],
                // "Origination Counsel Deliverables": ["LDR", "Closing/ Escrow Letter to Title Co", "Abstracts/ Other"],
                // "Third Party Reports": ["Insurance", "Environmental", "Property Condition", "Appraisal", "Other"]
            },
            files: [],
            documentList: [],
            selectedCategory: '',
            selectedType: '',
            selectedCategoryError: '',
            selectedTypeError: '',
            loading: false,
            isAddDocumentDialogOpen: props.isAddDocumentDialogOpen || false,
            isInvoiceUploadFlow: props.isInvoiceUploadFlow || false,
            invoiceNumber: "",
            invoiceAmount: "",
            invoiceNumberError: "",
            invoiceAmountError: ""
        };
    }

    onChangeEvent = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value, selectedCategoryError: '', selectedTypeError: ''});
    };

    onInputChangeEvent = (event) => {
        const {id, value} = event.target;
        this.setState({[id]: value});
    };

    toggleAddDocumentDialog = () => {
        if (this.state.isAddDocumentDialogOpen)
            this.setState({
                selectedCategory: '',
                selectedType: '',
                files: [],
                documentList: [],
                isAddDocumentDialogOpen: false
            });
        else
            this.setState({isAddDocumentDialogOpen: true});
        this.props.toggleAddDocumentDialogOpen();
    };

    uploadDocument = async (file) => {
        if (!file || Object.keys(file).length < 0)
            return "";
        let fileDetail = Utils.getFileName(file.name);
        let fileKey = Utils.getNewFileName(fileDetail.baseName, fileDetail.extension);
        let fileName = this.state.isInvoiceUploadFlow ? `invoices/${fileKey}` : `documents/${fileKey}`;
        let errorFailureMessage = this.state.isInvoiceUploadFlow ? apiFailureConstants.UPLOAD_INVOICE : apiFailureConstants.UPLOAD_DOCUMENT;
        await uploadFileToS3(file, fileName, file.type)
            .then(response => Utils.consoleLogger("UploadFileToS3 response ", response))
            .catch(err => {
                Utils.apiFailureToast(errorFailureMessage);
                Utils.consoleLogger("uploadFileToS3 error", err);
                return "";
            });
        return fileKey;
    };

    getDocumentsData = async (files) => {
        if (!files || files.length <= 0)
            return;

        let data = [];
        for (let index = 0; index < files.length; index++) {
            let documentInfo = {
                name: files[index].name,
                key: await this.uploadDocument(files[index]),
                addedBy: this.getAddedByInformation(),
                category: this.state.selectedCategory,
                categoryType: this.state.selectedType || stringConstants.DEFAULT,
                recordId: this.props.recordDetails && Object.keys(this.props.recordDetails).length && this.props.recordDetails.recordId ?
                    this.props.recordDetails.recordId : ""
            };
            data.push(documentInfo);
        }
        return data;
    };

    getInvoiceData = async (files) => {
        if (!files || files.length <= 0)
            return;

        let data = [];
        for (let index = 0; index < files.length; index++) {
            let invoiceInfo = {
                name: files[index].name,
                key: await this.uploadDocument(files[index]),
                addedBy: this.getAddedByData(),
            };
            data.push(invoiceInfo);
        }
        return data;
    };

    saveDocumentsOnS3 = async (files) => {
        return await this.getDocumentsData(files);
    };

    saveInvoiceOnS3 = async (files) => {
        return await this.getInvoiceData(files);
    };
    saveDocumentsInLDR = async () => {
        let documentList = this.getDocumentsDataForLDR();
        this.props.dispatchAction(eventConstants.SHOW_LOADER);

        let [error, documentResponse] = await Utils.parseResponse(DocumentService.addDocuments(documentList));
        if (error) {
            Utils.apiFailureToast("Unable to Add documents");
            return;
        }
        this.props.dispatchAction(eventConstants.HIDE_LOADER);

        this.toggleAddDocumentDialog();
    };

    uploadInvoiceForCounsel = async () => {
        let requestData = this.getInvoiceRequestData();
        if (!requestData || Object.keys(requestData).length < 1 || !requestData.subscriptionId) {
            Utils.apiFailureToast("Unable to upload invoice!!");
            return;
        }
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, invoiceResponse] = await Utils.parseResponse(SubscriptionService.updateSubscription(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error) {
            Utils.apiFailureToast("Unable to Upload invoices!!");
            return;
        }
        this.toggleAddDocumentDialog();
        Utils.apiSuccessToast("Invoices Uploaded Successfully!!");
    };

    validateUploadInvoice = () => {
        let {invoiceNumber, invoiceAmount} = this.state;
        this.setState({
            invoiceNumberError: !Utils.isEmpty(invoiceNumber) ? "" : stringConstants.EMPTY_INVOICE_NUMBER,
            invoiceAmountError: !Utils.isEmpty(invoiceAmount) ? "" : stringConstants.EMPTY_INVOICE_AMOUNT
        });
        return !Utils.isEmpty(invoiceNumber) && !Utils.isEmpty(invoiceNumber);
    };

    getInvoiceRequestData = () => {
        let {invoiceData} = this.state;
        let {company} = this.props;
        if (!invoiceData || invoiceData.length < 1)
            return {};
        return {
            subscriptionId: company.subscription.subscriptionId || "",
            invoice: {
                "invoiceNumber": this.state.invoiceNumber || "",
                "issueDate": Date.now(),
                "price": this.state.invoiceAmount || "",
                "fileName": invoiceData[0].name,
                "keyName": invoiceData[0].key,
                "addedBy": invoiceData[0].addedBy
            }
        }
    };

    getDocumentsDataForLDR = () => {
        let {documentList, files} = this.state;

        let filteredList = documentList.filter((document) => {
            return files.find(file => file.name === document.name)
        });
        return filteredList
    };

    getAddedByInformation() {
        let {company} = this.props;
        return {
            "companyId": company.companyId || '',
            ...this.getAddedByData()
        }
    }

    getAddedByData() {
        let {userDetails} = this.props;
        return {
            "userId": userDetails.userID || '',
            "email": userDetails.emailID || '',
            "firstName": userDetails.firstName || '',
            "role": userDetails.role && userDetails.role.length && userDetails.role[0] ? this.state.isInvoiceUploadFlow ? userDetails.role[0].title : userDetails.role[0] : {}
        }
    }

    onFileDrop = async (files) => {
        if (!this.validateUploadDocumentCategory())
            return;
        try {
            this.props.dispatchAction(eventConstants.SHOW_LOADER);
            let documentData = await this.saveDocumentsOnS3(files);
            this.props.dispatchAction(eventConstants.HIDE_LOADER);

            this.setState({
                files: files,
                loading: false,
                documentList: documentData
            });

        } catch (err) {
            this.props.dispatchAction(eventConstants.HIDE_LOADER);

            this.setState({
                files: [],
                loading: false,
                documentList: []
            });
        }
    };

    onInvoiceFileDrop = async (files) => {
        if (!this.validateUploadInvoice())
            return;
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, invoiceData] = await Utils.parseResponse(this.saveInvoiceOnS3(files));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error) {
            this.setState({
                invoices: [],
                loading: false,
                invoiceData: {}
            });
        }
        this.setState({
            invoices: files,
            loading: false,
            invoiceData: invoiceData
        });
    };

    validateUploadDocumentCategory = () => {
        let {selectedCategory, selectedType} = this.state;
        this.setState({
            selectedCategoryError: !Utils.isEmpty(selectedCategory) ? "" : stringConstants.EMPTY_DOCUMENT_CATEGORY,
            // selectedTypeError: !Utils.isEmpty(selectedType) ? "" : stringConstants.EMPTY_DOCUMENT_CATEGORY_TYPE
        });
        // return !Utils.isEmpty(selectedCategory) && !Utils.isEmpty(selectedType);
        return !Utils.isEmpty(selectedCategory);
    };

    onFileCancel = () => {
        this.setState({
            files: [],
            documentList: []
        });
    };

    deleteFile = (index) => {
        if (index < 0)
            return;

        let {files} = this.state;
        files.splice(index, 1);
        this.setState({files});
    };

    render() {
        return (
            <UploadDocumentsComponent state={this.state}
                                      isInvoiceUploadFlow={this.state.isInvoiceUploadFlow}
                                      onChangeEvent={this.onChangeEvent}
                                      onInputChangeEvent={this.onInputChangeEvent}
                                      toggleAddDocumentDialog={this.toggleAddDocumentDialog}
                                      uploadDocument={this.uploadDocument}
                                      onFileDrop={this.onFileDrop}
                                      onFileCancel={this.onFileCancel}
                                      deleteFile={this.deleteFile}
                                      saveDocumentsInLDR={this.saveDocumentsInLDR}
                                      uploadInvoiceForCounsel={this.uploadInvoiceForCounsel}
                                      onInvoiceFileDrop={this.onInvoiceFileDrop}
            />
        );
    }

}


const mapStateToProps = (state) => {
    return {user: state.user, company: state.company}
};

export default connect(mapStateToProps, {dispatchAction})(UploadDocument);
