import React from "react";
import BaseComponent from "../../modules/baseComponent";
import Button from "@material-ui/core/Button";
import {Multiselect} from "react-widgets";

class CustomInputWithAddUser extends BaseComponent {

    render() {
        return (
            <div className="display-flex flex-direction-column w-100">
                <div className="w-100 display-flex flex-direction-row align-items-start">
                    {/*<CustomInput id="fileNumber" type="text" placeholder={''}
                                 onChange={this.props.onChangeEvent}
                                 value={this.props.value} error={this.props.error ? this.props.error : ""}
                                 className="fs-15 p-2"/>*/}
                    <Multiselect
                        readOnly={this.props.isReadOnly}
                        data={this.props.data}
                        defaultValue={this.props.values}
                        value={this.props.values}
                        textField='name'
                        onChange={(value) => this.props.handleChangeSelected(value)}
                        caseSensitive={false}
                        className="display-flex multi-select w-100"
                    />
                    {this.props.isAddUserIconDisplay ?
                        <Button onClick={this.props.handleAddUser}
                                className="outline-none text-transform-capitalize p-none m-0">
                            <img src="/images/add-user.svg" alt='add-user-logo'/>
                        </Button> : ""}
                </div>
                <div className="display-flex fc-red fs-14 py-1">{this.props.error}</div>
            </div>
        );
    }
}

export default CustomInputWithAddUser;