import React from 'react';
// import {Range} from 'rc-slider';
import 'rc-slider/assets/index.css';
import Utility from "../../utility";
import CustomInput from "../../common/components/CustomInput";
import {Row} from "simple-flexbox";

const RangeSlider = (props) => {
    // let amountUnit = Utility.getAmountUnitByConfiguration();
    return (
        <div className="mt-3 " onFocus={() => props.onChangeEvent({
            target: {
                id: "selectedFilterType",
                value: "type1"
            }
        })}>
            {/*<Range defaultValue={props.value} min={props.minValue} max={props.maxValue}*/}
            {/*onChange={props.onChange} allowCross={props.allowCross} pushable={props.pushable}*/}
            {/*step={props.step} dots={props.dots}*/}
            {/*/>*/}
            {/*<div className="m-2">Min Value: <span className="fw-500">{`$${props.value[0]}${amountUnit}`}</span></div>*/}
            {/*<div className="m-2">Max Value: <span className="fw-500">{`$${props.value[1]}${amountUnit}`}</span></div>*/}

            <div className="mt-2 fw-900 ">
                <Row vertical={"center"}>
                    <div className="fw-500 w-60"> From</div>

                    <CustomInput id="fromAmount" type="number" placeholder={''}
                                 onChange={props.onChange}
                                 value={props.state.fromAmount}
                                 min={1}
                                 className="fs-15 p-2"/>
                </Row>
                <Row vertical={"center"}>
                    <div className="fw-500 w-60"> To</div>

                    <CustomInput id="toAmount" type="number" placeholder={''}
                                 onChange={props.onChange}
                                 value={props.state.toAmount}
                                 min={1}
                                 className="fs-15 p-2"/>
                </Row>
            </div>
            {props.state.rangeInputErrorText && <div
                className="display-flex text-align-center fc-red fs-14 m-2">{props.state.rangeInputErrorText}</div>}
        </div>
    );
};

export default RangeSlider;
