import {Column, Row} from "simple-flexbox";
import React from "react";
import Utility from "../../../utility";
import {genericConstants, pathConstants} from "../../../constants";
import {InformationRecords, SectionDetailsComponent, Settings, ProfileSettings, Notifications, Billing, PublishRequestView} from "../../index";
import {SubLenders} from "../../index"
import OriginationCounsel from "../originationCounsel";
import RecordDetails from "../../recordDetails";
import RejectionDetails from "../rejectedRecordview";
import {getSignedURL} from "../../../managers/awsService";


const SideMenuComponent = (props) => {
    return (
        <Column className="px-3 py-2 border-right min-vh-100 h-100" style={{width: '270px'}}>
            <Row horizontal={'left'} vertical={'center'}
                 className="my-3 flex-wrap">
                {!props.companyDetails ? "" : <img className="w-60"
                                                   src={props.companyDetails.logo ? getSignedURL(props.companyDetails.logo, '/images/photo-icon.svg') : "/images/photo-icon.svg"}/>}
                <Column className="fs-15 mx-3">
                    <div
                        className="font-weight-bold">{props.companyDetails ? props.companyDetails.name : 'Company Name'}</div>
                    <div className="fs-14 fc-brownish-grey">LIMB ID
                        : &nbsp;{props.companyDetails ? props.companyDetails.limbId : ''}</div>
                </Column>
            </Row>

            <Row vertical={'center'}
                 className={"mt-5 p-2 " + (Utility.isMenuActive('/information-records') ? 'active-menu' : 'menu')}
                 onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.INFORMATION_RECORDS)}>
                <img alt="information-records" className="mr-3"
                     src="/images/information-records.svg"/>

                <div className="pl-2">Information Records</div>
            </Row>

            {Utility.getUserRole(props.userDetails) === genericConstants.ROLES.LENDER ?
                <Row vertical={'center'}
                     className={"p-2 " + (Utility.isMenuActive('/transaction-accelerator') ? ' active-menu' : 'menu')}
                     onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.TRANSACTION_ACCELERATOR)}>
                    <img alt="information-records" className="mr-3"
                         src="/images/transaction-accelerator.svg"/>
                    <div className="pl-2"> Transaction Accelerator</div>
                </Row>
                : ""}

            {Utility.getUserRole(props.userDetails) === genericConstants.ROLES.LENDER ?<Row vertical={'center'}
                 className={"p-2 " + (Utility.isMenuActive('/publish-requests') ? 'active-menu' : 'menu')}
                 onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.PUBLISH_REQUESTS)}>
                <img alt="information-records" className="mr-3"
                     src="/images/publish-requests.svg"/>

                <div className="pl-2">Publish Requests</div>
            </Row> : ""}

            {Utility.getUserRole(props.userDetails) === genericConstants.ROLES.LENDER ?
                <Row vertical={'center'}
                     className={"p-2 " + (Utility.isMenuActive('/origination-counsel') ? ' active-menu' : 'menu')}
                     onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.ORIGINATION_COUNSEL)}>
                    <img alt="information-records" className="mr-3"
                         src="/images/origination-counsel.svg"/>
                    <div className="pl-2">Origination Counsel</div>
                </Row>
                : ""}

            {Utility.getUserRole(props.userDetails) === genericConstants.ROLES.LENDER ?
                <Row vertical={'center'}
                     className={"p-2 " + (Utility.isMenuActive('/seller-counsel') ? ' active-menu' : 'menu')}
                     onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.SELLER_COUNSEL)}>
                    <img alt="information-records" className="mr-3"
                         src="/images/seller-counsel.svg"/>
                    <div className="pl-2">Seller Counsel</div>
                </Row>
                : ""}

            {Utility.getUserRole(props.userDetails) === genericConstants.ROLES.LENDER ?
                <Row vertical={'center'}
                     className={"p-2 " + (Utility.isMenuActive('/sub-lender') ? ' active-menu' : 'menu')}
                     onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.SUB_LENDERS)}>
                    <img alt="information-records" className="mr-3"
                         src="/images/sub-lender.svg"/>
                    <div className="pl-2">Lender Sub-Users</div>
                </Row>
                : ""}

            {Utility.getUserRole(props.userDetails) === genericConstants.ROLES.LENDER ?
                <Row vertical={'center'}
                     className={"p-2 " + (Utility.isMenuActive('/billing') ? ' active-menu' : 'menu')}
                     onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.BILLING)}>
                    <img alt="information-records" className="mr-3"
                         src="/images/billing-icon.svg"/>
                    <div className="pl-2">Billing</div>
                </Row>
                : ""}

            <Row vertical={'center'} className={"p-2 " + (Utility.isMenuActive('/settings') ? ' active-menu' : 'menu')}
                 onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.SETTINGS)}>
                <img alt="information-records" className="mr-3"
                     src="/images/settings.svg"/>
                <div className="pl-2">Settings</div>
            </Row>
        </Column>
    )
};

function getInformationRecordComponent() {
    let pathName = window.location.pathname;
    let pathArray = pathName.split('/');
    return pathArray && pathArray.length > 3 ? (pathArray.length === 4 && pathArray[3] ?
        <RecordDetails/> : (pathArray.length === 5 && pathArray[4] ? <SectionDetailsComponent/> : null)) :
        <InformationRecords/>
}

function getPublishRecordComponent() {
    let pathName = window.location.pathname;
    let pathArray = pathName.split('/');
    return pathArray && pathArray.length > 3 ?
        <RejectionDetails/> : <PublishRequestView/>
}

function underDevelopment() {
    return (
        <div className="min-vh-100 display-flex fs-26 fc-brownish-grey justify-content-center align-items-center">
            Under Development
        </div>
    )
}

const HomeComponent = (props) => {
    let userRole = Utility.getUserRole(props.userDetails);
    let isSubUser = !(userRole === genericConstants.ROLES.LENDER || userRole === genericConstants.ROLES.ORIGINATION_COUNSEL || userRole === genericConstants.ROLES.SELLER_COUNSEL);
    return (
        <Column>
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.INFORMATION_RECORDS) ? getInformationRecordComponent() : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.SUB_LENDERS) ? <SubLenders/> : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.ORIGINATION_COUNSEL) ?
                <OriginationCounsel roleType={genericConstants.ROLES.ORIGINATION_COUNSEL}/> : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.SELLER_COUNSEL) ?
                <OriginationCounsel roleType={genericConstants.ROLES.SELLER_COUNSEL}/> : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.SETTINGS) ?
                (isSubUser ? <ProfileSettings/> : <Settings/>) : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.BILLING) ? <Billing/> : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.TRANSACTION_ACCELERATOR) ? underDevelopment() : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.PUBLISH_REQUESTS) ? getPublishRecordComponent() : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.NOTIFICATIONS) ? <Notifications/> : null}
        </Column>
    )
};

const dashboardComponent = (props) => {
    return (
        <Row className="h-100-per w-100 dashboard-container">
            <Column id="sideContainer">
                {SideMenuComponent(props)}
            </Column>
            <Column className="w-100 overflow-y-auto">
                {HomeComponent(props)}
            </Column>
        </Row>
    )
};
export default dashboardComponent;
