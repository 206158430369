/**
 * Created by Ayush Kulshrestha on 18/09/2019.
 */
//export all services from index file -

// export * from './user'
import {httpConstants} from "../constants";
import {httpService} from "../managers/httpService";
import Utils from "../utility";

export default {
    addRecordService,
    getRecordDetail,
    getRecordList,
    getSectionList,
    getQuestionsList,
    getActivityList,
    updateRecord,
    updateQuestion,
    updateSection,
    updateStatus,
    getSectionDetail,
    updateInvitation,
    getFilters,
    searchByCriteria,
    markSectionAsComplete,
    extractAnswer
}

async function addRecordService(requestData) {
    let url = process.env.REACT_APP_RECORD_SERVICE_URL + httpConstants.API_END_POINT.ADD_RECORD_DETAILS;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};

async function getRecordDetail(recordId) {
    let url = process.env.REACT_APP_RECORD_SERVICE_URL + httpConstants.API_END_POINT.RECORD_DETAILS + recordId;
    return httpService(httpConstants.METHOD_TYPE.GET, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, {}, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};

async function getRecordList(requestData, companyId) {
    let url = process.env.REACT_APP_RECORD_SERVICE_URL + httpConstants.API_END_POINT.RECORD_LIST + "/" + companyId;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};

async function getSectionList(recordId) {
    let url = process.env.REACT_APP_RECORD_SERVICE_URL + httpConstants.API_END_POINT.SECTION_LIST + recordId;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, {}, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};

async function getQuestionsList(recordId, sectionId) {
    let url = process.env.REACT_APP_RECORD_SERVICE_URL + httpConstants.API_END_POINT.QUESTION_LIST + recordId + '/' + sectionId;
    return httpService(httpConstants.METHOD_TYPE.GET, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, {}, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};

async function getActivityList(recordId, sectionId) {
    let url = process.env.REACT_APP_RECORD_SERVICE_URL + httpConstants.API_END_POINT.ACTIVITY_LIST;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, {recordId, sectionId}, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};

async function getSectionDetail(recordId, sectionId) {
    let url = process.env.REACT_APP_RECORD_SERVICE_URL + httpConstants.API_END_POINT.SECTION_DETAILS + recordId + '/' + sectionId;

    return httpService(httpConstants.METHOD_TYPE.GET, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, {}, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};

async function updateSection(requestData) {
    let url = process.env.REACT_APP_RECORD_SERVICE_URL + httpConstants.API_END_POINT.SECTION_DETAILS;
    return httpService(httpConstants.METHOD_TYPE.PUT, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function updateRecord(requestData) {
    let url = process.env.REACT_APP_RECORD_SERVICE_URL + httpConstants.API_END_POINT.UPDATE_RECORD;
    return httpService(httpConstants.METHOD_TYPE.PUT, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function updateStatus(requestData) {
    let url = process.env.REACT_APP_RECORD_SERVICE_URL + httpConstants.API_END_POINT.UPDATE_STATUS;
    return httpService(httpConstants.METHOD_TYPE.PUT, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function updateQuestion(requestData) {
    let url = process.env.REACT_APP_RECORD_SERVICE_URL + httpConstants.API_END_POINT.QUESTION_DETAILS;
    return httpService(httpConstants.METHOD_TYPE.PUT, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function markSectionAsComplete(requestData) {
    let url = process.env.REACT_APP_RECORD_SERVICE_URL + httpConstants.API_END_POINT.MARK_AS_COMPLETE;
    // let url = 'http://localhost:3003' + httpConstants.API_END_POINT.MARK_AS_COMPLETE;
    return httpService(httpConstants.METHOD_TYPE.PUT, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function extractAnswer(requestData) {
    let url = process.env.REACT_APP_RECORD_SERVICE_URL + httpConstants.API_END_POINT.EXTRACT_ANSWER;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function updateInvitation(requestData) {
    let url = process.env.REACT_APP_RECORD_SERVICE_URL + httpConstants.API_END_POINT.INVITATION + requestData.recordId;
    return httpService(httpConstants.METHOD_TYPE.PUT, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getFilters(requestData) {
    let url = process.env.REACT_APP_RECORD_SERVICE_URL + httpConstants.API_END_POINT.RECORD_FILTERS;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function searchByCriteria(requestData) {
    let url = process.env.REACT_APP_RECORD_SERVICE_URL + httpConstants.API_END_POINT.SEARCH_CRITERIA;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
