import React from "react";
import BaseComponent from '../../baseComponent'
import DashboardComponent from './dashboardComponent'
import {pathConstants} from "../../../constants";
import {history} from "../../../managers/history";
import {dispatchAction} from "../../../utility";
import {connect} from "react-redux";

class Dashboard extends BaseComponent {
    constructor(props) {
        super(props);
        let url = [];
        this.state = {
            activeMenu: pathConstants.DASHBOARD_MENU.INFORMATION_RECORDS,
        };
        if (history.location.pathname)
            url = history.location.pathname.split('/');
        if (url && url.length === 2)
            history.replace(pathConstants.DASHBOARD_MENU.INFORMATION_RECORDS);
    }

    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };

    onMenuClick = (activeMenu) => {
        this.setState({activeMenu: activeMenu});
        history.push({pathname: activeMenu});
    };

    render() {
        return (
            <DashboardComponent state={this.state}
                                userDetails={this.props.user ? this.props.user.userDetails : null}
                                company={this.props.company}
                                onMenuClick={this.onMenuClick}
                                onChangeEvent={this.onChangeEvent}/>
        );
    }
}

const mapStateToProps = (reducesState) => {
    return {record: reducesState.record, user: reducesState.user, company: reducesState.company,}
};

export default connect(mapStateToProps, {dispatchAction})(Dashboard);