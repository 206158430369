import React from "react";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {Column, Row} from "simple-flexbox";
import {Button} from "@material-ui/core";
import CustomInput from "./CustomInput";
import utility from "../../utility";
const NoteDialogComponent = (props) => {
    let [note, handleChangeNote] = React.useState('');
    React.useEffect(() => {
        handleChangeNote(
            props.questionObject && Object.keys(props.questionObject).length > 0 && props.questionObject.notes && props.questionObject.notes.length > 0 && props.questionObject.notes[0].note ?
                props.questionObject.notes[0].note : '')
    }, [props.questionObject]);
    let [noteError, handleChangeErrorMsg] = React.useState('');

    function handleInputChange(event) {
        handleChangeNote(event.target.value);
        handleChangeErrorMsg('');

    }

    function saveNoteInQuestionObject() {
        if(utility.isEmpty(note)){
            handleChangeErrorMsg('Please enter note');
            return;
        }
        let noteObject ={
            note: note,
            addedBy: {
                userId: props.userDetails.userID || '',
                name: props.userDetails.firstName || '',
                email: props.userDetails.emailID || '',
                role: props.userDetails.role || [],
            },
            addedOn: Date.now()
        };
        props.addQuestionNotes(noteObject);

    }

    return (
        <Dialog maxWidth={"sm"} open={props.isNoteDialogOpen} fullWidth={true}>
            <Column style={{width: '90%', margin: 'auto'}}>
                <Row>
                    <div
                        className="fs-20 fw-500 pt-4 pb-4">{props.headerMessage ? props.headerMessage:''}
                    </div>
                </Row>
                <Row className="mt-2">
                    <textarea  id="note"
                                 className="w-100 outline-none input-border p-2 fs-13 h-100px"
                                 type="area"
                                 onChange={handleInputChange}
                                 placeholder=""
                                 value={note}
                                 error={noteError} required={true} autoComplete="off"/>
                </Row>
                <Row className="display-flex fc-red fs-14 py-1">{noteError}</Row>

                <Row horizontal={"end"} className="mb-5 pt-3">
                    <Button onClick={() => props.toggleNoteDialog()}
                            className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two fc-blue mx-1 fw-500 px-3 cursor-pointer">Cancel
                    </Button>
                    <Button onClick={saveNoteInQuestionObject}
                            className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                        {props.questionObject && Object.keys(props.questionObject).length > 1 && props.questionObject.notes && props.questionObject.notes.length > 0 ?
                            'Update' : 'Add'}
                    </Button>
                </Row>

            </Column>
        </Dialog>
        )
};

export default NoteDialogComponent;