import {Row} from "simple-flexbox";
import React from "react";
import BaseComponent from "../../modules/baseComponent";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Popover from "@material-ui/core/Popover";
import CriteriaCellComponent from "./CriteriaCellComponent";
import Utils from "../../utility";

export default class CriteriaMenuComponent extends BaseComponent {
    constructor(props) {
        super(props);
    }

    renderCriteriaListView = (props) => {
        let {searchCriteriaList} = props.state;
        return (
            <div className="display-flex flex-direction-column w-350 p-2 bg-pale-grey-two">
                <div className="display-flex fs-12 p-2 fw-500">CRITERIA</div>
                <div
                    className="display-flex flex-1 bg-white br-bright-grey-1 br-4 fs-10 max-h-250 overflow-y-auto z-index-10">
                    {searchCriteriaList && searchCriteriaList.length > 0 ? this.renderCriteriaList(props, searchCriteriaList) : "No Criteria Found"}
                </div>
            </div>
        )
    };

    renderCriteriaList = (props, searchCriteriaList) => {

        if(searchCriteriaList && searchCriteriaList.length)
            Utils.ascSortOnCriteria(searchCriteriaList, "addedOn");
        return (
            <div className="display-flex flex-direction-column p-2">
                {searchCriteriaList.map((criteriaObject, index) => {
                        if (criteriaObject && criteriaObject.isSubCriteria)
                            return "";
                        return (
                            <CriteriaCellComponent
                                key={index} criteriaObject={criteriaObject}
                                props={props}
                                searchCriteriaList={searchCriteriaList}
                            />
                        )
                    }
                )}
            </div>
        )
    };

    render() {
        let {toggleCriteriaMenuView, state} = this.props;
        return (
            <div className="bg-pale-grey br-4 fs-15 fc-warm-grey p-2 mr-2 ">
                <div
                    className="display-flex flex-direction-row justify-content-center align-items-center cursor-pointer">
                    <Row onClick={() => toggleCriteriaMenuView()}>
                        <div id="criteriaMenu" aria-describedby="criteriaMenu" color="inherit"
                             className="outline-none text-transform-capitalize fc-steel-grey fs-15"
                        >
                            <div className="h-25px selected-criteria">ADD IT SEARCH OPTIONS</div>
                        </div>
                        <ArrowDropDownIcon className='ml-2 mr-2 bg-pale-grey'/>
                    </Row></div>

                <Popover
                    open={state.isCriteriaMenuViewRequest}
                    anchorEl={document.getElementById("criteriaMenu")}
                    onClose={() => toggleCriteriaMenuView()}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                    <div className="display-flex">{this.renderCriteriaListView(this.props)}</div>
                </Popover>
            </div>
        )
    }


}