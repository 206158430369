import {Column, Row} from "simple-flexbox";
import React from "react";
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar/Avatar";
import LinearProgressBar from "../../common/components/LinearProgressBar";
import {eventConstants, genericConstants, pathConstants, stringConstants} from "../../constants";
import {history} from "../../managers/history";
import CustomButton from "../../common/components/CustomButton";
import Utility from "../../utility";


function getPartHeadingText(role, part) {
    if (!role || !part)
        return;
    switch (part) {
        case genericConstants.RECORD_PART.PART_A:
            return role === genericConstants.ROLES.LENDER || role === genericConstants.ROLES.SUB_LENDER ? "Other Parts" : (role === genericConstants.ROLES.SELLER_COUNSEL) ? "For Review" : "Your Part";
        case genericConstants.RECORD_PART.PART_B:
            return role === genericConstants.ROLES.LENDER || role === genericConstants.ROLES.SUB_LENDER ? "Your Part" : (role === genericConstants.ROLES.SELLER_COUNSEL) ? "" : "Other Parts";
        case genericConstants.RECORD_PART.PART_C1:
            if (role === genericConstants.ROLES.LENDER || role === genericConstants.ROLES.SUB_LENDER)
                return;
            return role === genericConstants.ROLES.SELLER_COUNSEL || role === genericConstants.ROLES.SUB_SELLER_COUNSEL ? "Your Part" : "Other Parts";
    }
}

function isSectionEditable(role, part) {
    if (!role || !part)
        return;
    switch (part) {
        case genericConstants.RECORD_PART.PART_A:
            if (role === genericConstants.ROLES.LENDER || role === genericConstants.ROLES.SUB_LENDER || role === genericConstants.ROLES.SELLER_COUNSEL)
                return false;
            else if (role === genericConstants.ROLES.ORIGINATION_COUNSEL || role === genericConstants.ROLES.SUB_ORIGINATION_COUNSEL)
                return true;
            break;
        case genericConstants.RECORD_PART.PART_B:
            return role === genericConstants.ROLES.LENDER || role === genericConstants.ROLES.SUB_LENDER;
        case genericConstants.RECORD_PART.PART_C1:
        case genericConstants.RECORD_PART.PART_C2:
            return role === genericConstants.ROLES.SELLER_COUNSEL || role === genericConstants.ROLES.SUB_SELLER_COUNSEL;
        default:
            return false;
    }
}

function romanize(num) {
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
            "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
            "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}

function checkSectionPermission(userPermission, part, sectionId) {
    if (part === genericConstants.RECORD_PART.PART_C1 || part === genericConstants.RECORD_PART.PART_C2)
        part = genericConstants.RECORD_PART.PART_C;
    if (!userPermission || !userPermission.permissionList || userPermission.permissionList.length < 1 || !sectionId || !part)
        return false;
    let filteredPermission = userPermission.permissionList.filter(permissionObj => permissionObj.permission === `REVIEW`
        && permissionObj.miscellaneous && permissionObj.miscellaneous.length > 0 && permissionObj.miscellaneous.includes(`PART-${part}`));
    if (filteredPermission.length > 0)
        return true;


    filteredPermission = userPermission.permissionList.filter(permissionObj => permissionObj.permission === `PART-${part}`
        && permissionObj.miscellaneous && permissionObj.miscellaneous.length > 0 && permissionObj.miscellaneous.includes(sectionId));

    return filteredPermission.length > 0;
}

const isSellerAndIsSectionApproved = (sectionObj, role) => {
    return (sectionObj.status === genericConstants.SECTION_STATUS.APPROVED && role === genericConstants.ROLES.SELLER_COUNSEL)
};

const isSellerAndIsSectionINReviewForPartAAndPartB = (sectionObj, role) => {
    return ((sectionObj.status === genericConstants.PART_TYPE.PART_A || sectionObj.status === genericConstants.PART_TYPE.PART_B) && sectionObj.status === genericConstants.SECTION_STATUS.IN_REVIEW && role === genericConstants.ROLES.SELLER_COUNSEL)
};

const RenderStatusView = (sectionObj, role) => {
    if (isSellerAndIsSectionApproved(sectionObj, role))
        return (
            <div className="fs-15 fc-black">{Utility.viewSectionStatus(genericConstants.SECTION_STATUS.IN_REVIEW)}</div>
        );
    else if (isSellerAndIsSectionINReviewForPartAAndPartB(sectionObj, role))
        return (
            <div
                className="fs-15 fc-black">{Utility.viewSectionStatus(genericConstants.SECTION_STATUS.IN_PROGRESS)}</div>
        );
    else
        return (<div className="fs-15 fc-black">{Utility.viewSectionStatus(sectionObj.status)}</div>);
};

const renderAssignedUsers = (assignedToArray) => {
    if (!assignedToArray || assignedToArray.length === 0 || !Array.isArray(assignedToArray))
        return;

    return <Row>{assignedToArray.map((userObj, index) => <Avatar className="fs-15 w-30" key={index}
                                                                 src={Utility.getSignedUrl(userObj && userObj.photo ? userObj.photo : null, "/images/user-placeholder.svg")}/>)}
    </Row>
};

function subUsersHeaderComponent(recordDetails, part) {
    if (!recordDetails || !part)
        return;
    let subUsers = [];
    switch (part) {
        case genericConstants.RECORD_PART.PART_A:
            subUsers = recordDetails.subOriginationCounsel;
            break;
        case genericConstants.RECORD_PART.PART_B:
            subUsers = recordDetails.subLender;
            break;
        case genericConstants.RECORD_PART.PART_C1:
        case genericConstants.RECORD_PART.PART_C2:
            subUsers = recordDetails.subSellerCounsel;
            break;
    }
    if (!subUsers)
        return;
    return (
        <Row>
            {subUsers.map((userObj, index) => <Avatar className="fs-15 w-30" key={index}
                                                      src={Utility.getSignedUrl(userObj && userObj.photo ? userObj.photo : null, "/images/user-placeholder.svg")}/>)}
        </Row>
    );
}

function PartsTableComponent(properties) {
    const {role, part, isForReviewTextVisible, isSectionSeparated, isSectionVisible, toggleSection, props, recordDetails, sectionList, userPermission, partCompletion} = properties;
    let yourPartText = getPartHeadingText(role, part);
    let isEditable = isSectionEditable(role, part);
    return (
        <div className="w-100 my-1">
            {isSectionSeparated ? <div className="w-100 mt-5"/> : <div/>}
            <Table size="small" className='br-1 p-1' fixedHeader={true}>
                {yourPartText && role !== genericConstants.ROLES.SUPER_ADMIN ? <TableHead className="data-table fw-500">
                    <TableRow className="data-table">
                        <TableCell component="th" className='p-none text-left'>
                            <div className="fs-16 fc-black fw-500 ">{yourPartText}</div>
                        </TableCell>
                        {isForReviewTextVisible ? "" : <TableCell component="th" className='p-none text-left'>
                            <div className="fs-15">{isForReviewTextVisible ? "" : "Assigned to"}</div>
                        </TableCell>}
                        {isForReviewTextVisible ? "" :
                            <TableCell component="th" className='p-none text-left' style={{width: 180}}>
                                <div className="fs-15">Completion</div>
                            </TableCell>}
                        <TableCell component="th" className='p-none text-left'>
                            <div className="fs-15">Status</div>
                        </TableCell>
                        <TableCell style={{width: 12}} component="th" className='p-none text-left'/>
                    </TableRow>
                </TableHead> : null}
                <TableRow className="data-table bg-pale-grey">
                    <TableCell component="th" className='p-none text-left'>
                        <div className="fs-16 fc-black fw-500"><img
                            src={isSectionVisible ? "/images/collapse.svg" : "/images/expand.svg"}
                            className="mr-2 cursor-pointer" onClick={() => toggleSection(part)}/>
                            {"LDR Part " + part}
                        </div>
                    </TableCell>
                    {isForReviewTextVisible ? "" : <TableCell component="th" className='p-none text-left'>
                        {subUsersHeaderComponent(recordDetails, part)}
                    </TableCell>}
                    {isForReviewTextVisible ? "" :
                        <TableCell component="th" className='p-none text-left' style={{width: 180}}>
                            <LinearProgressBar variant="determinate"
                                               color="secondary"
                                               value={partCompletion}/>
                        </TableCell>}
                    <TableCell component="th" className='p-none text-left'>
                        <div className="fs-15 fc-black fw-500">In Progress</div>
                    </TableCell>
                    <TableCell component="th" className='p-none justify-content-start' style={{width: 12}}>
                        {isForReviewTextVisible ? "" :
                            <img src={isEditable ? "/images/edit-icon.svg" : "/images/view.svg"}/>}
                    </TableCell>
                </TableRow>
                {isSectionVisible ?
                    <TableBody className={'data-table'}>
                        {sectionList && sectionList.length ? sectionList.map((sectionObj, index) => {
                            const isItemSelected = true;
                            const labelId = `roles-row-${index}`;
                            return (
                                <TableRow role="checkbox" aria-checked={isItemSelected} tabIndex={-1}
                                          selected={false} key={sectionObj._id}
                                          onClick={() => {
                                              if (checkSectionPermission(userPermission, part, sectionObj.sectionId)) {
                                                  props.dispatchAction(eventConstants.SET_SECTION, (romanize(index + 1) + '. ' + sectionObj.name));
                                                  props.dispatchAction(eventConstants.SECTION_LIST, sectionList);
                                                  if (role === genericConstants.ROLES.SUPER_ADMIN)
                                                      history.push(pathConstants.DASHBOARD_MENU.PUBLISH_REQUESTS + '/' + recordDetails._id + '/' + sectionObj.sectionId);
                                                  else
                                                      history.push(pathConstants.DASHBOARD_MENU.INFORMATION_RECORDS + '/' + recordDetails._id + '/' + sectionObj.sectionId);
                                              }
                                          }}
                                          className={checkSectionPermission(userPermission, part, sectionList[index].sectionId) ? "cursor-pointer" : "cursor-not-allowed"}>
                                    <TableCell padding="checkbox" className={'p-none text-left'}>
                                        <div
                                            className="fs-15 fc-dark-slate-blue"> {romanize(index + 1) + '. ' + sectionObj.name}
                                        </div>
                                    </TableCell>
                                    {isForReviewTextVisible ? "" :
                                        <TableCell component="td" id={labelId} scope="row" padding="none"
                                                   className=" text-left">
                                            {renderAssignedUsers(sectionObj ? sectionObj.assignedTo : [])}
                                        </TableCell>}
                                    {isForReviewTextVisible ? "" :
                                        <TableCell component="td" id={labelId} scope="row" style={{width: 180}}
                                                   padding="none" className=" text-left">
                                            <LinearProgressBar variant="determinate"
                                                               color="secondary"
                                                               value={sectionObj && Object.keys(sectionObj).length > 0 && sectionObj.progress ? parseInt(sectionObj.progress) : 0}/>
                                        </TableCell>}
                                    <TableCell component="td" id={labelId} scope="row"
                                               padding="none" className=" text-left">
                                        {RenderStatusView(sectionObj, role)}

                                    </TableCell>
                                    <TableCell component="th" className='p-none justify-content-start'
                                               style={{width: 12}}>
                                        {isForReviewTextVisible && isSellerAndIsSectionApproved(sectionObj, role) ?
                                            <CustomButton
                                                class={"my-2 outline-none text-transform-capitalize accept-button cursor-pointer"}
                                                buttonText={"Review"}
                                            /> : isForReviewTextVisible ? "" :
                                                <img src={isEditable ? "/images/edit-icon.svg" : "/images/view.svg"}/>}
                                    </TableCell>

                                </TableRow>)
                        }) : null}
                    </TableBody> : null}
            </Table>
        </div>
    );
};

export default PartsTableComponent;
