import React from "react";
import {getSignedURL} from "../../managers/awsService";
import AvatarView from "./AvatarView";
import {Row} from "simple-flexbox";

const CounselFieldComponent = (props) => {
    let avatarSrc = props.logo || props.data && props.data.photo && (typeof props.data.photo === "string" ? props.data.photo : props.data.photo.fileName) || "";
    return (
        <Row vertical="center"
             className='display-flex flex-direction-row justify-content-between h-50px min-w-300 cursor-pointer'
             key={props.index} onClick={() => props.toggleSelectedCounsel(props.index)}>
            <Row vertical="center">
                <AvatarView source={getSignedURL(avatarSrc || props.data.logo, '/images/user-placeholder.svg')}/>
                <div className="fs-15 fw-500 mx-2 fc-dark-slate-blue ">{props.name}</div>
            </Row>
            <img src={props.isSelected ? '/images/tick-icon.svg' : ''}
                 className=''/>
        </Row>
    )
};

export default CounselFieldComponent;
