import React from "react";
import BaseComponent from '../baseComponent'
import SettingsComponent from './settingsComponent'
import Utils, {dispatchAction} from "../../utility";
import {apiFailureConstants, eventConstants, genericConstants, pathConstants, stringConstants} from "../../constants";
import {CompanyService, UserService} from "../../services";
import {history} from "../../managers/history";
import connect from "react-redux/es/connect/connect";
import {getSignedURL, uploadFileToS3} from "../../managers/awsService";
import SessionManager from '../../managers/sessionManager';

class Settings extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            userRole: "",
            email: "",
            emailError: "",
            password: "",
            passwordError: "",
            isPasswordVisible: false,
            showSaveButton: false,
            logoLoading: false,
            companyData: {},
            company: {
                name: "",
                address: "",
                websiteUrl: "",
                contactEmail: "",
                contactName: "",
                logo: "",
                logoURL: "",
                contactNumber: "",
            },
            profile: {
                logo: "",
                logoURL: "",
            },
            firstName: "",
        }
    }

    componentDidMount() {
        let userRole = Utils.getUserRole(this.props.user.userDetails);
        this.setState({userRole});
        if (userRole === genericConstants.ROLES.LENDER || userRole === genericConstants.ROLES.ORIGINATION_COUNSEL || userRole === genericConstants.ROLES.SELLER_COUNSEL)
            this.getCompanyDetails();
        else
            this.getUserDetails();
    };

    getCompanyDetails = async () => {
        let companyId = Utils.getCompanyID(this.props);
        if (!companyId) {
            Utils.apiFailureToast("Unable to get Company Information");
            return;
        }
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, companyResponse] = await Utils.parseResponse(CompanyService.getCompanyDetail(companyId));
        if (error) {
            Utils.apiFailureToast("Unable to get Company Information");
            return;
        }
        if (companyResponse && Object.keys(companyResponse).length > 0) {
            this.updateCompanyDataInState(companyResponse);
            this.props.dispatchAction(eventConstants.GET_COMPANY_SUCCESS, companyResponse);
        }

        this.props.dispatchAction(eventConstants.HIDE_LOADER);
    };

    getUserDetails = () => {
        if (!this.props || !this.props.user || !this.props.user.userDetails) {
            Utils.apiFailureToast("Unable to get user details");
            return;
        }
        this.setState({firstName: this.props.user.userDetails.firstName})
    };

    updateCompanyDataInState = async (companyResponse) => {
        let company = {
            name: companyResponse.name || "",
            address: Utils.generateAddressString(companyResponse.address),
            websiteUrl: companyResponse.websiteUrl || "",
            contactEmail: companyResponse.contactEmail || "",
            contactName: companyResponse.contactName || "",
            logo: companyResponse.logo || "",
            logoURL: await getSignedURL(companyResponse.logo),
            contactNumber: companyResponse.contactNumber || ""
        };
        this.setState({companyData: companyResponse, company});
    };

    handleChange = (event) => {
        let {company} = this.state;
        company[event.target.id] = event.target.value;
        this.setState({company: company, showSaveButton: true});
    };

    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };

    toggleSaveButton = () => {
        this.setState({showSaveButton: !this.state.showSaveButton})
    };

    onLogoutClicked = async (event) => {
        // event.preventDefault();
        let requestData = {
            user: this.props.user && this.props.user.userDetails && Object.keys(this.props.user.userDetails).length > 0 && this.props.user.userDetails.userID ?
                this.props.user.userDetails.userID : '',
            deviceID: this.props.user && this.props.user.deviceID ? this.props.user.deviceID : ''
        };
        let [error, logoutResponse] = await Utils.parseResponse(UserService.logoutUser(requestData));
        if (error) {
            Utils.apiFailureToast(apiFailureConstants.LOGOUT);
            return;
        }
        this.props.dispatchAction(eventConstants.LOGOUT_SUCCESS);
        this.deleteCredentialCookies();
        history.replace(pathConstants.LOGIN);
    };

    /**
     * Deletes all login session cookies
     */
    deleteCredentialCookies = () => {
        SessionManager.removeDataFromCookies("company");
        SessionManager.removeDataFromCookies("userPermission");
        SessionManager.removeDataFromCookies("sessionToken");
        SessionManager.removeDataFromCookies("user");
    };

    onFileCancel = () => {
        this.setState({
            files: []
        });
    };
    onFileDrop = async (files, props, requestType = "") => {
        this.setState({logoLoading: true});
        if (!files || files.length < 1) {
            Utils.apiFailureToast(apiFailureConstants.UPLOAD_PHOTO);
            this.setState({logoLoading: false});
            return;
        }
        let file = files[0];
        let fileDetail = Utils.getFileName(file.name);
        let image = "";
        if (requestType === genericConstants.ACTION_TYPE.UPDATE_PROFILE_IMAGE)
            image = Utils.getNewFileNameWithId('user', fileDetail.extension, props.userDetails.userID);
        if (requestType === genericConstants.ACTION_TYPE.UPDATE_COMPANY_IMAGE)
            image = Utils.getNewFileNameWithId('comp', fileDetail.extension, props.companyData.companyId);
        let resFromS3 = await uploadFileToS3(file, image, file.type).catch(err => {
            Utils.apiFailureToast(apiFailureConstants.UPLOAD_PHOTO);
            return false;
        });
        let imageURL = await getSignedURL(image);
        if (!resFromS3 || !imageURL) {
            Utils.apiFailureToast(apiFailureConstants.UPLOAD_PHOTO);
            return false;
        }

        if (!imageURL || !resFromS3) {
            this.setState({logoLoading: false});
            return;
        }

        if (requestType === genericConstants.ACTION_TYPE.UPDATE_COMPANY_IMAGE) {
            let {company} = this.state;
            company['logo'] = image;
            company['logoURL'] = imageURL;
            await this.setState({company, logoLoading: false});
            this.updateCompanyLogo();
        }

        if (requestType === genericConstants.ACTION_TYPE.UPDATE_PROFILE_IMAGE) {
            let {profile} = this.state;
            profile['logo'] = image;
            profile['logoURL'] = imageURL;
            await this.setState({profile, logoLoading: false});
            this.updateProfilePicture();
        }
    };
    updateCompanyLogo = () => {
        let request = {
            companyId: Utils.getCompanyID(this.props),
            logo: this.state.company.logo
        };
        this.updateCompany(request);
    };

    updateProfilePicture = () => {
        let request = {
            logo: this.state.company.logo
        };
        //this.changeProfilePicture(request);
    };

    updateCompanyAddress = (companyInfo) => {
        let request = {
            companyId: Utils.getCompanyID(this.props),
            address: Utils.parseLocationObject(companyInfo)
        };
        this.updateCompany(request)

    };
    changeUserPassword = async (passwordInfo) => {
        let request = {
            userID: this.props.user && this.props.user.userDetails && Object.keys(this.props.user.userDetails).length > 0 && this.props.user.userDetails.userID ?
                this.props.user.userDetails.userID : "",
            oldPassword: passwordInfo.currentPassword,
            password: passwordInfo.newPassword,
        };
        await this.changePassword(request)

    };

    updateCompanyDetailsOnSave = () => {
        let request = {
            companyId: Utils.getCompanyID(this.props),
            name: this.state.company.name,
            websiteUrl: this.state.company.websiteUrl,
            contactEmail: this.state.company.contactEmail,
            contactName: this.state.company.contactName,
            contactNumber: this.state.company.contactNumber,
        };
        this.setState({showSaveButton: false});
        this.updateCompany(request);

    };

    updateCompany = async (requestData) => {
        if (!requestData || Object.keys(requestData).length < 1 || !requestData.companyId)
            return;
        let [error, companyResponse] = await Utils.parseResponse(CompanyService.updateCompanyService(requestData));
        if (error)
            this.props.dispatchAction(eventConstants.UPDATE_COMPANY_FAILURE, null);
        if (companyResponse && Object.keys(companyResponse).length > 0) {
            this.updateCompanyDataInState(companyResponse);
            this.props.dispatchAction(eventConstants.UPDATE_COMPANY_SUCCESS, companyResponse);
        }
    };

    changePassword = async (requestData) => {
        if (!requestData || Object.keys(requestData).length < 1 || !requestData.userID)
            return;
        let [error, response] = await Utils.parseResponse(UserService.changePassword(requestData));
        if (error) {
            let errMsg = error && Object.keys(error).length > 0 && error.message && error.message.length > 0
            && error.message[0] && Object.keys(error.message[0]).length > 0 && error.message[0].msg ? error.message[0].msg : "Change password Failed!";
            Utils.apiFailureToast(errMsg);
            return false;
        }
        Utils.apiSuccessToast(stringConstants.PASSWORD_UPDATED_SUCCESSFULLY);
    };

    changeProfilePicture = async (requestData) => {
        if (!requestData || Object.keys(requestData).length < 1 || !requestData.userID)
            return;
        return;
        let [error, response] = await Utils.parseResponse(UserService.updateUser(requestData));
        if (error) {
            let errMsg = error && Object.keys(error).length > 0 && error.message && error.message.length > 0
            && error.message[0] && Object.keys(error.message[0]).length > 0 && error.message[0].msg ? error.message[0].msg : "Change password Failed!";
            Utils.apiFailureToast(errMsg);
            return false;
        }
        Utils.apiSuccessToast(stringConstants.PROFILE_PICTURE_UPDATED_SUCCESSFULLY);
    };

    render() {
        return (
            <SettingsComponent
                state={this.state}
                companyDetails={this.props.company}
                user={this.props.user}
                handleChange={this.handleChange}
                onChangeEvent={this.onChangeEvent}
                onLogoutClicked={this.onLogoutClicked}
                onFileCancel={this.onFileCancel}
                toggleSaveButton={this.toggleSaveButton}
                onFileDrop={this.onFileDrop}
                updateCompanyAddress={this.updateCompanyAddress}
                updateCompanyDetailsOnSave={this.updateCompanyDetailsOnSave}
                changeUserPassword={this.changeUserPassword}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user}
};

export default connect(mapStateToProps, {dispatchAction})(Settings);
