import React from "react";
import BaseComponent from '../../baseComponent'
import PublishRequestComponent from './publishRequestComponent'
import {PublishRecordService} from "../../../services";
import Utility, {dispatchAction} from "../../../utility";
import {connect} from "react-redux";
import {eventConstants, genericConstants, pathConstants} from "../../../constants";
import {history} from "../../../managers/history";
import ActivationRequestComponent from "../activationRequests/activationRequestComponent";

class PublishRequest extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            roleType: this.props.roleType,
            currentSort: 'asc',
            dataFilter: '',
            data: [],
            activePage: 1,
            counselList: [],
            filteredList: [],
            searchText: "",
            predictions: [],
            organisationCounsel: [],
            counselName: '',
            contactPerson: '',
            address: '',
            email: '',
            invitationStatus: '',
            selectedAssociate: {},
            subUserObjectForEdit: {},
            locationFilter: genericConstants.INITIAL_FILTER_VALUES.LOCATION,
            statusFilter: genericConstants.INITIAL_FILTER_VALUES.STATUS,
        };
    }

    componentDidMount() {
        this.initiateComponent();
    };

    initiateComponent() {
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        this.getPublishRequestList().then((response) => {
            if (response && Object.keys(response).length) {
                response.sort((a, b) => b.addedOn - a.addedOn);
                this.setState({data: response, filteredList: response})
                this.skippedCounselList(1, 10);
                this.setFilterData(response);
                this.props.dispatchAction(eventConstants.HIDE_LOADER);
            }
        }).catch(error => {
            Utility.consoleLogger("getPublishRequestList : error", error)
            this.props.dispatchAction(eventConstants.HIDE_LOADER);

        });
    }

    setFilterData(responseData) {
        if (!responseData || !responseData.length)
            return;
        let dataFilter = {state: [], status: []};
        for (const record of responseData) {
            dataFilter.state.push(record?.address?.state);
            dataFilter.status.push(record?.status?.toLowerCase());
        }
        this.setState({dataFilter: {state: [...new Set(dataFilter.state)], status: [...new Set(dataFilter.status)]}})
    }

    getPublishRequestList = async () => {
        let requestObj = {
            "status": genericConstants.COMPANY_STATUS.PENDING,
            "selectionKeys": ""
        };
        return await PublishRecordService.getPublishRequestList(requestObj)
    };

    getPredictions = (value) => {
        return this.state.organisationCounsel.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)

    };

    onChangeEvent = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: event.target.value});

        if (value && value.length > 0) {
            let predictions = this.getPredictions(value);
            this.setState({predictions: predictions})
        } else {
            this.setState({predictions: []})
        }
    };

    hideSuggestionBox = async (item) => {
        let address = item.address.city + ', ' + item.address.state;
        this.setState({selectedAssociate: item});
        await this.setState({
            predictions: [],
            counselName: item.name,
            contactPerson: item.contactName,
            address: address,
            email: item.contactEmail
        });
    };

    sorting = (data, key) => {
        this.sortData(data, key);
        this.onSortChange();
    };

    sortData = (data, key) => {

        let cur = this.state.currentSort;
        // a and b are simple objects of array
        let sortedData = data.sort(function (a, b) {
            console.log('a', a);
            let statusA = a[key].toLowerCase(),
                statusB = b[key].toLowerCase();
            return (cur === 'asc' ? (statusA < statusB ? -1 : 1) : (statusA > statusB ? -1 : 1))
        });

        this.setState({counselList: sortedData.slice(0, 10)});
        return this.state.counselList;

    };

    onSortChange = () => {
        const {currentSort} = this.state;
        let nextSort;

        if (currentSort === 'asc') nextSort = 'desc';
        else if (currentSort === 'desc') nextSort = 'asc';

        this.setState({
            currentSort: nextSort
        });
    };

    skippedCounselList = async (start, end) => {
        this.setState({counselList: this.state.filteredList.slice(start - 1, end)});
    };

    skippedOriginationCounselList = async (start, end) => {
        this.setState({counselList: this.state.filteredList.slice(start - 1, end)});
    };

    handlePageChange = async (pageNumber) => {
        console.log("pageNumber", pageNumber);
        await this.setState({activePage: pageNumber});
        let start = (pageNumber * 10) - 9;
        let end = (pageNumber * 10);
        this.skippedOriginationCounselList(start, end);
    };

    counselFilteredListSetValues = async (key, value) => {
        let filterList;
        await this.setState({[key]: value, filteredList: this.state.data});
        if (this.state.search !== "") {
            filterList = this.state.filteredList.filter(filterLender =>
                filterLender.publishedBy && filterLender.publishedBy.name.toLowerCase().includes(this.state.search.toLowerCase()));
            this.setState({filteredList: filterList})
        }
        this.skippedOriginationCounselList(1, 10);

    };
    onReviewClick = (publishRequestObj) => {
        if (!publishRequestObj || Object.keys(publishRequestObj).length < 1 || !publishRequestObj.publishRequestId || !publishRequestObj.recordId)
            return;
        this.props.dispatchAction(eventConstants.REVIEW_PUBLISH_REQUEST, publishRequestObj);
        history.push(pathConstants.DASHBOARD_MENU.PUBLISH_REQUESTS + '/' + publishRequestObj.recordId);

    };


    onSortClicked = (key, order) => {
        Utility.sortArrayForKey(this.state.filteredList, key, order);
        this.setState({filteredList: this.state.filteredList});
    };

    onSearchTextChanged = (event) => {
        let searchValue = event.target.value;
        this.state.searchText = Utility.isEmpty(searchValue) ? null : searchValue;
        this.refreshList();
    };

    onFilterChanged = (event) => {
        this.state[event.target.name] = event.target.value;
        this.refreshList();
    };

    async refreshList() {
        let {counselList, searchText, locationFilter, statusFilter} = this.state;
        if (!counselList || counselList.length < 1)
            return;
        //FILTER PROCESSING
        let filteredList = counselList.filter(counselObject => {
                return (Utility.isEmpty(searchText)
                    || (counselObject?.publishedBy?.name?.toLowerCase()?.includes(searchText.toLowerCase()))
                    || (counselObject?.fileNo?.toLowerCase()?.includes(searchText.toLowerCase()))
                    || (counselObject?.loanTag?.toLowerCase()?.includes(searchText.toLowerCase()))
                    || `${counselObject?.address?.city + ", " + counselObject?.address?.state}`.toLowerCase()?.includes(searchText.toLowerCase()))
                    && ((statusFilter === "" || statusFilter === "All Status")
                        || counselObject.status.toLowerCase().includes(statusFilter.toLowerCase()))
                    && ((locationFilter === "" || locationFilter === "All Locations")
                        || counselObject.address.state.toLowerCase().includes(locationFilter.toLowerCase()));
            }
        );
        this.setState({filteredList});
    }

    render() {
        return (
            <PublishRequestComponent
                state={this.state}
                onChangeEvent={this.onChangeEvent}
                sorting={this.sorting}
                handlePageChange={this.handlePageChange}
                counselFilteredListSetValues={this.counselFilteredListSetValues}
                hideSuggestionBox={this.hideSuggestionBox}
                onReviewClick={this.onReviewClick}
                onSortClicked={this.onSortClicked}
                onSearchTextChanged={this.onSearchTextChanged}
                onFilterChanged={this.onFilterChanged}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, company: state.company, publishRequest: state.publishRequest}
};

export default connect(mapStateToProps, {dispatchAction})(PublishRequest);