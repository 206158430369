import {combineReducers} from "redux";
import user from "./user";
import company from "./company";
import record from "./record";
import publishRequest from "./publishRequest";

export default combineReducers({
    user,
    company,
    record,
    publishRequest
});