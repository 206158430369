import React from "react";
import BaseComponent from '../baseComponent'
import ActivationStatusComponent from './activationStatusComponent'

export default class ActivationStatus extends BaseComponent {

    createCompany = () => {

    };

    render() {
        return (
            <ActivationStatusComponent/>
        );
    }
};