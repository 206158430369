import React from 'react'
import {Column, Row} from "simple-flexbox";
import {history} from "../../../managers/history";
import {genericConstants, pathConstants} from "../../../constants";
import Utils from "../../../utility";
import {getSignedURL} from "../../../managers/awsService";

function HeaderComponent() {
    function goBack() {
        history.replace(pathConstants.DASHBOARD_MENU.PUBLISH_REQUESTS);
    }

    return (
        <Row vertical="center" className="">
            <div className="fs-24 font-weight-bold"><img
                onClick={() => goBack()}
                className="cursor-pointer mr-3"
                src="/images/back-icon.svg"/>Rejection Details
            </div>
        </Row>
    )
}

function PublishRecordView(props) {
    let {recordDetails} = props;
    if (!recordDetails)
        return;
    let imageURL = getSignedURL(recordDetails.propertyPhoto, '/images/recent-companies.svg');
    return (
        <Row className="my-4" vertical="start">
            <Column className="max-w-225 w-100-per">
                <img src={imageURL} className="max-w-225 max-h-130 w-100-per"/>
            </Column>
            <div className="text-left line-height-2-27 w-350 fw-500 fs-14">
                <Row className="mx-3 justify-content-between">
                    <div className="fc-blue-grey mx-3">File Number</div>
                    <div className="fc-dark-slate-blue mx-3">
                        {recordDetails.fileNo ? recordDetails.fileNo : ""}</div>
                </Row>
                <Row className="mx-3 justify-content-between">
                    <div className="fc-blue-grey mx-3">Loan Tag</div>
                    <div className="fc-dark-slate-blue mx-3">
                        {recordDetails.loanTag ? recordDetails.loanTag : ""}</div>
                </Row>
                <Row className="mx-3 justify-content-between">
                    <div className="fc-blue-grey mx-3">Location</div>
                    <div className="fc-dark-slate-blue mx-3">
                        {recordDetails.address && recordDetails.address.city ? recordDetails.address.city : ""}
                        {recordDetails.address && recordDetails.address.city && recordDetails.address.state ? "," : ""}
                        {recordDetails.address && recordDetails.address.state ? " " + recordDetails.address.state : ""}
                    </div>
                </Row>
                <Row className="mx-3 justify-content-between">
                    <div className="fc-blue-grey mx-3">Request Date</div>
                    <div className="fc-dark-slate-blue mx-3">
                        {recordDetails && recordDetails.addedOn ? Utils.epochToDate(recordDetails.addedOn, "DD MMM YYYY") : ""}
                    </div>
                </Row>
                <Row className="mx-3 justify-content-between">
                    <div className="fc-blue-grey mx-3">Status</div>
                    <div className="fc-red mx-3">
                        Blockchain Publication Rejected
                    </div>
                </Row>
            </div>
        </Row>
    )
}

function RejectionReasonView(props) {
    let {recordDetails} = props;
    if (!recordDetails)
        return;
    return (
        <div>
            <div className="w-50per">
                <div className="fs-16 mt-lg-4 fw-500">Reason of Rejection</div>
                <div className="br-bright-grey-6 br-4 w-530 min-h-165 p-3">
                    {recordDetails.note}
                </div>
            </div>
        </div>
    )
}

function RejectionRecordComponent(props) {
    return (
        <Column horizontal={'center'} className="fc-dark-slate-blue w-100 p-5 min-vh-100 h-100 justify-content-between">
            <Column className="w-100">
                {HeaderComponent(props)}
                {PublishRecordView(props)}
                {RejectionReasonView(props)}
            </Column>
        </Column>
    );
}

export default RejectionRecordComponent;
