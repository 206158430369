import React from "react";
import BaseComponent from '../baseComponent'
import {history} from "../../managers/history";
import {
    actionTypeConstants,
    apiFailureConstants,
    eventConstants,
    genericConstants,
    stringConstants
} from "../../constants";
import Utils, {dispatchAction} from "../../utility";
import {CompanyService, RecordService, ReportService, UserService} from "../../services";
import {connect} from "react-redux";
import LenderIRComponent from '../lender/informationRecords/informationRecordsComponent';
import OriginationCounselIRComponent from '../originationCounsel/informationRecords/informationRecordsComponent';
import SellerCounselIRComponent from '../sellerCounsel/informationRecords/informationRecordsComponent';
import {getSignedURL, uploadFileToS3} from "../../managers/awsService";

class InformationRecords extends BaseComponent {
    constructor(props) {
        super(props);
        let url = [];
        this.state = {
            selectedReportType: 'EXPORT_REPORTS',
            isNewRecordDialogOpen: false,
            isAddNewCounselDialogOpen: false,
            fileNumber: "",
            fileNumberError: "",
            loanTag: "",
            loanTagError: "",
            propertyType: "",
            propertyPhoto: "",
            propertyPhotoUrl: "",
            propertySubType: "",
            streetAddress: "",
            streetAddressError: "",
            city: "",
            cityError: "",
            state: "",
            stateError: "",
            country: "",
            countryError: "",
            lat: "",
            lng: "",
            subLender: [],
            counselList: [],
            selectedRecordList: [],
            selectedSubLender: [],
            selectedOriginationCounsel: [],
            selectedSellerCounsel: [],
            subLenderError: "",
            originationCounsel: [],
            originationCounselError: "",
            sellerCounsel: [],
            sellerCounselError: "",

            //CriteriaMenuView
            isCriteriaMenuViewRequest : false,
            openPopOversId: null,
            //Search Criteria
            searchCriteriaList: [],
            selectedCriteria: [],
            criteriaObject: {},
            criteriaInput: "",
            criteriaResultsWithIds: [],
            //Search Filters
            searchText: "",
            filteredList: [],
            fileNoFilter: "",
            locationFilter: genericConstants.INITIAL_FILTER_VALUES.LOCATION,
            lenderFilter: genericConstants.INITIAL_FILTER_VALUES.LENDER,
            ocFilter: genericConstants.INITIAL_FILTER_VALUES.OC,
            scFilter: genericConstants.INITIAL_FILTER_VALUES.SC,
            poolFilter: genericConstants.INITIAL_FILTER_VALUES.POOL,
            statusFilter: genericConstants.INITIAL_FILTER_VALUES.STATUS,
            moreFilter: genericConstants.INITIAL_FILTER_VALUES.MORE,
            isCriteriaSelected: false,
            //Map View
            isMapViewRequested : false
        };
    }

    componentDidMount() {
        this.getRecords().catch(error => {
            Utils.consoleLogger("getRecords error", error);
        });
        this.setDefaults();
    }

    async setDefaults() {
        let subLender = await this.getSubLenderInformation();
        this.getSellerCounselInformation();
        this.getOriginationCounselInformation();
        this.getFilters();
        this.setState({subLender});
    }

    async getRecords() {
        let requestData = {
            "limit": 0,
            "skip": 0,
            "selectionKey": "",
            "role": Utils.getUserRole(this.props.user.userDetails)
        };

        if (requestData['role'] === genericConstants.ROLES.SUB_LENDER || requestData['role'] === genericConstants.ROLES.SUB_SELLER_COUNSEL || requestData['role'] === genericConstants.ROLES.SUB_ORIGINATION_COUNSEL)
            requestData['userId'] = this.props.user.userDetails.userID || "";

        let companyId = Utils.getCompanyID(this.props);
        if (!companyId || !requestData.role) {
            Utils.apiFailureToast(apiFailureConstants.GET_RECORD_LIST);
            return;
        }

        if (requestData['role'] === genericConstants.ROLES.SUB_LENDER || requestData['role'] === genericConstants.ROLES.SUB_SELLER_COUNSEL || requestData['role'] === genericConstants.ROLES.SUB_ORIGINATION_COUNSEL)
            if (!requestData['userId']) {
                Utils.apiFailureToast(apiFailureConstants.GET_RECORD_LIST);
                return;
            }


        let [error, recordListResponse] = await Utils.parseResponse(RecordService.getRecordList(requestData, companyId));
        if (error)
            Utils.apiFailureToast(apiFailureConstants.GET_RECORD_LIST);

        if (recordListResponse) {
            this.setState({recordList: recordListResponse, filteredList: recordListResponse})
        }
    };

    async getFilters() {
        let {user, company} = this.props;
        let requestData = {};
        requestData['type'] = Utils.getUserRole(user.userDetails);
        requestData['companyId'] = company.companyId;
        requestData["filterList"] = genericConstants.FILTERS;
        requestData["userId"] = user.userDetails && user.userDetails.userID;
        let [error, filterListResponse] = await Utils.parseResponse(RecordService.getFilters(requestData));
        if (error)
            Utils.apiFailureToast(apiFailureConstants.GET_FILTER_LIST);
        if (filterListResponse) {
            await this.setState({
                dataFilter: filterListResponse && filterListResponse.filterList,
                searchCriteriaList: filterListResponse && filterListResponse.criteriaList
            });
        }
    }

    onChangeEvent = (event) => {
        let targetElement = (event.target.id).split('.')[1] || event.target.id;
        this.setState({[targetElement]: event.target.value});
    };

    updateAddressData = (addressObject) => {
        this.setState({...addressObject});

    };

    toggleNewRecordDialog = () => {
        this.setState({isNewRecordDialogOpen: !this.state.isNewRecordDialogOpen});
    };

    handleAddUser = (actionType) => {
        switch (actionType) {
            case actionTypeConstants.ADD_ORIGINATION_COUNSEL:
                this.addOriginationCounsel();
                break;
            case actionTypeConstants.ADD_SELLER_COUNSEL:
                this.addSellerCounsel();
                break;
            case actionTypeConstants.ADD_SUB_LENDER:
                this.addSubLenderCounsel();
                break;
            default :
                break;
        }
    };

    handleChangeSelectedSubLender = (values) => {
        this.setState({selectedSubLender: values});
    };

    handleChangeSelectedOriginationCounsel = (values) => {
        this.setState({selectedOriginationCounsel: values});
    };

    handleChangeSelectedSellerCounsel = (values) => {
        this.setState({selectedSellerCounsel: values});
    };

    addOriginationCounsel = () => {
        let originationCounsel = this.state.originationCounsel;
        this.setState({
            counselDialogHeading: actionTypeConstants.ADD_ORIGINATION_COUNSEL_TEXT,
            counselList: originationCounsel
        });
        this.toggleNewCounselDialog();

    };

    addSubLenderCounsel = () => {
        let subLender = this.state.subLender;
        this.setState({counselDialogHeading: actionTypeConstants.ADD_SUB_USER_TEXT, counselList: subLender});
        this.toggleNewCounselDialog();
    };

    addSellerCounsel = () => {
        let sellerCounsel = this.state.sellerCounsel;
        this.setState({
            counselDialogHeading: actionTypeConstants.ADD_SELLER_COUNSEL_TEXT,
            counselList: sellerCounsel
        });

        this.toggleNewCounselDialog();
    };

    toggleNewCounselDialog = () => {
        if (this.state.isAddNewCounselDialogOpen)
            this.setState({counselDialogHeading: '', counselList: []});

        this.setState({isAddNewCounselDialogOpen: !this.state.isAddNewCounselDialogOpen});
    };

    toggleSelectedCounsel = (index) => {
        if (index >= 0) {
            let {counselList} = this.state;
            /*Allowing Multi Selection for Users but preventing it for Counsel Selection*/
            counselList.map((counselObject, arrayIndex) => {
                // Only Counsel Object contains "companyType" key.
                if(counselObject.hasOwnProperty("companyType") && arrayIndex !== index)
                    counselObject.isSelected = false;
            })
            counselList[index].isSelected = !counselList[index].isSelected;
            this.setState({counselList});
        }

    };

    handleSelectedCounsel = (actionType) => {
        let {counselList} = this.state;
        let selectedList = counselList.filter(obj => obj.isSelected === true);
        switch (actionType) {
            case actionTypeConstants.ADD_ORIGINATION_COUNSEL_TEXT:
                this.setState({selectedOriginationCounsel: selectedList});
                break;
            case actionTypeConstants.ADD_SELLER_COUNSEL_TEXT:
                this.setState({selectedSellerCounsel: selectedList});

                break;
            case actionTypeConstants.ADD_SUB_USER_TEXT:
                this.setState({selectedSubLender: selectedList});

                break;

            default :
                break;
        }

        this.toggleNewCounselDialog();
    };

    handleCreateRecord = async (event) => {
        event.preventDefault();
        let {
            selectedSellerCounsel, selectedOriginationCounsel,
            fileNumber, loanTag, propertyPhoto, propertySubType,
            propertyType, streetAddress, city, state, country, lat, lng, originationCounsel, sellerCounsel, selectedSubLender
        } = this.state;

        let {company} = this.props;
        if (!company || !company.companyId)
            return;
        if (this.validateAddRecordForm()) {
            this.props.dispatchAction(eventConstants.SHOW_LOADER);
            //TODO - RATNESH : MAKE REQUEST PARSER FOR THIS.
            let requestData = {
                "fileNo": fileNumber,
                "loanTag": loanTag,
                "propertyPhoto": propertyPhoto,
                "propertySubType": propertySubType,
                "propertyType": propertyType,
                "propertyAddress": {
                    "lat": lat || 0,
                    "lng": lng || 0,
                    "streetAddress": streetAddress,
                    "suite": "",
                    "city": city,
                    "state": state,
                    "country": country
                },
                "lender": this.getLenderInformation(),
                "sellerCounsel": selectedSellerCounsel[0],
                "originationCounsel": selectedOriginationCounsel[0],
                "subLender": selectedSubLender
            };
            let [error, recordResponse] = await Utils.parseResponse(RecordService.addRecordService(requestData));
            this.props.dispatchAction(eventConstants.HIDE_LOADER);
            if (error)
                this.props.dispatchAction(eventConstants.ADD_RECORD_FAILURE, null);
            if (recordResponse) {
                this.toggleNewRecordDialog();
                this.props.dispatchAction(eventConstants.ADD_RECORD_SUCCESS, recordResponse);
                this.getRecords().catch(error => {
                    Utils.consoleLogger("getRecords error", error);
                });
            }

        }
    };

    validateAddRecordForm = () => {
        let {propertyPhoto, fileNumber, loanTag, streetAddress, city, state, country, selectedOriginationCounsel, selectedSellerCounsel, selectedSubLender} = this.state;
        this.setState({
            propertyPhotoError: !Utils.isEmpty(propertyPhoto) ? "" : stringConstants.EMPTY_PROPERTY_PHOTO,
            fileNumberError: !Utils.isEmpty(fileNumber) ? "" : stringConstants.EMPTY_FILE_NUMBER,
            loanTagError: !Utils.isEmpty(loanTag) ? "" : stringConstants.EMPTY_LOAN_TAG,
            streetAddressError: !Utils.isEmpty(streetAddress) ? "" : stringConstants.EMPTY_PROPERTY_ADDRESS,
            cityError: !Utils.isEmpty(city) ? "" : stringConstants.EMPTY_CITY,
            stateError: !Utils.isEmpty(state) ? "" : stringConstants.EMPTY_STATE,
            countryError: !Utils.isEmpty(country) ? "" : stringConstants.EMPTY_STATE,
            subLenderError: !Utils.isEmptyArray(selectedSubLender) ? "" : stringConstants.EMPTY_SUB_LENDER,
            sellerCounselError: !Utils.isEmptyArray(selectedSellerCounsel) ? "" : stringConstants.EMPTY_SELLER_COUNSEL,
            originationCounselError: !Utils.isEmptyArray(selectedOriginationCounsel) ? "" : stringConstants.EMPTY_OC,
        });
        return !Utils.isEmpty(propertyPhoto) && !Utils.isEmpty(fileNumber) && !Utils.isEmpty(loanTag) && !Utils.isEmpty(city) && !Utils.isEmpty(streetAddress)
            && !Utils.isEmpty(state) && !Utils.isEmpty(country) && !Utils.isEmptyArray(selectedSellerCounsel)
            && !Utils.isEmptyArray(selectedSubLender) && !Utils.isEmptyArray(selectedOriginationCounsel);
    };

    //TODO Make sure these data insertion should be dynamic!
    getLenderInformation() {
        let {company} = this.props;
        return {
            "companyId": company.companyId,
            "limbId": company.limbId,
            "name": company.name,
            "logo": company.logo,
            "owner": company.owner
        }
    }

    async getSellerCounselInformation() {
        let {company} = this.props;
        let requestObj = {
            "companyId": company.companyId,
            "companyType": genericConstants.ROLES.SELLER_COUNSEL,
        };
        CompanyService.getAssociateList(requestObj).then((response) => {
            if (response && response.associatedTo)
                this.setState({sellerCounsel: response.associatedTo})
        }).catch(error => {
            Utils.consoleLogger("getSellerCounselInformation : error", error)
        });
    }


    getOriginationCounselInformation() {
        let {company} = this.props;
        let requestObj = {
            "companyId": company.companyId,
            "companyType": genericConstants.ROLES.ORIGINATION_COUNSEL,
        };
        CompanyService.getAssociateList(requestObj).then((response) => {
            if (response && response.associatedTo)
                this.setState({originationCounsel: response.associatedTo})
        }).catch(error => {
            Utils.consoleLogger("getOriginationCounselList : error", error)
        });
    }

    async getSubLenderInformation() {
        return await this.getSubUsersList();
    }

    getSubUsersList = async () => {
        if (!this.props || !this.props.user || !this.props.user.userDetails || !this.props.user.userDetails.miscellaneous || !this.props.user.userDetails.miscellaneous.company) {
            Utils.apiFailureToast("Unable to fetch Sub-Users!");
            return;
        }
        this.props.dispatchAction(eventConstants.SHOW_LOADER);

        let requestData = {};
        requestData['miscellaneous.company.companyId'] = this.props.user.userDetails.miscellaneous.company.companyId;
        let [error, userListResponse] = await Utils.parseResponse(UserService.getUserList(requestData));

        if (!userListResponse || userListResponse.length < 0)
            return [];

        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        let filteredList = userListResponse.filter(obj => obj.isInactive === 0).map(obj => {
            return {
                userId: obj.userID,
                photo: obj.photo,
                name: `${obj.firstName}`,
                email: obj.emailID,
                roleLevel: obj && obj.miscellaneous && obj.miscellaneous.roleLevel ? obj.miscellaneous.roleLevel : null,
                isSelected: false
            }
        });
        return filteredList;
    };

    /*
    getOriginationCounselInformation() {
            return [{
                "invitationStatus": "ACCEPTED",
                "companyId": "5db095803a5b440031dc4420",
                "limbId": "A&B3764",
                "name": "Peter Mckee"
            }]
     }*/

    onRecordClicked = (recordObject) => {
        history.push('/dashboard/information-records/' + recordObject.recordId);
        // ToastComponent("success");
    };


    onFileCancel = () => {
        this.setState({
            files: []
        });
    };
    onFileDrop = async (files) => {
        if (!files || files.length < 1)
            return;
        this.setState({files, propertyPhotoError: ''});
        let file = files[0];
        let fileDetail = Utils.getFileName(file.name);
        let propertyPhoto = Utils.getNewFileName('rec', fileDetail.extension);
        this.setState({propertyPhoto: propertyPhoto, files});

        let resFromS3 = await uploadFileToS3(file, propertyPhoto, file.type).catch(err => {
            Utils.apiFailureToast(apiFailureConstants.UPLOAD_PROPERTY_PHOTO);
            // if(err.code === 'RequestTimeTooSkewed')
            return false;
        });
        let s3Url = await getSignedURL(propertyPhoto);
        if (!resFromS3 || !s3Url) {
            Utils.apiFailureToast(apiFailureConstants.UPLOAD_PROPERTY_PHOTO);
            return false;
        }

        this.setState({propertyPhoto: propertyPhoto, propertyPhotoUrl: s3Url});
    };


    /*
            Origination Counsel specific Methods
     */

    onEditClick = (record) => {
        history.push('/dashboard/information-records/' + record.recordId);
    };

    onAcceptHandler = (record, invitationAction) => {
        //Utils.apiSuccessToast("Under Development!!!");
        this.updateInvitation(record, invitationAction);
    };

    deleteInvitation = (record, invitationAction) => {
        Utils.apiSuccessToast("Under Development!!!");
    };

    updateInvitation = async (record, invitationAction) => {
        let {user, company} = this.props;
        if (!user || !user.userDetails || !company.companyId || !record.recordId) {
            Utils.apiFailureToast("Unable to update invitation status!");
            return;
        }
        let role = Utils.getUserRole(user.userDetails);
        if (!role) {
            Utils.apiFailureToast("Unable to update invitation status!");
            return;
        }
        let requestData = {};
        requestData['updatedBy'] = role;
        requestData['recordId'] = record.recordId;
        requestData['companyId'] = company.companyId;
        requestData['invitationStatus'] = invitationAction;

        let [error, updateInvitationResponse] = await Utils.parseResponse(RecordService.updateInvitation(requestData));
        if (error)
            Utils.apiFailureToast("Unable to update invitation status!");
        if (updateInvitationResponse) {
            if (invitationAction === genericConstants.INVITATION_STATUS.REJECTED)
                Utils.apiSuccessToast("Invitation Rejected Successfully!");
            if (invitationAction === genericConstants.INVITATION_STATUS.ACCEPTED)
                Utils.apiSuccessToast("Invitation Accepted Successfully!");
            this.getRecords().catch(error => {
                Utils.consoleLogger("updateInvitation error", error);
            });
        }
    };

    onSortClicked = (key, order) => {
        Utils.sortArrayForKey(this.state.filteredList, key, order);
        this.setState({filteredList: this.state.filteredList});
    };

    onSearchTextChanged = (event) => {
        let searchValue = event.target.value;
        this.state.searchText= Utils.isEmpty(searchValue) ? null : searchValue;
        this.refreshList();
    };

    criteriaListForDropdown = () => {
        return Utils.generateCriteriaListForDropdown(this.state.searchCriteriaList);
    };

    onCriteriaSelection = async (criteriaObject) => {
        if (!criteriaObject || !criteriaObject.criteriaId)
            return;
        if ((this.state.selectedCriteria.filter(criteria => criteria.criteriaId === criteriaObject.criteriaId)).length > 0)
            return;
        this.setState({selectedCriteria: [...this.state.selectedCriteria, criteriaObject]});
    };

    handlePopoverClick = id => {
        this.setState({openPopOversId: id})
    };

    handlePopoverClose = () => {
        this.setState({openPopOversId: null});
    };
    //API CALL TO ELASTIC SEARCH VIA LDR SERVICE
    applyCriteriaFilters = async (criteriaObject, criteriaInput) => {
        if (!criteriaObject.type || !criteriaObject.name || this.state.criteriaInput === null) {
            Utils.apiFailureToast(apiFailureConstants.SEARCH_BY_CRITERIA);
            return;
        }
        let requestObject = {
            criteriaType: criteriaObject.type,
            criteria: criteriaObject.name,
            inputText: criteriaInput,        //this.state.criteriaInput
            questionId: criteriaObject.questionId
        };
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, searchByCriteriaResponse] = await Utils.parseResponse(RecordService.searchByCriteria(requestObject));
        this.handlePopoverClose();
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error) {
            Utils.apiFailureToast(apiFailureConstants.SEARCH_BY_CRITERIA);
            return;
        }
        this.filterRecordListBasedOnCriteria(searchByCriteriaResponse, criteriaObject.criteriaId);
    };

    onFilterChanged = async (event) => {
        await this.setState({[event.target.name]: event.target.value});
        this.refreshList();
    };

    async refreshList() {
        let {recordList, fileNoFilter, searchText, ocFilter, scFilter, locationFilter, lenderFilter, poolFilter, statusFilter} = this.state;
        if (!recordList || recordList.length < 1)
            return;
        //FILTER PROCESSING
        let filteredList = recordList.filter(recordObject => {
                return (fileNoFilter === "") && (Utils.isEmpty(searchText)
                    || recordObject.fileNo.toLowerCase().includes(searchText.toLowerCase())
                    || (recordObject.pool && recordObject.pool.name ? recordObject.pool.name.toLowerCase().includes(searchText.toLowerCase()) : false)
                    || recordObject.loanTag.toLowerCase().includes(searchText.toLowerCase()))
                    && ((lenderFilter === "" || lenderFilter === "All Lenders")
                        || recordObject.lender.name.toLowerCase().includes(lenderFilter.toLowerCase()))
                    && ((poolFilter === "" || poolFilter === "All Pools")
                        || (recordObject.pool && recordObject.pool.name && recordObject.pool.name.toLowerCase().includes(poolFilter.toLowerCase())))
                    && ((statusFilter === "" || statusFilter === "All Status")
                        || recordObject.status.toLowerCase().includes(statusFilter.toLowerCase()))
                    && ((locationFilter === "" || locationFilter === "All Locations")
                        || recordObject.address.state.toLowerCase().includes(locationFilter.toLowerCase()))
                    && ((ocFilter === "" || ocFilter === genericConstants.INITIAL_FILTER_VALUES.OC)
                        || recordObject.originationCounsel.name.toLowerCase().includes(ocFilter.toLowerCase()))
                    && ((scFilter === "" || scFilter === genericConstants.INITIAL_FILTER_VALUES.SC)
                        || recordObject.sellerCounsel.name.toLowerCase().includes(scFilter.toLowerCase()));
            }
        );
        //CALL to Search Criteria filter
        this.filterBasedOnCriteria(filteredList);
    }

    filterBasedOnCriteria = (currentFilteredList) => {
        if (this.state.criteriaAssociatedArray && this.state.recordIdSet) {// check selected criteria
            currentFilteredList = currentFilteredList.filter(({recordId}) => this.state.recordIdSet.has(recordId));
        }
        this.setState({filteredList: currentFilteredList});
    };

    filterRecordListBasedOnCriteria = async (searchByCriteriaResponse, criteriaId) => {
        if (!this.state.filteredList || !searchByCriteriaResponse || searchByCriteriaResponse.length < 1) {
            this.setState({filteredList: []});
            return;
        }
        let criteriaResultsWithIds = [];
        searchByCriteriaResponse.forEach(({recordId}) => {
            criteriaResultsWithIds.push(recordId);
        });

        //Making list of Applied Criteria with the record Ids of results.
        this.setState(
            {
                criteriaAssociatedArray: {
                    ...this.state.criteriaAssociatedArray, [criteriaId]: new Set(criteriaResultsWithIds)
                },
                criteriaInput: ""
            });

        //Find Unique Record IDs.
        this.makeRecordIdSet();
    };

    intersection = (setA, setB) => {
        let _intersection = new Set()
        for (let elem of setB) {
            if (setA.has(elem)) {
                _intersection.add(elem)
            }
        }
        return _intersection
    };

    makeRecordIdSet = async () => {
        let filteredList;

        for (let criteriaId in this.state.criteriaAssociatedArray) {
            if (!filteredList)
                filteredList = this.state.criteriaAssociatedArray[criteriaId];
            else {
                filteredList = this.intersection(filteredList, this.state.criteriaAssociatedArray[criteriaId])
            }
        }

        // let recordIds = [];
        // for (let criteriaId in this.state.criteriaAssociatedArray) {
        //     recordIds = recordIds.concat(this.state.criteriaAssociatedArray[criteriaId]);
        // }
        // let recordIdSet = new Set(recordIds);
        await this.setState({recordIdSet: filteredList});
        this.refreshList();
    };


    handleRemoveCriteria = (criteriaId) => {
        let updatedList = this.state.selectedCriteria.filter(criteriaObject => criteriaObject.criteriaId !== criteriaId);
        this.updateCriteriaListAndCriteriaResultsWithIds(criteriaId, updatedList);
    };

    updateCriteriaListAndCriteriaResultsWithIds = async (criteriaId, updatedSelectedCriteriaList) => {
        // Remove results related to this criteria(removal request) from the criteriaResultsWithIds;
        let criteriaAssociatedArray = Object.assign({}, this.state.criteriaAssociatedArray);
        delete criteriaAssociatedArray[criteriaId];
        await this.setState({
            criteriaAssociatedArray,
            selectedCriteria: updatedSelectedCriteriaList
        });
        this.makeRecordIdSet();
    };


    downloadReport = async (selectReportType) => {
        if (selectReportType === genericConstants.REPORT_TYPE.EXPORT_REPORT_TITLE) {
            Utils.apiFailureToast(apiFailureConstants.SELECT_REPORT_TYPE);
            return;
        }
        let {selectedRecordList} = this.state;
        if (!selectedRecordList || selectedRecordList.length < 1) {
            Utils.apiFailureToast(apiFailureConstants.SELECT_RECORD);
            return;
        } else if (selectedRecordList.length > 1) {
            Utils.apiFailureToast(apiFailureConstants.SELECT_SINGLE_RECORD);
            return;
        }

        let request = {
            type: selectReportType,
            recordId: selectedRecordList[0],
            fileName: this.selectFileNameBasedOnType(selectReportType)
        };
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, response] = await Utils.parseResponse(ReportService.createReport(request));
        if (error) {
            Utils.apiFailureToast(apiFailureConstants.EXPORT_REPORT);
            this.props.dispatchAction(eventConstants.HIDE_LOADER);
            return;
        }
        this.props.dispatchAction(eventConstants.HIDE_LOADER);

    };

    selectFileNameBasedOnType = (reportType) => {
        switch (reportType) {
            case genericConstants.REPORT_TYPE.REP_EXCEPTION:
                return genericConstants.FILE_NAME.REP_EXCEPTION;
            case genericConstants.REPORT_TYPE.SERVICING_ON_BOARDING:
                return genericConstants.FILE_NAME.SERVICING_ON_BOARDING;
            case genericConstants.REPORT_TYPE.OWNER_BORROWER:
                return genericConstants.FILE_NAME.OWNER_BORROWER;
            case genericConstants.REPORT_TYPE.DUE_DILIGENCE:
                return genericConstants.FILE_NAME.DUE_DILIGENCE;
            case genericConstants.REPORT_TYPE.REPO_REVIEW:
                return genericConstants.FILE_NAME.REPO_REVIEW;
            default:
                return "Report";
        }
    };

    updateSelectedRecordList = (list) => {
        this.setState({selectedRecordList: list});
    };

    toggleMapView = () => {
        this.setState({isMapViewRequested : !this.state.isMapViewRequested});
    };

    toggleCriteriaMenuView = () => {
        this.setState({isCriteriaMenuViewRequest : !this.state.isCriteriaMenuViewRequest})
    };

    selectComponentBasedOnRole = () => {
        let role = Utils.getUserRole(this.props.user.userDetails);
        switch (role) {
            case genericConstants.ROLES.LENDER :
            case genericConstants.ROLES.SUB_LENDER :
                return (
                    <LenderIRComponent state={this.state} isNewRecordDialogOpen={this.state.isNewRecordDialogOpen}
                                       onMenuClick={this.onMenuClick}
                                       userDetails={this.props.user.userDetails}
                                       toggleNewRecordDialog={this.toggleNewRecordDialog}
                                       handleAddUser={this.handleAddUser}
                                       onRecordClicked={this.onRecordClicked}
                                       handleCreateRecord={this.handleCreateRecord}
                                       onChangeEvent={this.onChangeEvent}
                                       onFileCancel={this.onFileCancel}
                                       onFileDrop={this.onFileDrop}
                                       onSortClicked={this.onSortClicked}
                                       onFilterChanged={this.onFilterChanged}
                                       onSearch={this.onSearchTextChanged}
                                       toggleNewCounselDialog={this.toggleNewCounselDialog}
                                       toggleSelectedCounsel={this.toggleSelectedCounsel}
                                       handleSelectedCounsel={this.handleSelectedCounsel}
                                       handleChangeSelectedSubLender={this.handleChangeSelectedSubLender}
                                       handleChangeSelectedOriginationCounsel={this.handleChangeSelectedOriginationCounsel}
                                       handleChangeSelectedSellerCounsel={this.handleChangeSelectedSellerCounsel}
                                       downloadReport={this.downloadReport}
                                       updateSelectedRecordList={this.updateSelectedRecordList}
                                       userRole={role}
                                       criteriaListForDropdown={this.criteriaListForDropdown}
                                       onCriteriaSelection={this.onCriteriaSelection}
                                       handlePopoverClick={this.handlePopoverClick}
                                       handlePopoverClose={this.handlePopoverClose}
                                       applyCriteriaFilters={this.applyCriteriaFilters}
                                       handleRemoveCriteria={this.handleRemoveCriteria}
                                       updateAddressData={this.updateAddressData}
                                       toggleMapView={this.toggleMapView}
                                       toggleCriteriaMenuView={this.toggleCriteriaMenuView}
                    />
                );

            case genericConstants.ROLES.ORIGINATION_COUNSEL:
            case genericConstants.ROLES.SUB_ORIGINATION_COUNSEL:
                return (
                    <OriginationCounselIRComponent state={this.state}
                                                   onMenuClick={this.onMenuClick}
                                                   userDetails={this.props.user.userDetails}
                                                   onEditClick={this.onEditClick}
                                                   onAcceptHandler={this.onAcceptHandler}
                                                   onRecordClicked={this.onRecordClicked}
                                                   onChangeEvent={this.onChangeEvent}
                                                   toggleInviteOCDialog={this.toggleInviteOCDialog}
                                                   sorting={this.sorting}
                                                   onSortClicked={this.onSortClicked}
                                                   onFilterChanged={this.onFilterChanged}
                                                   onSearch={this.onSearchTextChanged}
                                                   updateInvitation={this.updateInvitation}
                                                   deleteInvitation={this.deleteInvitation}
                                                   handlePageChange={this.handlePageChange}
                                                   counselFilteredListSetValues={this.counselFilteredListSetValues}
                                                   hideSuggestionBox={this.hideSuggestionBox}
                                                   onInviteCounselButtonClicked={this.onInviteCounselButtonClicked}
                                                   downloadReport={this.downloadReport}
                                                   updateSelectedRecordList={this.updateSelectedRecordList}
                                                   userRole={role}
                                                   criteriaListForDropdown={this.criteriaListForDropdown}
                                                   onCriteriaSelection={this.onCriteriaSelection}
                                                   handlePopoverClick={this.handlePopoverClick}
                                                   handlePopoverClose={this.handlePopoverClose}
                                                   applyCriteriaFilters={this.applyCriteriaFilters}
                                                   handleRemoveCriteria={this.handleRemoveCriteria}
                                                   toggleMapView={this.toggleMapView}
                                                   toggleCriteriaMenuView={this.toggleCriteriaMenuView}
                    />

                );
            case genericConstants.ROLES.SELLER_COUNSEL:
            case genericConstants.ROLES.SUB_SELLER_COUNSEL:
                return (
                    <SellerCounselIRComponent state={this.state}
                                              onMenuClick={this.onMenuClick}
                                              userDetails={this.props.user.userDetails}
                                              onEditClick={this.onRecordClicked}
                                              onAcceptHandler={this.onAcceptHandler}
                                              onRecordClicked={this.onRecordClicked}
                                              onChangeEvent={this.onChangeEvent}
                                              onSortClicked={this.onSortClicked}
                                              onFilterChanged={this.onFilterChanged}
                                              onSearch={this.onSearchTextChanged}
                                              downloadReport={this.downloadReport}
                                              updateSelectedRecordList={this.updateSelectedRecordList}
                                              userRole={role}
                                              criteriaListForDropdown={this.criteriaListForDropdown}
                                              onCriteriaSelection={this.onCriteriaSelection}
                                              handlePopoverClick={this.handlePopoverClick}
                                              handlePopoverClose={this.handlePopoverClose}
                                              applyCriteriaFilters={this.applyCriteriaFilters}
                                              handleRemoveCriteria={this.handleRemoveCriteria}
                                              toggleMapView={this.toggleMapView}
                                              toggleCriteriaMenuView={this.toggleCriteriaMenuView}
                    />
                );
            default :
                return (
                    <div>Invalid User Role!</div>
                )
        }
    };

    render() {
        return (
            this.selectComponentBasedOnRole()
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, company: state.company}
};

export default connect(mapStateToProps, {dispatchAction})(InformationRecords);
