import React from 'react'
import {Column, Row} from "simple-flexbox";
import {stringConstants} from "../../constants";
import CustomInput from "../../common/components/CustomInput";
import {Button} from "@material-ui/core";
import Dropzone from "react-dropzone";
import ProcessingLoader from "../../common/components/processingLoader";
import {getSignedURL} from "../../managers/awsService";
import Utils from "../../utility";


function CompanySetup(props) {
    let {state, onFileCancel, onFileDrop, updateCompany, onChangeEvent, onNumberChangeEvent} = props;
    let acceptanceString = '.jpg, .jpeg, .png, .svg';
    let loader = state.logoLoading ? <ProcessingLoader/> : null;
    return (
        <Column className="max-w-540 justify-content-center align-items-center text-center">
            <Column className="w-100 p-2">
                <form onSubmit={updateCompany} className="">
                    <Column horizontal={'center'}>
                        <Row className="m-3 fs-28 fc-dark-slate-blue fw-500 justify-content-center">
                            {stringConstants.SETUP_COMPANY_HEADER}
                        </Row>
                        <Row className="fs-18 justify-content-center fc-chambray ">
                            {stringConstants.SETUP_COMPANY_DETAIL}
                        </Row>
                        <Row className="mt-4">
                            <Dropzone
                                multiple={false}
                                accept={acceptanceString}
                                onDrop={(files) => {
                                    onFileDrop(files, props)
                                }}
                                noClick={true}
                                onFileDialogCancel={() => onFileCancel()}>
                                {({getRootProps, getInputProps, open}) => (
                                    <div {...getRootProps()} className="outline-none">
                                        <input {...getInputProps()} />
                                        <Column className="br-4 br-dark-grey m-4 px-5 py-3" horizontal={"center"}>
                                            {loader ? loader :
                                                <img
                                                    src={state.companyLogo ? state.companyLogo : "/images/company-logo-placeholder.svg"}
                                                    alt='limb-logo' className="max-w-150"/>
                                            }
                                            <Column className="mt-3">
                                                <p className="fc-warm-grey ">Company Logo</p>
                                                <Button onClick={() => open()}
                                                        className="outline-none text-transform-capitalize bg-azure fc-white fs-13">Upload</Button>
                                            </Column>
                                        </Column>
                                    </div>
                                )}
                            </Dropzone>

                        </Row>
                        <div className="w-85-percent fc-blue-grey">
                            <Column>
                                <Row className="fs-14 mt-3 fw-500" horizontal={"start"}>Company Website<p
                                    className="fc-red m-0">*</p></Row>
                                <CustomInput id="website" type="text" placeholder={''} onChange={onChangeEvent}
                                             value={state.website} error={state ? state.websiteError : ""}
                                             className="fs-15 p-2"/>
                            </Column>
                            <Column>
                                <Row className="fs-14 mt-3 fw-500" horizontal={"start"}>Primary Contact<p
                                    className="fc-red m-0">*</p></Row>
                                <CustomInput id="primaryContact" type="text" placeholder={''} onChange={onChangeEvent}
                                             value={state.primaryContact} error={state ? state.primaryContactError : ""}
                                             className="fs-15 p-2"/>
                            </Column>
                            <Column>
                                <Row className="fs-14 mt-3 fw-500" horizontal={"start"}>Contact Email<p
                                    className="fc-red m-0">*</p></Row>
                                <CustomInput id="companyEmail" type="text" placeholder={''} onChange={onChangeEvent}
                                             value={state.companyEmail} error={state ? state.companyEmailError : ""}
                                             className="fs-15 p-2"/>
                            </Column>
                            <Column>
                                <Row className="fs-14 mt-3 fw-500" horizontal={"start"}>Phone<p
                                    className="m-0 fw-300 fc-blue-grey">&nbsp;(Optional)</p></Row>
                                <CustomInput id="phone" type="text" placeholder={''} onChange={onNumberChangeEvent}
                                             value={state.phone} className="fs-15 p-2"/>
                            </Column>

                            <Button type='submit'
                                    className="outline-none text-transform-capitalize fc-white bg-blue w-100 h-50px fs-18 mt-5 ">Get
                                Started</Button>
                        </div>
                    </Column>

                </form>

            </Column>
        </Column>
    );
}

export default function CompanySetupComponent(props) {
    return (
        <div className="w-100 p-3 min-vh-100">
            <img src="/images/limb-logo.svg" alt='limb-logo' className="w-150"/>
            <Column horizontal={'center'} className="mb-5 mt-4 min-vh-100 jc-space-between">
                {CompanySetup(props)}
            </Column>
        </div>
    );
};