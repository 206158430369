import {Column, Row} from "simple-flexbox";
import React from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, LinearProgress,
    Select, withStyles
} from "@material-ui/core";
import CustomSelectInput from "../../../common/components/CustomSelectInput";
import CustomInput from "../../../common/components/CustomInput";
import CustomInputWithAddUser from "../../../common/components/CustomInputWithAddUser";
import {actionTypeConstants, genericConstants} from "../../../constants";
import Utils from "../../../utility";
import Avatar from "@material-ui/core/Avatar/Avatar";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Dropzone from 'react-dropzone';
import ProcessingLoader from "../../../common/components/processingLoader";
import CounselFieldComponent from "../../../common/components/CounselField";
import SortingComponent from "../../../common/components/SortingComponent";
import CriteriaInputComponent from "../../../common/components/CriteriaInputComponent";
import CriteriaMenuComponent from "../../../common/components/CriteriaMenuComponent";
import {getSignedURL} from "../../../managers/awsService";
import {Dropdown, Input} from 'semantic-ui-react'
import Notification from "../../notification";
import AddressForm from "../../../common/components/addressForm";
import MapComponent from "../../../common/components/MapView";


const HeaderComponent = (props) => {
    let {state, toggleNewRecordDialog, downloadReport} = props;
    let [selectReportType, updateReportType] = React.useState('Export Report');
    React.useEffect(() => {
        updateReportType(state.selectedReportType)
    }, [state.selectedReportType]);


    function onChangeEvent(event) {
        downloadReport(event.target.value);
        return;
    }

    return (
        <Row className="justify-content-between" style={{maxHeight: props.height}}>
            <div className="fs-24 font-weight-bold">Information Records</div>
            <Row vertical="center">
                <Notification/>
                <Select value={selectReportType} className="my-1 br-2 input-border" id="reportType"
                        input={<CustomSelectInput name="reportType" id="reportType"/>}
                        onChange={onChangeEvent}>
                    <MenuItem
                        value={genericConstants.REPORT_TYPE.EXPORT_REPORT_TITLE}>{genericConstants.VIEWABLE_REPORT_TYPE.EXPORT_REPORT_TITLE}</MenuItem>
                    <MenuItem
                        value={genericConstants.REPORT_TYPE.REP_EXCEPTION}>{genericConstants.VIEWABLE_REPORT_TYPE.REP_EXCEPTION}</MenuItem>
                    <MenuItem
                        value={genericConstants.REPORT_TYPE.SERVICING_ON_BOARDING}>{genericConstants.VIEWABLE_REPORT_TYPE.SERVICING_ON_BOARDING_TITLE}</MenuItem>
                    <MenuItem
                        value={genericConstants.REPORT_TYPE.OWNER_BORROWER}>{genericConstants.VIEWABLE_REPORT_TYPE.OWNER_BORROWER_TITLE}</MenuItem>
                    <MenuItem
                        value={genericConstants.REPORT_TYPE.DUE_DILIGENCE}>{genericConstants.VIEWABLE_REPORT_TYPE.DUE_DILIGENCE_TITLE}</MenuItem>
                    <MenuItem
                        value={genericConstants.REPORT_TYPE.REPO_REVIEW}>{genericConstants.VIEWABLE_REPORT_TYPE.REPO_REVIEW_TITLE}</MenuItem>
                </Select>
                {Utils.getUserRole(props.userDetails) === genericConstants.ROLES.LENDER ?
                    <Button onClick={toggleNewRecordDialog}
                            className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                        New Record
                    </Button> : null}
                <img src="/images/more-icon.svg" className="p-1 mx-1 cursor-pointer"
                     onClick={() => Utils.showUnderDevelopment()}/>
            </Row>
        </Row>
    )
};

const BorderLinearProgress = withStyles({
    root: {
        height: 6,
        backgroundColor: '#dfe2e8',
        borderRadius: '3px',
        width: '100%'
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#0052cc',
    },
})(LinearProgress);

const RecentlyViewedCell = (Obj, index, props) => {
    return (
        <Column className="br-4 b-1-white-two m-2 max-w-250 h-160px w-100-per cursor-pointer justify-content-between"
                key={index}
                onClick={() => props.onRecordClicked(props.userDetails.miscellaneous.recentlyVisitedRecord[index])}>
            <img src={getSignedURL(Obj.propertyPhoto)} placeholder={"/images/recent-companies.svg"}
                 className=" max-h-130 w-100-per px-2 pt-2"/>
            <Row className="p-1 justify-content-between" vertical="center">
                <div className="w-75">
                    <BorderLinearProgress
                        variant="determinate"
                        color="secondary"
                        value={Obj && Object.keys(Obj).length > 0 && Obj.progress ? parseInt(Obj.progress) : 0}
                    />
                </div>
                <div className="fs-12 w-25 fc-warm-grey text-right">{Utils.epocToPrettyTime(Obj.accessedOn)}</div>
            </Row>
        </Column>
    )
};

const RecentlyViewedComponent = (props) => {
    let recentlyVisitedRecord = props.userDetails && props.userDetails.miscellaneous && props.userDetails.miscellaneous.recentlyVisitedRecord && props.userDetails.miscellaneous.recentlyVisitedRecord.length > 0 ?
        props.userDetails.miscellaneous.recentlyVisitedRecord : [];
    return (
        <Column>
            <div className="fs-15 fc-blue-grey  my-2">Recently Accessed</div>
            <Row>
                {recentlyVisitedRecord && recentlyVisitedRecord.length > 0 ?
                    recentlyVisitedRecord.map((obj, index) => RecentlyViewedCell(obj, index, props))
                    : ""}
            </Row>
        </Column>
    )
};

const RecordsTableComponent = (props) => {
    const {updateSelectedRecordList, userRole, toggleAll, state, handleOnSelect, onRecordClicked, onSortClicked, toggleEmployeeDetailView, onCheckAdmin, onSearchTextChanged, handleFilter} = props;
    let {filteredList:  recordList, isMapViewRequested} = state;
    let [sortingState, updateSortingState] = React.useState({
        fileNo: null,
        'pool.name': null,
        status: null,
        completion: null
    });
    const [selected, setSelected] = React.useState([]);

    function isSelected(recordId) {
        return selected.indexOf(recordId) !== -1
    }

    function onDescendingSort(key) {
        onSortClicked(key, genericConstants.SORTING_ORDER.DESCENDING);
        updateSortingState({[key]: genericConstants.SORTING_ORDER.DESCENDING});
    }

    function onAscendingSort(key) {
        onSortClicked(key, genericConstants.SORTING_ORDER.ASCENDING);
        updateSortingState({[key]: genericConstants.SORTING_ORDER.ASCENDING});
    }

    function selectRecord(event, name) {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        updateSelectedRecordList(newSelected);

    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = recordList.map(n => n.recordId);
            setSelected(newSelecteds);
            updateSelectedRecordList(newSelecteds);
            return;
        }
        setSelected([]);
        updateSelectedRecordList([]);
    }

    return (
        <div>
            {!isMapViewRequested ? <div className="my-2 overflow-x-auto">
                <div className="display-flex ml-lg-4 mt-4"><span
                    className="fw-900 fc-blue mr-2">{recordList && recordList.length}</span> records found
                </div>
                {recordList ?
                    <Column className="bg-white h-100-per fs-15">
                        <Table size="small" className='br-1 p-1'>
                            <TableHead className="data-table fw-500">
                                <TableRow>
                                    {userRole === genericConstants.ROLES.SUB_LENDER ? "" :
                                        <TableCell padding="checkbox" style={{width: 12}} className={'p-none'}>
                                            <Checkbox className='table-checkbox'
                                                      onClick={event => handleSelectAllClick(event)}
                                            />
                                        </TableCell>}
                                    <TableCell component="th" className={'p-none'}>
                                        <Row vertical={"center"}>File Number
                                            <SortingComponent state={sortingState.fileNo}
                                                              onDescendingSort={() => onDescendingSort("fileNo")}
                                                              onAscendingSort={() => onAscendingSort("fileNo")}/>
                                        </Row>
                                    </TableCell>
                                    <TableCell component="th" className={'p-none'}>
                                        <Row vertical={"center"}>Loan Tag
                                            <SortingComponent state={sortingState.loanTag}
                                                              onDescendingSort={() => onDescendingSort("loanTag")}
                                                              onAscendingSort={() => onAscendingSort("loanTag")}/>
                                        </Row>
                                    </TableCell>
                                    <TableCell component="th" className={'p-none'}>
                                        <div className="cursor-pointer">Location</div>
                                    </TableCell>
                                    {userRole === genericConstants.ROLES.SUB_LENDER ? "" :
                                        <TableCell component="th" className={'p-none'}>
                                            <Row vertical={"center"}>Pool
                                                <SortingComponent state={sortingState['pool.name']}
                                                                  onDescendingSort={() => onDescendingSort("pool.name")}
                                                                  onAscendingSort={() => onAscendingSort("pool.name")}/>
                                            </Row>
                                        </TableCell>}
                                    <TableCell component="th" className={'p-none'}>
                                        <div className="cursor-pointer">Completion</div>
                                    </TableCell>
                                    <TableCell component="th" className={'p-none'}>
                                        <Row vertical={"center"}>Status
                                            <SortingComponent state={sortingState.status}
                                                              onDescendingSort={() => onDescendingSort("status")}
                                                              onAscendingSort={() => onAscendingSort("status")}/>
                                        </Row>
                                    </TableCell>
                                    <TableCell component="th" className={'p-none'}>Origination Counsel</TableCell>
                                    <TableCell component="th" className={'p-none'}>Seller Counsel</TableCell>
                                    <TableCell component="th" className={'p-none'}>Lender Sub-Users</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={'data-table'}>
                                {recordList.map((recordObj, index) => {
                                    const isItemSelected = isSelected(recordObj.recordId);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow role="checkbox" aria-checked={isItemSelected} tabIndex={-1}
                                                  selected={false}
                                            // onClick={() => onRecordClicked(recordObj)}
                                                  className="cursor-pointer">
                                            {userRole === genericConstants.ROLES.SUB_LENDER ? "" :
                                                <TableCell padding="checkbox" className={'p-none'}>
                                                    <Checkbox checked={isItemSelected} className={'table-checkbox'}
                                                              onClick={event => selectRecord(event, recordObj.recordId)}
                                                              inputProps={{'aria-label': labelId}}
                                                    />
                                                </TableCell>}
                                            <TableCell component="td" id={labelId} scope="row"
                                                       onClick={() => onRecordClicked(recordObj)}
                                                       padding="none">
                                                {recordObj && recordObj.fileNo ? recordObj.fileNo : "-"}
                                            </TableCell>
                                            <TableCell component="td" id={labelId} scope="row"
                                                       onClick={() => onRecordClicked(recordObj)}
                                                       padding="none">
                                                {recordObj && recordObj.loanTag ? recordObj.loanTag : "-"}
                                            </TableCell>
                                            <TableCell component="td" id={labelId} scope="row"
                                                       onClick={() => onRecordClicked(recordObj)}
                                                       padding="none">
                                                {recordObj && recordObj.address ? Utils.generateSortAddressString(recordObj.address) : "-"}
                                            </TableCell>
                                            {userRole === genericConstants.ROLES.SUB_LENDER ? "" :
                                                <TableCell component="td" id={labelId} scope="row"
                                                           onClick={() => onRecordClicked(recordObj)}
                                                           padding="none">
                                                    {recordObj && recordObj.pool && recordObj.pool.name && recordObj.pool.name.trim().length > 0 ? recordObj.pool.name : "-"}
                                                </TableCell>}
                                            <TableCell component="td" id={labelId} scope="row"
                                                       onClick={() => onRecordClicked(recordObj)}
                                                       padding="none">
                                                <BorderLinearProgress
                                                    variant="determinate"
                                                    color="secondary"
                                                    value={recordObj && Object.keys(recordObj).length > 0 && recordObj.progress ? parseInt(recordObj.progress) : 0}
                                                />
                                            </TableCell>
                                            <TableCell component="td" id={labelId} scope="row"
                                                       onClick={() => onRecordClicked(recordObj)}
                                                       padding="none">
                                                {recordObj && recordObj.status ? Utils.viewSectionStatus(recordObj.status) : genericConstants.SECTION_STATUS_FOR_VIEW.OPEN}
                                            </TableCell>
                                            <TableCell component="td" id={labelId} scope="row"
                                                       onClick={() => onRecordClicked(recordObj)}
                                                       padding="none">
                                                <div className="display-flex flex-direction-row align-items-center">
                                                    <Avatar
                                                        src={recordObj && recordObj.originationCounsel ? getSignedURL(recordObj.originationCounsel.logo) : "/images/company-placeholder.svg"}
                                                        className="p-1 mr-2"/>
                                                    {recordObj && recordObj.originationCounsel && recordObj.originationCounsel.name ? recordObj.originationCounsel.name : "-"}
                                                </div>
                                            </TableCell>
                                            <TableCell component="td" id={labelId} scope="row"
                                                       onClick={() => onRecordClicked(recordObj)}
                                                       padding="none">
                                                <div className="display-flex flex-direction-row align-items-center">
                                                    <Avatar
                                                        src={recordObj && recordObj.sellerCounsel && recordObj.sellerCounsel.logo ? getSignedURL(recordObj.sellerCounsel.logo) : "/images/company-placeholder.svg"}
                                                        className="p-1 mr-2"/>
                                                    {recordObj && recordObj.sellerCounsel && recordObj.sellerCounsel.name ? recordObj.sellerCounsel.name : "-"}
                                                </div>
                                            </TableCell>
                                            <TableCell component="td" id={labelId} scope="row"
                                                       onClick={() => onRecordClicked(recordObj)}
                                                       padding="none">
                                                <div className="display-flex flex-direction-row">
                                                    {recordObj && recordObj.subLender ? recordObj.subLender.map((lenderObj) => {
                                                        return <Avatar
                                                            src={lenderObj && lenderObj.photo && typeof lenderObj.photo === "string" ? getSignedURL(lenderObj.photo, "/images/user-placeholder.svg") : "/images/user-placeholder.svg"}
                                                            className="p-1"/>
                                                    }) : "-"}
                                                </div>
                                            </TableCell>

                                        </TableRow>)
                                })}
                            </TableBody>
                        </Table>
                    </Column>
                    : <Row className="p-5 mx-4 my-4 justify-content-center bg-color-white">
                        No Record found !
                    </Row>}
            </div> : renderMapView(props)}
        </div>
    );
};

const renderMapView = (props) => {
    return (
        <div>
            <MapComponent {...props}/>
        </div>
    )
};

const newRecordDialogComponent = (props) => {
    let {updateAddressData, handleChangeSelectedSellerCounsel, handleChangeSelectedOriginationCounsel, handleChangeSelectedSubLender, onChangeEvent, toggleNewRecordDialog, state, handleAddUser, handleCreateRecord} = props;
    let loader = state.propertyPhoto && !state.propertyPhotoUrl ? <ProcessingLoader/> : null;

    let acceptanceString = '.jpg, .jpeg, .png, .svg';

    function updateAddressInfo(addressObject) {
        updateAddressData(addressObject);
    }

    return (
        <Dialog open={state.isNewRecordDialogOpen} className="p-3 fc-blue-grey">

            <Column>
                <form className="m-4">
                    <Column flex={"1"}>
                        <p className=" fc-dark-slate-blue fs-18 fw-500">New Information Record</p>
                        <Row alignContent={"space-between"}>
                            <Column flex={"6"} alignContent={"space-between"}>
                                <Column>
                                    <Row className="fs-14 fw-500" horizontal={"start"}>File Number<p
                                        className="fc-red m-0">*</p></Row>
                                    <CustomInput id="fileNumber" type="text" placeholder={''}
                                                 onChange={onChangeEvent}
                                                 value={state.fileNumber} error={state ? state.fileNumberError : ""}
                                                 className="fs-15 p-2"/>
                                </Column>
                                <Column>
                                    <Row className="fs-14 fw-500" horizontal={"start"}>Loan Tag<p
                                        className="fc-red m-0">*</p></Row>
                                    <CustomInput id="loanTag" type="text" placeholder={''}
                                                 onChange={onChangeEvent}
                                                 value={state.loanTag} error={state ? state.loanTagError : ""}
                                                 className="fs-15 p-2"/>
                                </Column>
                            </Column>
                            <Column className="ml-3 min-w-150 mb-2">
                                <Row className="fs-14 fw-500" horizontal={"start"}>Property Photo<p
                                    className="fc-red m-0">*</p></Row>
                                <Column flex={"4"} className="br-4 b-1-white-two w-100 fc-mischka" vertical={"center"}
                                        horizontal={"center"} justifyContent={"space-between"}>
                                    <Dropzone
                                        multiple={false}
                                        accept={acceptanceString}
                                        onDrop={(files) => {
                                            props.onFileDrop(files)
                                        }}
                                        noClick={true}
                                        disableClick
                                        onFileDialogCancel={props.onFileCancel}>
                                        {({getRootProps, getInputProps, open}) => (
                                            <div {...getRootProps()} className="outline-none">
                                                <input {...getInputProps()} />
                                                <div className=" text-center" onClick={() => open()}>
                                                    {loader ? loader :
                                                        <img
                                                            src={state.propertyPhotoUrl ? state.propertyPhotoUrl : "/images/photo-icon.svg"}
                                                            alt='limb'
                                                            className={state.propertyPhotoUrl ? "min-w-40 max-w-125" : "w-40"}/>
                                                    }
                                                </div>
                                                <Column>
                                                    <Button onClick={() => open()}
                                                            className="outline-none text-transform-capitalize fc-mischka fs-13 cursor-pointer">{state.propertyPhotoUrl ? "Change Photo" : "Upload Photo"}</Button>
                                                </Column>
                                            </div>
                                        )}
                                    </Dropzone>
                                </Column>
                                {state && state.propertyPhotoError ?
                                    <div
                                        className="display-flex fc-red fs-14 py-1">{state.propertyPhotoError}</div> : ""}
                            </Column>
                        </Row>

                        <Row justifyContent={"space-between"}>
                            <Column flex={"0.48"}>
                                <Row className="fs-14 mt-3 fw-500" horizontal={"start"}>Property Type</Row>
                                <CustomInput id="propertyType" type="text" placeholder={''}
                                             onChange={onChangeEvent}
                                             value={state.propertyType}
                                             className="fs-15 p-2"/>
                            </Column>
                            <Column flex={"0.48"}>
                                <Row className="fs-14 mt-3 fw-500" horizontal={"start"}>Property Sub Type</Row>
                                <CustomInput id="propertySubType" type="text" placeholder={''}
                                             onChange={onChangeEvent}
                                             value={state.propertySubType}
                                             className="fs-15 p-2"/>
                            </Column>
                        </Row>
                        <Column className="bg-seperator h-1px my-4"></Column>
                        <AddressForm companyInfo={state} handleInputChange={onChangeEvent}
                                     updateAddressInfo={updateAddressInfo} requestFor={"propertyAddress"}/>

                        <Column className="bg-seperator h-1px my-4"></Column>
                        <Column>
                            <Row className="fs-14 fw-500" horizontal={"start"}>Lender Sub-Users<p
                                className="fc-red m-0">*</p></Row>

                            <CustomInputWithAddUser id="subLender" type="text" placeholder={''}
                                                    onChange={onChangeEvent}
                                                    isReadOnly={false}
                                                    isAddUserIconDisplay={true}
                                                    data={state.selectedSubLender}
                                                    values={state.selectedSubLender}
                                                    handleChangeSelected={(value) => handleChangeSelectedSubLender(value)}
                                                    handleAddUser={() => handleAddUser(actionTypeConstants.ADD_SUB_LENDER)}
                                                    error={state ? state.subLenderError : ""}
                                                    className="fs-15 "/>

                        </Column>
                        <Column>
                            <Row className="fs-14 mt-3 fw-500" horizontal={"start"}>Origination Counsel<p
                                className="fc-red m-0">*</p></Row>
                            <CustomInputWithAddUser id="originationCounsel" type="text" placeholder={''}
                                                    onChange={onChangeEvent}
                                                    isReadOnly={false}
                                                    isAddUserIconDisplay={true}
                                                    data={state.selectedOriginationCounsel}
                                                    values={state.selectedOriginationCounsel}
                                                    handleChangeSelected={(value) => handleChangeSelectedOriginationCounsel(value)}
                                                    handleAddUser={() => handleAddUser(actionTypeConstants.ADD_ORIGINATION_COUNSEL)}
                                                    error={state ? state.originationCounselError : ""}
                                                    className="fs-15"/>

                        </Column>
                        <Column>
                            <Row className="fs-14 mt-3 fw-500" horizontal={"start"}>Seller Counsel<p
                                className="fc-red m-0">*</p></Row>

                            <CustomInputWithAddUser id="sellerCounsel" type="text" placeholder={''}
                                                    onChange={onChangeEvent}
                                                    isReadOnly={false}
                                                    data={state.selectedSellerCounsel}
                                                    values={state.selectedSellerCounsel}
                                                    isAddUserIconDisplay={true}
                                                    handleAddUser={() => handleAddUser(actionTypeConstants.ADD_SELLER_COUNSEL)}
                                                    handleChangeSelected={(value) => handleChangeSelectedSellerCounsel(value)}
                                                    error={state ? state.sellerCounselError : ""}
                                                    className="fs-15"/>
                        </Column>
                    </Column>

                    <Row horizontal={"end"} className="mt-5">
                        <Button onClick={toggleNewRecordDialog}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two fc-blue mx-1 fw-500 w-150 px-3 cursor-pointer bg-transparent">Cancel
                        </Button>
                        <Button onClick={handleCreateRecord}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 w-150 px-3 cursor-pointer">Create
                        </Button>
                    </Row>
                </form>
            </Column>
        </Dialog>
    )
};


const AddNewCounselDialogComponent = (props) => {
    let {handleSelectedCounsel, toggleSelectedCounsel, toggleNewCounselDialog, state, handleAddUser, handleCreateRecord} = props;

    return (
        <Dialog open={state.isAddNewCounselDialogOpen} className="p-3 fc-blue-grey">

            <Column>
                <form className="m-3">
                    <Column flex={"1"}>
                        <Row className="justify-content-between mb-3">
                            <Column className=" fc-dark-slate-blue fs-16 fw-500">{state.counselDialogHeading}</Column>
                            <img src='/images/cross-icon.svg'
                                 className='w-15 pt-sm-1 cursor-pointer' onClick={toggleNewCounselDialog}
                            />
                        </Row>
                        <Row className="flex-direction-column">
                            {state.counselList && state.counselList.length > 0 &&
                                state.counselList.map((counselObj, index) => {
                                    return <CounselFieldComponent
                                        logo={counselObj && counselObj.photo && counselObj.photo.fileName ? counselObj.photo.fileName : ""}
                                        name={counselObj.name}
                                        index={index}
                                        isSelected={counselObj.isSelected}
                                        data={counselObj}
                                        toggleSelectedCounsel={toggleSelectedCounsel}
                                    />
                                })}
                        </Row>
                    </Column>

                    <Row horizontal={"end"} className="mt-3">
                        <Button onClick={() => handleSelectedCounsel(state.counselDialogHeading)}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">Add
                        </Button>
                    </Row>
                </form>
            </Column>
        </Dialog>
    )
};

const renderMenuItem = (value, index) => {
    if (!value)
        return "";
    return <MenuItem className="p-2 cursor-pointer outline-none" value={value} key={index}>{value}</MenuItem>
};

const criteriaComponentView = (props) => {
    if (!props.state || !props.state.selectedCriteria || props.state.selectedCriteria.length < 1)
        return "";
    return renderCriteriaCells(props);
};

const renderCriteriaCells = (props) => {
    return (
        <div className="display-flex flex-direction-row w-100 flex-wrap">
            {props.state.selectedCriteria.map((data, index) => {
                let propData = {...props, data: data, index};
                return <CriteriaInputComponent {...propData}/>
            })}
        </div>
    );
};

const SearchFilterComponent = (props) => {
    let {state, userRole} = props;
    return (
        <Column>
            <Row className="bg-color-white fs-15 mt-4" vertical="center">
                <Row className="br-2 bg-pale-grey p-2 b-1-pale-grey-three">
                    <input placeholder={'Search Records'} id={'search'} onChange={props.onSearch}
                           className="outline-none b-none bg-pale-grey fw-500"/>
                    <img className="mx-2" src="/images/search-icon.svg" alt="search"/>
                </Row>
                <Select value={state.locationFilter}
                        className="br-2 mx-2 fc-warm-grey"
                        onChange={props.onFilterChanged}
                        input={<CustomSelectInput name="locationFilter"/>}>
                    <MenuItem className="p-2 cursor-pointer outline-none"
                              value={genericConstants.INITIAL_FILTER_VALUES.LOCATION}>
                        {genericConstants.INITIAL_FILTER_VALUES.LOCATION}
                    </MenuItem>
                    {state && state.dataFilter && Object.keys(state.dataFilter).length > 0 && state.dataFilter.state && Object.keys(state.dataFilter.state).length > 0 ?
                        (state.dataFilter.state).map((key, index) => {
                            return renderMenuItem(key, index)
                        }) : ""}
                </Select>
                {userRole ? "" : <Select value={state.poolFilter}
                                         className="br-2 mx-2 fc-warm-grey"
                                         onChange={props.onFilterChanged}
                                         input={<CustomSelectInput name="poolFilter"/>}>
                    <MenuItem className="p-2 cursor-pointer outline-none"
                              value={genericConstants.INITIAL_FILTER_VALUES.POOL}>
                        {genericConstants.INITIAL_FILTER_VALUES.POOL}
                    </MenuItem>
                </Select>}
                <Select value={state.ocFilter}
                        className="br-2 mx-2 fc-warm-grey"
                        onChange={props.onFilterChanged}
                        input={<CustomSelectInput name="ocFilter"/>}>
                    <MenuItem className="p-2 cursor-pointer outline-none"
                              value={genericConstants.INITIAL_FILTER_VALUES.OC}>
                        {genericConstants.INITIAL_FILTER_VALUES.OC}
                    </MenuItem>
                    {state && state.dataFilter && Object.keys(state.dataFilter).length > 0 && state.dataFilter.originationCounsel && Object.keys(state.dataFilter.originationCounsel).length > 0 ?
                        Object.keys(state.dataFilter.originationCounsel).map((key, index) => {
                            return renderMenuItem(state.dataFilter.originationCounsel[key], index)
                        }) : ""}
                </Select>
                <Select value={state.scFilter}
                        className="br-2 mx-2 fc-warm-grey"
                        onChange={props.onFilterChanged}
                        input={<CustomSelectInput name="scFilter"/>}>
                    <MenuItem className="p-2 cursor-pointer outline-none"
                              value={genericConstants.INITIAL_FILTER_VALUES.SC}>
                        {genericConstants.INITIAL_FILTER_VALUES.SC}
                    </MenuItem>
                    {state && state.dataFilter && Object.keys(state.dataFilter).length > 0 && state.dataFilter.sellerCounsel && Object.keys(state.dataFilter.sellerCounsel).length > 0 ?
                        Object.keys(state.dataFilter.sellerCounsel).map((key, index) => {
                            return renderMenuItem(state.dataFilter.sellerCounsel[key], index)
                        }) : ""}
                </Select>
                <Select value={state.statusFilter}
                        className="br-2 mx-2 fc-warm-grey"
                        onChange={props.onFilterChanged}
                        input={<CustomSelectInput name="statusFilter"/>}>
                    <MenuItem className="p-2 cursor-pointer outline-none"
                              value={genericConstants.INITIAL_FILTER_VALUES.STATUS}>
                        {genericConstants.INITIAL_FILTER_VALUES.STATUS}
                    </MenuItem>
                    {state && state.dataFilter && Object.keys(state.dataFilter).length > 0 && state.dataFilter.status && Object.keys(state.dataFilter.status).length > 0 ?
                        Object.keys(state.dataFilter.status).map((key, index) => {
                            return renderMenuItem(state.dataFilter.status[key], index)
                        }) : ""}
                </Select>
                <CriteriaMenuComponent {...props}/>
            </Row>
        </Column>
    )
};

const MapViewSelectionComponent = (props) => {
    let {isMapViewRequested} = props.state;

    return (<div className=" outline-none
                       cursor-pointer position-absolute bottom-10 right-20"
                 onClick={() => props.toggleMapView()}>
        <Avatar
            src={isMapViewRequested ? "/images/list-view.svg" : "/images/map-view.svg"}
            className="w-60 h-60px"/>
    </div>)
};


const informationRecordsComponent = (props) => {
    return (
        <Column className="min-vh-100 p-4 fc-dark-slate-blue" style={{maxHeight: props.height}}>
            {HeaderComponent(props)}
            {RecentlyViewedComponent(props)}
            {SearchFilterComponent(props)}
            {criteriaComponentView(props)}
            {RecordsTableComponent(props)}
            {newRecordDialogComponent(props)}
            {AddNewCounselDialogComponent(props)}
            {MapViewSelectionComponent(props)}
        </Column>
    )
};
export default informationRecordsComponent;
