import {Column, Row} from "simple-flexbox";
import React from "react";
import {history} from '../../managers/history'
import Avatar from "@material-ui/core/Avatar/Avatar";
import LinearProgressBar from "../../common/components/LinearProgressBar";
import QuestionCell from './questionComponent'
import Utility from "../../utility";
import {
    genericConstants,
    pathConstants,
    questionListToPreventOtherQuestionInSection,
    stringConstants
} from "../../constants";
import {Button} from "@material-ui/core";
import CustomMenuBar from "../../common/components/menuBar";
import ActivityCell from "../../common/components/activityCell";
import Notification from "../notification";
import ConfirmationPopUp from "../../common/components/ConfirmationPopUp";
import RecordInfoComponent from "../../common/components/RecordInfoComponent";

const HeaderComponent = (props) => {
    return (
        <Column>
            <Row className="justify-content-between" style={{maxHeight: props.height}}>
                <div className="fs-24 font-weight-bold"><img onClick={() => props.reDirectToPreviousSection()}
                                                             className="cursor-pointer mr-3"
                                                             src="/images/back-icon.svg"/>
                    {props ? props.sectionName : "-"}
                </div>
                <Row vertical="center">
                    <Notification/>
                    <img src="/images/more-icon.svg" className="p-1 mx-1 cursor-pointer"/>
                </Row>
            </Row>
        </Column>
    )
};

const CommentCell = (commentObject) => {
    return (
        <Row className="m-1 w-100">
            <Avatar src="/images/user-placeholder.svg" className="p-1"/>
            <Column className="mx-1 w-100">
                <Row className="w-100">
                    <div className="w-50 fs-14 fw-500 fc-dark-slate-blue text-left">
                        {commentObject && commentObject.addedBy &&
                        commentObject.addedBy.name ? commentObject.addedBy.name : "-"}
                    </div>
                    <div className="w-50 fs-13 fc-warm-grey-two text-right">
                        {commentObject && commentObject.addedOn ? Utility.epocToPrettyTime(commentObject.addedOn) : ""}
                    </div>
                </Row>
                <p className="fc-dark-slate-blue word-break-all fs-14">{commentObject ? commentObject.comment : null}
                </p>
            </Column>
        </Row>
    );
};

const StatusAndActivityComponent = (props, isDisabled) => {
    let {sectionDetails, statusList, isAbleToUpdateStatusForView} = props ? props.state : {};
    let {userDetails, updateSectionStatus, state} = props;

    let [isSaveButtonVisible, toggleSaveButton] = React.useState(false);
    let [selectedItem, updateStatus] = React.useState({});
    React.useEffect(() => {
        updateStatus(sectionDetails && sectionDetails.status ? Utility.getSectionStatusForView(sectionDetails.status) : {})
    }, [sectionDetails]);
    let [commentText, onCommentTextChange] = React.useState("");

    async function onCommentClick() {
        if (!userDetails) {
            Utility.apiFailureToast("Unable to add comment!");
            return;
        }
        let commentObj = {};
        commentObj.addedBy = {};
        commentObj.addedBy.id = userDetails._id;
        commentObj.addedBy.name = userDetails.firstName;
        commentObj.addedBy.email = userDetails.emailID;
        // commentObj.addedBy.photo = userDetails.photo;
        commentObj.comment = commentText;
        commentObj.addedOn = new Date().getTime();
        commentObj.modifiedOn = new Date().getTime();

        await props.onAddComment(commentObj);
        onCommentTextChange("");
        toggleSaveButton(false);
    }

    return (
        <Column className="my-4 w-350 fs-16 " vertical="start">
            {/*<div style={{pointerEvents: isDisabled ? 'none' : 'default'}}>*/}
            <div>
                <Column className="my-1">
                    <Button className="outline-none text-transform-capitalize p-2
                    br-4 b-1-white-two bg-green fc-white fs-14  mx-1 fw-500 px-3 cursor-pointer max-w-115 w-70-per"
                            onClick={() => props.toggleConfirmationPopUp(stringConstants.MARK_AS_COMPLETE)}
                    >
                        Mark As Complete
                    </Button>
                    <div className="fc-blue-grey fw-500 pt-3">Status</div>
                    {/*<Row className="p-2 width-fit-content br-4 fc-white bg-greyish-two fw-500 cursor-pointer">Review*/}
                    {/*Pending<img*/}
                    {/*src="/images/down-chevron.svg" className="ml-2"/></Row>*/}
                    {selectedItem && Object.keys(selectedItem).length > 0 ?
                        <CustomMenuBar
                            statusList={statusList}
                            isDisabled={isDisabled}
                            selectedItem={selectedItem}
                            isAbleToUpdateStatusForView={isAbleToUpdateStatusForView}
                            updateStatus={updateStatus}
                            updateSectionStatus={updateSectionStatus}
                        /> : ""
                    }

                </Column>
                <div className="my-2">
                    <div className="fc-blue-grey my-1 fw-500">Lender Sub-Users</div>
                    <Column className="cursor-pointer " vertical="center">
                        {sectionDetails && sectionDetails.assignedTo ? sectionDetails.assignedTo.map((userObj, index) => {
                            return <Row className="cursor-pointer" vertical="center">
                                <Avatar className="w-40 h-40 mx-2 my-1" key={index}
                                        src={Utility.getSignedUrl(userObj && userObj.photo ? userObj.photo : null, "/images/user-placeholder.svg")}/>
                                {userObj ? userObj.name : ""}
                            </Row>
                        }) : null}
                    </Column>
                </div>
                <div className="my-2">
                    <div className="fc-blue-grey my-1 fw-500">Loan Seller Counsel</div>
                    <Row className="cursor-pointer" vertical="center"><Avatar
                        src="/images/user-placeholder.svg"
                        className="w-30 p-1"/>
                        Reviewer
                    </Row>
                </div>
                <div className="my-2">
                    <div className="fc-blue-grey my-1 fw-500">Completion</div>
                    <LinearProgressBar variant="determinate"
                                       color="secondary"
                                       value={state.sectionDetails && Object.keys(state.sectionDetails).length > 0 && state.sectionDetails.progress ? parseInt(state.sectionDetails.progress) : 0}/>
                </div>
            </div>
            {ActivityComponent(props)}
            <div className="my-2">
                <div className="fc-blue-grey my-1 fw-500">Comments</div>
                <textarea id="comment" rows="2" className="w-100 outline-none input-border p-2 fc-dark-slate-blue"
                          value={commentText} onChange={(event) => onCommentTextChange(event.target.value)}
                          onFocus={() => toggleSaveButton(true)} placeholder={"Add a comment"}/>
                {isSaveButtonVisible ? <Row className="display-flex align-content-end my-2" horizontal={"end"}>
                    <div>
                        <Button onClick={() => toggleSaveButton(false)}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-pale-grey fc-brownish-grey mx-1 fw-500 px-3 cursor-pointer">
                            Cancel
                        </Button>
                        <Button onClick={onCommentClick}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                            Comment
                        </Button>
                    </div>
                </Row> : null}
            </div>
            {sectionDetails && sectionDetails.comments ? sectionDetails.comments.map((commentObject) => {
                return CommentCell(commentObject)
            }) : null}
        </Column>
    )
};


const ActivityComponent = (props) => {

    let {state} = props;
    let list = Utility.generateActivityArray(state.activityList);
    return (
        <div className="my-4">
            <div className="fc-blue-grey my-1 fw-500  ">Activities</div>
            <div>
                {list && list.length > 0 ? list.map((activityData, index) => <ActivityCell key={index}
                                                                                           activityText={activityData.activityText}
                                                                                           activityTime={Utility.epocToPrettyTime(activityData.activityTime)}/>) : "No updates available!"}
            </div>
        </div>
    )
};

const QuestionsComponent = (props, isDisabled) => {
    let {state, questionsList, getSectionDetails, redirectToNextSection, reDirectToDashboard, removeQuestionInCompleteQuestionIdsList} = props;

    /**
     *  This method is written in order to resolve LC-1191, in which remaining questions of section will not appear in question list
     *  if 2 of specific question is marked as "NO".
     */
    /*
    if (state.isSectionViewModificationApplicable)
        questionsList = isToPreventQuestionRenderingBasedOnOtherQuestionResponse(questionsList);

    function isToPreventQuestionRenderingBasedOnOtherQuestionResponse(questionsList) {
        if(!questionsList || questionsList.length <1)
            return questionsList;
        let newQuestionListToRender;
        newQuestionListToRender = questionsList.filter((questionObject) => {
            let isQuestionThatCanModifyQuestionRendering = (questionObject && questionListToPreventOtherQuestionInSection.hasOwnProperty(questionObject.questionId));
            let requiredAnswer = questionListToPreventOtherQuestionInSection[questionObject.questionId];
            let currentAnswer = questionObject && questionObject.answer && questionObject.answer.value ? questionObject.answer.value : "";

            return isQuestionThatCanModifyQuestionRendering && currentAnswer && requiredAnswer && currentAnswer.toUpperCase().includes(requiredAnswer.toUpperCase());
        });
        if (Object.keys(questionListToPreventOtherQuestionInSection).length === newQuestionListToRender.length)
            return newQuestionListToRender;

        return questionsList;
    }
    */

    return (
        <Column className="w-100 my-4 mr-4 fs-16 " vertical="start">
            {questionsList ? questionsList.map((questionObject, index) => {
                if (questionObject && questionObject.isSubQuestion)
                    return;
                return <QuestionCell key={index} props={props}
                                     inCompleteQuestionIdsList={props.state.inCompleteQuestionIdsList || []}
                                     removeQuestionInCompleteQuestionIdsList={removeQuestionInCompleteQuestionIdsList}
                                     questionObject={questionObject}
                                     questionsList={questionsList} getSectionDetails={getSectionDetails}
                                     isDisabled={isDisabled}/>
            }) : null}

            {questionsList && questionsList.length > 0 ?
                <Row className="justify-content-between">
                    <Column>
                        <Button className="outline-none text-transform-capitalize p-2
                    br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer"
                                onClick={() => reDirectToDashboard()}
                        >
                            Save/Return to Dashboard
                        </Button>
                    </Column>
                    <Column>
                        <Button className="outline-none text-transform-capitalize p-2
                    br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer"
                                onClick={() => redirectToNextSection()}
                        >
                            Save/Next
                        </Button>
                    </Column>
                </Row>
                : ""}
        </Column>
    )
};

let isSectionEditable = (props) => {
    if (!props || !props.state || !props.state.sectionDetails || !props.state.sectionDetails.part)
        return false;
    let userRole = Utility.getUserRole(props.userDetails);

    switch (props.state.sectionDetails.part) {
        case genericConstants.PART_TYPE.PART_A:
            return userRole === genericConstants.ROLES.SUPER_ADMIN || userRole === genericConstants.ROLES.ORIGINATION_COUNSEL || userRole === genericConstants.ROLES.SELLER_COUNSEL || userRole === genericConstants.ROLES.SUB_SELLER_COUNSEL || userRole === genericConstants.ROLES.SUB_ORIGINATION_COUNSEL;
        case genericConstants.PART_TYPE.PART_B:
            return userRole === genericConstants.ROLES.SUPER_ADMIN || userRole === genericConstants.ROLES.SELLER_COUNSEL || userRole === genericConstants.ROLES.SUB_SELLER_COUNSEL || userRole === genericConstants.ROLES.LENDER || userRole === genericConstants.ROLES.SUB_LENDER;
        case genericConstants.PART_TYPE.PART_C1:
        case genericConstants.PART_TYPE.PART_C2:
            return userRole === genericConstants.ROLES.SUPER_ADMIN || userRole === genericConstants.ROLES.SELLER_COUNSEL || userRole === genericConstants.ROLES.SUB_SELLER_COUNSEL
    }
};

const sectionDetailsComponent = (props) => {
        let isDisabled = isSectionEditable(props);
        let userRole = Utility.getUserRole(props.userDetails);
        let {state, toggleConfirmationPopUp, markAllSessionQuestionsAsComplete} = props

        return (
            <Column className="min-vh-100 p-4 fc-dark-slate-blue">
                {HeaderComponent(props)}
                <div className="display-flex flex-direction-row">
                    <div className="flex-1 w-100 my-4 mr-4 fs-16">
                        {RecordInfoComponent(props.state)}
                        {QuestionsComponent(props, !isDisabled)}
                    </div>
                    {userRole === genericConstants.ROLES.SUPER_ADMIN ? "" : StatusAndActivityComponent(props, !isDisabled)}
                </div>
                {state.isConfirmationPopUpOpen ?
                    <ConfirmationPopUp
                        isConfirmationPopUpOpen={state.isConfirmationPopUpOpen}
                        headerMessage={state.headerMessage}
                        closePopup={toggleConfirmationPopUp}
                        onYesClicked={markAllSessionQuestionsAsComplete}
                    /> : ""}
            </Column>
        )
    }
;
export default sectionDetailsComponent;
