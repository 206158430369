import React from 'react'
import {Column, Row} from "simple-flexbox";
import {Button} from "@material-ui/core";
import CustomInput from "../../common/components/CustomInput";
import {history} from "../../managers/history";
import {pathConstants} from "../../constants";


const createPasswordComponent = (props) => {
    let {state, onChangeEvent} = props;
    return (
        <form onSubmit={props.resetPassword}
              className="w-100 my-4 display-flex align-items-center flex-direction-column">
            <Row className="jc-center mt-2 fs-28 text-center">Create New
                Password</Row>
            <Row className="mt-4  w-100">
                <CustomInput id="newPassword" type={'password'} placeholder={'New Password'}
                             error={state ? state.newPasswordError : ""} className="fs-15 p-2 mt-1"
                             value={state ? state.newPassword : ""} onChange={onChangeEvent}/>
            </Row>
            <Row className="mt-4  w-100">
                <CustomInput id="confirmPassword" type={'password'} placeholder={'Confirm Password'}
                             error={state ? state.confirmPasswordError : ""} className="fs-15 p-2 mt-1"
                             value={state ? state.confirmPassword : ""} onChange={onChangeEvent}/>
            </Row>
            <Button type='submit' className="bg-blue outline-none text-transform-capitalize
                fc-white w-100 py-2 fs-17 mt-3 cursor-pointer">Update Password</Button>
        </form>

    );
};

const linkExpiredComponent = (props) => {
    return (
        <div className="my-4 w-75 p-4 fs-24 text-center">Link has been expired !</div>
    );
};

function RecoverPasswordForm(props) {
    let {state} = props;
    return (
        <Column className="w-450 my-5 align-items-center px-3" horizontal={'center'}>
            {state.isInvalidUrl ? linkExpiredComponent(props) : createPasswordComponent(props)}
            <div className="w-100 fs-17 text-center">Return to&nbsp;
                <a className="fc-blue cursor-pointer" onClick={() => {
                    history.replace(pathConstants.LOGIN)
                }}>
                    Sign In
                </a>
            </div>
        </Column>
    );
}

function HeaderComponent(props) {
    return (
        <Row vertical="center" className="justify-content-between">
            <img src="/images/limb-logo.svg" alt='limb' className="w-150"/>
            <Row vertical="center" className="display-none">
                <Column vertical="center" className="fc-brownish-grey fs-15 px-2 py-1">New user?</Column>
                <Column vertical="center" className="fc-blue br-4 b-1-blue fs-17 px-2 py-1 cursor-pointer"
                        onClick={() => history.replace("/sign-up")}>
                    <a href="/sign-up">Sign Up</a>
                </Column>
            </Row>
        </Row>
    )
}

function resetPasswordComponent(props) {
    return (
        <Column horizontal={'center'} className="fc-dark-slate-blue w-100 p-3 min-vh-100 h-100 justify-content-between">
            <Column className="w-100">
                {HeaderComponent(props)}
                <Row horizontal="center" className="w-100 my-5">
                    {RecoverPasswordForm(props)}
                </Row>
            </Column>
            <Column className="jc-center w-100 align-items-center fs-14 my-3">
                <div className="fc-warm-grey">©2020 LIMB CRE. All Rights Reserved.</div>
                <div className="fc-blue">Privacy Policy</div>
            </Column>
        </Column>
    );
}

export default resetPasswordComponent;
