import React from "react";
import BaseComponent from '../baseComponent'
import BillingComponent from './billingComponent'
import Utils, {dispatchAction} from "../../utility";
import {apiFailureConstants, eventConstants, genericConstants, pathConstants, stringConstants} from "../../constants";
import {CompanyService, SubscriptionService, UserService} from "../../services";
import {history} from "../../managers/history";
import {connect} from "react-redux";
import {getSignedURL, uploadFileToS3} from "../../managers/awsService";

class Billing extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            userRole: "",
            email: "",
            emailError: "",
            password: "",
            passwordError: "",
            isPasswordVisible: false,
            showSaveButton: false,
            logoLoading: false,
            companyData: {},
            company: {
                name: "",
                address: "",
                websiteUrl: "",
                contactEmail: "",
                contactName: "",
                logo: "",
                logoURL: "",
                contactNumber: "",
            },
            profile: {
                logo: "",
                logoURL: "",
            },
            firstName: "",
        }
    }

    componentDidMount() {
        let userRole = Utils.getUserRole(this.props.user.userDetails);
        this.setState({userRole});
        this.getCompanyDetails();
    };

    getCompanyDetails = async () => {
        let companyId = Utils.getCompanyID(this.props);
        if (!companyId) {
            Utils.apiFailureToast("Unable to get Company Information");
            return;
        }
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, companyResponse] = await Utils.parseResponse(CompanyService.getCompanyDetail(companyId));
        if (error) {
            Utils.apiFailureToast("Unable to get Company Information");
            return;
        }
        if (companyResponse && Object.keys(companyResponse).length > 0) {
            this.updateCompanyDataInState(companyResponse);
            this.props.dispatchAction(eventConstants.GET_COMPANY_SUCCESS, companyResponse);
            if (companyResponse.subscription && companyResponse.subscription.subscriptionId) {
                this.getSubscriptionData(companyResponse.subscription.subscriptionId)
            }
        }

        this.props.dispatchAction(eventConstants.HIDE_LOADER);
    };

    async getSubscriptionData(subscriptionId) {
        if (!subscriptionId)
            return;
        let requestData = {subscriptionId};
        let [error, subscriptionResponse] = await Utils.parseResponse(SubscriptionService.getSubscriptionData(requestData));
        if (error)
            return Utils.consoleLogger("getSubscriptionData error", error);
        this.setState({subscriptionData : subscriptionResponse, invoiceList: subscriptionResponse.invoices || []});
    }

    viewInvoice = async (invoiceObject) => {
        if (!invoiceObject || Object.keys(invoiceObject).length < 1 || !invoiceObject.keyName) {
            Utils.apiFailureToast(apiFailureConstants.VIEW_INVOICE);
            return;
        }
        window.open(await getSignedURL(`invoices/${invoiceObject.keyName}`), '_blank');
    };

    getUserDetails = () => {
        if (!this.props || !this.props.user || !this.props.user.userDetails) {
            Utils.apiFailureToast("Unable to get user details");
            return;
        }
        this.setState({firstName: this.props.user.userDetails.firstName})
    };

    updateCompanyDataInState = async (companyResponse) => {
        Utils.consoleLogger("companyResponse", companyResponse);
        let company = {
            address: Utils.generateAddressString(companyResponse.billingAddress),
        };
        this.setState({companyData: companyResponse, company});
    };

    handleChange = (event) => {
        let {company} = this.state;
        company[event.target.id] = event.target.value;
        this.setState({company: company, showSaveButton: true});
    };

    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };

    toggleSaveButton = () => {
        this.setState({showSaveButton: !this.state.showSaveButton})
    };

    updateCompanyAddress = (companyInfo) => {
        let request = {
            companyId: Utils.getCompanyID(this.props),
            billingAddress: Utils.parseLocationObject(companyInfo)
        };
        this.updateCompany(request)

    };

    updateCompany = async (requestData) => {
        if (!requestData || Object.keys(requestData).length < 1 || !requestData.companyId)
            return Utils.apiFailureToast("Unable to update billing address!");
        let [error, companyResponse] = await Utils.parseResponse(CompanyService.updateCompanyService(requestData));
        if (error)
            this.props.dispatchAction(eventConstants.UPDATE_COMPANY_FAILURE, null);
        if (companyResponse && Object.keys(companyResponse).length > 0) {
            this.updateCompanyDataInState(companyResponse);
            this.props.dispatchAction(eventConstants.UPDATE_COMPANY_SUCCESS, companyResponse);
        }
    };

    render() {
        return (
            <BillingComponent state={this.state}
                              companyDetails={this.props.company}
                              user={this.props.user}
                              handleChange={this.handleChange}
                              onChangeEvent={this.onChangeEvent}
                              toggleSaveButton={this.toggleSaveButton}
                              updateCompanyAddress={this.updateCompanyAddress}
                              viewInvoice={this.viewInvoice}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, company: state.company}
};

export default connect(mapStateToProps, {dispatchAction})(Billing);
