import {Column, Row} from "simple-flexbox";
import React from "react";
import {Button, Select, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import CustomSelectInput from "../../../common/components/CustomSelectInput";
import MenuItem from "@material-ui/core/MenuItem";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import {genericConstants, stringConstants} from "../../../constants";
import Utility from "../../../utility";
import Notification from "../../notification";
import {getSignedURL} from "../../../managers/awsService";
import Avatar from "@material-ui/core/Avatar/Avatar";
import SortingComponent from "../../../common/components/SortingComponent";

const SearchFilterComponent = (props) => {

    return (
        <Column>
            <Row className="bg-color-white fs-12 mt-4" vertical="center">
                <Row className="br-2 bg-pale-grey p-2 b-1-pale-grey-three">
                    <input placeholder={'Search Plan'} id={'search'}
                           onChange={e => props.counselFilteredListSetValues(e.target.id, e.target.value)}
                           className="outline-none b-none fc-bluey-grey bg-pale-grey fw-500"/>
                    <img className="mx-2" src="/images/search-icon.svg" alt="search"/>
                </Row>
            </Row>
        </Column>
    )
};

const TableViewComponent = (props) => {
    const {handlePageChange, state, roleType} = props;
    let [sortingState, updateSortingState] = React.useState({
        name: null,
        invitationStatus: null,
        "address.city": null
    });
    return (
        <div className="my-2 overflow-x-auto">
            {state.data && state.data.length > 0 ?
                <Column className="bg-white h-100-per fs-15">
                    <Table>
                        {getRoleBasedRecordTableHeader(roleType, props, sortingState, updateSortingState)}
                        <TableBody>
                            {state.counselList.length !== 0 ? state.counselList.map((counsels, index) => (

                                getRoleBasedRecordTableBody(roleType, props, counsels, index)
                            )) : null
                            }
                        </TableBody>
                    </Table>

                </Column>
                : <div className="p-5 mx-4 mb-4 jc-center flex-container bg-color-white">
                    {'No Request Found'}
                </div>}
            {state.filteredList && state.filteredList.length > 0 ?
                <div>
                    <Pagination style={{marginTop: 20}}
                                onChange={(pn) => handlePageChange(pn)}
                                current={state.activePage}
                                total={state.filteredList ? state.filteredList.length : 0}
                                pageSize={10}
                    />
                </div>
                :
                ""}
        </div>
    );
};


const getRoleBasedRecordTableHeader = (role, props, sortingState, updateSortingState) => {
    const {state, onSortClicked} = props;

    function onDescendingSort(key) {
        onSortClicked(key, genericConstants.SORTING_ORDER.DESCENDING);
        updateSortingState({[key]: genericConstants.SORTING_ORDER.DESCENDING});
    }

    function onAscendingSort(key) {
        onSortClicked(key, genericConstants.SORTING_ORDER.ASCENDING);
        updateSortingState({[key]: genericConstants.SORTING_ORDER.ASCENDING});
    }

    return (<TableHead>
        <TableRow style={{padding: 20}}>

            <TableCell className='fs-14'>
                <Row vertical="center">
                    Plan Name
                    <SortingComponent
                        state={sortingState.planName}
                        onDescendingSort={() => onDescendingSort("planName")}
                        onAscendingSort={() => onAscendingSort("planName")}
                    />
                </Row>
            </TableCell>
            <TableCell className='fs-14'>
                <Row>
                    Yearly Price
                </Row>

            </TableCell>
            <TableCell className='fs-14'>
                <Row>
                    Status
                </Row>
            </TableCell>
            <TableCell></TableCell>
        </TableRow>
    </TableHead>);
};
const getRoleBasedRecordTableBody = (role, props, counsels, index) => {
    return (<TableRow className='h-60px' key={index}>
        <TableCell className='fs-15 fc-blue-grey'>{counsels.planName}</TableCell>
        <TableCell className='fs-15 fc-blue-grey'>{counsels.price ? `$${counsels.price}` : ''}</TableCell>
        <TableCell
            className='fs-15 fc-blue-grey'>{Utility.viewInvitationStatus(counsels.status)}</TableCell>
        <TableCell>
            {/*<div className="flex-column cursor-pointer justify-content-between">*/}
            {/*    <img src="/images/edit-icon.svg"*/}
            {/*         onClick={() => Utility.showUnderDevelopment()}/>*/}
            {/*    <img className="cursor-pointer mx-3"*/}
            {/*         src="/images/delete-icon.svg"*/}
            {/*         onClick={() => Utility.showUnderDevelopment()}/>*/}
            {/*</div>*/}
        </TableCell>
    </TableRow>);
};

const headerComponent = (props) => {

    return (
        <Row className='justify-content-between'>
            <div className="fs-24 font-weight-bold display-flex flex-row">{'Activation Requests'}</div>
            <Row className="justify-content-between">
                <Row>
                    <Notification/>
                </Row>
            </Row>
        </Row>
    )
};
const SubscriptionPlanComponent = (props) => {

    return (
        <Column className="min-vh-100 p-4 fc-dark-slate-blue" style={{maxHeight: props.height}}>
            {headerComponent(props)}
            {SearchFilterComponent(props)}
            {TableViewComponent(props)}
        </Column>
    )
};
export default SubscriptionPlanComponent;