import React from "react";
import BaseComponent from '../../baseComponent'
import LendersComponent from './lendersComponent'
import {CompanyService} from "../../../services";
import {eventConstants, genericConstants} from "../../../constants";
import Utility, {dispatchAction} from "../../../utility";
import {connect} from "react-redux";

class Lender extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentSort: 'asc',
            data: [],
            activePage: 1,
            lendersList: [],
            allExistingLenders: [],
            filteredList: [],
            search: "",
            predictions: "",
            counselName: '',
            contactPerson: '',
            address: '',
            email: '',
            isEditRequest: false,
            selectedAssociate: {}
        };
    }

    componentDidMount() {
        this.initiateComponent();
    };

    initiateComponent() {
        this.getExistingLendersData();
        this.generateLendersList();
    }

    getExistingLendersData() {
        this.getTypeBasedCompany().then(response => {
            this.setState({allExistingLenders: response})
        }).catch(error => {
            Utility.consoleLogger("getTypeBasedCompany : error", error);
        });
    }

    generateLendersList() {
        this.getLendersList().then((response) => {
            if (response && response.associatedTo) {
                Utility.consoleLogger("getLendersList : response", response);
                this.setState({data: response.associatedTo, filteredList: response.associatedTo});
                this.skippedLenderList(1, 10);
            }
        }).catch(error => {
            //Utility.apiFailureToast("Unable to fetch Lender List!");
            Utility.consoleLogger("getLendersList : error", error)
        });
    }

    getTypeBasedCompany = async () => {
        let requestObj = {
            "type": genericConstants.ROLES.LENDER
        };
        return await CompanyService.getTypeBasedCompany(requestObj)
    };

    getLendersList = async () => {
        let {company} = this.props;
        let requestObj = {
            "companyId": company.companyId,
            "companyType": genericConstants.ROLES.LENDER
        };
        return await CompanyService.getAssociateList(requestObj);
    };

    onChangeEvent = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});
        if (value.length > 0) {
            let predictions = this.getPredictions(value);
            this.setState({predictions: predictions})
        } else {
            this.setState({predictions: []})
        }
    };

    getPredictions = (value) => {
        if (this.state.allExistingLenders)
            return this.state.allExistingLenders.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)
    };

    hideSuggestionBox = async (item) => {
        let address = item.address.city + ', ' + item.address.state;
        this.setState({selectedAssociate: item});
        await this.setState({
            predictions: [],
            counselName: item.name,
            contactPerson: item.contactName,
            address: address,
            email: item.contactEmail
        });
    };

    /**
     * sorting function takes a data i.e a list and a key
     * where a is individual object or item of the list
     * @param data
     * @param key
     */

    sorting = (data, key) => {
        this.sortData(data, key);
        this.onSortChange();
    };

    /**
     * sortdata function for sorting the list in alphabetical manner
     * @param data
     * @param key
     * @returns {[]|Int16Array|T[]}
     */
    sortData = (data, key) => {

        let cur = this.state.currentSort;
        let sortedData = data.sort(function (a, b) {
            let statusA = a[key].toLowerCase(),
                statusB = b[key].toLowerCase();
            return (cur === 'asc' ? (statusA < statusB ? -1 : 1) : (statusA > statusB ? -1 : 1))
        });

        this.setState({lendersList: sortedData.slice(0, 10)});
        return this.state.lendersList;

    };

    /**
     * onSortChange function just sets the current sort state value to ascending or,
     * descending so that we can provide the corresponding sorting
     */
    onSortChange = () => {
        const {currentSort} = this.state;
        let nextSort;

        if (currentSort === 'asc') nextSort = 'desc';
        else if (currentSort === 'desc') nextSort = 'asc';

        this.setState({
            currentSort: nextSort
        });
    };

    skippedLenderList = async (start, end) => {
        this.setState({lendersList: this.state.filteredList.slice(start - 1, end)});
    };

    //function for pagination
    handlePageChange = async (pageNumber) => {
        await this.setState({activePage: pageNumber});
        let start = (pageNumber * 10) - 9;
        let end = (pageNumber * 10);
        console.log('start', start, 'end', end);
        this.skippedLenderList(start, end);
    };

    //function to filter name on the basis of the lenders name we type in the search box
    lendersFilteredListSetValues = async (key, value) => {
        let filterList;
        await this.setState({[key]: value, filteredList: this.state.data});
        if (this.state.search !== "") {
            filterList = this.state.filteredList.filter(filterLender => filterLender.name.toLowerCase().includes(this.state.search.toLowerCase()));
            this.setState({filteredList: filterList});
        }
        this.skippedLenderList(1, 10);
    };

    toggleInviteLenderDialog = (lender, isEditRequest = false) => {
        if (!isEditRequest){
            this.setState({isInviteLenderDialogOpen: !this.state.isInviteLenderDialogOpen,
                counselName:'',
                contactPerson:'',
                address:'',
                email:'',
                invitationStatus:'',
                isEditRequest: false});
            return;
        }
        this.setState({
            subUserObjectForEdit: lender,
            counselName: lender.name,
            contactPerson: lender.contactName,
            address: lender.location.city + ", " + lender.location.state,
            email: lender.email,
            invitationStatus: lender.invitationStatus,
            isInviteLenderDialogOpen: !this.state.isInviteLenderDialogOpen,
            isEditRequest: true
        });
    }

    onInviteLenderClicked = () => {
        if (!this.validateFormData()) {
            if (this.state.isEditRequest) {
                this.updateCounselStatus();
                return;
            }
            this.inviteAssociate();
        } else {
            let toastMessage = 'Please fill in the required details to continue';
            Utility.apiSuccessToast(toastMessage);
        }
    };

    deleteCounsel = async () => {
        await this.setState({invitationStatus: genericConstants.INVITATION_STATUS.DELETED});
        this.updateCounselStatus();
    };
    updateCounselStatus = async () => {
        let requestData = this.updateCounselRequestObject();

        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        if (!requestData || Object.keys(requestData).length < 1)
            return "";
        Utility.consoleLogger("updateCounselStatus : requestData", requestData);
        let [error, invitationResponse] = await Utility.parseResponse(CompanyService.updateAssociate(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error) {
            if (error && error.responseCode && (error.responseCode === 402))
                Utility.apiFailureToast("Invitation sent already!");
            else
                Utility.apiFailureToast("Unable to Update Counsel Status!");
            return;
        }
        if (invitationResponse) {
            this.toggleInviteLenderDialog();
            Utility.apiSuccessToast("Counsel Updated Successfully!");
            this.generateLendersList();
        }
    };

    updateCounselRequestObject() {
        let {company} = this.props;
        let {subUserObjectForEdit, invitationStatus} = this.state;
        Utility.consoleLogger("Seller updateCounselRequestObject company", company);
        return {
            "updatedBy": {
                companyId: company.companyId || "",
                name: company.name || "",
                type: company.type || ""
            },
            "associatedTo": {
                companyId: subUserObjectForEdit.companyId || "",
                invitationStatus: invitationStatus || "",
            }
        };
    }
    validateFormData() {
        return !!((Utility.isEmpty((this.state.counselName).trim()) && Utility.isEmpty(this.state.contactPerson.trim())
            && Utility.isEmpty(this.state.address.trim()) && Utility.isEmpty((this.state.email).trim())));
    }

    async inviteAssociate() {
        let requestData = this.createInviteRequestObject();
        this.props.dispatchAction(eventConstants.SHOW_LOADER);

        let [error, invitationResponse] = await Utility.parseResponse(CompanyService.inviteAssociate(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);

        if (error) {
            if(error && error.responseCode && (error.responseCode == 402))
                Utility.apiFailureToast("Invitation already exists!");
            else
                Utility.apiFailureToast("Unable to Send Invitation!");
            return;
        }
        if (invitationResponse) {
            this.toggleInviteLenderDialog();
            Utility.apiSuccessToast("Invitation sent Successfully!");
            this.generateLendersList();
        }
    };

    createInviteRequestObject() {
        let {company} = this.props;
        return {
            "invitedBy": Utility.parseAssociateObject(company, genericConstants.ROLES.SELLER_COUNSEL),
            "associate": Utility.parseAssociateObject(this.state.selectedAssociate, genericConstants.ROLES.LENDER)
        };
    }

    updateInvitation = async (lendersData, invitationAction) => {

        let {company} = this.props;

        let requestData = {};
        requestData['updatedBy'] = {
            'companyId': company.companyId,
            name: company.name,
            type: company.type
        };
        requestData['associatedTo'] = {
            'invitationStatus': invitationAction, 'companyId': lendersData.companyId
        };

        let [error, updateInvitationResponse] = await Utility.parseResponse(CompanyService.updateInvitation(requestData));
        if (error)
            Utility.apiFailureToast("Unable to update invitation status!");
        if (updateInvitationResponse) {
            if (invitationAction === genericConstants.INVITATION_STATUS.REJECTED)
                Utility.apiSuccessToast("Invitation Rejected Successfully!");
            if (invitationAction === genericConstants.INVITATION_STATUS.ACCEPTED)
                Utility.apiSuccessToast("Invitation Accepted Successfully!");
            this.generateLendersList();
        }
    };

    render() {
        return (
            <LendersComponent state={this.state} companyId={this.props.company.companyId}
                              onMenuClick={this.onMenuClick}
                              toggleInviteLenderDialog={this.toggleInviteLenderDialog}
                              handleAddUser={this.handleAddUser}
                              onChangeEvent={this.onChangeEvent}
                              sorting={this.sorting}
                              handlePageChange={this.handlePageChange}
                              hideSuggestionBox={this.hideSuggestionBox}
                              onInviteLenderClicked={this.onInviteLenderClicked}
                              lendersFilteredListSetValues={this.lendersFilteredListSetValues}
                              updateInvitation={this.updateInvitation}
                              deleteCounsel={this.deleteCounsel}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, company: state.company}
};

export default connect(mapStateToProps, {dispatchAction})(Lender);
