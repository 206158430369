import React from "react";
import BaseComponent from '../baseComponent'
import CompanySetupComponent from './companySetupComponent'
import Utils, {dispatchAction} from "../../utility";
import {CompanyService, SubscriptionService, UserService} from "../../services";
import {eventConstants, stringConstants, pathConstants, genericConstants, apiFailureConstants} from "../../constants";
import {connect} from "react-redux";
import {history} from "../../managers/history";
import AddCompanyInfoComponent from "./addCompanyInfoComponent";
import {getSignedURL, uploadFileToS3} from "../../managers/awsService";

class CompanySetup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            companyEmail: "",
            website: "",
            primaryContact: "",
            phone: "",
            companyStatus: "null",
            companyLogo: null,
            companyLogoKey: null,
            logoLoading: false
        }
    }

    componentDidMount() {
        let userRole = this.props && this.props.user && this.props.user.userDetails ? Utils.getUserRole(this.props.user.userDetails) : null;
        if (userRole === genericConstants.ROLES.SUPER_ADMIN) {
            history.replace(pathConstants.DASHBOARD_MENU.PUBLISH_REQUESTS);
            return;
        }
        this.getCompanyDetails();
        this.addAddressListeners();
        this.getPlansList();
    }

    getPlansList = async () => {
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, planList] = await Utils.parseResponse(SubscriptionService.getSubscriptionPlans());
        if (error || !planList || planList.length === 0) {
            this.props.dispatchAction(eventConstants.HIDE_LOADER);
            return;
        }
        this.setState({planList: planList});
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
    };
    getCompanyDetails = async () => {
        let companyId = Utils.getCompanyID(this.props);
        if (!companyId) {
            this.setState({companyStatus: ""});
            return;
        }
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, companyResponse] = await Utils.parseResponse(CompanyService.getCompanyDetail(companyId));
        if (error)
            return;
        if (companyResponse) {
            this.setState({companyStatus: companyResponse.status});
            this.props.dispatchAction(eventConstants.GET_COMPANY_SUCCESS, companyResponse);
        }
        //TODO : Handle Error Situation if getCompanyDetails API fails
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
    };

    addAddressListeners = () => {
        const google = window.google = window.google ? window.google : {}
        this.addressLineAutocomplete = new google.maps.places.Autocomplete(document.getElementById('streetAddress'), {})
        this.cityAutocomplete = new google.maps.places.Autocomplete(document.getElementById('city'), {
            types: ['(cities)']
        });
        this.stateAutocomplete = new google.maps.places.Autocomplete(document.getElementById('state'), {});
        this.countryAutocomplete = new google.maps.places.Autocomplete(document.getElementById('country'), {types: ['(cities)']});

        this.cityAutocomplete.addListener("place_changed", this.handlePlaceSelect);
        this.addressLineAutocomplete.addListener("place_changed", this.handleAddressSelect);
        this.stateAutocomplete.addListener("place_changed", this.handleStateSelect);
        this.countryAutocomplete.addListener("place_changed", this.handleCountrySelect);
    };

    handleStateSelect = () => {
        let goggleAddressObject = this.stateAutocomplete.getPlace();
        if (!goggleAddressObject || !goggleAddressObject.address_components)
            return;
        let addressArray = goggleAddressObject.address_components;
        // let formattedAddressObj = this.getFormattedAddressObj(addressArray);
        // let addressObj = this.props.addressObj;
        // addressObj[this.props.addressObj.addressType]['state'] = formattedAddressObj.state;
        // addressObj[this.props.addressObj.addressType]['country'] = formattedAddressObj.country;
        // addressObj[this.props.addressObj.addressType]['zipCode'] = formattedAddressObj.zipCode;
        // this.setState({addressObj})
    }

    handleCountrySelect = () => {
        let goggleAddressObject = this.countryAutocomplete.getPlace();
        if (!goggleAddressObject || !goggleAddressObject.address_components)
            return;
        let addressArray = goggleAddressObject.address_components;
        // let formattedAddressObj = this.getFormattedAddressObj(addressArray);
        // let addressObj = this.props.addressObj;
        // addressObj[this.props.addressObj.addressType]['state'] = formattedAddressObj.state;
        // addressObj[this.props.addressObj.addressType]['country'] = formattedAddressObj.country;
        // addressObj[this.props.addressObj.addressType]['zipCode'] = formattedAddressObj.zipCode;
        // this.setState({addressObj})
    }

    handleAddressSelect = () => {
        let goggleAddressObject = this.addressLineAutocomplete.getPlace();
        if (!goggleAddressObject || !goggleAddressObject.address_components)
            return;
        let addressArray = goggleAddressObject.address_components;

        //
        // let formattedAddressObj = this.getFormattedAddressObj(addressArray,goggleAddressObject);
        // formattedAddressObj.addressLine1 = formattedAddressObj.addressLine1.trim();
        //
        // let addressObj = this.props.addressObj;
        // addressObj[this.props.addressObj.addressType]['addressLine1'] = formattedAddressObj.addressLine1;
        // addressObj[this.props.addressObj.addressType]['addressLine2'] = formattedAddressObj.addressLine2 || '';
        // addressObj[this.props.addressObj.addressType]['city'] = formattedAddressObj.city;
        // addressObj[this.props.addressObj.addressType]['state'] = formattedAddressObj.state;
        // addressObj[this.props.addressObj.addressType]['country'] = formattedAddressObj.country;
        // addressObj[this.props.addressObj.addressType]['zipCode'] = formattedAddressObj.zipCode;
        // this.setState({addressObj})
    }

    handlePlaceSelect = () => {

        let goggleAddressObject = this.cityAutocomplete.getPlace();
        if (!goggleAddressObject || !goggleAddressObject.address_components)
            return;
        let addressArray = goggleAddressObject.address_components;

        // let formattedAddressObj = this.getFormattedAddressObj(addressArray,goggleAddressObject);
        // let addressObj = this.props.addressObj;
        // addressObj[this.props.addressObj.addressType]['city'] = formattedAddressObj.city;
        // addressObj[this.props.addressObj.addressType]['state'] = formattedAddressObj.state;
        // addressObj[this.props.addressObj.addressType]['country'] = formattedAddressObj.country;
        // addressObj[this.props.addressObj.addressType]['zipCode'] = formattedAddressObj.zipCode;
        // this.setState({addressObj})
    }
    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value, [`${event.target.id}Error`]: ''});
    };

    onNumberChangeEvent = (event) => {
        const data = (event.target.validity.valid) ? event.target.value : this.state[event.target.id];
        this.setState({[event.target.id]: data});
    };

    onFileCancel = () => {
        this.setState({
            files: []
        });
    };
    onFileDrop = async (files, props) => {
        this.setState({logoLoading: true});
        if (!files || files.length < 1) {
            Utils.apiFailureToast(apiFailureConstants.UPLOAD_PHOTO);
            this.setState({logoLoading: false});

            return;
        }
        let file = files[0];
        let fileDetail = Utils.getFileName(file.name);
        let companyLogo = Utils.getNewFileNameWithId('comp', fileDetail.extension, props.company.companyId);
        let resFromS3 = await uploadFileToS3(file, companyLogo, file.type).catch(err => {
            Utils.apiFailureToast(apiFailureConstants.UPLOAD_PROPERTY_PHOTO);
            return false;
        });
        let logoURL = await getSignedURL(companyLogo);
        if (!resFromS3 || !logoURL) {
            Utils.apiFailureToast(apiFailureConstants.UPLOAD_PROPERTY_PHOTO);
            return false;
        }

        if (!logoURL || !resFromS3) {
            this.setState({logoLoading: false});
            return;
        }
        this.setState({companyLogo: logoURL, companyLogoKey: companyLogo, logoLoading: false});
    };

    validateCompanyProfileForm = () => {
        this.setState({
            companyEmailError: this.state.companyEmail ? Utils.validateEmail(this.state.companyEmail) ? "" : stringConstants.INVALID_EMAIL : stringConstants.EMPTY_CONTACT_EMAIL,
            primaryContactError: !Utils.isEmpty(this.state.primaryContact) ? "" : stringConstants.EMPTY_PRIMARY_CONTACT,
            websiteError: !Utils.isEmpty(this.state.website) ? "" : stringConstants.EMPTY_COMPANY_WEBSITE,
        });
        return Utils.validateEmail(this.state.companyEmail) && !Utils.isEmpty(this.state.primaryContact) && !Utils.isEmpty(this.state.primaryContact);
    };

    updateCompany = async (event) => {
        event.preventDefault();
        let {company} = this.props;
        if (!company || !company.companyId)
            return;
        if (this.validateCompanyProfileForm()) {
            let {companyEmail, website, primaryContact, phone, companyLogoKey} = this.state;
            let requestData = {
                companyId: company.companyId,
                contactEmail: companyEmail,
                websiteUrl: website,
                contactName: primaryContact,
                logo: companyLogoKey,
                contactNumber: phone,
                status: genericConstants.COMPANY_STATUS.COMPLETED
            };
            let [error, companyResponse] = await Utils.parseResponse(CompanyService.updateCompanyService(requestData));
            if (error)
                this.props.dispatchAction(eventConstants.UPDATE_COMPANY_FAILURE, null);
            if (companyResponse) {
                this.props.dispatchAction(eventConstants.UPDATE_COMPANY_SUCCESS, companyResponse);
                history.replace(pathConstants.DASHBOARD_MENU.INFORMATION_RECORDS);
            }
        }
    };

    onAddCompanyInfo = async (requestData) => {
        if (!requestData || !this.props || !this.props.user || !this.props.user.userDetails) {
            Utils.apiFailureToast("Unable to process");
            return;
        }
        let {user} = this.props;
        requestData["owner"] = {
            "id": user.userDetails._id,
            "name": user.userDetails.firstName,
            "email": user.userDetails.emailID,
            "roleLevel": Utils.getUserRoleLevel(user.userDetails),
        };
        requestData.company = {...requestData.company, type: Utils.getUserRole(this.props.user.userDetails)};
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, companyResponse] = await Utils.parseResponse(CompanyService.addCompanyService(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error || !companyResponse) {
            Utils.apiFailureToast("Unable to process");
            this.props.dispatchAction(eventConstants.UPDATE_COMPANY_FAILURE, null);
            return;
        }
        /** Create subscription
         * @type {boolean}
         */
        let res = await this.subscribeUser(companyResponse.responseData);
        if (!res)
            return;
        // this.props.dispatchAction(eventConstants.UPDATE_COMPANY_SUCCESS, companyResponse);
        // await this.getUserDetails();
        // this.getCompanyDetails();
        history.replace(pathConstants.DASHBOARD_MENU.ACTIVATION);
    };

    subscribeUser = async (companyData) => {
        let subscriptionRequest = this.getSubscriptionRequestData(companyData);
        if (!subscriptionRequest || Object.keys(subscriptionRequest).length < 1)
            return false;
        let [error, subscribeResponse] = await Utils.parseResponse(SubscriptionService.subscribeUser(subscriptionRequest));
        if (error || !subscribeResponse) {
            Utils.apiFailureToast("Unable to subscribe user");
            this.props.dispatchAction(eventConstants.UPDATE_COMPANY_FAILURE, null);
            return false;
        }
        return subscribeResponse;
    };

    getSubscriptionRequestData = (companyData) => {
        console.log("companyData--",companyData)
        if (!companyData || Object.keys(companyData).length < 1 || !companyData.userID || !companyData.miscellaneous || !companyData.miscellaneous.company)
            return false;
        let selectedPlan = Utils.getSpecificPlanWithRole(Utils.getUserRole(companyData), this.state.planList);
        console.log("selectedPlan=-==", selectedPlan);
        if (!selectedPlan || Object.keys(selectedPlan).length < 1)
            return false;
        return {
            subscriptionPlanId: selectedPlan.subscriptionPlanId || '',
            planName: selectedPlan.planName || '',
            price: selectedPlan.price || '',
            companyId: companyData.miscellaneous.company.companyId || '',
            subscribedBy: {
                id: companyData.userID || '',
                name: companyData.firstName || '',
                email: companyData.emailID || '',
            }
        }
    };

    getUserDetails = async () => {
        let requestData = {userID: this.props.user.userDetails._id};
        let [userDetailsError, response] = await Utils.parseResponse(UserService.getUserDetails(requestData));
        if (userDetailsError || !response) {
            Utils.apiFailureToast("Unable to process");
            return;
        }
        await this.props.dispatchAction(eventConstants.GET_USER_DETAILS_SUCCESS, response);
    };

    getCompanyComponent = () => {
        switch (this.state.companyStatus) {
            case "PENDING":
                return <CompanySetupComponent state={this.state}
                                              company={this.props.company}
                                              updateCompany={this.updateCompany}
                                              onChangeEvent={this.onChangeEvent}
                                              onFileCancel={this.onFileCancel}
                                              onFileDrop={this.onFileDrop}
                                              onNumberChangeEvent={this.onNumberChangeEvent}/>;
            case "ACTIVE":
                return <CompanySetupComponent state={this.state}
                                              company={this.props.company}
                                              updateCompany={this.updateCompany}
                                              onChangeEvent={this.onChangeEvent}
                                              onFileCancel={this.onFileCancel}
                                              onFileDrop={this.onFileDrop}
                                              onNumberChangeEvent={this.onNumberChangeEvent}/>;
            case "ACCEPTED":
                return <CompanySetupComponent state={this.state}
                                              company={this.props.company}
                                              updateCompany={this.updateCompany}
                                              onChangeEvent={this.onChangeEvent}
                                              onFileCancel={this.onFileCancel}
                                              onFileDrop={this.onFileDrop}
                                              onNumberChangeEvent={this.onNumberChangeEvent}/>;
            case "COMPLETED":
                history.replace(pathConstants.DASHBOARD_MENU.INFORMATION_RECORDS);
                return <div></div>;
            case "null":
                return <div></div>;
            default :
                return <AddCompanyInfoComponent
                    selectedPlan={Utils.getSpecificPlanWithRole(Utils.getUserRole(this.props.user.userDetails), this.state.planList)}
                    user={this.props.user} onAddCompanyInfo={this.onAddCompanyInfo}/>
        }

    }

    render() {
        return (
            <div>{
                this.getCompanyComponent()}
            </div>
        );
    }

};

const mapStateToProps = (state) => {
    return {company: state.company, user: state.user}
};

export default connect(mapStateToProps, {dispatchAction})(CompanySetup);
