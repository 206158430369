import React from "react";
import BaseComponent from '../../baseComponent'
import TransferRecordComponent from './transferRecordComponent'
import Utils, {dispatchAction} from "../../../utility";
import {RecordService, PublishRecordService, DocumentService, CompanyService} from "../../../services";
import {eventConstants} from "../../../constants";
import {connect} from "react-redux";
import {history} from "../../../managers/history";

class TransferRecord extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            token: null,
            password: null,
            recordDetails: null,
            isHistoryRequested: false,
            recordId: null,
            receivingLenderId: null,
            isVerifySelected: true,
        }
    }

    componentDidMount() {
        let pathName = window.location.pathname;
        let pathArray = pathName.split('/');
        if (pathArray && pathArray.length === 3) {
            this.getRecordDetails(pathArray[pathArray.length - 1]);
            this.getDocumentList(pathArray[pathArray.length - 1]);
        }
        if (pathArray && pathArray.length === 4) {
            this.setState({isHistoryRequested: true, recordId: pathArray[pathArray.length - 1]});
            this.getRecordDetails(pathArray[pathArray.length - 1]);
            this.getPublishHistory(pathArray[pathArray.length - 1]);
        }
    }

    getRecordDetails = async (recordId) => {
        if (!recordId) {
            Utils.apiFailureToast("Unable to get record details");
            return;
        }
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, recordDetails] = await Utils.parseResponse(RecordService.getRecordDetail(recordId));
        if (error) {
            Utils.apiFailureToast("Unable to get record details");
            return;
        }
        this.setState({recordDetails: recordDetails});
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
    };

    getDocumentList = async (recordId) => {
        let request = {};
        if (recordId)
            request['recordId'] = recordId;
        else
            request['recordId'] = this.state.recordDetails && Object.keys(this.state.recordDetails).length > 0 && this.state.recordDetails.recordId ?
                this.state.recordDetails.recordId : "";

        let [error, documentResponse] = await Utils.parseResponse(DocumentService.getDocuments(request));
        if (error) {
            Utils.apiFailureToast("Unable to Get document List");
            return;
        }
        this.setState({documentList: documentResponse});

    };

    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value});

    };

    onSearchLenderClicked = (limbId) => {
        this.getCompanyDetails(limbId).catch(error => Utils.consoleLogger("Error : onSearchLenderClicked : ", error));
    };

    getCompanyDetails = async (limbId) => {
        if (!limbId || typeof limbId !== "string" || Utils.isEmpty(limbId)) {
            Utils.apiFailureToast("Please enter the valid LIMB ID");
            return;
        }
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, companyResponse] = await Utils.parseResponse(CompanyService.getCompanyDetailUsingLimbId(limbId));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error) {
            Utils.apiFailureToast("Invalid LIMB ID!");
            return;
        }
        if (companyResponse) {
            this.setState({receivingLenderData: companyResponse});
        }
    };

    handleCheckboxClick(){
        this.setState({isVerifySelected : !this.state.isVerifySelected});
    }

    onTransferClicked = async () => {
        return Utils.showUnderDevelopment();
        let {recordDetails} = this.state;
        let {company} = this.props;
        let requestData;
        let transactionData = {};
        let publishedBy = {};
        publishedBy['limbId'] = company.limbId || "";
        publishedBy['companyId'] = company.companyId || "";
        publishedBy['name'] = company.name || "";
        publishedBy['email'] = company && company.owner && company.owner.email || "";
        publishedBy['role'] = company.type || "";
        transactionData['recordId'] = recordDetails.recordId || "";
        transactionData['propertyPhoto'] = recordDetails.propertyPhoto || "";
        transactionData['documents'] = this.parseDocumentsRequestObject(this.state.documentList); //Will also consist IDs of Reports

        requestData = {transactionData, publishedBy};
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, recordResponse] = await Utils.parseResponse(PublishRecordService.publishRecord(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error || !recordResponse) {
            Utils.apiFailureToast("Unable to publish record!");
            return;
        }
        setTimeout(history.goBack(), 5000);
        Utils.apiSuccessToast("Record Published Successfully!!");
    };

    getPublishHistory = async () => {
        let {recordDetails, recordId} = this.state;
        if (!recordId)
            return;
        let requestData = {
            "recordId": this.state.recordId || recordDetails.recordId
        };
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, historyResponse] = await Utils.parseResponse(PublishRecordService.getPublishHistory(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error) {
            Utils.apiFailureToast("Unable to fetch publish history!");
            return;
        }
        this.setState({publishHistory: historyResponse});
    };


    render() {
        return (
            <TransferRecordComponent state={this.state} recordDetails={this.state.recordDetails}
                                     onTransferClicked={this.onTransferClicked}
                                     onChangeEvent={this.onChangeEvent}
                                     handleCheckboxClick={this.handleCheckboxClick}
                                     onSearchLenderClicked={this.onSearchLenderClicked}
            />
        );
    }

    parseDocumentsRequestObject(documentList) {
        let parseDocumentRequestObject = [];
        if (!documentList || documentList.length < 1)
            return parseDocumentRequestObject;
        documentList.forEach(document => {
            let documentObject = {};
            documentObject["documentId"] = document.documentId;
            documentObject["recordId"] = document.recordId;
            documentObject["sectionId"] = document.sectionId;
            documentObject["questionId"] = document.questionId;
            documentObject["category"] = document.category;
            documentObject["categoryType"] = document.categoryType;
            documentObject["name"] = document.name;
            documentObject["key"] = document.key;
            parseDocumentRequestObject.push(documentObject);
        });
        return parseDocumentRequestObject;
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, company: state.company}
};

export default connect(mapStateToProps, {dispatchAction})(TransferRecord);