/**
 * Created by Sunny Kumar on 10/12/2019.
 */

import {httpConstants} from "../constants";
import {httpService} from "../managers/httpService";

export default {
    addDocuments,
    getDocuments,
    deleteDocument
}

async function addDocuments(requestData) {
    let url = process.env.REACT_APP_DOCUMENT_SERVICE_URL + httpConstants.API_END_POINT.ADD_DOCUMENTS;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });

}
async function deleteDocument(requestData) {
    let url = process.env.REACT_APP_DOCUMENT_SERVICE_URL + httpConstants.API_END_POINT.DOCUMENT + '/' + requestData.documentId ;
    return httpService(httpConstants.METHOD_TYPE.DELETE, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });

}
async function getDocuments(requestData) {
    let url = process.env.REACT_APP_DOCUMENT_SERVICE_URL + httpConstants.API_END_POINT.GET_DOCUMENTS;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });

}


