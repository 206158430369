import React from 'react'
import {Column, Row} from "simple-flexbox";
import {Button} from "@material-ui/core";
import CustomInput from "../../../common/components/CustomInput";
import {history} from "../../../managers/history";
import {pathConstants} from "../../../constants";
import RecordInformationCard from "../../../common/components/recordInformationCard";
import Utils from "../../../utility";
import {getSignedURL} from "../../../managers/awsService";


function HeaderComponent(state) {
    return (
        <Row vertical="center" className="mt-lg-3">
            <img
                src={state && state.recordDetails && state.recordDetails.lender && state.recordDetails.lender.logo ? getSignedURL(state.recordDetails.lender.logo, "/images/company-placeholder.svg") : "/images/company-placeholder.svg"}
                alt='company-logo' className="justify-content-start w-100px"/>

            <div className="display-flex flex-1 justify-content-center align-items-end fs-32 fw-500 mr-lg-4">Transfer
                Information Record
            </div>
            <div className="display-flex justify-content-end w-100px"/>
        </Row>
    )
}

function TransferRecordView(props) {

    const [receivingLenderId, setReceivingLenderId] = React.useState("");
    const [isSearchButtonVisible, toggleSearchButtonView] = React.useState(true);

    function handleInputChange(event) {
        setReceivingLenderId(event.target.value.toUpperCase());
    }

    function onFocusHandle(event) {
        toggleSearchButtonView(true);
    }

    function removeFocus() {
        toggleSearchButtonView(false);
    }

    return (
        <div className="display-flex flex-direction-column justify-content-center align-items-center mt-6rem">
            <div className="w-50per">
                <div className="fs-16 fw-500">Information Record</div>
                <Row className="w-100 min-h-300">
                    {props && props.state && props.state.recordDetails ? RecordInformationCard(props.state.recordDetails) : ""}
                </Row>
                <div className="mt-lg-4">
                    <div className="fs-16 fw-500">LIMB ID of the receiving Lender<span className="fc-red">*</span></div>
                    <CustomInput id="receivingLenderId" type="text" placeholder={''}
                                 value={receivingLenderId}
                                 onChange={handleInputChange}
                                 onFocus={onFocusHandle}
                                 className="fc-dark-slate-blue fs-16 p-2 h-50px"
                    />
                </div>
                {props && props.state && props.state.receivingLenderData && !isSearchButtonVisible ? SearchResultView(props) : SearchButtonView(receivingLenderId, removeFocus, props)}
            </div>
        </div>

    )
}

function SearchButtonView(receivingLenderId, removeFocus, props) {
    function onSearchClicked() {
        props.onSearchLenderClicked(receivingLenderId);
        removeFocus();
    }

    return (
        <Row className="mt-5 ">
            <Button onClick={() => history.goBack()} className="flex-1 outline-none text-transform-capitalize p-2 br-4 fs-18
                            b-1-red-two fc-red  px-3 cursor-pointer bg-transparent">
                Cancel
            </Button>
            <div className="flex-1 max-w-50"/>
            <Button onClick={() => onSearchClicked(receivingLenderId)} className="flex-1 outline-none text-transform-capitalize p-2 br-4 fs-18
                            b-1-white-two bg-blue fc-white   px-3 cursor-pointer">
                Search Lender
            </Button>
        </Row>
    )
}

function SearchResultView(props) {
    let {state} = props;
    let receivingLenderData = state && state.receivingLenderData || {};

    return (
        <div className="mt-lg-3">
            <div className="fs-16">Search Result</div>
            <Row className="b-1-pale-grey-three mt-1 w-100 p-4">
                <img
                    src={receivingLenderData && receivingLenderData.logo ? getSignedURL(receivingLenderData.logo, "/images/company-placeholder.svg") : "/images/company-placeholder.svg"}
                    alt='company-logo' className="justify-content-start w-100px"/>

                <div className="display-flex flex-1 flex-direction-column justify-content-center fs-14 fw-500 ml-4">
                    {receivingLenderData && receivingLenderData.name ?
                        <div className="fs-18">{receivingLenderData.name}</div> : ""}
                    {receivingLenderData && receivingLenderData.limbId ?
                        <div className="fc-dark-grey fs-16">LIMB ID : {receivingLenderData.limbId}</div> : ""}
                    {receivingLenderData && receivingLenderData.websiteUrl ?
                        <div className="fc-dark-grey fs-16">{receivingLenderData.websiteUrl}</div> : ""}
                </div>
            </Row>
            {/*<Row className="align-items-center mt-2">
                <input style={{width: '15px'}} id="isVerifySelected" type="checkbox"
                       checked={state.isVerifySelected}
                       onChange={() => props.handleCheckboxClick()}
                       className="checkbox-border p-3"/>
                <span className="fs-16 ml-2 fc-greyish-brown">I verify that this is the correct receiving lender.</span>
            </Row>*/}
            <Row className="mt-5 ">
                <Button onClick={() => history.goBack()} className="flex-1 outline-none text-transform-capitalize p-2 br-4 fs-18
                            b-1-red-two fc-red  px-3 cursor-pointer bg-transparent">
                    Cancel
                </Button>
                <div className="flex-1 max-w-50"/>
                <Button onClick={() => props.onTransferClicked()} className="flex-1 outline-none text-transform-capitalize p-2 br-4 fs-18
                            b-1-white-two bg-blue fc-white   px-3 cursor-pointer">
                    Transfer Information Record
                </Button>
            </Row>
            <div className="display-flex fs-16 fc-brownish-grey p-5 text-align-center">Tranferring of Information Record
                is an non-reversible process. Please verify all the details before clicking on
                Transfer Information Record button.
            </div>
        </div>
    )
}

function TransferRecordComponent(props) {
    return (
        <Column horizontal={'center'} className="fc-dark-slate-blue w-100 p-3 min-vh-100 h-100 justify-content-between">
            <Column className="w-100">
                {HeaderComponent(props.state)}
                {/*{props.state && props.state.isHistoryRequested ? PublishHistory(props.recordDetails) : PublishRecordView(props)}*/}
                {TransferRecordView(props)}
            </Column>
        </Column>
    );
}

export default TransferRecordComponent;
