import {httpConstants} from "../constants";
import {httpService} from "../managers/httpService";

export default {
    getNotifications,
    getNotificationCount,
    markReadNotifications
}

async function getNotifications(requestData) {
    let url = process.env.REACT_APP_NOTIFICATION_SERVICE_URL + httpConstants.API_END_POINT.NOTIFICATION_LIST;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });

}
async function getNotificationCount(requestData) {
    let url = process.env.REACT_APP_NOTIFICATION_SERVICE_URL + httpConstants.API_END_POINT.NOTIFICATION_COUNT;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });

}
async function markReadNotifications(requestData) {
    let url = process.env.REACT_APP_NOTIFICATION_SERVICE_URL + httpConstants.API_END_POINT.MARK_BULK_NOTIFICATION_READ;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });

}
