import BaseComponent from "../../modules/baseComponent";
import React from "react";
import Utils from "../../utility";

export default class CriteriaCellComponent extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            isActiveCell: null,
            isNestedViewActive: false,
            subCriteriaList: this.setSubCriteriaList()
        }
    }

    setSubCriteriaList() {
        let {criteriaObject, searchCriteriaList} = this.props;
        return Utils.getCompleteCriteriaObject(criteriaObject.subCriteriaArray, searchCriteriaList);
    }

    toggleActiveCell = (criteriaId) => {
        this.setState({isActiveCell: criteriaId});
    };

    toggleNestedListView = () => {
        this.setState({isNestedViewActive: !this.state.isNestedViewActive});
    };

    handleCriteriaSelection = (criteriaObject, onCriteriaSelection) => {

        if (criteriaObject.type === "PARENT_CRITERIA")
            return this.toggleNestedListView();

        onCriteriaSelection(criteriaObject);
    };

    render() {
        let {criteriaObject, index, searchCriteriaList} = this.props;
        let {onCriteriaSelection} = this.props.props;
        let {isActiveCell, subCriteriaList, isNestedViewActive} = this.state;
        let activeColor = isActiveCell ? " bg-white-two" : "";
        let parentCSS = isNestedViewActive ? " fw-900" : "";
        return (
            <div>
                <div onMouseEnter={() => this.toggleActiveCell(criteriaObject.criteriaId)}
                     onMouseLeave={() => this.toggleActiveCell(null)}
                     onClick={() => this.handleCriteriaSelection(criteriaObject, onCriteriaSelection)}
                     key={index}
                     className={"cursor-pointer p-v-7px p-h-4px fs-12" + `${activeColor + parentCSS}`}>{criteriaObject.name}
                    {criteriaObject.type === "PARENT_CRITERIA" ?  isNestedViewActive ? <i className="fa fa-angle-up ml-2 fw-900" aria-hidden="true"/> : <i className="fa fa-angle-down ml-2 fw-900" aria-hidden="true"/> : ""}
                </div>
                <div className="pl-4">
                    {isNestedViewActive && subCriteriaList.map((criteriaObject) => {
                        return <CriteriaCellComponent
                            key={index} criteriaObject={criteriaObject}
                            props={this.props.props}
                            searchCriteriaList={searchCriteriaList}
                        />
                    })}</div>
            </div>

        )
    }
}