import React from "react";
import BaseComponent from '../../baseComponent'
import SubLendersComponent from './subLendersComponent'
import connect from "react-redux/es/connect/connect";
import {dispatchAction} from "../../../utility";
import Utils from "../../../utility";
import {UserService} from "../../../services";
import {eventConstants} from "../../../constants";
import {InviteService} from "../../../services";
import Utility from "../../../utility";

class SubLenders extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            subUsersList: [],
            filteredList: [],
            statusFilter: "All",
            roleFilter: "All",
            searchText: null,
            subUserObjectForEdit: {},
            isConfirmationPopUpOpen: false,
            headerMessage: "",
            userObject: {},
            functionality: ""
        }
    }

    componentDidMount() {
        this.getSubUsersList();
    }

    getSubUsersList = async () => {
        if (!this.props || !this.props.user || !this.props.user.userDetails || !this.props.user.userDetails.miscellaneous || !this.props.user.userDetails.miscellaneous.company) {
            Utils.apiFailureToast("Unable to fetch Sub-Users!");
            return;
        }
        this.props.dispatchAction(eventConstants.SHOW_LOADER);

        let requestData = {};
        requestData['miscellaneous.company.companyId'] = this.props.user.userDetails.miscellaneous.company.companyId;
        requestData['isDeleted'] = 0;
        let [error, userListResponse] = await Utils.parseResponse(UserService.getUserList(requestData));

        if (error) {
            Utils.apiFailureToast("Unable to fetch Sub-Users!");
            return;
        }
        this.setState({subUsersList: userListResponse, filteredList: userListResponse});
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
    };

    onChangeEvent = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});
    };

    toggleAddUserDialog = () => {
        this.setState({isAddUserDialogOpen: !this.state.isAddUserDialogOpen});
    };

    toggleAddSubUserDialog = (userDetail) => {
        if (userDetail && Object.keys(userDetail).length <= 0)
            return;

        this.setState({subUserObjectForEdit: userDetail});
        this.toggleAddUserDialog();

    };

    onSortClicked = (key, order) => {
        Utility.sortArrayForKey(this.state.filteredList, key, order);
        this.setState({filteredList: this.state.filteredList});
    };

    onSearchTextChanged = async (event) => {
        let searchValue = event.target.value;
        await this.setState({searchText: Utility.isEmpty(searchValue) ? null : searchValue});
        this.refreshList();
    };

    onFilterChanged = async (event) => {
        await this.setState({[event.target.name]: event.target.value});
        this.refreshList();
    };

    refreshList() {
        let filteredList = this.state.subUsersList.filter(userObject =>
            (this.state.statusFilter === "All" || userObject.isInactive === this.state.statusFilter)
            // && (this.state.roleFilter === "All" || userObject.role === this.state.roleFilter)
            && (Utility.isEmpty(this.state.searchText) || userObject.firstName.toLowerCase().includes(this.state.searchText.toLowerCase())));
        this.setState({filteredList: filteredList});

    }

    onCreateUserClicked = async (requestData) => {
        if (!this.props.user || !this.props.user.deviceID)
            return;
        requestData.deviceID = this.props.user.deviceID;
        requestData.emailID = typeof requestData.emailID === "string"? [requestData.emailID]: requestData.emailID;

        this.props.dispatchAction(eventConstants.SHOW_LOADER);

        let [error, invitationResponse] = await Utils.parseResponse(InviteService.inviteUser(requestData));
        if (error) {
            Utils.apiFailureToast("Unable to invite user!");
            return;
        }
        Utils.apiSuccessToast("User invited successfully");
        this.setState({isAddUserDialogOpen: false});
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        this.getSubUsersList();
    };

    onResendInvitationClicked = async (requestData) => {
        if (!this.props.user || !this.props.user.deviceID)
            return;
        requestData.deviceID = this.props.user.deviceID;
        requestData.emailID = typeof requestData.emailID === "string"? [requestData.emailID]: requestData.emailID;

        this.props.dispatchAction(eventConstants.SHOW_LOADER);

        let [error, invitationResponse] = await Utils.parseResponse(InviteService.inviteUser(requestData));
        if (error) {
            Utils.apiFailureToast("Unable to resend invitation!");
            return;
        }
        Utils.apiSuccessToast("Invitation resent successfully");
        this.setState({isAddUserDialogOpen: false});
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
    };

    deleteSubUser = async (userObject) => {
        console.log("userObject=", userObject);
        if (!userObject || Object.keys(userObject).length < 0 || !userObject.userID)
            return;

        this.props.dispatchAction(eventConstants.SHOW_LOADER);

        let [error, response] = await Utils.parseResponse(UserService.deleteUser(userObject));

        if (error) {
            Utils.apiFailureToast("Unable to Delete User!");
            return;
        }
        Utils.apiSuccessToast("User Deleted successfully");
        this.setState({isAddUserDialogOpen: false});
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        this.getSubUsersList();

    };

    onUpdateUserClicked = async (requestData) => {
        if (!this.props.user || !this.props.user.deviceID)
            return;
        requestData.deviceID = this.props.user.deviceID;
        requestData.miscellaneous = JSON.stringify(requestData.miscellaneous);
        if (requestData.role)
            requestData.role = JSON.stringify(requestData.role);

        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, userDetails] = await Utils.parseResponse(UserService.updateUser(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error) {
            Utils.apiFailureToast("Unable to update user!")
            return;
        }

        Utils.apiSuccessToast("User updated successfully");
        this.setState({isAddUserDialogOpen: false, subUserObjectForEdit: {}});
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        this.getSubUsersList();
    };


    toggleConfirmationPopUp = (userObj = null, func, headMessage) => {
        if (!userObj || Object.keys(userObj).length < 1) {
            if (this.state.isConfirmationPopUpOpen)
                this.setState({isConfirmationPopUpOpen: false});
            else
                this.setState({isConfirmationPopUpOpen: true, functionality: func, headerMessage: headMessage});
        } else
            this.setState({isConfirmationPopUpOpen: true, userObject: userObj, functionality: func, headerMessage: headMessage});
    };

    render() {
        return (
            <SubLendersComponent state={this.state}
                                 userDetails={this.props && this.props.user && this.props.user.userDetails ? this.props.user.userDetails : null}
                                 onSortClicked={this.onSortClicked}
                                 onSearch={this.onSearchTextChanged}
                                 onFilterChanged={this.onFilterChanged}
                                 onResendClicked={this.onResendInvitationClicked}
                                 onCreateUserClicked={this.onCreateUserClicked}
                                 toggleAddUserDialog={this.toggleAddUserDialog}
                                 deleteSubUser={this.deleteSubUser}
                                 onUpdateUserClicked={this.onUpdateUserClicked}
                                 toggleAddSubUserDialog={this.toggleAddSubUserDialog}
                                 toggleConfirmationPopUp={this.toggleConfirmationPopUp}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, company: state.company}
};

export default connect(mapStateToProps, {dispatchAction})(SubLenders);
