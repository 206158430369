import React from "react";
import BaseComponent from '../baseComponent'
import ProfileSettingsComponent from './settingsComponent'
import Utils, {dispatchAction} from "../../utility";
import {apiFailureConstants, eventConstants, genericConstants, pathConstants, stringConstants} from "../../constants";
import {CompanyService, UserService} from "../../services";
import {history} from "../../managers/history";
import connect from "react-redux/es/connect/connect";
import {getSignedURL, uploadFileToS3} from "../../managers/awsService";

class Settings extends BaseComponent {
    constructor(props) {
        super(props);
        let userDetails = this.props && this.props.user && this.props.user.userDetails ? this.props.user.userDetails : null;
        this.state = {
            firstName: userDetails ? userDetails.firstName : "",
            userRole: Utils.getUserRole(userDetails),
            password: "",
            passwordError: "",
            isPasswordVisible: false,
            showSaveButton: false,
            logoLoading: false,
            profile: {
                logo: userDetails && userDetails.photo ? userDetails.photo.fileName : "",
                logoURL: userDetails && userDetails.photo ? getSignedURL(userDetails.photo.fileName) : ""
            }
        }
    }

    componentDidMount() {
        if (!this.props || !this.props.user || !this.props.user.userDetails) {
            Utils.apiFailureToast("Unable to get user details");
            return;
        }
    };

    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value});
        if (event.target.id === "firstName")
            this.setState({showSaveButton: true})
    };

    toggleSaveButton = () => {
        if (this.state.showSaveButton && this.props && this.props.user && this.props.user.userDetails)
            this.setState({firstName: this.props.user.userDetails.firstName});
        this.setState({showSaveButton: !this.state.showSaveButton})
    };

    onLogoutClicked = async (event) => {
        // event.preventDefault();
        this.props.dispatchAction(eventConstants.LOGOUT_SUCCESS);
        history.replace(pathConstants.LOGIN);
    };


    onFileCancel = () => {
        this.setState({
            files: []
        });
    };

    onFileDrop = async (files, props, requestType = "") => {
        this.setState({logoLoading: true});
        if (!files || files.length < 1) {
            Utils.apiFailureToast(apiFailureConstants.UPLOAD_PHOTO);
            this.setState({logoLoading: false});
            return;
        }
        let file = files[0];
        let fileDetail = Utils.getFileName(file.name);
        let image = Utils.getNewFileNameWithId('user', fileDetail.extension, props.userDetails.userID);

        let resFromS3 = await uploadFileToS3(file, image, file.type).catch(err => {
            Utils.apiFailureToast(apiFailureConstants.UPLOAD_PHOTO);
            return false;
        });
        let imageURL = await getSignedURL(image);
        if (!resFromS3 || !imageURL) {
            Utils.apiFailureToast(apiFailureConstants.UPLOAD_PHOTO);
            return false;
        }

        if (!imageURL || !resFromS3) {
            this.setState({logoLoading: false});
            return;
        }
        if (requestType === genericConstants.ACTION_TYPE.UPDATE_PROFILE_IMAGE) {
            let {profile} = this.state;
            profile['logo'] = image;
            profile['logoURL'] = imageURL;
            this.setState({profile, logoLoading: false});
            await this.updateUserDetails({userID: this.props.user.userDetails.userID, 'photo.fileName': image});
        }
    };

    changeUserPassword = async (passwordInfo) => {
        let request = {
            userID: this.props.user && this.props.user.userDetails && Object.keys(this.props.user.userDetails).length > 0 && this.props.user.userDetails.userID ?
                this.props.user.userDetails.userID : "",
            oldPassword: passwordInfo.currentPassword,
            password: passwordInfo.newPassword,
        };
        await this.changePassword(request)
    };

    getErrorMessage = (error, defaultMessage) => {
        return error && Object.keys(error).length > 0 && error.message && error.message.length > 0
        && error.message[0] && Object.keys(error.message[0]).length > 0 && error.message[0].msg ? error.message[0].msg : defaultMessage;
    };

    changePassword = async (requestData) => {
        if (!requestData || Object.keys(requestData).length < 1 || !requestData.userID)
            return;
        let [error, response] = await Utils.parseResponse(UserService.changePassword(requestData));
        if (error) {
            Utils.apiFailureToast(this.getErrorMessage(error, "Unable to Change password!"));
            return;
        }
        Utils.apiSuccessToast(stringConstants.PASSWORD_UPDATED_SUCCESSFULLY);
    };

    updateUserDetails = async (requestData) => {
        console.log('Class: Settings, Function: updateUserDetails ==', requestData);
        if (!requestData || Object.keys(requestData).length < 1 || !requestData.userID)
            return;
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, response] = await Utils.parseResponse(UserService.updateUser(requestData));
        if (error) {
            Utils.apiFailureToast(this.getErrorMessage(error, "Unable to update profile"));
            this.props.dispatchAction(eventConstants.HIDE_LOADER);
            return;
        }
        Utils.apiSuccessToast(stringConstants.PROFILE_PICTURE_UPDATED_SUCCESSFULLY);
        this.props.dispatchAction(eventConstants.GET_USER_DETAILS_SUCCESS, response)
        this.setState({showSaveButton: false})
    };

    render() {
        return (
            <ProfileSettingsComponent state={this.state}
                                      user={this.props.user}
                                      onChangeEvent={this.onChangeEvent}
                                      onLogoutClicked={this.onLogoutClicked}
                                      onFileCancel={this.onFileCancel}
                                      toggleSaveButton={this.toggleSaveButton}
                                      onFileDrop={this.onFileDrop}
                                      updateUserDetails={this.updateUserDetails}
                                      changeUserPassword={this.changeUserPassword}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user}
};

export default connect(mapStateToProps, {dispatchAction})(Settings);
