import {LinearProgress, withStyles} from "@material-ui/core";

const LinearProgressBar = withStyles({
    root: {
        height: 7,
        backgroundColor: '#dfe2e8',
        borderRadius: '3px',
        width: '100%'
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#0052cc',
    },
})(LinearProgress);

export default LinearProgressBar;