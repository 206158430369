import React from "react";
import BaseComponent from '../baseComponent'
import SignUpComponent from './signUpComponent'
import Utils, {dispatchAction} from "../../utility";
import {CompanyService, SubscriptionService, UserService} from "../../services";
import connect from "react-redux/es/connect/connect";
import {eventConstants, pathConstants, stringConstants} from "../../constants";
import {history} from "../../managers/history";

class Register extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            nameError: "",
            email: "",
            emailError: "",
            password: "",
            passwordError: "",
            confirmPassword: "",
            confirmPasswordError: "",
            selectedRole: {},
            selectedPlan: {},
            rolesList: []
        }
    }

    async componentDidMount() {
        this.getPlansList();
        await this.getRolesList();
    }

    getPlansList = async () => {
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, planList] = await Utils.parseResponse(SubscriptionService.getSubscriptionPlans());
        if (error || !planList || planList.length === 0) {
            this.props.dispatchAction(eventConstants.HIDE_LOADER);
            return;
        }
        this.setState({planList: planList});
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
    };

    getRolesList = async () => {
        this.props.dispatchAction(eventConstants.SHOW_LOADER);

        let [error, rolesList] = await Utils.parseResponse(UserService.getUserRoles());

        this.props.dispatchAction(eventConstants.HIDE_LOADER);

        if (error || !rolesList || rolesList.length === 0)
            return;
        rolesList = rolesList.filter((roleObj) => roleObj.title !== 'SUPER_ADMIN');
        rolesList = rolesList.map((roleObject) => {
            roleObject.roleID = roleObject._id;
            delete roleObject._id;
            return roleObject;
        });
        await this.setState({rolesList: rolesList});
        //TODO: Work for role selection-
    };

    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value, [`${event.target.id}Error`]: ''});
    };

    togglePassword = (event) => {
        this.setState({isPasswordVisible: !this.state.isPasswordVisible});
    };


    validateLoginForm = () => {
        this.setState({
            nameError: Utils.isEmpty(this.state.name) ? stringConstants.EMPTY_NAME : (Utils.validateName(this.state.name) ? "" : stringConstants.INVALID_NAME),
            emailError: Utils.isEmpty(this.state.email) ? stringConstants.EMPTY_EMAIL :
                (!Utils.validateEmail(this.state.email) ? stringConstants.INVALID_EMAIL : ''),
            passwordError: Utils.isEmpty(this.state.password) ? stringConstants.EMPTY_PASSWORD :
                (Utils.isPasswordValid(this.state.password) ? "" : stringConstants.PASSWORD_VALIDATION),

            confirmPasswordError: Utils.isEmpty(this.state.confirmPassword) ? stringConstants.EMPTY_PASSWORD :
                (Utils.isPasswordValid(this.state.confirmPassword) ? (this.state.password === this.state.confirmPassword ? "" : stringConstants.PASSWORD_DO_NOT_MATCH) :
                    stringConstants.PASSWORD_VALIDATION)

        });
        return Utils.validateName(this.state.name) && Utils.validateEmail(this.state.email) && Utils.isPasswordValid(this.state.password)
            && Utils.isPasswordValid(this.state.confirmPassword) && this.state.password === this.state.confirmPassword;
    };

    handlePlanSelection = (selectedRole, selectedPlan) => {
        this.setState({selectedRole: selectedRole, selectedPlan: selectedPlan});
    };

    onSignUpClicked = async (event) => {
        event.preventDefault();

        if (!this.validateLoginForm())
            return;
        if (!this.state.rolesList || this.state.rolesList.length === 0) {
            console.log("RolesList not available");
            return;
        }

        this.props.dispatchAction(eventConstants.SHOW_LOADER);

        let requestData = {};
        requestData.firstName = this.state.name;
        requestData.emailID = this.state.email;
        requestData.password = this.state.password;
        let roleArray = [];
        roleArray.push(this.state.selectedRole);
        requestData.role = JSON.stringify(roleArray);
        let miscellaneous = {roleLevel: Utils.getUserRoleLevelForCompany(this.state.selectedRole)};
        requestData.miscellaneous = JSON.stringify(miscellaneous);
        let [error, response] = await Utils.parseResponse(UserService.registerUser(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);

        if (error) {
            console.log('Class: Register, Function: onSignUpClicked ==', error);
            Utils.apiFailureToast(error && error.message && error.message[0] ? error.message[0].msg : "Unable to add user");
            return
        }
        this.props.dispatchAction(eventConstants.SIGN_UP_SUCCESS, response);
        await this.getUserPermissions(response);
        history.push(pathConstants.COMPANY);

    };
    getUserPermissions = async (userData) => {
        console.log("userDetails===getUserPermissions", userData);

        if (!userData || Object.keys(userData).length < 1 || !userData.userDetails || Object.keys(userData.userDetails).length < 1)
            return;
        let roleTittle = Utils.getUserRole(userData.userDetails);
        let roleLevel = Utils.getUserRoleLevel(userData.userDetails);
        console.log("roleTittle", roleTittle);
        console.log("roleLevel", roleLevel);

        if (!roleTittle || !roleLevel)
            return;

        let roleID = Utils.getUserRoleID(roleTittle, roleLevel);
        console.log("roleID===", roleID);
        let requestData = {
            roleID: roleID
        };
        let [error, response] = await Utils.parseResponse(UserService.getUserPermissions(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error) {
            Utils.consoleLogger("error", error);
            Utils.apiFailureToast(error && error.message && error.message.length !== 0 && error.message[0] && error.message[0].msg ? error.message[0].msg : "Unable to login")
            return
        }
        console.log("response in getUserPermissions", response);
        this.props.dispatchAction(eventConstants.GET_USER_PERMISSION_SUCCESS, response);
    };

    render() {
        return (
            <SignUpComponent state={this.state}
                             onChangeEvent={this.onChangeEvent}
                             togglePassword={this.togglePassword}
                             handlePlanSelection={this.handlePlanSelection}
                             onLoginClicked={this.onSignUpClicked}/>
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user}
};

export default connect(mapStateToProps, {dispatchAction})(Register);
