import Utility from "../../utility";
import {getSignedURL} from "../../managers/awsService";
import {Column, Row} from "simple-flexbox";
import {genericConstants} from "../../constants";
import {Button, LinearProgress, withStyles} from "@material-ui/core";
import React from "react";


const BorderLinearProgress = withStyles({
    root: {
        height: 6,
        backgroundColor: '#dfe2e8',
        borderRadius: '3px',
        width: '100%'
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#0052cc',
    },
})(LinearProgress);

const RecordInfoComponent = (props) => {

    let {recordDetails, userDetails} = props;
    let userRole = Utility.getUserRole(userDetails);

    if (!recordDetails)
        return;
    let imageURL = getSignedURL(recordDetails.propertyPhoto, '/images/recent-companies.svg');
    return (
        <Row className="my-4" vertical="start">
            <Column className="max-w-225 w-100-per">
                <img src={imageURL} className="max-w-225 w-100-per"/>
            </Column>
            <div className="text-left line-height-2-27 w-450 fw-500 fs-15">
                <Row className="mx-3 justify-content-between">
                    <div className="fc-blue-grey mx-3">File Number</div>
                    <div className="fc-dark-slate-blue mx-3">
                        {recordDetails && recordDetails.fileNo ? recordDetails.fileNo : ""}</div>
                </Row>
                <Row className="mx-3 justify-content-between">
                    <div className="fc-blue-grey mx-3">Loan Tag</div>
                    <div className="fc-dark-slate-blue mx-3">
                        {recordDetails && recordDetails.loanTag ? recordDetails.loanTag : ""}</div>
                </Row>
                <Row className="mx-3 justify-content-between">
                    <div className="fc-blue-grey mx-3">Location</div>
                    <div className="fc-dark-slate-blue mx-3">
                        {recordDetails && recordDetails.address && recordDetails.address.city ? recordDetails.address.city : ""}
                        {recordDetails && recordDetails.address && recordDetails.address.city && recordDetails.address.state ? "," : ""}
                        {recordDetails && recordDetails.address && recordDetails.address.state ? " "+ recordDetails.address.state  : ""}
                    </div>
                </Row>
                <Row className="mx-3 justify-content-between">
                    <div className="fc-blue-grey mx-3">Type</div>
                    <div className="fc-dark-slate-blue mx-3">
                        {recordDetails && recordDetails.propertyType ? recordDetails.propertyType: ""}
                        {recordDetails && recordDetails.propertyType && recordDetails.propertySubType ? "," : ""}
                        {recordDetails && recordDetails.propertySubType ? " "+recordDetails.propertySubType : ""}
                    </div>
                </Row>
                <Row className="mx-3 justify-content-between">
                    <div className="fc-blue-grey mx-3">Status</div>
                    <div className="fc-dark-slate-blue mx-3">
                        {recordDetails && recordDetails.status ? recordDetails.status : ""}
                    </div>
                </Row>
                <Row className="mx-3 justify-content-between">
                    <div className="fc-blue-grey mx-3">Completion</div>
                    <Row vertical="center" className="fc-dark-slate-blue mx-3 w-100">
                        <BorderLinearProgress
                            variant="determinate"
                            color="secondary"
                            value={recordDetails && Object.keys(recordDetails).length > 0 && recordDetails.progress ? parseInt(recordDetails.progress) : 0}
                        />
                    </Row>
                </Row>
            </div>

        </Row>
    )
};
export default RecordInfoComponent;