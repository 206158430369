/**
 * Created by Ayush Kulshrestha on 18/09/2019.
 */


export const httpConstants = {
    METHOD_TYPE: {
        POST: 'POST',
        PUT: 'PUT',
        GET: 'GET',
        DELETE: 'DELETE',
    },
    CONTENT_TYPE: {
        APPLICATION_JSON: 'application/json',
        MULTIPART_FORM_DATA: 'multipart/form-data',
        APPLICATION_FORM_URLENCODED: 'application/x-www-form-urlencoded',
        IMAGE_PNG: 'image/png'
    },
    DEVICE_TYPE: {
        WEB: 'web'
    },
    API_END_POINT: {
        //COMPANY_SERVICE_END_POINTS
        COMPANY_DETAILS: "/company",
        COMPANY_DETAILS_USING_LIMB_ID: "/company/limb-id",
        ADD_COMPANY_DETAILS: "/add-company",
        GET_LENDERS_LIST: "/add-relation",
        GET_ASSOCIATE: "/get-associate",
        INVITE_ASSOCIATE: "/invite-associate",
        GET_COMPANY_BASED_ON_ITS_TYPE: "/company",
        GET_COMPANY_BASED_ON_ITS_STATUS: "/companies",
        INVITE_COUNSEL: "/invite-counsel",

        //RECORD_SERVICE_END_POINT
        ADD_RECORD_DETAILS: "/add-record",
        RECORD_DETAILS: "/record/",
        UPDATE_RECORD: "/record",
        SECTION_DETAILS: "/section/",
        UPDATE_STATUS: "/update-status",
        QUESTION_DETAILS: "/question/",
        MARK_AS_COMPLETE: "/mark-complete",
        RECORD_LIST: "/record-list",
        SECTION_LIST: "/section-list/",
        QUESTION_LIST: "/question-list/",
        ACTIVITY_LIST: "/get-activities",
        INVITATION: "/invitation/",
        RECORD_FILTERS: "/record-filter",
        SEARCH_CRITERIA: "/search-criteria",
        EXTRACT_ANSWER: "/extract-answer",

        //USER_SERVICE_END_POINTS

        REGISTER_DEVICE: '/register-device',
        REGISTER_USER: '/register-user',
        LOGIN: '/login',
        LOGOUT: '/logout',
        GET_ROLE_LIST: '/get-role-list',
        GET_USER_DETAILS: '/get-user-details',
        GET_USER_LIST: '/get-users',
        DELETE_USER: '/delete-user',
        GET_USER_LIST_BY_QUERY_OBJ: '/get-user-list-by-queryObj',
        GET_PERMISSION_LIST: '/get-permission-list',
        RESET_PASSWORD: '/reset-password',
        CHANGE_PASSWORD: '/change-password',
        UPDATE_USER: '/update-user',
        FORGOT_PASSWORD: '/forgot-password',

        //INVITE_SERVICE_END_POINTS
        INVITE_USER: '/invite-user',

        //DOCUMENT_SERVICE_END_POINTS
        ADD_DOCUMENTS: '/add-documents',
        PLANS: '/plans',
        SUBSCRIPTION: '/subscription',
        INVOICES: '/invoices',
        DOCUMENT: '/document',
        CREATE_REPORT: '/report',
        GET_DOCUMENTS: '/get-documents',

        //NOTIFICATION_SERVICE_END_POINTS
        NOTIFICATION_LIST: '/notification-list',
        NOTIFICATION_COUNT: '/notification-count',
        MARK_BULK_NOTIFICATION_READ: '/mark-bulk-notification-read',

        //PUBLISH_RECORD_MICRO_SERVICE
        PUBLISH: '/publish',
        PUBLISH_REQUEST_LIST: '/publish-request-list',
        PUBLISH_REQUEST_LIST_BY_COMPANY: '/request-list',
        PUBLISH_REQUEST: '/publish-request',
        STATE_PUBLISH: '/state-publish',
        STATE_TRANSFER: '/state-transfer',
    }
};

export const genericConstants = {
    FILE_NAME: {
        REP_EXCEPTION: 'repException',
        SERVICING_ON_BOARDING: 'ServicingOnBoarding',
        OWNER_BORROWER: 'ownerBorrower',
        DUE_DILIGENCE: 'dueDiligence',
        REPO_REVIEW: 'repoReview'
    },
    ACCEPTED_IMAGE_FORMATS: ".jpg, .jpeg, .png, .svg",
    ACCEPTED_FILES_FORMATS: ".doc, .docx, .pdf, .xlsx, .xlsm, .xlsb, .xls",
    FILTERS: ["fileNo", "status", "lender", "originationCounsel", "sellerCounsel", "pool", "state", "city", "lat", "lng"],
    REPORT_TYPE: {
        REP_EXCEPTION: 'REP_EXCEPTION',
        SERVICING_ON_BOARDING: 'SERVICING_ON_BOARDING',
        EXPORT_REPORT_TITLE: 'EXPORT_REPORTS',
        OWNER_BORROWER: 'OWNER_BORROWER',
        DUE_DILIGENCE: 'DUE_DILIGENCE',
        REPO_REVIEW: 'REPO_REVIEW'
    },
    VIEWABLE_REPORT_TYPE: {
        EXPORT_REPORT_TITLE: 'Export Reports',
        SERVICING_ON_BOARDING_TITLE: 'Servicing On-Boarding Report',
        OWNER_BORROWER_TITLE: 'Owner Borrower Report',
        DUE_DILIGENCE_TITLE: 'Due Diligence Questionnaire Report',
        REPO_REVIEW_TITLE: 'Repo Review Report',
        REP_EXCEPTION: 'Rep Exceptions Report',
    },
    INITIAL_FILTER_VALUES: {
        LOCATION: "All Locations",
        LENDER: "All Lenders",
        OC: "All Origination Counsel",
        SC: "All Loan Seller Counsel",
        STATUS: "All Status",
        POOL: "All Pools",
        MORE: "More"
    },
    ROLES: {
        SUPER_ADMIN: "SUPER_ADMIN",
        LENDER: "LENDER",
        SUB_LENDER: "SUB_LENDER",
        ORIGINATION_COUNSEL: "ORIGINATION_COUNSEL",
        SUB_ORIGINATION_COUNSEL: "SUB_ORIGINATION_COUNSEL",
        SELLER_COUNSEL: "SELLER_COUNSEL",
        SUB_SELLER_COUNSEL: "SUB_SELLER_COUNSEL",
    },
    VIEWABLE_COMPANY_NAME: {
        LENDER: "Lender",
        ORIGINATION_COUNSEL: "Origination Counsel",
        SELLER_COUNSEL: "Seller Counsel",
    },
    INVITATION_STATUS: {
        INVITED: "INVITED",
        ACCEPTED: "ACCEPTED",
        REJECTED: "REJECTED",
        PENDING: "PENDING",
        IN_ACTIVE: "IN-ACTIVE",
        ACTIVE: "ACTIVE",
        DELETED: "DELETED",
        COMPLETED: "COMPLETED",

    },
    PUBLISH_HISTORY_CATEGORY: {
        TYPE_A: "typeA",
        TYPE_B: "typeB",
        TYPE_C: "typeC",
    },
    PUBLISH_HISTORY_CATEGORY_TAG: {
        TYPE_A: "Loan Origination",
        TYPE_B: "Loan Origination/Securitization",
        TYPE_C: "Loan Servicing Event",
        TYPE_UN_CATEGORIZED: "Uncategorized",
    },
    RECORD_PART: {
        PART_A: "A",
        PART_B: "B",
        PART_C1: "C1",
        PART_C: "C",
        PART_C2: "C2",
    },
    PART_TYPE: {
        PART_A: "PART-A",
        PART_B: "PART-B",
        PART_C: "PART-C",
        PART_C1: "PART-C1",
        PART_C2: "PART-C2",
    },
    LEVEL_TYPE: {
        LEVEL_1: "LEVEL-1",
        LEVEL_2: "LEVEL-2",
        LEVEL_3: "LEVEL-3",

    },
    VIEWABLE_LEVEL_TYPE: {
        LEVEL_1: "Level 1",
        LEVEL_2: "Level 2",
        LEVEL_3: "Level 3",

    },
    QUESTION_TYPE: {
        CHECKBOX: "CHECKBOX",
        DESCRIPTION: "DESCRIPTION",
        INFO: "INFO",
        DESCRIPTIVE_CHECKBOX: "DESCRIPTIVE_CHECKBOX",
        QUESTION_CHECKBOX: "QUESTION_CHECKBOX",
    },
    SORTING_ORDER: {
        ASCENDING: "ASCENDING",
        DESCENDING: "DESCENDING"
    },
    ANSWER_TYPE: {
        CHECKBOX: "CHECKBOX",
        DESCRIPTION: "DESCRIPTION",
        INFO: "INFO",
        DESCRIPTIVE_CHECKBOX: "DESCRIPTIVE_CHECKBOX",
    },
    SECTION_STATUS_FOR_VIEW: {
        OPEN: "Open",
        IN_PROGRESS: "In Progress",
        SEND_FOR_REVIEW: "Send For Review",
        REVIEW_PENDING: "Review Pending",
        APPROVE: "Approve",
        REJECT: "Reject",
        DONE: "Done",
        PENDING: "Pending",
        COMPLETED: "Completed",
        PUBLISHED: "Published",
        APPROVED_FOR_SC_REVIEW: "Approve For SC Review",
    },
    SECTION_STATUS: {
        OPEN: "OPEN",
        IN_PROGRESS: "IN-PROGRESS",
        PENDING: "PENDING",
        IN_REVIEW: "IN-REVIEW",
        APPROVED: "APPROVED",
        REJECTED: "REJECTED",
        COMPLETED: "COMPLETED",
        PUBLISHED: "PUBLISHED",
        IN_COMPLETE: "IN-COMPLETE"
    },
    COMPANY_STATUS: {
        PENDING: "PENDING",
        ACTIVE: "ACTIVE",
        COMPLETED: "COMPLETED",
        ACCEPTED: "ACCEPTED"
    },
    REQUEST_TYPE: {
        SECTION_STATUS_CHANGED: "SECTION_STATUS_CHANGED",
        QUESTION_STATUS_CHANGED: "QUESTION_STATUS_CHANGED",

    },
    ACTION_TYPE: {
        UPDATE_PROFILE_IMAGE: "UPDATE_PROFILE_IMAGE",
        UPDATE_COMPANY_IMAGE: "UPDATE_COMPANY_IMAGE",
    },
    CRITERIA_TYPE: {
        TEXT: "TEXT",
        CALENDER_RANGE: "CALENDER_RANGE",
        AMOUNT_RANGE: "AMOUNT_RANGE",
        AMOUNT: "AMOUNT",
        QUESTION_CHECKBOX: "QUESTION_CHECKBOX",
        CHECKBOX: "CHECKBOX"
    },
    QUERY_OBJECT_KEY: {
        QUERY_OBJECT: "queryObj",
        POSTED_TO: "postedTo",
        PAYLOAD_SEND_TO_USER_ID: 'payload.sendTo._id',
        IS_CLEARED: 'isCleared',
        IS_READ: 'isRead',
        PAYLOAD_SEND_FROM_USER_ID: 'payload.sentFrom._id',
        PAYLOAD_COMPANY_ID: 'payload.company.id',
        PAYLOAD_IS_FOR_EMPLOYEE: 'payload.isForEmployee',
        PAYLOAD_NOTIFICATION_TYPE: 'payload.notificationType',
        TYPE: 'type',
        SELECTION_STRING: "selectionString",
        NOTIFICATION_ID_ARRAY: "notificationIDArray",
    },
    RECORD_STATUS: {
        PUBLISHED: "PUBLISHED",
        REJECTED: "REJECTED"
    },
    ACTIVITY_TYPE: {
        ASSIGNMENT_FOR_REVIEW: "ASSIGNMENT_FOR_REVIEW",
        APPROVAL_FOR_SC_REVIEW: "APPROVAL_FOR_SC_REVIEW",
        REJECTION: "REJECTION",
        REJECTION_BY_SC: "REJECTION_BY_SC",
        APPROVAL_BY_SC: "APPROVAL_BY_SC"
    },
    RECORD_DETAILS_MENU_OPTION: {
        TRANSFER: "TRANSFER",
        PUBLISH: "PUBLISH",
    },
    PLAN_NAME: {
        LOAN_SELLER_COUNSEL: 'Loan Seller Counsel',
        LOAN_ORIGINATION_COUNSEL: 'Loan Origination Counsel',
        LOAN_SELLER: 'Loan Seller',
    },
    SUBSCRIPTION_TYPE: {
        MONTHLY: "MONTHLY",
        YEARLY: "YEARLY"
    },
    SUBSCRIPTION_PLAN: {
        YEARLY: "YEARLY",
        MONTHLY: "MONTHLY",
    },
    SUBSCRIPTION_PLAN_TEXT: {
        YEARLY: "Annually",
        MONTHLY: "Monthly",
    },
    AMOUNT_UNIT: {
        THOUSAND: "THOUSAND",
        MILLION: "MILLION",
        BILLION: "BILLION",
    },
    AMOUNT_UNIT_SIGN: {
        THOUSAND: "K",
        MILLION: "M",
        BILLION: "B",
    },
    AMOUNT_MULTIPLIER_VALUE_BY_UNIT: {
        THOUSAND: "1000",
        MILLION: "1000000",
        BILLION: "1000000000",
    },

};

export const eventConstants = {

    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",

    GET_COMPANY_SUCCESS: "GET_COMPANY_SUCCESS",
    GET_COMPANY_FAILURE: "GET_COMPANY_FAILURE",

    UPDATE_COMPANY_SUCCESS: "UPDATE_COMPANY_SUCCESS",
    UPDATE_COMPANY_FAILURE: "UPDATE_COMPANY_FAILURE",

    SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
    GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
    GET_USER_PERMISSION_SUCCESS: "GET_USER_PERMISSION_SUCCESS",

    REGISTER_DEVICE_SUCCESS: "REGISTER_DEVICE_SUCCESS",
    REGISTER_DEVICE_FAILURE: "REGISTER_DEVICE_FAILURE",

    ADD_RECORD_SUCCESS: "ADD_RECORD_SUCCESS",
    ADD_RECORD_FAILURE: "ADD_RECORD_FAILURE",

    GET_RECORD_LIST_REQUESTED: "GET_RECORD_LIST_REQUESTED",
    GET_RECORD_LIST_SUCCESS: "GET_RECORD_LIST_SUCCESS",
    GET_RECORD_LIST_FAILURE: "GET_RECORD_LIST_FAILURE",

    SET_SECTION: "SET_SECTION",
    SECTION_LIST: "SECTION_LIST",

    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

    REVIEW_PUBLISH_REQUEST: 'REVIEW_PUBLISH_REQUEST'
};

export const actionTypeConstants = {

    ADD_SUB_LENDER: "ADD_SUB_LENDER",
    ADD_SUB_USER: "ADD_SUB_USER",
    ADD_ORIGINATION_COUNSEL: "ADD_ORIGINATION_COUNSEL",
    ADD_SELLER_COUNSEL: "ADD_SELLER_COUNSEL",
    ADD_SUB_USER_TEXT: "Add Sub-Users",
    ADD_ORIGINATION_COUNSEL_TEXT: "Add Origination Counsel",
    ADD_SELLER_COUNSEL_TEXT: "Add Seller Counsel",
};

export const apiSuccessConstants = {

    GET_RECORD_LIST: "Record List fetched successfully!",
    ACTIVATE_COMPANY: "Account Activated - User will be notified",
    DELETE_DOCUMENT: "Document has been deleted successfully ",
};


export const documentCategoryTypeList =Object.freeze({
    "IN-PROCESS/ DRAFTS":0,
    "LEGAL BINDER/ LOAN DOCUMENTS": 1,
    "SPLIT LOAN/ ADDIT DEBT-RELATED": 2,
    "THIRD PARTY REPORTS": 3,
    "LOAN UNDERWRITING": 4,
    "ASSET SUMMARY": 5,
    "LEGAL DATA RECORD": 6,
    "OTHER DOCUMENTS/ DELIVERABLES": 7,
    "SERVICING REPORTS/ CONSENTS": 8,
    "INTERNAL ONLY/ NOT FOR TRANSFER": 9,
});
export const apiFailureConstants = {

    GET_FILTER_LIST: "Unable to fetch Filter List!",
    SEARCH_BY_CRITERIA: "Unable to apply Filter!!",
    GET_RECORD_LIST: "Unable to fetch Record List!",
    UPLOAD_PROPERTY_PHOTO: "Unable to upload property photo",
    UPLOAD_PHOTO: "Unable to upload photo!",
    UPLOAD_DOCUMENT: "Unable to upload document",
    UPLOAD_INVOICE: "Unable to upload invoice",
    VIEW_DOCUMENT: "Unable to View document",
    VIEW_INVOICE: "Unable to View invoice",
    GET_DOCUMENT: "Unable to get document",
    DELETE_DOCUMENT: "Unable to delete document",
    NO_DOCUMENT: "Document not found",
    PARAMETER_VIEW_DOCUMENT: "Invalid parameter to View Document",
    SELECT_REPORT_TYPE: "Please Select the Report Type",
    SELECT_RECORD: "Please Select a Record",
    SELECT_SINGLE_RECORD: "Please Select a Single Record",
    EXPORT_REPORT: "Unable to Export Report",
    LOGOUT: "Unable to Logout current User",
    ACTIVATE_COMPANY: "Unable to Activate Company Account",
    SUBSCRIPTION_DATA_NOT_FOUND: "Subscription Data not found",
    PUBLISH_REQUEST_DELETED: "Unable to delete the publish request!",
};


export const stringConstants = {
    DOWNLOAD_PRIVATE_KEY: "Download Private Key",
    ACTIVATION_PENDING_HEADER: "Activation Pending",
    SETUP_COMPANY_HEADER: "Setup your Company Profile",
    ACTIVATION_PENDING_DETAIL: "Your account will be activated once your wire transfer is confirmed by LIMB admin." +
        " You will be notified via your registered email.",
    SETUP_COMPANY_DETAIL: "Creating your company profile helps other counsels to search you and connect with you on LIMB plaform",
    CONTACT_ON_QUERY: "You can contact support@limbcre.com in case you have any questions.",
    SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL || "support@limbcre.com",
    INVALID_EMAIL: "Invalid email address",
    EMPTY_CONTACT_EMAIL: "Contact email cannot be empty",
    EMPTY_PRIMARY_CONTACT: "Primary contact cannot be empty",
    EMPTY_COMPANY_WEBSITE: "Company website cannot be empty",
    EMPTY_COMPANY_LOGO: "Please upload company logo",
    EMPTY_FILE_NUMBER: "File Number cannot be empty",
    EMPTY_PROPERTY_PHOTO: "Please Upload the property Photo",
    EMPTY_INVOICE_NUMBER: "Please enter invoice number",
    EMPTY_INVOICE_AMOUNT: "Please enter invoice amount",
    EMPTY_DOCUMENT_CATEGORY: "Please Select the Category",
    EMPTY_DOCUMENT_CATEGORY_TYPE: "Please Select the Category Type",
    EMPTY_LOAN_TAG: "Loan Tag cannot be empty",
    EMPTY_PROPERTY_ADDRESS: "Property Address cannot be empty",
    EMPTY_PROPERTY_TYPE: "Property Type cannot be empty",
    EMPTY_CITY: "City cannot be empty",
    EMPTY_STATE: "State cannot be empty",
    EMPTY_COUNTRY: "Country cannot be empty",
    EMPTY_SUB_LENDER: "Sub Lender cannot be empty",
    EMPTY_OC: "Organisation Counsel cannot be empty",
    EMPTY_NAME: "Name cannot be empty",
    EMPTY_PASSWORD: "Password cannot be empty",
    EMPTY_EMAIL: "Email cannot be empty",
    EMPTY_LOCATION: "Location cannot be empty",
    EMPTY_ROLE_LEVEL: "Role Level cannot be empty",
    EMPTY_COMPANY_NAME: "Company Name cannot be empty",
    EMPTY_STREET_ADDRESS: "Street Address cannot be empty",
    EMPTY_SUITE: "Suite cannot be empty",
    EMPTY_SELLER_COUNSEL: "Seller Counsel cannot be empty",
    INVALID_NAME: "First letter should be capital",

    PASSWORD_VALIDATION: "Password should contain at least 1 capital letter, 1 special character and a minimum length of 8 characters",
    PASSWORD_DO_NOT_MATCH: "Passwords do not match",
    INVITATION_LINK_EXPIRED: "Invitation Link Expired!",
    LINK_EXPIRED: "Link Expired!",
    ACCOUNT_CREATED_SUCCESSFULLY: "Account created successfully",
    PASSWORD_RESET_SUCCESSFULLY: "Password changed successfully",
    PROFILE_PICTURE_UPDATED_SUCCESSFULLY: "Profile picture updated successfully!",
    PASSWORD_UPDATED_SUCCESSFULLY: "Password changed successfully!",
    INVITATION_PENDING: "Invitation Pending",
    REVIEW_PENDING: "Review Pending",
    ACTIVE: "Active",
    REJECTED: "Rejected",
    DELETED: "Deleted",
    INACTIVE: "Inactive",
    INCOMPLETE_SECTION: "Section is not completed yet! It is having some incomplete questions!",
    PLEASE_FILL_THIS_FIELD: "Please fill this field",
    INVITE_LENDER: "Invite New Lender",
    UPDATE_LENDER: "Update Lender",
    INVITE_OC: "Invite New Origination Counsel",
    INVITE_SC: "Invite New Loan Seller Counsel",
    EMPTY_FIELD: "This field cannot be empty",

    INVITE: "Invite",
    UPDATE: "Update",
    PUBLISH: 'Publish',
    REJECT: 'Reject',

    CONFIRM_DELETE_INVITATION: "Are you sure you want to delete this invitation?",
    CONFIRM_RESEND_INVITATION: "Are you sure you want to resend this invitation?",
    CONFIRM_LOGOUT: "Are you sure you want to log out of this account?",
    CONFIRM_DELETE_DOCUMENT: "Are you sure you want to delete this Document?",
    CONFIRM_ACTIVATE_ACCOUNT: "Are you sure you want to activate this account?",
    MARK_AS_COMPLETE: "Are you sure you want to mark this section as complete?",
    RESEND: "resend",
    DELETE: "delete",
    LOGOUT: "logout",
    DEFAULT: "default",

    DATE_RANGE_INPUT_ERROR: '\'From\' field cannot be later than \'To\' field.',
    DATE_INPUT_ERROR: 'Please enter valid values'
};


export const pathConstants = {
    LOGIN: '/',
    SIGN_UP: '/sign-up',
    COMPANY: '/company',
    DASHBOARD_MENU: {
        PUBLISH_REQUESTS: '/dashboard/publish-requests',
        ACTIVATION_REQUESTS: '/dashboard/activation-requests',
        SUBSCRIPTION_PLANS: '/dashboard/subscription-plans',
        INFORMATION_RECORDS: '/dashboard/information-records',
        INFORMATION_RECORDS_DETAILS: '/dashboard/information-records/',
        TRANSACTION_ACCELERATOR: '/dashboard/transaction-accelerator',
        ORIGINATION_COUNSEL: '/dashboard/origination-counsel',
        SELLER_COUNSEL: '/dashboard/seller-counsel',
        SUB_LENDERS: '/dashboard/sub-lender',
        BILLING: '/dashboard/billing',
        SETTINGS: '/dashboard/settings',
        LENDER: '/dashboard/lender',
        SUB_COUNSEL: '/dashboard/sub-counsel',
        NOTIFICATIONS: '/dashboard/notifications',
        PUBLISH: '/publish/',
        TRANSFER: '/transfer/',
        HISTORY: 'history/',
        ACTIVATION: '/activation',
    }
};

export const cookiesConstants = {
    DEVICE_ID: 'deviceId',
    SESSION_TOKEN: 'sessionToken',
    USER: 'user',
    USER_PERMISSION: 'userPermission',
    COMPANY: 'company',
    PUBLISH_REQUEST: 'publishRequest',
    SECTION_INFO: 'section-info',
    SECTION_LIST: 'section-list',
    IS_ADMIN_VIEW: 'admin-view',
};

export const dateQuestionIdsList = [
    "5dd7d99a299c840031e57a56",
    "5dd7d96b299c840031e57a4e",
    "5dd7d91c299c840031e57a46",
    "5dd7d8cc299c840031e57a43",
    "5dd7c7c6299c840031e579e6",
    "5dd7c7b9299c840031e579e5",
    "5dd69a804b32ae00317a6617",
    "5dd694884b32ae00317a65d9",
    "5dd663bc4b32ae00317a34ce",
    "5dd647044b32ae00317a2810",
    "5daee7ea1ae493c73f19a10f",
    "5daee7cf1ae493c73f19a10e",
    "5daee79e1ae493c73f19a10d",
    "5daea7b2192f42c6155b1288",
    "5da705c1258d23112fef6bed",
    "5da705a1258d23112fef6beb",
    "5da6cd87258d23112fef6b63",
    "5da6cd71258d23112fef6b61",
    "5da5cca2b1a6cc06b42ee464",
    "5da58152b1a6cc06b42ee3b2",
    "5da4110ae10a0702612900ff",
    "5da2d23af9c36610c514633c",
    "5da2d213f9c36610c514633a",
    "5da2d0cff9c36610c514632a",
    "5da2ce5af9c36610c5146309",
    "5da2ccb7f9c36610c51462ff",
    "5da0b3e4a9afa80b6fa1817d",
    "5e81bdf81c9d44000054a546",
    "5e81be671c9d44000054a547",
    "5e81bea51c9d44000054a548",
    "5e81bee31c9d44000054a549",
    "5e81bf2b1c9d44000054a54a",
    "5e81bf641c9d44000054a54b",
    "5da028c679020c09da03f2d6",
    "5eea210384dc370031542f37",
    "5eea212284dc370031542f38",
    "5eea215d84dc370031542f39",
    "5eea218684dc370031542f3a",
    "5eea21a384dc370031542f3b"
];

export const underlinedQuestionList = {
    "5da09dd8a9afa80b6fa180f1": "Misapplication"
};

export const questionListToPreventOtherQuestionInSection = {
    "5da5dc9577a7990912c9446b": "No",
    "5da5dc7e77a7990912c94467": "No"
};

export const sectionListToPreventSpecificQuestionInSection = [
    "5d9effb31f951102d9e3e67e"
];

export const poolRelatedQuestionIds = [
    "5dd799604b32ae00317a8df0", //PART_C1_POOL_ID
    "5dd7996c4b32ae00317a8df1",  //PART_C1_POOL_NAME
];