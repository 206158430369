import {Row} from "simple-flexbox";
import {Button} from "@material-ui/core";
import React from "react";
import Notification from "../../modules/notification";

const headerComponent = (props) => {

    return (
        <Row className='justify-content-between'>
            <div className="fs-24 font-weight-bold display-flex flex-row">{props.componentName}</div>
            <Row className="justify-content-between">
                <Row>
                    <Notification/>
                    <Button onClick={props.invitationButtonClicked}
                            className='outline-none text-transform-capitalize p-2 px-4 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer'
                    >{props.buttonName}
                    </Button>
                    <img src="/images/more-icon.svg" className='p-2'/>
                </Row>
            </Row>
        </Row>
    )
};

export default headerComponent;
