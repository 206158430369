import React from "react";
import BaseComponent from '../../baseComponent'
import OriginationCounselComponent from './originationCounselComponent'
import {CompanyService} from "../../../services";
import Utility, {dispatchAction} from "../../../utility";
import {connect} from "react-redux";
import {eventConstants, genericConstants} from "../../../constants";

class OriginationCounsel extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            roleType: this.props.roleType,
            currentSort: 'asc',
            data: [],
            activePage: 1,
            counselList: [],
            filteredList: [],
            search: "",
            predictions: [],
            organisationCounsel: [],
            counselName: '',
            contactPerson: '',
            address: '',
            email: '',
            invitationStatus: '',
            selectedAssociate: {},
            subUserObjectForEdit: {},
            locationFilter: genericConstants.INITIAL_FILTER_VALUES.LOCATION,
            statusFilter: genericConstants.INITIAL_FILTER_VALUES.STATUS,
        };
    }

    componentDidMount() {
        this.initiateComponent();
    };

    initiateComponent() {
        this.getExistingOriginationCounselData();
        this.generateOriginationCounselList();

    }

    getExistingOriginationCounselData() {
        this.getTypeBasedCompany().then(response => {
            this.setState({organisationCounsel: response})
        }).catch(error => {
            Utility.consoleLogger("getTypeBasedCompany : error", error);
        });
    }

    generateOriginationCounselList() {
        this.getOriginationCounselList().then((response) => {
            if (response && Object.keys(response).length && response.associatedTo) {
                this.setState({data: response.associatedTo, filteredList: response.associatedTo})
                this.skippedOriginationCounselList(1, 10);
            }
        }).catch(error => {
            //Utility.apiFailureToast("Unable to fetch Origination Counsel List!");
            Utility.consoleLogger("getOriginationCounselList : error", error)
        });
    }

    getTypeBasedCompany = async () => {
        let requestObj = {
            // "type": genericConstants.ROLES.ORIGINATION_COUNSEL
            "type": this.props.roleType
        };
        return await CompanyService.getTypeBasedCompany(requestObj)
    };

    getOriginationCounselList = async () => {
        let {company} = this.props;
        let requestObj = {
            "companyId": company.companyId,
            // "companyType": genericConstants.ROLES.ORIGINATION_COUNSEL,
            "companyType": this.props.roleType
        };
        return await CompanyService.getAssociateList(requestObj)
    };

    getPredictions = (value) => {
        return this.state.organisationCounsel.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)

    };

    onChangeEvent = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: event.target.value});

        if (value && value.length > 0) {
            let predictions = this.getPredictions(value);
            this.setState({predictions: predictions})
        } else {
            this.setState({predictions: []})
        }
    };

    hideSuggestionBox = async (item) => {
        let address = item.address.city + ', ' + item.address.state;
        this.setState({selectedAssociate: item});
        await this.setState({
            predictions: [],
            counselName: item.name,
            contactPerson: item.contactName,
            address: address,
            email: item.contactEmail
        });
    };

    skippedOriginationCounselList = async (start, end) => {
        this.setState({counselList: this.state.filteredList.slice(start - 1, end)});
    };

    handlePageChange = async (pageNumber) => {
        console.log("pageNumber", pageNumber);
        await this.setState({activePage: pageNumber});
        let start = (pageNumber * 10) - 9;
        let end = (pageNumber * 10);
        this.skippedOriginationCounselList(start, end);
    };

    counselFilteredListSetValues = async (key, value) => {
        let filterList;
        await this.setState({[key]: value, filteredList: this.state.data});
        if (this.state.search !== "") {
            filterList = this.state.filteredList.filter(filterLender =>
                filterLender.name.toLowerCase().includes(this.state.search.toLowerCase()));
            this.setState({filteredList: filterList})
        }
        this.skippedOriginationCounselList(1, 10);

    };

    onFilterChanged = async (event) => {
        await this.setState({[event.target.name]: event.target.value});
        this.refreshList();
    };

    refreshList() {
        let {counselList, locationFilter, statusFilter} = this.state;
        if (!counselList || counselList.length < 1)
            return;
        //FILTER PROCESSING
        let filteredList = counselList.filter(counselObject => {
                return ((statusFilter === "" || statusFilter === genericConstants.INITIAL_FILTER_VALUES.STATUS)
                    || counselObject.invitationStatus.toLowerCase().includes(statusFilter.toLowerCase()))
                    && ((locationFilter === "" || locationFilter === genericConstants.INITIAL_FILTER_VALUES.LOCATION)
                        || counselObject.location.city.toLowerCase().includes(locationFilter.toLowerCase()))
            }
        );
        this.setState({filteredList: filteredList});
    }

    toggleInviteOCDialog = () => {
        if (this.state.isInviteOCDialogOpen)
            this.setState({
                subUserObjectForEdit: {},
                counselName: '',
                contactPerson: '',
                address: '',
                email: '',
                invitationStatus: '',
                isInviteOCDialogOpen: false
            });
        else
            this.setState({isInviteOCDialogOpen: true});
    };

    validateFormData() {
        return !!((Utility.isEmpty((this.state.counselName).trim()) && Utility.isEmpty(this.state.contactPerson.trim())
            && Utility.isEmpty(this.state.address.trim()) && Utility.isEmpty((this.state.emailID).trim())));
    }

    onInviteCounselButtonClicked = () => {
        if (this.state.subUserObjectForEdit && Object.keys(this.state.subUserObjectForEdit).length > 0) {
            this.updateCounselStatus();
            return;
        }

        let validateFormData = this.validateFormData();
        if (!validateFormData) {
            this.inviteAssociate();
        } else {
            let toastMessage = 'Please fill in the required details to continue';
            Utility.apiSuccessToast(toastMessage);
        }
    };

    deleteCounsel = async () => {
        await this.setState({invitationStatus: genericConstants.INVITATION_STATUS.DELETED});
        this.updateCounselStatus();
    };

    updateCounselStatus = async () => {
        let requestData = this.updateCounselRequestObject();

        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        if (!requestData || Object.keys(requestData).length < 1)
            return "";

        let [error, invitationResponse] = await Utility.parseResponse(CompanyService.updateAssociate(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error) {
            if (error && error.responseCode && (error.responseCode === 402))
                Utility.apiFailureToast("Invitation sent already!");
            else
                Utility.apiFailureToast("Unable to Update Counsel Status!");
            return;
        }
        if (invitationResponse) {
            this.toggleInviteOCDialog();
            Utility.apiSuccessToast("Counsel Updated Successfully!");
            this.initiateComponent();
        }
    };

    async inviteAssociate() {
        let requestData = this.createInviteRequestObject();
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        if (!requestData || Object.keys(requestData).length < 1)
            return "";
        let [error, invitationResponse] = await Utility.parseResponse(CompanyService.inviteAssociate(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error) {
            if (error && error.responseCode && (error.responseCode === 402))
                Utility.apiFailureToast("Invitation sent already!");
            else
                Utility.apiFailureToast("Unable to Send Invitation!");
            return;
        }
        if (invitationResponse) {
            this.toggleInviteOCDialog();
            Utility.apiSuccessToast("Invitation sent Successfully!");
            //this.initiateComponent();
            this.generateOriginationCounselList();
        }
    };

    updateCounselRequestObject() {
        let {company} = this.props;
        let {subUserObjectForEdit, invitationStatus} = this.state;
        return {
            "updatedBy": {
                companyId: company.companyId || "",
                name: company.name || "",
                type: company.type || ""
            },
            "associatedTo": {
                companyId: subUserObjectForEdit.companyId || "",
                invitationStatus: invitationStatus || "",
            }
        };
    }

    createInviteRequestObject() {
        let {company} = this.props;
        return {
            "invitedBy": Utility.parseAssociateObject(company, genericConstants.ROLES.LENDER),
            // "associate": Utility.parseAssociateObject(this.state.selectedAssociate, genericConstants.ROLES.ORIGINATION_COUNSEL)
            "associate": Utility.parseAssociateObject(this.state.selectedAssociate, this.props.roleType)
        };
    }

    toggleEditCounselDialog = (userDetail) => {

        if (userDetail && Object.keys(userDetail).length <= 0)
            return;

        this.setState({
            subUserObjectForEdit: userDetail,
            counselName: userDetail.name,
            contactPerson: userDetail.contactName,
            address: userDetail.location.city + ", " + userDetail.location.state,
            email: userDetail.email,
            invitationStatus: userDetail.invitationStatus
        });
        this.toggleInviteOCDialog();

    };

    updateInvitation = async (counselData, invitationAction) => {

        let {company} = this.props;

        let requestData = {};
        requestData['updatedBy'] = {
            companyId: company.companyId,
            name: company.name,
            type: company.type
        };
        requestData['associatedTo'] = {
            'invitationStatus': invitationAction, 'companyId': counselData.companyId
        };
        Utility.consoleLogger("requestData", requestData);

        let [error, updateInvitationResponse] = await Utility.parseResponse(CompanyService.updateInvitation(requestData));
        if (error)
            Utility.apiFailureToast("Unable to update invitation status!");
        if (updateInvitationResponse) {
            if (invitationAction === genericConstants.INVITATION_STATUS.REJECTED)
                Utility.apiSuccessToast("Invitation Rejected Successfully!");
            if (invitationAction === genericConstants.INVITATION_STATUS.ACCEPTED)
                Utility.apiSuccessToast("Invitation Accepted Successfully!");
            this.generateOriginationCounselList();
        }
    };

    onSortClicked = (key, order) => {
        Utility.sortArrayForKey(this.state.filteredList, key, order);
        this.setState({filteredList: this.state.filteredList});
    };

    render() {
        return (
            <OriginationCounselComponent state={this.state} companyId={this.props.company.companyId}
                                         roleType={this.props.roleType}
                                         onMenuClick={this.onMenuClick}
                                         onChangeEvent={this.onChangeEvent}
                                         toggleInviteOCDialog={this.toggleInviteOCDialog}
                                         handlePageChange={this.handlePageChange}
                                         counselFilteredListSetValues={this.counselFilteredListSetValues}
                                         hideSuggestionBox={this.hideSuggestionBox}
                                         onInviteCounselButtonClicked={this.onInviteCounselButtonClicked}
                                         toggleEditCounselDialog={this.toggleEditCounselDialog}
                                         deleteCounsel={this.deleteCounsel}
                                         updateInvitation={this.updateInvitation}
                                         onSortClicked={this.onSortClicked}
                                         onFilterChanged={this.onFilterChanged}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, company: state.company}
};

export default connect(mapStateToProps, {dispatchAction})(OriginationCounsel);
