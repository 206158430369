import React from 'react'
import {Column, Row} from "simple-flexbox";
import {Button} from "@material-ui/core";
import CustomInput from "../../common/components/CustomInput";
import {history} from "../../managers/history";
import Utils from "../../utility";
import {genericConstants} from "../../constants";

function SignUpForm(props) {
    let {state, onChangeEvent, onCreateAccountClicked} = props;
    return (
        <Column className="w-450 h-100 my-5 py-2 fc-dark-slate-blue">
            <form onSubmit={onCreateAccountClicked} className="w-100 my-5 py-5 ">

                <label className="text-center w-100 fs-28 my-2">Welcome {props.state.firstName}</label>
                <label className="text-center w-100 fs-19 fc-blue-grey">Set-up your account</label>

                <Row className="fs-15 mt-4 fw-500">Password</Row>
                <CustomInput id="newPassword" type={"password"} value={state ? state.newPassword : ""}
                             onChange={onChangeEvent} error={state ? state.newPasswordError : ""}
                             className="fs-15 p-2 mt-1"/>

                <Row className="fs-15 mt-4 fw-500">Confirm Password</Row>
                <CustomInput id="confirmPassword" type={"password"} value={state ? state.confirmPassword : ""}
                             onChange={onChangeEvent} error={state ? state.confirmPasswordError : ""}
                             className="fs-15 p-2 mt-1"/>

                <Button type='submit' className="bg-blue outline-none text-transform-capitalize
                fc-white w-100 py-2 fs-17 mt-4 cursor-pointer">
                    Create Account
                </Button>
                <div className="w-100 text-left fs-15 my-1">By clicking on continue, you agree to the LIMB CRE
                    <a className="fc-blue"> Terms of Service </a>
                    and <a className="fc-blue">Privacy Policy.</a>
                </div>
            </form>
        </Column>
    );
}

function HeaderComponent(props) {
    return (
        <Row vertical="center" className="justify-content-between w-100">
            <img src="/images/limb-logo.svg" alt='limb' className="w-150"/>
            <Row vertical="center">
                <Column vertical="center" className="fc-brownish-grey fs-15 px-2 py-1">Existing user?</Column>
                <Column vertical="center" className="fc-blue br-4 b-1-blue fs-17 px-2 py-1 cursor-pointer"
                        onClick={() => history.replace("/")}>

                    Log in</Column>
            </Row>
        </Row>
    )
}

function registerSubUserComponent(props) {
    return (
        <Column horizontal={'center'} className="w-100 p-3 min-vh-100">
            {HeaderComponent(props)}
            {SignUpForm(props)}
        </Column>
    );
}

export default registerSubUserComponent;