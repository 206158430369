import {cookiesConstants, eventConstants} from "../constants";
import sessionManager from "../managers/sessionManager";
import Utils from "../utility";


const companyData = sessionManager.getDataFromCookies(cookiesConstants.COMPANY);

export default function user(state = companyData || {}, action) {
    switch (action.type) {
        case eventConstants.GET_COMPANY_SUCCESS:
        case eventConstants.UPDATE_COMPANY_SUCCESS:
            sessionManager.setDataInCookies(action.data, cookiesConstants.COMPANY);
            return action.data;
        case eventConstants.LOGOUT_SUCCESS:
            sessionManager.setDataInCookies(null, cookiesConstants.COMPANY);
            return null;

        case eventConstants.UPDATE_COMPANY_FAILURE:
        case eventConstants.GET_COMPANY_FAILURE:
        default:
            return state;
    }
}