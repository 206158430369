import React from "react";
import BaseComponent from '../../baseComponent'
import PublishRecordComponent from './publishRecordComponent'
import Utils, {dispatchAction} from "../../../utility";
import {RecordService, PublishRecordService, DocumentService} from "../../../services";
import {eventConstants, genericConstants} from "../../../constants";
import {connect} from "react-redux";
import {history} from "../../../managers/history";

class PublishRecord extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            token: null,
            password: null,
            recordDetails: null,
            publishHistoryPartA: [],
            publishHistoryPartB: [],
            publishHistoryPartC: [],
            publishHistoryPartUnCategories: [],
            isHistoryRequested: false,
            recordId: null
        }
    }

    componentDidMount() {
        let pathName = window.location.pathname;
        let pathArray = pathName.split('/');
        if (pathArray && pathArray.length === 3) {
            this.getRecordDetails(pathArray[pathArray.length - 1]);
            this.getDocumentList(pathArray[pathArray.length - 1]);
        }
        if (pathArray && pathArray.length === 4) {
            this.setState({isHistoryRequested: true, recordId: pathArray[pathArray.length - 1]});
            this.getRecordDetails(pathArray[pathArray.length - 1]);
            this.getPublishHistory(pathArray[pathArray.length - 1]);
        }
    }

    getRecordDetails = async (recordId) => {
        if (!recordId) {
            Utils.apiFailureToast("Unable to get record details");
            return;
        }
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, recordDetails] = await Utils.parseResponse(RecordService.getRecordDetail(recordId));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error) {
            Utils.apiFailureToast("Unable to get record details");
            return;
        }
        await this.filterPublishHistoryData(recordDetails);
    };

    filterPublishHistoryData = (recordDetails) => {
        let publishHistoryPartA = [], publishHistoryPartB = [], publishHistoryPartC = [],
            publishHistoryPartUnCategories = [], publishHistory;
        for(let index=0;index<recordDetails.publishInfo.length;index++){
            publishHistory = recordDetails.publishInfo[index];
            if(publishHistory?.publishTag?.type ===genericConstants.PUBLISH_HISTORY_CATEGORY.TYPE_A)
                publishHistoryPartA.push(publishHistory);
            else if (publishHistory?.publishTag?.type ===genericConstants.PUBLISH_HISTORY_CATEGORY.TYPE_B)
                publishHistoryPartB.push(publishHistory);
            else if (publishHistory?.publishTag?.type ===genericConstants.PUBLISH_HISTORY_CATEGORY.TYPE_C)
                publishHistoryPartC.push(publishHistory);
            else
                publishHistoryPartUnCategories.push(publishHistory);
            this.setState({recordDetails,publishHistoryPartA,publishHistoryPartB,publishHistoryPartC, publishHistoryPartUnCategories });

        }
    }

    getDocumentList = async (recordId) => {
        let request = {};
        if (recordId)
            request['recordId'] = recordId;
        else
            request['recordId'] = this.state.recordDetails && Object.keys(this.state.recordDetails).length > 0 && this.state.recordDetails.recordId ?
                this.state.recordDetails.recordId : "";

        let [error, documentResponse] = await Utils.parseResponse(DocumentService.getDocuments(request));
        if (error) {
            Utils.apiFailureToast("Unable to Get document List");
            return;
        }
        this.setState({documentList: documentResponse});

    };

    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value});

    };

    onPublishClicked = async () => {
        let {recordDetails} = this.state;
        let {company} = this.props;
        let requestData;
        let transactionData = {};
        let publishedBy = {};
        publishedBy['limbId'] = company.limbId || "";
        publishedBy['companyId'] = company.companyId || "";
        publishedBy['name'] = company.name || "";
        publishedBy['email'] = company && company.owner && company.owner.email || "";
        publishedBy['role'] = company.type || "";

        transactionData['recordId'] = recordDetails.recordId || "";
        transactionData['propertyPhoto'] = recordDetails.propertyPhoto || "";
        transactionData['documents'] = this.parseDocumentsRequestObject(this.state.documentList); //Will also consist IDs of Reports

        requestData = {transactionData, publishedBy};
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, recordResponse] = await Utils.parseResponse(PublishRecordService.publishRecord(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error || !recordResponse) {
            Utils.apiFailureToast("Unable to publish record!");
            return;
        }
        setTimeout(history.goBack(), 5000);
        Utils.apiSuccessToast("Record Published Successfully!!");
    };

    sendPublishRequest = async () => {
        let {recordDetails} = this.state;
        let {company, user} = this.props;
        let requestData = {};
        let publishedBy = {};
        publishedBy['userId'] = user.userDetails && user.userDetails.userID || "";
        publishedBy['limbId'] = company.limbId || "";
        publishedBy['companyId'] = company.companyId || "";
        publishedBy['name'] = company.name || "";
        publishedBy['email'] = company && company.owner && company.owner.email || "";
        publishedBy['role'] = company.type || "";

        requestData['recordId'] = recordDetails.recordId || "";
        requestData['fileNo'] = recordDetails.fileNo || "";
        requestData['loanTag'] = recordDetails.loanTag || "";
        requestData['address'] = recordDetails.address || "";
        requestData['propertyPhoto'] = recordDetails.propertyPhoto || "";

        requestData = {...requestData, publishedBy};
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, recordResponse] = await Utils.parseResponse(PublishRecordService.publishRequest(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error || !recordResponse) {
            Utils.apiFailureToast("Unable to send publish request to Admin!");
            return;
        }
        setTimeout(history.goBack(), 5000);
        Utils.apiSuccessToast(`Your Request for publishing record ${requestData['fileNo']} on BlockChain is Successfully placed.
         You will be notified once it approved by admin.`);
    };

    getPublishHistory = async () => {
        let {recordDetails, recordId} = this.state;
        if (!recordId)
            return;
        let requestData = {
            "recordId": this.state.recordId || recordDetails.recordId
        };
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, historyResponse] = await Utils.parseResponse(PublishRecordService.getPublishHistory(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error) {
            Utils.apiFailureToast("Unable to fetch publish history!");
            return;
        }
        Utils.consoleLogger("historyResponse", historyResponse);
        this.setState({publishHistory: historyResponse});
    };


    render() {
        return (
            <PublishRecordComponent state={this.state}
                                    recordDetails={this.state.recordDetails}
                                    publishHistoryPartA={this.state.publishHistoryPartA}
                                    publishHistoryPartB={this.state.publishHistoryPartB}
                                    publishHistoryPartC={this.state.publishHistoryPartC}
                                    publishHistoryPartUnCategories={this.state.publishHistoryPartUnCategories}
                                    userDetails={this?.props?.user?.userDetails}
                                    onPublishClicked={this.sendPublishRequest}
                                    onChangeEvent={this.onChangeEvent}
            />
        );
    }

    parseDocumentsRequestObject(documentList) {
        let parseDocumentRequestObject = [];
        if (!documentList || documentList.length < 1)
            return parseDocumentRequestObject;
        documentList.forEach(document => {
            let documentObject = {};
            documentObject["documentId"] = document.documentId;
            documentObject["recordId"] = document.recordId;
            documentObject["sectionId"] = document.sectionId;
            documentObject["questionId"] = document.questionId;
            documentObject["category"] = document.category;
            documentObject["categoryType"] = document.categoryType;
            documentObject["name"] = document.name;
            documentObject["key"] = document.key;
            parseDocumentRequestObject.push(documentObject);
        });
        return parseDocumentRequestObject;
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, company: state.company}
};

export default connect(mapStateToProps, {dispatchAction})(PublishRecord);