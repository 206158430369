import React from 'react'
import {Column, Row} from "simple-flexbox";
import {Button, Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import {history} from "../../../managers/history";
import {genericConstants} from "../../../constants";
import RecordInformationCard from "../../../common/components/recordInformationCard";
import Utils from "../../../utility";
import {getSignedURL} from "../../../managers/awsService";
import publishHistoryCell from "../../../common/components/PublishHistoryCell";

function HeaderComponent(state) {
    return (
        <Row vertical="center" className="mt-lg-3">
            <img
                src={state && state.recordDetails && state.recordDetails.lender && state.recordDetails.lender.logo ? getSignedURL(state.recordDetails.lender.logo, "/images/company-placeholder.svg") : "/images/company-placeholder.svg"}
                alt='company-logo' className="justify-content-start w-100px"/>

            <div
                className="display-flex flex-1 justify-content-center align-items-end fs-32 fw-500 mr-lg-4"> {state.isHistoryRequested ? "Publish History" : "Publish to Blockchain"}
            </div>
            <div className="display-flex justify-content-end w-100px"/>
        </Row>
    )
}

function PublishRecordView(props) {
    return (
        <div className="display-flex flex-direction-column justify-content-center align-items-center mt-6rem">
            <div className="w-50per">
                <div className="fs-16">Information Record</div>
                <Row className="w-100 min-h-300">
                    {props && props.state && props.state.recordDetails ? RecordInformationCard(props.state.recordDetails) : ""}
                </Row>
                {/*<div className="mt-lg-4">
                    <div className="fs-16">Enter Password</div>
                    <CustomInput id="password" type="password" placeholder={''}
                                 value={props.state.password}
                                 onChange={props.onChangeEvent}
                                 className="fc-dark-slate-blue fs-16 p-2 letter-space-8 h-50px"
                    />
                </div>*/}
                <Row className="mt-5 ">
                    <Button onClick={() => history.goBack()} className="flex-1 outline-none text-transform-capitalize p-2 br-4 fs-18
                            b-1-red-two fc-red  px-3 cursor-pointer bg-transparent">
                        Cancel
                    </Button>
                    <div className="flex-1 max-w-50"/>
                    <Button onClick={() => props.onPublishClicked()} className="flex-1 outline-none text-transform-capitalize p-2 br-4 fs-18
                            b-1-white-two bg-blue fc-white   px-3 cursor-pointer">
                        Publish
                    </Button>
                </Row>
            </div>
        </div>

    )
}

const PublishHistory = (props) => {
    let {recordDescription, publishHistoryList, isSectionVisible, toggleSection, part} = props;
    if (publishHistoryList && publishHistoryList.length > 0)
        Utils.descSortOnCriteria(publishHistoryList, "publishedOn");
    return (<div className="display-flex flex-direction-column justify-content-center align-items-center m-4">
            <Table size="small" className='br-1 p-1' fixedHeader={true}>
                <TableRow className="data-table bg-pale-grey">
                    <TableCell component="th" className='p-none text-left'>
                        <div className="fs-16 fc-black fw-500"><img
                            src={isSectionVisible ? "/images/collapse.svg" : "/images/expand.svg"}
                            className="mr-2 cursor-pointer" onClick={() => toggleSection(part)}/>
                            {part}
                        </div>
                    </TableCell>
                </TableRow>
                {isSectionVisible ?
                    <TableBody className={'data-table'}>
                        {publishHistoryList && publishHistoryList.length > 0 ? publishHistoryList.map((publishData, index) => {
                            return (<TableRow role="checkbox" aria-checked={false} tabIndex={-1}
                                              selected={false} key={index}>
                                {publishHistoryCell(publishData, recordDescription)}
                            </TableRow>)
                                ;
                        }) : ""}
                    </TableBody> : null}
            </Table>
        </div>
    )
}

function ViewPublishHistoryComponent(props) {
    let {recordDetails, publishHistoryPartA, publishHistoryPartB, publishHistoryPartC, publishHistoryPartUnCategories} = props;

    let [partsVisibility, updatePartsVisibility] = React.useState({
        'Loan Origination': !!publishHistoryPartA && publishHistoryPartA.length > 0,
        'Loan Origination/Securitization': !!publishHistoryPartB && publishHistoryPartB.length > 0,
        'Loan Servicing Event': !!publishHistoryPartC && publishHistoryPartC.length > 0,
        'Uncategorized': !!publishHistoryPartUnCategories && publishHistoryPartUnCategories.length > 0,
    });

    let toggleSection = (key) => {
        updatePartsVisibility({...partsVisibility, [key]: !partsVisibility[key]})
    };
    let poolName = recordDetails && recordDetails.pool && recordDetails.pool.name && recordDetails.pool.name.trim().length > 0 ? recordDetails.pool.name : "";
    let loanTag = recordDetails && recordDetails.loanTag ? recordDetails.loanTag : "";
    let recordDescription = getRecordDescription(loanTag, poolName);
    return (<div className="m-auto w-80-percent">
        {publishHistoryPartA && publishHistoryPartA.length > 0 ?
            <PublishHistory
                publishHistoryList={publishHistoryPartA}
                toggleSection={toggleSection}
                isSectionVisible={partsVisibility[genericConstants.PUBLISH_HISTORY_CATEGORY_TAG.TYPE_A]}
                part={genericConstants.PUBLISH_HISTORY_CATEGORY_TAG.TYPE_A}
                recordDescription={recordDescription}
            /> : null
        }
        {publishHistoryPartB && publishHistoryPartB.length > 0 ?
            <PublishHistory
                publishHistoryList={publishHistoryPartB}
                toggleSection={toggleSection}
                isSectionVisible={partsVisibility[genericConstants.PUBLISH_HISTORY_CATEGORY_TAG.TYPE_B]}
                part={genericConstants.PUBLISH_HISTORY_CATEGORY_TAG.TYPE_B}
                recordDescription={recordDescription}
            /> : null
        }
        {publishHistoryPartC && publishHistoryPartC.length > 0 ?
            <PublishHistory
                publishHistoryList={publishHistoryPartC}
                toggleSection={toggleSection}
                isSectionVisible={partsVisibility[genericConstants.PUBLISH_HISTORY_CATEGORY_TAG.TYPE_C]}
                part={genericConstants.PUBLISH_HISTORY_CATEGORY_TAG.TYPE_C}
                recordDescription={recordDescription}
            /> : null
        }
        {publishHistoryPartUnCategories && publishHistoryPartUnCategories.length > 0 ?
            <PublishHistory
                publishHistoryList={publishHistoryPartUnCategories}
                toggleSection={toggleSection}
                isSectionVisible={partsVisibility[genericConstants.PUBLISH_HISTORY_CATEGORY_TAG.TYPE_UN_CATEGORIZED]}
                part={genericConstants.PUBLISH_HISTORY_CATEGORY_TAG.TYPE_UN_CATEGORIZED}
                recordDescription={recordDescription}
            /> : null
        }
    </div>)

}

function getRecordDescription(loanTag, poolName) {
    if (loanTag && poolName)
        return `[${loanTag}] [${poolName}]`;
    else if (loanTag || poolName)
        return loanTag ? `[${loanTag}]` : (poolName ? `[${poolName}]` : '');
    else
        return '';
}

const PublishRecordComponent = (props) => {
    let {recordDetails, publishHistoryPartA, publishHistoryPartB, publishHistoryPartC, publishHistoryPartUnCategories} = props;

    return (
        <Column horizontal={'center'} className="fc-dark-slate-blue w-100 p-3 min-vh-100 h-100 justify-content-between">
            <Column className="w-100">
                {HeaderComponent(props.state)}
                {props.state && props.state.isHistoryRequested ? <ViewPublishHistoryComponent
                    recordDetails={recordDetails}
                    publishHistoryPartA={publishHistoryPartA}
                    publishHistoryPartB={publishHistoryPartB}
                    publishHistoryPartC={publishHistoryPartC}
                    publishHistoryPartUnCategories={publishHistoryPartUnCategories}
                /> : PublishRecordView(props)}
            </Column>
        </Column>
    );
}

export default PublishRecordComponent;
