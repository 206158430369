import React from "react";
import BaseComponent from "../../modules/baseComponent";
import {Row} from "simple-flexbox";
import {Popover, MenuItem} from "@material-ui/core";
import Utility from "../../utility";
import {genericConstants} from "../../constants";

class CustomMenuBar extends BaseComponent {
    constructor() {
        super()
        this.state = {
            openMenuBar: false,
            menuAnchorEl: "",
            selectedItem: {}
        }

    }

    componentDidMount = () => {
        this.setState({selectedItem: this.props.selectedItem});
    }

    handleClick = (event) => {
        if(!this.props.isDisabled)
        this.setState({menuAnchorEl: event.target, openMenuBar: true});
    };
    handleClose = () => {
        this.setState({menuAnchorEl: '', openMenuBar: false});
    };
    selectedMenuHandler = async (Obj, index) => {
        this.setState({selectedItem: Obj});
        await this.props.updateSectionStatus(Obj.value);
        if (this.props.isAbleToUpdateStatusForView)
            this.props.updateStatus(Obj);
        this.handleClose();
    };

    render() {
        let stringObj = this.props.selectedItem && this.props.selectedItem.value ?
            this.props.selectedItem.value === genericConstants.SECTION_STATUS_FOR_VIEW.SEND_FOR_REVIEW ? genericConstants.SECTION_STATUS_FOR_VIEW.REVIEW_PENDING :
                this.props.selectedItem.value === genericConstants.SECTION_STATUS_FOR_VIEW.APPROVE ? genericConstants.SECTION_STATUS_FOR_VIEW.APPROVED_FOR_SC_REVIEW :
                    this.props.selectedItem.value : "";
        return (
            <div className={this.props.isDisabled ? "display-flex flex-direction-column w-100 width-fit-content fc-white  fw-500 cursor-not-allowed" : "display-flex flex-direction-column w-100 width-fit-content fc-white  fw-500 cursor-pointer"}>

                <div
                    className={this.props.selectedItem.color ? `${this.props.selectedItem.color} br-4 p-2` : "br-4 bg-greyish-two p-2"}
                    onClick={(event) => this.handleClick(event)}>
                    {stringObj}<img
                    src="/images/down-chevron-white.svg" className="ml-2"/></div>
                <Popover
                    id={"menu-position"}
                    open={this.state.openMenuBar}
                    anchorEl={this.state.menuAnchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClose={this.handleClose}>
                    <div>
                        {this.props.statusList && this.props.statusList.length > 0 ?
                            this.props.statusList.map((Obj, index) => {
                                if (Obj.value !== this.props.selectedItem.value)
                                    return getMenuItem(Obj, index, this.selectedMenuHandler)
                            })
                            : ""}
                    </div>

                </Popover>
            </div>
        );
    }

}

const getMenuItem = (Obj, index, callBack) => {
    return <MenuItem key={index} className="fc-dark-slate-blue fw-500 cursor-pointer" onClick={() => {
        callBack(Obj, index)
    }}>
        {Obj.value}
    </MenuItem>

};

export default CustomMenuBar;