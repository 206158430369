import React from "react";
import BaseComponent from '../../baseComponent'
import Utils, {dispatchAction} from "../../../utility";
import {CompanyService, DocumentService, RecordService, SubscriptionService, UserService} from "../../../services";
import connect from "react-redux/es/connect/connect";
import CounselDetailsComponent from "./counselDetailsComponent";
import {actionTypeConstants, apiFailureConstants, eventConstants, genericConstants} from "../../../constants";
import {getSignedURL} from "../../../managers/awsService";
import moment from "moment";

class RecordDetails extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            companyData: {},
            company: {
                name: "",
                address: "",
                websiteUrl: "",
                contactEmail: "",
                contactName: "",
                logo: "",
                logoURL: "",
                contactNumber: "",
                addedOn: "",
            },
            isAddDocumentDialogOpen: false
        };
    }

    componentDidMount() {
        let pathName = window.location.pathname;
        let pathArray = pathName.split('/');
        if (pathArray && pathArray.length === 4) {
            this.getCompanyDetails(pathArray[pathArray.length - 1]);
        }
    }

    getCompanyDetails = async (companyId) => {
        if (!companyId) {
            Utils.apiFailureToast("Unable to get Company Information");
            return;
        }
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, companyResponse] = await Utils.parseResponse(CompanyService.getCompanyDetail(companyId));
        if (error) {
            Utils.apiFailureToast("Unable to get Company Information");
            return;
        }
        if (companyResponse && Object.keys(companyResponse).length > 0) {
            this.updateCompanyDataInState(companyResponse);
            this.props.dispatchAction(eventConstants.GET_COMPANY_SUCCESS, companyResponse);
            if (companyResponse && companyResponse.subscription && companyResponse.subscription.subscriptionId) {
                this.getSubscriptionData(companyResponse.subscription.subscriptionId)
            }
        }
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
    };

    async getInvoiceList(subscriptionId) {
        if (!subscriptionId)
            return;
        let requestData = {subscriptionId};
        let [error, invoiceResponse] = await Utils.parseResponse(SubscriptionService.getInvoiceList(requestData));
        if (error)
            return Utils.consoleLogger("error", error);
        this.setState({invoiceList: invoiceResponse.invoices});
    }

    async getSubscriptionData(subscriptionId) {
        if (!subscriptionId)
            return;
        let requestData = {subscriptionId};
        let [error, subscriptionResponse] = await Utils.parseResponse(SubscriptionService.getSubscriptionData(requestData));
        if (error)
            return Utils.consoleLogger("getSubscriptionData error", error);
        this.setState({subscriptionData: subscriptionResponse, invoiceList: subscriptionResponse.invoices || []});
    }

    updateCompanyDataInState = async (companyResponse) => {
        let company = {
            name: companyResponse.name || "",
            address: Utils.generateAddressString(companyResponse.address),
            websiteUrl: companyResponse.websiteUrl || "",
            contactEmail: companyResponse.contactEmail || "",
            contactName: companyResponse.contactName || "",
            logo: companyResponse.logo || "",
            logoURL: await getSignedURL(companyResponse.logo),
            contactNumber: companyResponse.contactNumber || "",
            addedOn: Utils.getDateString(companyResponse.addedOn)
        };
        this.setState({companyData: companyResponse, company});
    };

    toggleAddDocumentDialogOpen = () => {
        let {company} = this.props;
        if (this.state.isAddDocumentDialogOpen && company && company.subscription && company.subscription.subscriptionId) {
            this.getSubscriptionData(company.subscription.subscriptionId)
        }
        this.setState({
            isAddDocumentDialogOpen: !this.state.isAddDocumentDialogOpen
        });
    };

    viewInvoice = async (invoiceObject) => {
        if (!invoiceObject || Object.keys(invoiceObject).length < 1 || !invoiceObject.keyName) {
            Utils.apiFailureToast(apiFailureConstants.VIEW_INVOICE);
            return;
        }
        window.open(await getSignedURL(`invoices/${invoiceObject.keyName}`), '_blank');
    };

    render() {
        return (
            <CounselDetailsComponent state={this.state}
                                     userDetails={this.props && this.props.user ? this.props.user.userDetails : null}
                                     userPermission={this.props && this.props.user ? this.props.user.userPermission : null}
                                     toggleAddDocumentDialogOpen={this.toggleAddDocumentDialogOpen}
                                     viewInvoice={this.viewInvoice}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, company: state.company}
};

export default connect(mapStateToProps, {dispatchAction})(RecordDetails);
