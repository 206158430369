import BaseComponent from "../../baseComponent";
import connect from "react-redux/es/connect/connect";
import {dispatchAction} from "../../../utility/index";
import DocumentManagementComponent from "./documentManagementComponent";
import React from "react";
import Utils from "../../../utility";
import {apiFailureConstants, apiSuccessConstants, stringConstants} from "../../../constants";
import {getSignedURL} from "../../../managers/awsService";
import {DocumentService} from "../../../services";

class DocumentManagement extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            categoryTypeDocumentList: {},
            expendableDocumentList: [],
            selectedCategory: '',
            isConfirmationPopUpOpen: false,
            headerMessage: '',
            selectedDocument: {},
            isAddDocumentDialogOpen: props.isAddDocumentDialogOpen || false,
        };
    }
    openCategoryTypeDocumentList=(category)=>{
        if(!category)
            return ;
        let categoryTypeDocumentList = this.props.documentList && this.props.documentList[category] ? this.props.documentList[category]:{};
        let expendableDocumentList = categoryTypeDocumentList && categoryTypeDocumentList[stringConstants.DEFAULT] ? categoryTypeDocumentList[stringConstants.DEFAULT]:[];


        this.setState({selectedCategory : category,categoryTypeDocumentList, expendableDocumentList});
    }

    openExpendableDocumentList=(categoryType)=>{
        if(!categoryType)
            return ;
        let expendableDocumentList = this.state.categoryTypeDocumentList && this.state.categoryTypeDocumentList[categoryType] ? this.state.categoryTypeDocumentList[categoryType]:[];

        this.setState({expendableDocumentList});
    }
    closeExpendableDocumentList=()=>{
        this.setState({expendableDocumentList:[]});
    }
    openCategoryDocumentList=()=>{
        this.setState({categoryTypeDocumentList:{}});
    }
    openCategoryDocumentList=()=>{
        this.setState({categoryTypeDocumentList:{}});
    }
    viewDocument=async (documentObj)=>{
        if(!documentObj || Object.keys(documentObj).length <1 || !documentObj.key){
            Utils.apiFailureToast(apiFailureConstants.VIEW_DOCUMENT);
            return ;
        }
        window.open(await getSignedURL(`documents/${documentObj.key}`), '_blank');
        return;
    }

    toggleConfirmationPopUp = (document = null) => {
        if (!document || Object.keys(document).length < 1) {
            if (this.state.isConfirmationPopUpOpen)
                this.setState({isConfirmationPopUpOpen: false, selectedDocument: {}, headerMessage: ''});
            else
                this.setState({isConfirmationPopUpOpen: true, headerMessage: ''});
        } else
            this.setState({isConfirmationPopUpOpen: true, selectedDocument: document, headerMessage: stringConstants.CONFIRM_DELETE_DOCUMENT});
    };

    deleteDocument = async () => {
        let document = this.state.selectedDocument;
        if (!document || Object.keys(document).length < 1 || !document.documentId)
            return;
        let [error, documentResponse] = await Utils.parseResponse(DocumentService.deleteDocument(document));
        if (error) {
            Utils.apiFailureToast(apiFailureConstants.DELETE_DOCUMENT);
            return;
        }
        Utils.apiSuccessToast(apiSuccessConstants.DELETE_DOCUMENT);

        await this.props.getDocumentList("");
    };

    render() {
        return (
            <DocumentManagementComponent
                state={this.state}
                documentList={this.props.documentList}
                openCategoryTypeDocumentList={this.openCategoryTypeDocumentList}
                openCategoryDocumentList={this.openCategoryDocumentList}
                openExpendableDocumentList={this.openExpendableDocumentList}
                closeExpendableDocumentList={this.closeExpendableDocumentList}
                viewDocument={this.viewDocument}
                toggleConfirmationPopUp={this.toggleConfirmationPopUp}
                deleteDocument={this.deleteDocument}
            />
        );
    }

}


const mapStateToProps = (state) => {
    return {user: state.user, company: state.company}
};

export default connect(mapStateToProps, {dispatchAction})(DocumentManagement);