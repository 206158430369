import {Column, Row} from "simple-flexbox";
import React from "react";
import Utility from "../../../utility";
import {genericConstants, pathConstants} from "../../../constants";
import {SectionDetailsComponent, AdminSettings, Notifications} from "../../index";
import Counsel from "../counsel";
import PublishRequest from "../publishRequest";
import ActivationRequest from "../activationRequests";
import SubscriptionPlan from "../subscriptionPlan";
import CounselDetails from "../counselDetails";
import RecordDetails from "../../recordDetails";


const SideMenuComponent = (props) => {
    return (
        <Column className="px-3 py-2 border-right min-vh-100 h-100" style={{width: '270px'}}>
            <Row horizontal={'left'} vertical={'center'}
                 className="my-3 flex-wrap">
                <img src={"/images/limb-side-bar-logo.svg"}/>
            </Row>

            <Row vertical={'center'}
                 className={"mt-5 p-2 " + (Utility.isMenuActive('/publish-requests') ? 'active-menu' : 'menu')}
                 onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.PUBLISH_REQUESTS)}>
                <img alt="information-records" className="mr-3"
                     src="/images/publish-requests.svg"/>

                <div className="pl-2">Publish Requests</div>
            </Row>


                <Row vertical={'center'}
                     className={"p-2 " + (Utility.isMenuActive('/activation-requests') ? ' active-menu' : 'menu')}
                     onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.ACTIVATION_REQUESTS)}>
                    <img alt="information-records" className="mr-3"
                         src="/images/activation-requests.svg"/>
                    <div className="pl-2">Activation Requests</div>
                </Row>


                <Row vertical={'center'}
                     className={"p-2 " + (Utility.isMenuActive('/lender') ? ' active-menu' : 'menu')}
                     onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.LENDER)}>
                    <img alt="information-records" className="mr-3"
                         src="/images/lenders.svg"/>
                    <div className="pl-2">Lenders</div>
                </Row>


                <Row vertical={'center'}
                     className={"p-2 " + (Utility.isMenuActive('/origination-counsel') ? ' active-menu' : 'menu')}
                     onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.ORIGINATION_COUNSEL)}>
                    <img alt="information-records" className="mr-3"
                         src="/images/origination-counsel.svg"/>
                    <div className="pl-2">Origination Counsel</div>
                </Row>


                <Row vertical={'center'}
                     className={"p-2 " + (Utility.isMenuActive('/seller-counsel') ? ' active-menu' : 'menu')}
                     onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.SELLER_COUNSEL)}>
                    <img alt="information-records" className="mr-3"
                         src="/images/seller-counsel.svg"/>
                    <div className="pl-2">Seller Counsel</div>
                </Row>

            <Row vertical={'center'} className={"p-2 " + (Utility.isMenuActive('/subscription-plans') ? ' active-menu' : 'menu')}
                 onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.SUBSCRIPTION_PLANS)}>
                <img alt="information-records" className="mr-3"
                     src="/images/subscription-plans.svg"/>
                <div className="pl-2">Subscription Plans</div>
            </Row>

            <Row vertical={'center'} className={"p-2 " + (Utility.isMenuActive('/settings') ? ' active-menu' : 'menu')}
                 onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.SETTINGS)}>
                <img alt="information-records" className="mr-3"
                     src="/images/settings.svg"/>
                <div className="pl-2">Settings</div>
            </Row>
        </Column>
    )
};

function getInformationRecordComponent() {
    let pathName = window.location.pathname;
    let pathArray = pathName.split('/');
    return pathArray && pathArray.length > 3 ? (pathArray.length === 4 && pathArray[3] ?
        <RecordDetails/> : (pathArray.length === 5 && pathArray[4] ? <SectionDetailsComponent/> : null)) :
        <PublishRequest/>
}
function getAllCounselComponent(role) {
    let pathName = window.location.pathname;
    let pathArray = pathName.split('/');
    return pathArray && pathArray.length > 3 ? (pathArray.length === 4 && pathArray[3] ?
        <CounselDetails/> : null) :
        counselComponent(role)
}
function counselComponent(role) {
    switch (role) {
        case genericConstants.ROLES.LENDER:
            return <Counsel roleType={genericConstants.ROLES.LENDER}/>;
        case genericConstants.ROLES.ORIGINATION_COUNSEL:
            return <Counsel roleType={genericConstants.ROLES.ORIGINATION_COUNSEL}/>;
        case genericConstants.ROLES.SELLER_COUNSEL:
            return <Counsel roleType={genericConstants.ROLES.SELLER_COUNSEL}/>;
        default:
            return '';
    }
}

function underDevelopment() {
    return (
        <div className="min-vh-100 display-flex fs-26 fc-brownish-grey justify-content-center align-items-center">
            Under Development
        </div>
    )
}

const HomeComponent = (props) => {

    return (
        <Column>
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.PUBLISH_REQUESTS) ? getInformationRecordComponent() : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.ACTIVATION_REQUESTS) ? <ActivationRequest/> : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.LENDER) ? getAllCounselComponent(genericConstants.ROLES.LENDER)
                 : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.ORIGINATION_COUNSEL) ?getAllCounselComponent(genericConstants.ROLES.ORIGINATION_COUNSEL) : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.SELLER_COUNSEL) ?getAllCounselComponent(genericConstants.ROLES.SELLER_COUNSEL) : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.SUBSCRIPTION_PLANS) ? <SubscriptionPlan/> : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.SETTINGS) ? <AdminSettings/> : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.NOTIFICATIONS) ? <Notifications/> : null}
        </Column>
    )
};

const dashboardComponent = (props) => {
    return (
        <Row className="h-100-per w-100 dashboard-container">
            <Column id="sideContainer">
                {SideMenuComponent(props)}
            </Column>
            <Column className="w-100 overflow-y-auto">
                {HomeComponent(props)}
            </Column>
        </Row>
    )
};
export default dashboardComponent;
