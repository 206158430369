import React from 'react';
import {Router, Route} from 'react-router-dom';
import {Redirect, Switch} from "react-router";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import {connect} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {
    Login,
    SignUp,
    LenderDashboard,
    ActivationStatus,
    CompanySetup,
    OCDashboard,
    SCDashboard,
    RegisterSubUser,
    ResetPassword,
    RecoverPassword,
    AdminDashboard,
    PublishRecord,
    TransferRecord
} from "./modules";
import {history} from "./managers/history";
import BaseComponent from "./modules/baseComponent";
import LoaderComponent from "./common/components/loader";
import Utility, {dispatchAction} from "./utility";
import {UserService} from "./services";
import {eventConstants, genericConstants} from "./constants";
import {ToastContainer} from "react-toastify";
import * as firebase from "firebase/app";
import "firebase/messaging";

class Routes extends BaseComponent {

    async componentDidMount() {
        this.registerForPush();

    }

    async registerForPush() {
        var firebaseConfig = {
            apiKey: process.env.REACT_APP_API_KEY,
            authDomain: process.env.REACT_APP_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_DATABASE_URL,
            projectId: process.env.REACT_APP_PROJECT_ID,
            storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_APP_ID,
            measurementId: process.env.REACT_APP_MEASUREMENT_ID
        };
        console.log("firebaseConfig===", firebaseConfig);
        try {
            firebase.initializeApp(firebaseConfig);
            const messaging = firebase.messaging();
            messaging.usePublicVapidKey(process.env.REACT_APP_FIREBASE_WEB_PUSH_PUBLIC_KEY);

            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                    // ...
                    messaging.getToken().then((currentToken) => {
                        if (currentToken) {
                            // sendTokenToServer(currentToken);
                            // updateUIForPushEnabled(currentToken);
                            this.registerDevice(currentToken).catch((error) => Utility.consoleLogger("Device Registration Error! ", error));

                        } else {
                            // Show permission request.
                            console.log('No Instance ID token available. Request permission to generate one.');
                            // Show permission UI.
                            // updateUIForPushPermissionRequired();
                            // setTokenSentToServer(false);
                            this.registerDevice().catch((error) => Utility.consoleLogger("Device Registration Error! ", error));
                        }
                    }).catch((err) => {
                        console.log('An error occurred while retrieving token. ', err);
                        // showToken('Error retrieving Instance ID token. ', err);
                        // setTokenSentToServer(false);
                    });
                } else {
                    console.log('Unable to get permission to notify.');
                    this.registerDevice().catch((error) => Utility.consoleLogger("Device Registration Error! ", error));
                }
            })
        } catch (e) {
            console.log("error handling in try catch", e);
            this.registerDevice().catch((error) => Utility.consoleLogger("Device Registration Error! ", error));
        }

    }

    async registerDevice(token) {
        if (Utility.isEmpty(this.props.user.deviceID)) {
            let requestData = {};
            requestData['deviceType'] = "windows";
            requestData['pushToken'] = token ? token : '';
            let [error, deviceResponse] = await Utility.parseResponse(UserService.registerDevice(requestData));
            this.props.dispatchAction(error ? eventConstants.REGISTER_DEVICE_FAILURE : eventConstants.REGISTER_DEVICE_SUCCESS,
                deviceResponse ? deviceResponse.deviceID : null);
        }
    }

    getDashboardComponent = () => {
        if (!this.props || !this.props.user || !this.props.user.userDetails || !this.props.user.userDetails.role)
            return LenderDashboard;

        for (let item of this.props.user.userDetails.role) {
            if (!item || !item.title)
                continue;
            switch (item.title) {
                case genericConstants.ROLES.SUPER_ADMIN:
                    return AdminDashboard;
                case genericConstants.ROLES.LENDER:
                case genericConstants.ROLES.SUB_LENDER:
                    return LenderDashboard;
                case (genericConstants.ROLES.ORIGINATION_COUNSEL):
                case genericConstants.ROLES.SUB_ORIGINATION_COUNSEL:
                    return OCDashboard;
                case genericConstants.ROLES.SELLER_COUNSEL :
                case genericConstants.ROLES.SUB_SELLER_COUNSEL:
                    return SCDashboard;
                default:
                    return LenderDashboard;
            }
        }
        return LenderDashboard;

    };


    //Common component to render the Toast-
    toast = () => {
        return (
            <ToastContainer position="top-center" autoClose={2500} hideProgressBar newestOnTop={true}
                            closeOnClick={true} rtl={false} pauseOnVisibilityChange={false} draggable={false}
                            pauseOnHover={false} closeButton={null}/>
        );
    };

    getPublicRoutes = () => {
        return (
            <Switch>
                <Route exact path={'/'} component={Login}/>
                <Route exact path={'/sign-up'} component={SignUp}/>
                <Route exact path={'/register'} component={RegisterSubUser}/>
                <Route exact path={'/recover-password'} component={RecoverPassword}/>
                <Route exact path={'/reset-password'} component={ResetPassword}/>
                <Redirect exact from='*' to="/"/>
            </Switch>
        )
    };
    getPrivateRoutes = () => {
        let dashboardComponent = this.getDashboardComponent();
        return (
            <Switch>
                <Route exact path={'/'} component={Login}/>
                <Route exact path={'/sign-up'} component={SignUp}/>
                <Route exact path={'/register'} component={RegisterSubUser}/>
                <Route exact path={'/recover-password'} component={RecoverPassword}/>
                <Route exact path={'/reset-password'} component={ResetPassword}/>
                <Route exact path={'/activation'} component={ActivationStatus}/>
                <Route exact path={'/dashboard'} component={dashboardComponent}/>
                <Route exact path={'/dashboard/:menu'} component={dashboardComponent}/>
                <Route exact path={'/dashboard/:menu/:section'} component={dashboardComponent}/>
                <Route exact path={'/dashboard/:menu/:section/:questions'} component={dashboardComponent}/>
                <Route exact path={'/company'} component={CompanySetup}/>
                <Route path={'/publish/'} component={PublishRecord}/>
                <Route path={'/transfer/'} component={TransferRecord}/>
                <Redirect exact from='*' to="/"/>
            </Switch>
        )
    };

    render() {
        let loader = this.props && this.props.user && this.props.user.loading ? <LoaderComponent/> : null;
        let isUserLoggedIn = this.props && this.props.user && this.props.user.userDetails;
        return (

            <MuiThemeProvider muiTheme={getMuiTheme()}>
                <Router history={history}>
                    {loader}
                    {this.toast()}
                    {isUserLoggedIn ? this.getPrivateRoutes() : this.getPublicRoutes()}
                </Router>
            </MuiThemeProvider>);
    }
}

const mapStateToProps = (state) => {
    return {user: state.user}
};
export default connect(mapStateToProps, {dispatchAction})(Routes);
