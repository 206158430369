import React from 'react'
import {Column, Row} from "simple-flexbox";
import Popover from "@material-ui/core/Popover/Popover";
import Badge from '@material-ui/core/Badge';
import Utils from "../../utility";
import {pathConstants} from "../../constants";
import {history} from "../../managers/history";
import NotificationListComponent from "./notificationListComponent";

const NotificationCellComponent = (notificationObj) => {
    return (
        <Column className="p-3 border-bottom-pale-grey-four">
            <Column flex="3" className="fs-13 fc-brownish-grey" vertical="start">
                {Utils.epocToPrettyTime(notificationObj.addedOn)}
            </Column>
            <Column flex="7" className={(notificationObj.isRead ? "fc-brownish-grey" : "fw-600") + " py-1 fs-15"} vertical="center"
                    style={{wordBreak: 'break-word'}}>
                {notificationObj.title}
            </Column>
        </Column>
    );
};

function NotificationPopOverComponent(props) {
    let {state, notificationList,markReadNotifications} = props;
    const [notificationAnchorEl, setNotifAnchorEl] = React.useState(null);
    const isNotificationMenuOpen = Boolean(notificationAnchorEl);


    function handleNotificationsMenuOpen(event) {
        setNotifAnchorEl(event.currentTarget);
    }

    function handleNotificationMenuClose() {
        setNotifAnchorEl(null);
        markReadNotifications(3);
    }
    function viewAllNotification() {
        history.push(pathConstants.DASHBOARD_MENU.NOTIFICATIONS);
        markReadNotifications(notificationList.length);
    }

    return (
        <Column className="fc-dark-slate-blue mr-3">
            <Badge badgeContent={state.notificationCount} color="error">
                <img className="p-9px br-4 b-1-white-two bg-white-two mx-1 cursor-pointer"
                     src="/images/notification-bell.svg" onClick={handleNotificationsMenuOpen}/>
            </Badge>
            <Popover
                className="subscription-plan-card-shadow mt-2 br-10"
                open={isNotificationMenuOpen}
                anchorEl={notificationAnchorEl}
                onClose={handleNotificationMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <Column style={{width: '300px'}} className="overflow-y-auto">
                    {notificationList && notificationList.length > 0 ? notificationList.map((notificationObj, index) => {
                        if (index > 3)
                            return;
                        return NotificationCellComponent(notificationObj)
                    }) : <div className="px-3 py-5 jc-center display-flex">No new Notification</div>}
                    {notificationList && notificationList.length > 3 ?
                        <div onClick={() => viewAllNotification()}
                             className="p-3 text-center w-100 fc-blue cursor-pointer">View All</div> : null}
                </Column>
            </Popover>
        </Column>
    );
}

function NotificationComponent(props) {
    let isNotificationListComponent = Utils.isMenuActive(pathConstants.DASHBOARD_MENU.NOTIFICATIONS);
    return (
        <div>
            {isNotificationListComponent ? NotificationListComponent(props) : NotificationPopOverComponent(props)}
        </div>
    );
}

export default NotificationComponent;
