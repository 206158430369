import React from 'react';
import BaseComponent from "../../modules/baseComponent";
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import {apiFailureConstants, genericConstants, stringConstants} from "../../constants";
import RangeSlider from "../components/RangeSlider";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {Column, Row} from "simple-flexbox";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Radio from "@material-ui/core/Radio/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Utility from "../../utility";
import CustomInput from "./CustomInput";
import DatePickerComponent from "./DatePicker";
import RangeSelector from "./rangeSelector";

class CriteriaInputComponent extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            criteriaInput: "",
            openPopOversId: "",
            rangeInput: [],
            inputFromDate: "",
            inputToDate: "",
            inputErrorText: "",
            fromAmount: "",
            toAmount: "",
            selectedFilterType: "type1",
            selectedDateFilterType: "date-type1",
            rangeInputErrorText: "",
        }
    }


    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };

    applyDateRangeCriteriaFilter = (criteriaObject) => {
        //Convert into epoch
        let fromDate = Utility.getEpochFromTimeString(this.state.inputFromDate, "YYYY-MM-DD");
        let toDate = Utility.getEpochFromTimeString(this.state.inputToDate, "YYYY-MM-DD");
        if (!fromDate || !toDate) {
            this.setState({dateRangeInputErrorText: stringConstants.DATE_INPUT_ERROR});
            return;
        }
        if (fromDate > toDate) {
            this.setState({dateRangeInputErrorText: stringConstants.DATE_RANGE_INPUT_ERROR});
            return;
        }
        this.props.applyCriteriaFilters(criteriaObject, [fromDate, toDate]).catch(error => {
            Utility.consoleLogger("Error : applyDateRangeCriteriaFilter  : ", error);
        })
    };

    onDateSliderChangeEvent = (noOfMonth) => {
        let inputFromDate = Utility.getDateStringWithSubtractionOfMonth(Date.now().valueOf(), noOfMonth, "YYYY-MM-DD");
        let inputToDate = Utility.getDateString(Date.now().valueOf(), "YYYY-MM-DD");
        this.setState({
            inputFromDate,
            inputToDate,
        });
    };

    onRangeChangeEvent = (event) => {
        let range = [];
        switch (event.target.value) {
            case "type2":
                range = [0, 20000000];
                break;
            case "type3":
                range = [0, 30000000];
                break;
            case "type4":
                range = [30000000, "MAX"];
                break;
        }
        this.setState({
            [event.target.id]: event.target.value,
            rangeInput: range,
            rangeInputErrorText: ""
        });
    };

    onDateRangeChangeEvent = (event) => {
        let inputFromDate = "";
        let inputToDate = "";
        let updatedData = {
            [event.target.id]: event.target.value,
            dateRangeInputErrorText: ""
        }
        switch (event.target.value) {
            case "date-type2":
                inputFromDate = Utility.getDateString(new Date("1970-01-02").valueOf(), "YYYY-MM-DD");
                inputToDate = Utility.getDateString(Date.now().valueOf(), "YYYY-MM-DD");
                updatedData = {
                    ...updatedData, inputFromDate,
                    inputToDate
                }
                break;
        }
        this.setState(updatedData);
    };

    applyAmountRangeCriteriaFilter = (criteriaObject, state) => {

        if ((state.selectedFilterType === "type1" && (state.fromAmount === "" || state.toAmount === ""))) {
            this.setState({rangeInputErrorText: stringConstants.DATE_INPUT_ERROR});
            return;
        }
        if (state.selectedFilterType !== "type1" &&
            (!state.rangeInput || state.rangeInput.length < 1)) {
            Utility.apiFailureToast(apiFailureConstants.SEARCH_BY_CRITERIA);
            return;
        }
        let criteriaInput = [];
        if (state.selectedFilterType === "type1")
            criteriaInput = [parseFloat(state.fromAmount), parseFloat(state.toAmount)];
        else
            criteriaInput = state.rangeInput;

        this.props.applyCriteriaFilters(criteriaObject, criteriaInput).catch(error => {
            Utility.consoleLogger("Error : applyAmountRangeCriteriaFilter  : ", error);
        })
    };

    renderTypeBasedInputView = (props, data) => {
        switch (data.type) {
            case genericConstants.CRITERIA_TYPE.TEXT:
                return (
                    <div>
                        <CustomInput id="criteriaInput" type="text" placeholder={''}
                                     value={this.state.criteriaInput}
                                     onChange={this.onChangeEvent}
                                     className="fc-dark-slate-blue fs-16 p-2 h-50px"/>
                        <Button onClick={() => props.handlePopoverClose()}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-pale-grey fc-brownish-grey mx-1 fw-500 px-3 cursor-pointer">
                            Cancel
                        </Button>
                        <Button onClick={() => props.applyCriteriaFilters(data, this.state.criteriaInput)}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                            Search
                        </Button>
                    </div>
                );
            case genericConstants.CRITERIA_TYPE.QUESTION_CHECKBOX:
                return (
                    <Column>
                        <RadioGroup className="display-flex flex-row" value={this.state.criteriaInput}
                                    onChange={(event) => this.onChangeEvent({
                                        target: {
                                            id: "criteriaInput",
                                            value: event.target.value
                                        }
                                    })}>
                            <FormControlLabel value="QUESTION_CHECKBOX" control={<Radio/>} label="Selected"/>
                            <FormControlLabel value="" control={<Radio/>}
                                              label="Not Selected"/>
                        </RadioGroup>
                        <Row justifyContent="end">
                            <Button onClick={() => props.handlePopoverClose()}
                                    className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-pale-grey fc-brownish-grey mx-1 fw-500 px-3 cursor-pointer">
                                Cancel
                            </Button>
                            <Button onClick={() => props.applyCriteriaFilters(data, this.state.criteriaInput)}
                                    className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                                Search
                            </Button>
                        </Row>
                    </Column>
                );
            case genericConstants.CRITERIA_TYPE.CHECKBOX:
                return (
                    <Column>
                        <RadioGroup className="display-flex flex-row" value={this.state.criteriaInput}
                                    onChange={(event) => this.onChangeEvent({
                                        target: {
                                            id: "criteriaInput",
                                            value: event.target.value
                                        }
                                    })}>
                            {data && data.optionArray && data.optionArray.length > 0 ?
                                data.optionArray.map((object) => {
                                    return <FormControlLabel value={object.value} control={<Radio/>}
                                                             label={object.value}/>
                                }) : null
                            }

                        </RadioGroup>
                        <Row justifyContent="end">
                            <Button onClick={() => props.handlePopoverClose()}
                                    className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-pale-grey fc-brownish-grey mx-1 fw-500 px-3 cursor-pointer">
                                Cancel
                            </Button>
                            <Button onClick={() => props.applyCriteriaFilters(data, this.state.criteriaInput)}
                                    className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                                Search
                            </Button>
                        </Row>
                    </Column>
                );
            case genericConstants.CRITERIA_TYPE.AMOUNT_RANGE:
                return (
                    <Column>
                        <div className="mb-2 fw-500">Amount Range ($)</div>
                        <RadioGroup aria-label="quiz" value={this.state.selectedFilterType}
                                    onChange={(event) => this.onRangeChangeEvent({
                                        target: {
                                            id: "selectedFilterType",
                                            value: event.target.value
                                        }
                                    })}>
                            <FormControlLabel value={"type1"} control={<Radio/>} label={<RangeSlider
                                state={this.state}
                                onChange={this.onRangeChangeEvent}
                                onChangeEvent={this.onChangeEvent}
                            />}/>
                            <FormControlLabel value={"type2"} control={<Radio/>} label="Under $20 Million"/>
                            <FormControlLabel value={"type3"} control={<Radio/>} label="Under $30 Million"/>
                            <FormControlLabel value={"type4"} control={<Radio/>} label="$30 Million or greater"/>

                        </RadioGroup>


                        <Row justifyContent="end">
                            <Button onClick={() => props.handlePopoverClose()}
                                    className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-pale-grey fc-brownish-grey mx-1 fw-500 px-3 cursor-pointer">
                                Cancel
                            </Button>
                            <Button
                                // onClick={() => props.applyCriteriaFilters(data, Utility.generateActualValueOfAmountRangeValue(this.state.rangeInput))}
                                onClick={() => this.applyAmountRangeCriteriaFilter(data, this.state)}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                                Search
                            </Button>
                        </Row>
                    </Column>
                );

            case genericConstants.CRITERIA_TYPE.CALENDER_RANGE:
                return (
                    <Column className=" w-100-per">
                        <div className="mb-2 fw-500">Date Range</div>
                        <RadioGroup aria-label="quiz" value={this.state.selectedDateFilterType}
                                    onChange={(event) => this.onDateRangeChangeEvent({
                                        target: {
                                            id: "selectedDateFilterType",
                                            value: event.target.value
                                        }
                                    })}>
                            <FormControlLabel value={"date-type1"} control={<Radio/>}
                                              label={<div className="mt-2 fw-300 " onFocus={() => this.onChangeEvent({
                                                  target: {
                                                      id: "selectedDateFilterType",
                                                      value: "date-type1"
                                                  }
                                              })}>
                                                  <div className="fw-500 mb-2"> From</div>
                                                  <DatePickerComponent
                                                      value={this.state.inputFromDate && this.state.selectedDateFilterType === "date-type1" ? this.state.inputFromDate : ""}
                                                      id={"inputFromDate"}
                                                      onChange={this.onDateRangeChangeEvent}
                                                  />
                                                  <div className="fw-500 mt-2 mb-2"> To</div>
                                                  <DatePickerComponent
                                                      value={this.state.inputToDate  && this.state.selectedDateFilterType === "date-type1" ? this.state.inputToDate : ""}
                                                      id={"inputToDate"}
                                                      onChange={this.onDateRangeChangeEvent}
                                                  />
                                              </div>}/>
                            <FormControlLabel value={"date-type2"} control={<Radio/>}
                                              label={<div className="fw-500 w-100-per">All</div>}/>
                            <FormControlLabel value={"date-type3"} control={<Radio/>}
                                              label={<div className="fw-300 mb-2">
                                                  <div className="fw-500 mb-2 w-100-per">Last n number of months</div>
                                                  <RangeSelector value={[3]} minValue={3} maxValue={12}
                                                                 onChange={this.onDateSliderChangeEvent}
                                                                 marks={{3: 3, 6: 6, 9: 9, 12: 12}}/>
                                              </div>}/>
                        </RadioGroup>

                        <div
                            className="display-flex text-align-center fc-red fs-14 m-2">{this.state.dateRangeInputErrorText}</div>
                        <Row justifyContent="end">
                            <Button onClick={() => props.handlePopoverClose()}
                                    className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-pale-grey fc-brownish-grey mx-1 fw-500 px-3 cursor-pointer">
                                Cancel
                            </Button>
                            <Button
                                onClick={() => this.applyDateRangeCriteriaFilter(data)}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                                Search
                            </Button>
                        </Row>
                    </Column>

                );

        }

    };

    render() {
        let {data, index, handlePopoverClick, handleRemoveCriteria, handlePopoverClose, state} = this.props;
        return (
            <div key={index} className="bg-pale-grey mt-4 br-4 fs-15 fc-warm-grey p-2 mr-2 ">

                <div
                    className="display-flex flex-direction-row justify-content-center align-items-center cursor-pointer">
                    <Row onClick={() => handlePopoverClick(data.criteriaId)}>
                        <div id={data.criteriaId} aria-describedby={data.criteriaId} color="inherit"
                             className="outline-none text-transform-capitalize fc-steel-grey fs-15"
                        >
                            <div className="text-overflow-ellipses h-25px selected-criteria max-w-150">{data.name}</div>

                        </div>
                        <ArrowDropDownIcon className='ml-2 mr-2'/>
                    </Row>
                    <i className="fa fa-times fc-red" aria-hidden="true"
                       onClick={() => handleRemoveCriteria(data.criteriaId)}/>

                </div>

                <Popover
                    open={data.criteriaId === state.openPopOversId}
                    anchorEl={document.getElementById(data.criteriaId)}
                    onClose={() => handlePopoverClose()}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                    <div className="w-250 p-4">{this.renderTypeBasedInputView(this.props, data)}</div>
                </Popover>
            </div>
        );
    }

}

export default CriteriaInputComponent;
