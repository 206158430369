import React from "react";
import BaseComponent from '../baseComponent'
import Utils, {dispatchAction} from "../../utility";
import {CompanyService, DocumentService, PublishRecordService, RecordService, UserService} from "../../services";
import connect from "react-redux/es/connect/connect";
import RecordDetailsComponent from "./recordDetailsComponent";
import {
    actionTypeConstants,
    documentCategoryTypeList,
    eventConstants,
    genericConstants,
    pathConstants,
    stringConstants
} from "../../constants";
import {history} from "../../managers/history";

class RecordDetails extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            recordDetails: {},
            documentList: {},
            isManageUserDialogOpen: false,
            isAddNewCounselDialogOpen: false,
            isAddDocumentDialogOpen: false,
            isEditRecordDialogOpen: false,
            isNoteDialogOpen: false,
            subUsers: [],
            isPublishTagSelectionDialogOpen: false,
            selectedPublishTag: {
                tag: "",
                type: ""
            }
        }
    }

    componentDidMount() {
        let pathName = window.location.pathname;
        let pathArray = pathName.split('/');
        if (pathArray && pathArray.length === 4) {
            this.getRecordDetails(pathArray[pathArray.length - 1]);
            this.getSectionList(pathArray[pathArray.length - 1]);
            this.getDocumentList(pathArray[pathArray.length - 1]);
            this.extractAnswer(pathArray[pathArray.length - 1]);
        }
    }

    getSubUsersList = async () => {
        if (!this.props || !this.props.user || !this.props.user.userDetails || !this.props.user.userDetails.miscellaneous || !this.props.user.userDetails.miscellaneous.company) {
            Utils.apiFailureToast("Unable to fetch Sub-Users!");
            return;
        }

        let requestData = {};
        requestData['miscellaneous.company.companyId'] = this.props.user.userDetails.miscellaneous.company.companyId;
        let [error, userListResponse] = await Utils.parseResponse(UserService.getUserList(requestData));

        if (!userListResponse || userListResponse.length < 0)
            this.setState({subUsers: []});


        let subUsers = userListResponse.filter(obj => obj.isInactive === 0).map(obj => {
            return {
                userId: obj.userID,
                photo: obj.photo && obj.photo.fileName ? obj.photo.fileName : "",
                name: `${obj.firstName}`,
                email: obj.emailID,
                roleLevel: obj && obj.miscellaneous ? obj.miscellaneous.roleLevel : null,
                isSelected: this.isUserSelected(obj)
            }
        });
        this.setState({subUsers: subUsers});
    };

    isUserSelected(userObject) {
        switch (Utils.getUserRole(userObject)) {
            case genericConstants.ROLES.LENDER:
            case genericConstants.ROLES.SUB_LENDER:
                return userObject && userObject.userId && this.state.recordDetails
                    && this.state.recordDetails.subLender && (!!this.state.recordDetails.subLender.find((obj) => obj.userId === userObject.userId));
            case genericConstants.ROLES.ORIGINATION_COUNSEL:
            case genericConstants.ROLES.SUB_ORIGINATION_COUNSEL:
                return userObject && userObject.userId && this.state.recordDetails
                    && this.state.recordDetails.subOriginationCounsel && (!!this.state.recordDetails.subOriginationCounsel.find((obj) => obj.userId === userObject.userId));
            case genericConstants.ROLES.SELLER_COUNSEL:
            case genericConstants.ROLES.SUB_SELLER_COUNSEL:
                return userObject && userObject.userId && this.state.recordDetails
                    && this.state.recordDetails.subSellerCounsel && (!!this.state.recordDetails.subSellerCounsel.find((obj) => obj.userId === userObject.userId));

        }
    }

    getSellerCounselInformation() {
        let {company} = this.props;
        let requestObj = {
            "companyId": company.companyId,
            "companyType": genericConstants.ROLES.SELLER_COUNSEL,
        };
        CompanyService.getAssociateList(requestObj).then((response) => {
            if (response && response.associatedTo)
                this.setState({sellerCounsel: response.associatedTo})
        }).catch(error => {
            Utils.consoleLogger("getOriginationCounselList : error", error)
        });
    }


    getOriginationCounselInformation() {
        let {company} = this.props;
        let requestObj = {
            "companyId": company.companyId,
            "companyType": genericConstants.ROLES.ORIGINATION_COUNSEL,
        };
        CompanyService.getAssociateList(requestObj).then((response) => {
            if (response && response.associatedTo)
                this.setState({originationCounsel: response.associatedTo})
        }).catch(error => {
            Utils.consoleLogger("getOriginationCounselList : error", error)
        });
    }


    getRecordDetails = async (recordId) => {
        if (!recordId) {
            Utils.apiFailureToast("Unable to get record details");
            return;
        }
        let [error, recordDetails] = await Utils.parseResponse(RecordService.getRecordDetail(recordId));
        if (error) {
            Utils.apiFailureToast("Unable to get record details");
            return;
        }
        Utils.consoleLogger("Record Details ", recordDetails);
        this.setState({recordDetails: recordDetails});
        this.updateRecentVisitedRecord();
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
    };

    extractAnswer = async (recordId) => {
        if (!recordId) {
            Utils.apiFailureToast("Unable to get Publish Tag data");
            return;
        }
        let requestObject = {
            "recordId": recordId,
            "questionArray": ["5dd7996c4b32ae00317a8df1", "5dd799604b32ae00317a8df0", "5dd7d8cc299c840031e57a43", "5dd7c7c6299c840031e579e6", "5da028c679020c09da03f2d6"]
        }
        let [error, publishTagRelatedData] = await Utils.parseResponse(RecordService.extractAnswer(requestObject));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error) {
            Utils.apiFailureToast("Unable to get record details");
            return;
        }

        this.generatePublishCategory(publishTagRelatedData)
    };

    generatePublishCategory = (publishTagRelatedData) => {
        let loanOriginationDate = "";
        let poolName = "";
        let SecuritizationDate = "";
        let servicingEventDate = ""; // PART of LDR D

        for (let index = 0; index < publishTagRelatedData.length; index++) {
            switch (publishTagRelatedData[index].questionId) {
                case "5dd7996c4b32ae00317a8df1" :
                    poolName = publishTagRelatedData[index].answer && publishTagRelatedData[index].answer.value || "";
                    continue;
                case "5da028c679020c09da03f2d6" :
                    loanOriginationDate = publishTagRelatedData[index].answer && publishTagRelatedData[index].answer.value || "";
                    continue;
                case "5dd7c7c6299c840031e579e6" :
                    SecuritizationDate = publishTagRelatedData[index].answer && publishTagRelatedData[index].answer.value || "";
                    continue;
                case "5dd7d8cc299c840031e57a43" :
                    loanOriginationDate = publishTagRelatedData[index].answer && publishTagRelatedData[index].answer.value || "";

            }
        }

        let publishTagList = [
            {type: "typeA", tag: `Loan Origination [${loanOriginationDate}]`},
            {
                type: "typeB",
                tag: `Loan Origination [${loanOriginationDate}]/ Securitization [${poolName} (${SecuritizationDate})`
            },
            {type: "typeC", tag: `Loan Servicing Event [${servicingEventDate}]`}
        ]
        this.setState({publishTagRelatedData: publishTagRelatedData, publishTagList});
    }

    updateRecentVisitedRecord = async () => {
        if (!this.props.user.userDetails || !this.props.user.userDetails.miscellaneous || !this.props.user.userDetails.miscellaneous)
            return;
        let miscellaneous = this.props.user.userDetails && this.props.user.userDetails.miscellaneous ? this.props.user.userDetails.miscellaneous : {};
        let recentlyVisitedRecord = miscellaneous && miscellaneous.recentlyVisitedRecord ? miscellaneous.recentlyVisitedRecord : [];

        recentlyVisitedRecord = recentlyVisitedRecord.filter(obj => obj.recordId !== this.state.recordDetails.recordId);

        recentlyVisitedRecord.push({
            recordId: this.state.recordDetails.recordId,
            propertyPhoto: this.state.recordDetails.propertyPhoto,
            progress: this.state.recordDetails.progress,
            accessedOn: new Date().getTime()
        });
        recentlyVisitedRecord = recentlyVisitedRecord.sort((a, b) => b.accessedOn - a.accessedOn);

        if (recentlyVisitedRecord.length > 5) {
            recentlyVisitedRecord = recentlyVisitedRecord.slice(0, recentlyVisitedRecord.length - 4);
        }
        miscellaneous = {...miscellaneous, recentlyVisitedRecord: recentlyVisitedRecord};

        let requestData = {
            userID: this.props.user.userDetails.userID,
            miscellaneous: JSON.stringify(miscellaneous)
        };
        let [error, userDetails] = await Utils.parseResponse(UserService.updateUser(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error) {
            Utils.apiFailureToast("Unable to update recent Visited record")
            return;
        }
        //TODO change SIGN_UP_SUCCESS update user//@Sunny

        if (userDetails && Object.keys(userDetails)) {
            this.props.dispatchAction(eventConstants.SIGN_UP_SUCCESS, {userDetails});

        }

    };

    getSectionList = async (recordId) => {
        if (!recordId) {
            Utils.apiFailureToast("Unable to fetch section data!");
            return;
        }
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, sectionList] = await Utils.parseResponse(RecordService.getSectionList(recordId));
        if (error) {
            Utils.apiFailureToast("Unable to get record details");
            return;
        }
        if (!sectionList || !Array.isArray(sectionList) || !sectionList.length)
            return;

        let sectionListPartA = this.getPartSpecificList(sectionList, genericConstants.PART_TYPE.PART_A);
        let sectionListPartB = this.getPartSpecificList(sectionList, genericConstants.PART_TYPE.PART_B);
        let sectionListPartC1 = this.getPartSpecificList(sectionList, genericConstants.PART_TYPE.PART_C1);
        let sectionListPartC2 = this.getPartSpecificList(sectionList, genericConstants.PART_TYPE.PART_C2);
        this.setState({
            sectionList: sectionList,
            sectionListPartA,
            sectionListPartB,
            sectionListPartC1,
            sectionListPartC2
        });
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
    };

    getPartSpecificList = (sectionList, part) => {
        return sectionList.filter((sectionObject) => {
                return sectionObject && sectionObject.part === part
            }
        )
    };

    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };

    onPublishTagSelectionEvent = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleChangeSelectedSubLender = (values) => {
        this.setState({selectedSubUsers: values});
    };

    handleChangeSelectedOriginationCounsel = (values) => {
        this.setState({selectedOriginationCounsel: values});
    };

    handleChangeSelectedSellerCounsel = (values) => {
        this.setState({selectedSellerCounsel: values});
    };

    //Manage Users Functionality starts here-

    getSubUsers = (userRole) => {
        if (!userRole || !this.state.recordDetails)
            return [];
        switch (userRole) {
            case genericConstants.ROLES.LENDER:
                return this.state.recordDetails.subLender;
            case genericConstants.ROLES.ORIGINATION_COUNSEL:
                return this.state.recordDetails.subOriginationCounsel;
            case genericConstants.ROLES.SELLER_COUNSEL:
                return this.state.recordDetails.subSellerCounsel;
        }
    };

    onManageUsersClicked = () => {
        if (this.state.isManageUserDialogOpen) {
            this.setState({isManageUserDialogOpen: !this.state.isManageUserDialogOpen});
            return;
        }

        if (!this.props && !this.props.user && !this.props.user.userDetails)
            return;

        let userRole = Utils.getUserRole(this.props.user.userDetails);
        this.setState({
            isManageUserDialogOpen: !this.state.isManageUserDialogOpen,
            selectedSubUsers: this.getSubUsers(userRole),
            selectedSellerCounsel: this.state.recordDetails.sellerCounsel,
            selectedOriginationCounsel: this.state.recordDetails.originationCounsel,
        });

        this.getSubUsersList();
        if (Utils.getUserRole(this.props.user.userDetails) === genericConstants.ROLES.LENDER) {
            this.getSellerCounselInformation();
            this.getOriginationCounselInformation();
        }

    };

    handleSelectedCounsel = (actionType, counselList) => {
        if (!actionType || !counselList)
            return;
        let selectedList = counselList.filter(obj => obj.isSelected === true);

        switch (actionType) {
            case actionTypeConstants.ADD_ORIGINATION_COUNSEL_TEXT:
                this.setState({selectedOriginationCounsel: selectedList && selectedList.length > 0 ? selectedList[0] : null});
                break;
            case actionTypeConstants.ADD_SELLER_COUNSEL_TEXT:
                this.setState({selectedSellerCounsel: selectedList && selectedList.length > 0 ? selectedList[0] : null});
                break;
            case actionTypeConstants.ADD_SUB_USER_TEXT:
                this.setState({selectedSubUsers: selectedList});

                break;

            default :
                break;
        }

        this.toggleNewCounselDialog();
    };

    handleAddUser = (actionType) => {
        switch (actionType) {
            case actionTypeConstants.ADD_ORIGINATION_COUNSEL:
                this.addOriginationCounsel();
                break;
            case actionTypeConstants.ADD_SELLER_COUNSEL:
                this.addSellerCounsel();
                break;
            case actionTypeConstants.ADD_SUB_USER:
                this.addSubUsers();
                break;
            default :
                break;
        }
    };

    isUserSelected = (counselObj) => {
        if (!this.props || !this.props.user || !this.props.user.userDetails || !this.state || !this.state.recordDetails)
            return false;

        let userRole = Utils.getUserRole(this.props.user.userDetails);
        switch (userRole) {
            case genericConstants.ROLES.LENDER:
                return this.state.recordDetails.subLender ? !!this.state.recordDetails.subLender.find((userObj) => userObj.userId === counselObj.userId) : false;
            case genericConstants.ROLES.ORIGINATION_COUNSEL:
                return this.state.recordDetails.subOriginaionCounsel ? !!this.state.recordDetails.subOriginaionCounsel.find((userObj) => userObj.userId === counselObj.userId) : false;
            case genericConstants.ROLES.SELLER_COUNSEL:
                return this.state.recordDetails.subSellerCounsel ? !!this.state.recordDetails.subSellerCounsel.find((userObj) => userObj.userId === counselObj.userId) : false;
            default :
                break;
        }
    };

    addOriginationCounsel = () => {
        let counselList = [];
        let originationCounsels = this.state.originationCounsel;

        if (originationCounsels && Array.isArray(this.state.originationCounsel))
            counselList = originationCounsels.map((counselObj) => {
                return {
                    ...counselObj,
                    isSelected: this.state.recordDetails && this.state.recordDetails.originationCounsel && counselObj.companyId === this.state.recordDetails.originationCounsel.companyId
                }
            });
        if (!counselList || !counselList.length) {
            Utils.apiFailureToast("No Counsel added yet");
            return;
        }
        this.setState({
            counselDialogHeading: actionTypeConstants.ADD_ORIGINATION_COUNSEL_TEXT,
            counselList: counselList
        });
        this.toggleNewCounselDialog();

    };

    addSubUsers = () => {
        let subUsersList = [];
        let subUsers = this.state.subUsers;

        if (subUsers && Array.isArray(subUsers))
            subUsersList = subUsers.map((counselObj) => {
                return {
                    ...counselObj,
                    isSelected: this.isUserSelected(counselObj)
                }
            });

        if (!subUsersList || !subUsersList.length) {
            Utils.apiFailureToast("No sub-user added yet");
            return;
        }
        this.setState({
            counselDialogHeading: actionTypeConstants.ADD_SUB_USER_TEXT,
            counselList: subUsersList
        });
        this.toggleNewCounselDialog();
    };

    addSellerCounsel = () => {
        let counselList = [];
        let sellerCounsel = this.state.sellerCounsel;

        if (sellerCounsel && Array.isArray(this.state.originationCounsel))
            counselList = sellerCounsel.map((counselObj) => {
                return {
                    ...counselObj,
                    isSelected: counselObj.companyId === this.state.recordDetails.sellerCounsel.companyId
                }
            });

        if (!counselList || !counselList.length) {
            Utils.apiFailureToast("No Counsel added yet");
            return;
        }
        this.setState({
            counselDialogHeading: actionTypeConstants.ADD_SELLER_COUNSEL_TEXT,
            counselList: counselList
        });

        this.toggleNewCounselDialog();
    };

    toggleNewCounselDialog = () => {
        if (this.state.isAddNewCounselDialogOpen)
            this.setState({counselDialogHeading: '', counselList: []});

        this.setState({isAddNewCounselDialogOpen: !this.state.isAddNewCounselDialogOpen});
    };


    getUpdateRecordRequestDataForManageUsers() {
        if (!this.props && !this.props.user && !this.props.user.userDetails)
            return;

        let userRole = Utils.getUserRole(this.props.user.userDetails);
        switch (userRole) {
            case genericConstants.ROLES.LENDER:
                return {
                    subLender: this.state.selectedSubUsers,
                    originationCounsel: this.state.selectedOriginationCounsel,
                    sellerCounsel: this.state.selectedSellerCounsel
                };
            case genericConstants.ROLES.ORIGINATION_COUNSEL:
                return {subOriginationCounsel: this.state.selectedSubUsers};
            case genericConstants.ROLES.SELLER_COUNSEL:
                return {subSellerCounsel: this.state.selectedSubUsers};
            default :
                break;
        }
    }


    handleUpdateRecord = async (recordDetails, isNotForManageUsers) => {
        if (!this.state || !this.state.recordDetails) {
            Utils.apiFailureToast("Unable to update Record");
            return;
        }
        let requestData = {};
        if (!isNotForManageUsers)
            requestData = this.getUpdateRecordRequestDataForManageUsers();
        else
            requestData = recordDetails;
        requestData.recordId = this.state.recordDetails._id;
        Utils.consoleLogger("requestData", requestData);

        if (requestData.hasOwnProperty("originationCounsel") && (requestData.originationCounsel === null || Array.isArray(requestData.originationCounsel) && !requestData.originationCounsel.length)
            || requestData.hasOwnProperty("sellerCounsel") && (requestData.sellerCounsel === null || Array.isArray(requestData.sellerCounsel) && !requestData.sellerCounsel.length)) {
            Utils.apiFailureToast("Invalid update request. Please provide mandatory information!");
            return;
        }
        this.props.dispatchAction(eventConstants.SHOW_LOADER);

        let [error, recordUpdateResponse] = await Utils.parseResponse(RecordService.updateRecord(requestData));

        this.props.dispatchAction(eventConstants.HIDE_LOADER);

        if (error) {
            Utils.apiFailureToast("Unable to update Record");
            return;
        }
        let pathName = window.location.pathname;
        let pathArray = pathName.split('/');
        if (pathArray && pathArray.length === 4 && !this.state.isEditRecordDialogOpen)
            this.getSectionList(pathArray[pathArray.length - 1]);

        this.setState({
            recordDetails: recordUpdateResponse,
            isManageUserDialogOpen: false,
            isEditRecordDialogOpen: false
        });
    };

    //Document Upload Functionality starts here-

    getDocumentList = async (recordId) => {
        let request = {
            //In case of role specific documents-
            // role: this.props.user && this.props.user.userDetails.role && this.props.user.userDetails.role.length && this.props.user.userDetails.role[0] ? this.props.user.userDetails.role[0] : {},
            isGetAssociatedDocumentsList: true
        };
        if (recordId)
            request['recordId'] = recordId;
        else
            request['recordId'] = this.state.recordDetails && Object.keys(this.state.recordDetails).length > 0 && this.state.recordDetails.recordId ?
                this.state.recordDetails.recordId : "";

        let [error, documentResponse] = await Utils.parseResponse(DocumentService.getDocuments(request));
        if (error) {
            Utils.apiFailureToast("Unable to Get document List");
            return;
        }
        this.sortDocumentAssociatedList(documentResponse);
    };

    sortDocumentAssociatedList = (documentResponse) => {
        if (!documentResponse || Object.keys(documentResponse).length < 1)
            return;

        let keys = Object.keys(documentResponse);
        keys.sort((a, b) => documentCategoryTypeList[a] - documentCategoryTypeList[b])

        let sortedDocumentList = {};
        for (let index = 0; index < keys.length; index++)
            sortedDocumentList[keys[index]] = documentResponse[keys[index]];

        this.setState({documentList: sortedDocumentList});
    }

    toggleAddDocumentDialogOpen = () => {
        if (this.state.isAddDocumentDialogOpen)
            this.getDocumentList("");
        this.setState({isAddDocumentDialogOpen: !this.state.isAddDocumentDialogOpen});
    };

    onEditRecordClicked = () => {
        this.setState({isEditRecordDialogOpen: !this.state.isEditRecordDialogOpen});
    };

    toggleSelectedCounsel = (index) => {
        if (index >= 0) {
            let {counselList} = this.state;
            /*Allowing Multi Selection for Users but preventing it for Counsel Selection*/
            counselList.map((counselObject, arrayIndex) => {
                // Only Counsel Object contains "companyType" key.
                if (counselObject.hasOwnProperty("companyType") && arrayIndex !== index)
                    counselObject.isSelected = false;
            })
            counselList[index].isSelected = !counselList[index].isSelected;
            this.setState({counselList});
        }
    };

    onMenuOptionClicked = (selectedOption) => {
        if (!this.state.recordDetails.recordId) {
            return Utils.apiFailureToast("Unable to process Publish Request!!");
        }
        switch (selectedOption) {
            case genericConstants.RECORD_DETAILS_MENU_OPTION.PUBLISH :
                history.push({pathname: pathConstants.DASHBOARD_MENU.PUBLISH + this.state.recordDetails.recordId});
                break;
            case genericConstants.RECORD_DETAILS_MENU_OPTION.TRANSFER :
                history.push({pathname: pathConstants.DASHBOARD_MENU.TRANSFER + this.state.recordDetails.recordId});
                break;
        }
    };

    onViewRecordClicked = () => {
        if (!this.state.recordDetails.recordId) {
            return Utils.apiFailureToast("Unable to view published records!");
        }
        history.push({pathname: pathConstants.DASHBOARD_MENU.PUBLISH + pathConstants.DASHBOARD_MENU.HISTORY + this.state.recordDetails.recordId})
    };

    togglePublishTagSelectionDialogState = () => {
        this.setState({isPublishTagSelectionDialogOpen: !this.state.isPublishTagSelectionDialogOpen})
    }

    handleOnPublishClickByAdmin = () => {

        if (!this.state || !this.state.selectedPublishTag || !this.state.selectedPublishTag.tag)
            return this.setState({selectedPublishTagError: "Please select a LDR publish tag"})
        this.updatePublishRequest(stringConstants.PUBLISH).catch((error) => {
            Utils.consoleLogger("handleOnPublishClickByAdmin : updatePublishRequest", error);
        });
        this.togglePublishTagSelectionDialogState();
    }

    updatePublishRequest = async (type, note = '') => {
        let {recordDetails, selectedPublishTag} = this.state;
        let {publishRequest} = this.props;
        let requestData;
        let transactionData = {};
        let publishedBy = {...publishRequest.publishedBy};

        transactionData['recordId'] = publishRequest.recordId || "";
        transactionData['publishRequestId'] = publishRequest.publishRequestId || "";
        transactionData['type'] = type || "";
        transactionData['note'] = note || "";
        transactionData['propertyPhoto'] = recordDetails.propertyPhoto || "";
        if (type === stringConstants.PUBLISH) {
            let [error, documentList] = await Utils.parseResponse(DocumentService.getDocuments({recordId: recordDetails.recordId}));
            if (error) {
                Utils.apiFailureToast("Unable to Get document List");
                return;
            }
            transactionData['documents'] = Utils.parseDocumentsRequestObject(documentList); //Will also consist IDs of Reports
            transactionData['publishTag'] = selectedPublishTag || "";
        } else
            transactionData['documents'] = [];

        requestData = {transactionData, publishedBy};
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        let [error, recordResponse] = await Utils.parseResponse(PublishRecordService.publishRecord(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        if (error || !recordResponse) {
            Utils.apiFailureToast(`Unable to ${type} Record!`);
            return;
        }
        setTimeout(history.goBack(), 5000);
        Utils.apiSuccessToast(`Record has been ${type}ed Successfully!`);
    };

    toggleNoteDialog = () => {
        this.setState({isNoteDialogOpen: !this.state.isNoteDialogOpen});
    };

    rejectPublishRequest = async (noteObj) => {
        if (!noteObj || Object.keys(noteObj).length < 1 || !noteObj.note)
            return;
        await this.updatePublishRequest(stringConstants.REJECT, noteObj.note);
        this.toggleNoteDialog();

    };

    render() {
        return (
            <RecordDetailsComponent state={this.state} recordDetails={this.state.recordDetails}
                                    dispatchAction={this.props.dispatchAction}
                                    userDetails={this.props && this.props.user ? this.props.user.userDetails : null}
                                    userPermission={this.props && this.props.user ? this.props.user.userPermission : null}
                                    company={this.props && this.props.company ? this.props.company : null}
                                    isNewRecordDialogOpen={this.state.isNewRecordDialogOpen}
                                    onEditRecordClicked={this.onEditRecordClicked}
                                    onMenuClick={this.onMenuClick}
                                    handleAddUser={this.handleAddUser}
                                    toggleNewCounselDialog={this.toggleNewCounselDialog}
                                    handleChangeSelectedSubLender={this.handleChangeSelectedSubLender}
                                    handleChangeSelectedOriginationCounsel={this.handleChangeSelectedOriginationCounsel}
                                    handleChangeSelectedSellerCounsel={this.handleChangeSelectedSellerCounsel}
                                    onManageUsersClicked={this.onManageUsersClicked}
                                    toggleAddDocumentDialogOpen={this.toggleAddDocumentDialogOpen}
                                    toggleSelectedCounsel={this.toggleSelectedCounsel}
                                    onChangeEvent={this.onChangeEvent}
                                    onPublishTagSelectionEvent={this.onPublishTagSelectionEvent}
                                    handleOnPublishClickByAdmin={this.handleOnPublishClickByAdmin}
                                    togglePublishTagSelectionDialogState={this.togglePublishTagSelectionDialogState}
                                    handleSelectedCounsel={this.handleSelectedCounsel}
                                    handleUpdateRecord={this.handleUpdateRecord}
                                    onMenuOptionClicked={this.onMenuOptionClicked}
                                    onViewRecordClicked={this.onViewRecordClicked}
                                    updatePublishRequest={this.updatePublishRequest}
                                    toggleNoteDialog={this.toggleNoteDialog}
                                    rejectPublishRequest={this.rejectPublishRequest}
                                    getDocumentList={this.getDocumentList}
            />
        );
    }
}

const mapStateToProps = (state) => {
        return {user: state.user, company: state.company, publishRequest: state.publishRequest}
    };

export default connect(mapStateToProps, {dispatchAction})(RecordDetails);
