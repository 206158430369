import {Column, Row} from "simple-flexbox";
import React from "react";
import Utility from "../../../utility";
import {genericConstants, pathConstants} from "../../../constants";
import InformationRecords from "../../informationRecords";
import SubOriginationCounsel from "../../subCounsel";
import Lender from "../lenders";
import Settings from "../../settings";
import RecordDetails from "../../recordDetails";
import {Billing, ProfileSettings, SectionDetailsComponent} from "../../index";
import {getSignedURL} from "../../../managers/awsService";


const SideMenuComponent = (props) => {
    return (
        <Column className="px-3 py-2 border-right min-vh-100 h-100" style={{width: '270px'}}>
            <Row horizontal={'left'} vertical={'center'}
                 className="my-3">
                {!props.company ? "" : <img className="max-w-200px"
                                            src={props.company.logo ? getSignedURL(props.company.logo, '/images/photo-icon.svg') : "/images/photo-icon.svg"}/>}
                {/*<Column className="fs-15 mx-3">
                    <div className="font-weight-bold">{props && props.company ? props.company.name : ""}</div>
                    <div className="fs-14 fc-brownish-grey">
                        {'LIMB ID: ' + (props && props.company ? props.company.limbId : "-")}</div>
                </Column>*/}
            </Row>

            <Row vertical={'center'}
                 className={"mt-5 p-2 " + (Utility.isMenuActive('/information-records') ? 'active-menu' : 'menu')}
                 onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.INFORMATION_RECORDS)}>
                <img alt="information-records" className="mr-3"
                     src="/images/information-records.svg"/>

                <div className="pl-2">Information Records</div>
            </Row>

            {Utility.getUserRole(props.userDetails) === genericConstants.ROLES.ORIGINATION_COUNSEL ?
                <Row vertical={'center'}
                     className={"p-2 " + (Utility.isMenuActive('/lender') ? ' active-menu' : 'menu')}
                     onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.LENDER)}>
                    <img alt="lender-logo" className="mr-3"
                         src="/images/seller-counsel.svg"/>
                    <div className="pl-2 display-flex flex-direction-row">Lender<p
                        className="m-0">&nbsp;({props.invitationCount ? props.invitationCount : "1"})</p></div>
                </Row>
                : ""}

            {Utility.getUserRole(props.userDetails) === genericConstants.ROLES.ORIGINATION_COUNSEL ?
                <Row vertical={'center'}
                     className={"p-2 " + (Utility.isMenuActive('/sub-counsel') ? ' active-menu' : 'menu')}
                     onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.SUB_COUNSEL)}>
                    <img alt="sub-counsel-logo " className="mr-3"
                         src="/images/sub-lender.svg"/>
                    <div className="pl-2">Counsel Sub-Users</div>
                </Row>
                : ""}

            {Utility.getUserRole(props.userDetails) === genericConstants.ROLES.ORIGINATION_COUNSEL ?
                <Row vertical={'center'}
                     className={"p-2 " + (Utility.isMenuActive('/billing') ? ' active-menu' : 'menu')}
                     onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.BILLING)}>
                    <img alt="billing-logo" className="mr-3"
                         src="/images/billing-icon.svg"/>
                    <div className="pl-2">Billing</div>
                </Row>
                : ""}

            <Row vertical={'center'} className={"p-2 " + (Utility.isMenuActive('/settings') ? ' active-menu' : 'menu')}
                 onClick={() => props.onMenuClick(pathConstants.DASHBOARD_MENU.SETTINGS)}>
                <img alt="setting-logo" className="mr-3"
                     src="/images/settings.svg"/>
                <div className="pl-2">Settings</div>
            </Row>
        </Column>
    )
};

function getInformationRecordComponent() {
    let pathName = window.location.pathname;
    let pathArray = pathName.split('/')
    return pathArray && pathArray.length > 3 ? (pathArray.length === 4 && pathArray[3] ?
        <RecordDetails/> : (pathArray.length === 5 && pathArray[4] ? <SectionDetailsComponent/> : null)) :
        <InformationRecords/>
}

const HomeComponent = (props) => {
    let userRole = Utility.getUserRole(props.userDetails);
    let isSubUser = !(userRole === genericConstants.ROLES.LENDER || userRole === genericConstants.ROLES.ORIGINATION_COUNSEL || userRole === genericConstants.ROLES.SELLER_COUNSEL);

    return (
        <Column>
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.INFORMATION_RECORDS) ? getInformationRecordComponent() : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.ORIGINATION_COUNSEL) ? <SubOriginationCounsel/> : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.LENDER) ? <Lender/> : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.SUB_COUNSEL) ? <SubOriginationCounsel/> : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.BILLING) ? <Billing/> : null}
            {Utility.isMenuActive(pathConstants.DASHBOARD_MENU.SETTINGS) ? (isSubUser ? <ProfileSettings/> : <Settings/>): null}
        </Column>
    )
};

const dashboardComponent = (props) => {
    return (
        <Row className="h-100-per w-100" style={{maxHeight: props.height}}>
            <Column id="sideContainer">
                {SideMenuComponent(props)}
            </Column>
            <Column className="w-100 overflow-y-auto">
                {HomeComponent(props)}
            </Column>
        </Row>
    )
};
export default dashboardComponent;
