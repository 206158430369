import React from "react";
import {InputBase, withStyles} from "@material-ui/core";


const CustomSelectInput = withStyles(theme => ({
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#f4f5f7',//theme.palette.background.paper,
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Roboto-Medium',
            'Arial',
            'sans-serif',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
        },
    },
}))(InputBase);

export default CustomSelectInput;