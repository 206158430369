import {Column, Row} from "simple-flexbox";
import React from "react";
import headerComponent from "../../../common/components/CustomHeader";
import {Select, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import CustomSelectInput from "../../../common/components/CustomSelectInput";
import MenuItem from "@material-ui/core/MenuItem";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Utility from "../../../utility";
import {genericConstants, stringConstants} from "../../../constants";
import {getSignedURL} from "../../../managers/awsService";
import SortingComponent from "../../../common/components/SortingComponent";


const SearchFilterComponent = (props) => {
    function searchInList(event) {
        props.lendersFilteredListSetValues(event.target.id, event.target.value);
    }
    let {state} = props;
    return (
        <Column>
            <Row className="bg-color-white fs-12 mt-4" vertical="center">
                <Row className="br-2 bg-pale-grey p-2 b-1-pale-grey-three">
                    <input placeholder={'Search Records'} id={'search'}
                           onChange={searchInList}
                           className="outline-none b-none fc-bluey-grey bg-pale-grey fw-500"/>
                    <img className="mx-2" src="/images/search-icon.svg" alt="search"/>
                </Row>
                <Select value={state.statusFilter}
                        className="br-2 mx-2 fc-warm-grey"
                        onChange={props.onFilterChanged}
                        input={<CustomSelectInput name="statusFilter"/>}>
                    <MenuItem className="p-2 cursor-pointer outline-none"
                              value={genericConstants.INITIAL_FILTER_VALUES.STATUS}>
                        {genericConstants.INITIAL_FILTER_VALUES.STATUS}
                    </MenuItem>
                    {getUniqueStatusMenuOption(state)}
                </Select>
            </Row>
        </Column>
    )
};

const getUniqueStatusMenuOption = (state) => {
    let menuOptions = [];
    let statusArray = Utility.generateUniqueEntityList(state.lendersList, "invitationStatus");
    statusArray.forEach((status, index) => {
        menuOptions.push(renderMenuItem(status, index));
        // menuOptions.push(renderMenuItem(Utility.viewInvitationStatus(status), index));
    });
    return menuOptions;
};

const renderMenuItem = (value, index) => {
    if (!value)
        return "";

    return <MenuItem className="p-2 cursor-pointer outline-none" value={value} key={index}>{value}</MenuItem>
};

const RecordsTableComponent = (props) => {
    const {handlePageChange, state, onSortClicked, updateInvitation, companyId, toggleInviteLenderDialog} = props;
    let [sortingState, updateSortingState] = React.useState({
        name: null,
        invitationStatus: null,
    });

    function onDescendingSort(key) {
        onSortClicked(key, genericConstants.SORTING_ORDER.DESCENDING);
        updateSortingState({[key]: genericConstants.SORTING_ORDER.DESCENDING});
    }

    function onAscendingSort(key) {
        onSortClicked(key, genericConstants.SORTING_ORDER.ASCENDING);
        updateSortingState({[key]: genericConstants.SORTING_ORDER.ASCENDING});
    }

    return (
        <div className="my-2 overflow-x-auto">
            {state.data && state.data.length > 0 ?
                <Column className="bg-white h-100-per fs-15">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className='fs-15 fc-blue-grey'>Logo</TableCell>
                                <TableCell className='fs-15 fc-blue-grey'>
                                    <Row vertical={"center"}>
                                        Lender Name
                                        <SortingComponent state={sortingState.name}
                                                          onDescendingSort={() => onDescendingSort("name")}
                                                          onAscendingSort={() => onAscendingSort("name")}/>
                                    </Row>
                                </TableCell>
                                <TableCell className='fs-15 fc-blue-grey'>
                                    <Row vertical={"center"}>
                                        Status
                                        <SortingComponent state={sortingState.invitationStatus}
                                                          onDescendingSort={() => onDescendingSort("invitationStatus")}
                                                          onAscendingSort={() => onAscendingSort("invitationStatus")}/>
                                    </Row>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.filteredList && state.filteredList.length ? state.filteredList.map((lenders, index) => (
                                <TableRow className='h-60px' id={index}>
                                    <TableCell component="th" scope="row">
                                        <img
                                            src={lenders && lenders.logo ? getSignedURL(lenders.logo) : "/images/company-placeholder.svg"}
                                            className="w-60" alt="counsel-logo"/>
                                    </TableCell>
                                    <TableCell className='fs-15 fc-blue-grey'>{lenders.name}</TableCell>
                                    <TableCell className='fs-15 fc-blue-grey'>{Utility.viewInvitationStatus(lenders.invitationStatus)}</TableCell>
                                    <TableCell>
                                        {lenders.invitationStatus === 'PENDING' && lenders.invitedBy !== companyId ?
                                            <Row>
                                                <img src='/images/tick-icon.svg'
                                                     className='tick-icon-v2' onClick={() => updateInvitation(lenders, genericConstants.INVITATION_STATUS.ACCEPTED)}
                                                /><img src='/images/cross-icon.svg'
                                                       className='cross-icon'onClick={() => updateInvitation(lenders , genericConstants.INVITATION_STATUS.REJECTED)}
                                            />
                                            </Row>
                                            :
                                            <Row>
                                                <img className="cursor-pointer" src='/images/edit-icon.svg' onClick={() => toggleInviteLenderDialog(lenders, true)}/>
                                            </Row>
                                        }

                                    </TableCell>
                                </TableRow>
                            )) : null
                            }
                        </TableBody>
                    </Table>

                </Column>
                : <div className="p-5 mx-4 mb-4 jc-center flex-container bg-color-white">
                    No Lender found
                </div>}
            {state.filteredList && state.filteredList.length > 0 ?
                <div>
                    <Pagination style={{marginTop: 20}}
                                onChange={(pn) => handlePageChange(pn)}
                                current={state.activePage}
                                total={state.filteredList ? state.filteredList.length : 0}
                                pageSize={10}
                    />
                </div>
                :
                ""}

        </div>
    );
};

const inviteLenderComponent = (props) => {
    let {onChangeEvent, toggleInviteLenderDialog, hideSuggestionBox, onInviteLenderClicked, state, deleteCounsel} = props;
    let isToUpdateLender = state.isEditRequest;
    return (
        <Dialog maxWidth={"sm"} open={state.isInviteLenderDialogOpen} fullWidth={true}>
            <form className='overflow-hidden'>
                <Column style={{width: '90%', margin: 'auto'}}>
                    <Row>
                        <div className="fs-20 fw-500 pt-4">{state.isEditRequest ? stringConstants.UPDATE_LENDER : stringConstants.INVITE_LENDER}</div>
                    </Row>
                    <Column style={{margin: '30px 0px'}}>
                        <label className="input-label">Lender Name</label>
                        <div style={{width: '100%'}} className={state.predictions.length > 0 ? '' : 'mb-4'}>
                            <input type="text" className="input-border counsel-entities form-control" name='counselName'
                                   onChange={onChangeEvent} value={state.counselName}
                                   disabled={isToUpdateLender}
                            />
                            <div
                                className={state && state.predictions && state.predictions.length > 0 ? 'suggestion-box' : 'display-none'}>{
                                state && state.predictions && state.counselName && state.counselName.length > 0 ? state.predictions.map((item, index) => (
                                    <div style={{padding: '10px 0px 0px 10px'}} key={index + item}
                                         onClick={() => hideSuggestionBox(item)}><span
                                        className='counsel-entities'>{item.name + ','}</span> <span
                                        className='city-and-state'>{item.address.city + ',' + item.address.state}</span>
                                    </div>
                                )) : ''
                            }
                            </div>

                        </div>
                        <label className="input-label">Address</label>
                        <input type="text" disabled className="mb-4 counsel-entities input-border form-control" name={'address'}
                               onChange={onChangeEvent} value={state.address}/>
                        {isToUpdateLender  && (state.invitationStatus === genericConstants.INVITATION_STATUS.ACCEPTED || state.invitationStatus === genericConstants.INVITATION_STATUS.ACTIVE|| state.invitationStatus === genericConstants.INVITATION_STATUS.IN_ACTIVE) ? <div>
                            <label className="input-label">Status</label>
                            <Row className="br-2">
                                <Select value={state.invitationStatus} name="invitationStatus" className="mb-4 counsel-entities input-border form-control p-0"
                                        onChange={onChangeEvent}
                                        input={<CustomSelectInput name="invitationStatus" id="pools-select"/>}
                                >

                                    <MenuItem className="p-2 cursor-pointer outline-none" value={state.invitationStatus}>
                                        Select Values
                                    </MenuItem>
                                    <MenuItem className="p-2 cursor-pointer outline-none" value={genericConstants.INVITATION_STATUS.ACTIVE}>{stringConstants.ACTIVE}</MenuItem>
                                    <MenuItem className="p-2 cursor-pointer outline-none" value={genericConstants.INVITATION_STATUS.IN_ACTIVE}>{stringConstants.INACTIVE}</MenuItem>
                                </Select>
                            </Row>
                        </div> : "" }
                        <label className="input-label">Email</label>
                        <Row className="br-2 bg-pale-grey p-2 b-1-pale-grey-three justify-content-between">

                            <input id={'search'}
                                   name={'emailID'}
                                   onChange={onChangeEvent}
                                   value={state.email}
                                   disabled
                                   className="outline-none counsel-entities b-none bg-pale-grey w-100"/>
                        </Row>
                    </Column>

                    {isToUpdateLender && (state.invitationStatus === genericConstants.INVITATION_STATUS.PENDING || state.invitationStatus === genericConstants.INVITATION_STATUS.REJECTED) ?
                        <Row  className="mb-5 justify-content-between">
                            <Column>
                                <Button onClick={deleteCounsel}
                                        className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">Delete
                                </Button>
                            </Column>
                            <Row>
                                <Button onClick={toggleInviteLenderDialog}
                                        className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two fc-blue mx-1 fw-500 px-3 cursor-pointer">Cancel
                                </Button>
                                <Button onClick={onInviteLenderClicked}
                                        className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                                    {isToUpdateLender ? 'Update': 'Create'}
                                </Button>
                            </Row>
                        </Row>
                    :
                        <Row horizontal={"end"} className="mb-5">
                            <Button onClick={toggleInviteLenderDialog}
                                    className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two fc-blue mx-1 fw-500 px-3 cursor-pointer">Cancel
                            </Button>
                            <Button onClick={onInviteLenderClicked}
                                    className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                                {isToUpdateLender ? 'Update': 'Create'}
                            </Button>
                        </Row>}

                </Column>
            </form>
        </Dialog>
    )
};

const lendersComponent = (props) => {
    let updatedProps = {
        componentName: 'Lenders',
        buttonName: 'Invite Lender',
        invitationButtonClicked: props.toggleInviteLenderDialog
    };
    return (
        <Column className="min-vh-100 p-4 fc-dark-slate-blue" style={{maxHeight: props.height}}>
            {headerComponent(updatedProps)}
            {SearchFilterComponent(props)}
            {RecordsTableComponent(props)}
            {inviteLenderComponent(props)}
        </Column>
    )
};
export default lendersComponent;