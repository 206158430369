import React from "react";
import BaseComponent from '../baseComponent'
import RecoverPasswordComponent from './resetPasswordComponent'
import Utils, {dispatchAction} from "../../utility";
import {eventConstants, stringConstants} from "../../constants";
import {UserService} from "../../services";
import {history} from "../../managers/history";
import connect from "react-redux/es/connect/connect";

class ResetPassword extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            token: null,
            userName: null,
            newPassword: null,
            newPasswordError: null,
            confirmPassword: null,
            confirmPasswordError: null,
            isInvalidUrl: false,
        }
    }

    componentDidMount() {
        const urlParams = window.location.search;
        console.log('Class: ResetPassword, Function: componentDidMount = urlParams =', urlParams);
        // ?token=TVRVNE1Ea3dOREl3TURnMk9UVmxNVGd3WWpVeE5XWmpObVUxTURBeU5UaGxZMkUyTnc9PQ==
        if (!urlParams || !urlParams.includes('token'))
            this.setInvalidUrl();
        else {
            let token = urlParams.substring(urlParams.indexOf('token=') + 6);
            if (!token || !Utils.decodeBase64(token)) {
                this.setInvalidUrl();
                return;
            }
            console.log('Class: ResetPassword, Function: componentDidMount = token =', Utils.decodeBase64(token));
            this.setState({token: Utils.decodeBase64(token)})
        }
    }

    setInvalidUrl = () => {
        this.setState({isInvalidUrl: true});
    };

    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value});

        if (!Utils.isEmpty(this.state.emailError))
            this.validateRecoveryForm();
    };

    validateRecoveryForm = () => {
        this.setState({
            newPasswordError: Utils.isPasswordValid(this.state.newPassword) ? "" : stringConstants.PASSWORD_VALIDATION,
            confirmPasswordError: Utils.isPasswordValid(this.state.confirmPassword) ? (this.state.newPassword === this.state.confirmPassword ? "" : stringConstants.PASSWORD_DO_NOT_MATCH) : stringConstants.PASSWORD_VALIDATION
        });
        return Utils.isPasswordValid(this.state.newPassword) && Utils.isPasswordValid(this.state.confirmPassword) && this.state.newPassword === this.state.confirmPassword;
    };

    resetPassword = async (event) => {
        event.preventDefault();

        if (!this.validateRecoveryForm())
            return;

        this.props.dispatchAction(eventConstants.SHOW_LOADER);

        let requestData = {};
        requestData.userToken = this.state.token;
        requestData.newPassword = this.state.newPassword;

        let [error, userDetails] = await Utils.parseResponse(UserService.resetPassword(requestData));
        this.props.dispatchAction(eventConstants.HIDE_LOADER);
        history.replace('/');
        if (error || !userDetails || userDetails.length === 0) {
            Utils.apiFailureToast(stringConstants.LINK_EXPIRED);
            return;
        }
        Utils.apiSuccessToast(stringConstants.PASSWORD_RESET_SUCCESSFULLY);

    };

    render() {
        return (
            <RecoverPasswordComponent state={this.state}
                                      onChangeEvent={this.onChangeEvent}
                                      togglePassword={this.togglePassword}
                                      resetPassword={this.resetPassword}/>
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user}
};

export default connect(mapStateToProps, {dispatchAction})(ResetPassword);
