import React from 'react'
import {Column, Row} from "simple-flexbox";
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {stringConstants} from "../../constants";
import CustomInput from "../../common/components/CustomInput";
import ProcessingLoader from "../../common/components/processingLoader";
import Utils from "../../utility";

const UpdateAddressComponent = (props) => {
    let {state, toggleChangeAddress, updateLatestCompanyAddress} = props;
    let [companyInfo, updateCompanyInfo] = React.useState({
        streetAddress: '',
        suite: '',
        city: '',
        state: '',
        country: ''
    });
    React.useEffect(() => {
        updateCompanyInfo(
            {
                streetAddress: state.companyData.billingAddress.streetAddress || "",
                suite: state.companyData.billingAddress.suite || "",
                city: state.companyData.billingAddress.city || "",
                state: state.companyData.billingAddress.state || "",
                country: state.companyData.billingAddress.country || "",
            })
    }, [state.companyData.address]);
    let [errorCompanyInfo, updateErrorCompanyInfo] = React.useState({
        streetAddress: '',
        suite: '',
        city: '',
        state: '',
        country: ''
    });

    function handleInputChange(event) {
        updateCompanyInfo({...companyInfo, [event.target.id]: event.target.value});
        updateErrorCompanyInfo({...errorCompanyInfo, [event.target.id]: ''})
    }

    function validateAddressField() {
        let isAddressFieldValid = true;
        if (!companyInfo || Object.keys(companyInfo).length < 1) {
            isAddressFieldValid = false;
            updateErrorCompanyInfo({
                streetAddress: stringConstants.PLEASE_FILL_THIS_FIELD,
                suite: stringConstants.PLEASE_FILL_THIS_FIELD,
                city: stringConstants.PLEASE_FILL_THIS_FIELD,
                state: stringConstants.PLEASE_FILL_THIS_FIELD,
                country: stringConstants.PLEASE_FILL_THIS_FIELD,
            });
            return isAddressFieldValid;
        }
        let errorCompanyObj = Object.assign({}, errorCompanyInfo);
        for (let key in companyInfo) {
            if (key === 'suite')
                continue;
            if (companyInfo[key]) {
                errorCompanyObj[key] = "";
            } else {
                errorCompanyObj[key] = stringConstants.PLEASE_FILL_THIS_FIELD;
                isAddressFieldValid = false;
            }
        }
        updateErrorCompanyInfo(errorCompanyObj);
        return isAddressFieldValid

    }

    function onUpdateAddress() {
        if (!validateAddressField()) {
            return;
        }
        updateLatestCompanyAddress(companyInfo);
        toggleChangeAddress(false);
    }

    function cancelAddressChange() {
        updateCompanyInfo({
            streetAddress: '',
            suite: '',
            city: '',
            state: '',
            country: ''
        });

        updateErrorCompanyInfo({
            streetAddress: '',
            suite: '',
            city: '',
            state: '',
            country: ''
        });
        toggleChangeAddress(false);

    }

    return (
        <div className="mt-3 mb-3">
            <div className="fc-blue-grey fw-500 mt-2">Street Address<label className="fc-red">*</label></div>
            <CustomInput id="streetAddress" className=" p-2 fs-13" onChange={handleInputChange} required={true}
                         placeholder=""
                         value={companyInfo.streetAddress} error={errorCompanyInfo.streetAddress} autoComplete="off"/>

            <div className="fc-blue-grey fw-500 mt-2">Suite/Unit</div>
            <CustomInput id="suite" className="p-2 fs-13" onChange={handleInputChange} placeholder=""
                         value={companyInfo.suite} error={errorCompanyInfo.suite} autoComplete="off"/>

            <Row className="mt-2">
                <div className="mr-1">
                    <div className="fc-blue-grey fw-500">City<label
                        className="fc-red">*</label></div>
                    <CustomInput id="city" className="p-2 fs-13" type="select" onChange={handleInputChange}
                                 placeholder=""
                                 value={companyInfo.city} error={errorCompanyInfo.city} required={true}
                                 autoComplete="off"/>
                </div>
                <div className="mx-1">
                    <div className=" fc-blue-grey fw-500">State<label
                        className="fc-red">*</label></div>
                    <CustomInput id="state" className=" p-2 fs-13" type="select" onChange={handleInputChange}
                                 placeholder=""
                                 value={companyInfo.state} error={errorCompanyInfo.state} required={true}
                                 autoComplete="off"/>
                </div>
                <div className="ml-1">
                    <div className=" fc-blue-grey fw-500">Country<label
                        className="fc-red">*</label></div>
                    <CustomInput id="country" className=" p-2 fs-13" type="select" onChange={handleInputChange}
                                 placeholder=""
                                 value={companyInfo.country} error={errorCompanyInfo.country} required={true}
                                 autoComplete="off"/>
                </div>
            </Row>

            <Row horizontal={"end"} className="mt-2">
                <Column>
                    <Button className="outline-none text-transform-capitalize p-2
                    br-4 b-1-white-two fc-blue mx-1 fw-500 px-3 cursor-pointer"
                            onClick={() => cancelAddressChange()}>
                        Cancel
                    </Button>
                </Column>
                <Column>
                    <Button className="outline-none text-transform-capitalize p-2
                    br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer"
                            onClick={() => onUpdateAddress()}>
                        Update
                    </Button>
                </Column>
            </Row>
            <hr/>
        </div>)
};

function BillingView(props) {
    let [isAbleToChangeAddress, toggleChangeAddress] = React.useState(false);
    let {state, handleChange, updateCompanyAddress, changeUserPassword, companyDetails} = props;

    async function updateLatestCompanyAddress(companyInfo) {
        await updateCompanyAddress(companyInfo);

    }

    return (
        <Row className="p-3 my-3">
            <div className="mx-5 m-l-15rem w-50per">
                <Column className="fc-dark-slate-blue fs-18">
                    <Row className="my-2 fw-500" horizontal={"start"}>Billing Address</Row>
                    {isAbleToChangeAddress ?
                        <UpdateAddressComponent
                            toggleChangeAddress={toggleChangeAddress}
                            state={state}
                            updateLatestCompanyAddress={updateLatestCompanyAddress}
                        />
                        :
                        <Row alignItems={"center"}>
                            <div
                                className="fs-16 fc-dark-slate-blue">{Utils.generateFullAddressString(companyDetails.billingAddress)}</div>
                            <Button
                                className="fs-16 text-transform-capitalize outline-none fc-blue mx-2 fw-500 px-3 cursor-pointer"
                                onClick={() => toggleChangeAddress(true)}>
                                Change
                            </Button>
                        </Row>
                    }

                </Column>
                <hr/>
                {renderPaymentPlan(companyDetails)}
                <hr/>
                {renderInvoiceSection(props)}
            </div>
        </Row>
    );
}

function renderPaymentPlan(companyDetails) {
    let {subscription} = companyDetails;
    return (
        <div className="fs-18 fw-500">
            <div className="fc-dark-slate-blue py-3 ">Payment Plan</div>
            {subscription ? <Row alignItems={"end"}>
                {subscription.price ? <div className="fs-22 fw-500">${subscription.price} <span
                    className="fs-14">{`/${Utils.getSubscriptionPlanText(subscription.subscriptionType)}`}</span>
                </div> : ""}
                {/*<Row alignItems={"center"}>*/}
                {/*    <Button className="outline-none text-transform-capitalize p-2 ml-5 mr-3*/}
                {/*br-4 b-1-blue fc-blue mx-1 fw-500 px-3 cursor-pointer"*/}
                {/*            onClick={() => Utils.showUnderDevelopment()}>*/}
                {/*        Change your Current Plan*/}
                {/*    </Button>*/}
                {/*    <div className="fs-12 fc-greyish-brown">or</div>*/}
                {/*    <Button className="outline-none text-transform-capitalize p-2 ml-3*/}
                {/*br-4 b-1-blue fc-blue mx-1 fw-500 px-3 cursor-pointer"*/}
                {/*            onClick={() => Utils.showUnderDevelopment()}>*/}
                {/*        Unsubscribe*/}
                {/*    </Button>*/}
                {/*</Row>*/}
            </Row> : "No Subscription Data Found!"}
        </div>
    )
}

function renderInvoiceSection(props) {
    let {state} = props;

    return (
        <div className="fw-500">
            <div className="py-3">Invoices</div>
            <Table>
                {InvoiceTableHead(props)}
                <TableBody>
                    {state && state.invoiceList && state.invoiceList.length !== 0 ? state.invoiceList.map((invoiceData, index) => (
                        RenderInvoiceCell(props, invoiceData)
                    )) : <Row className='fs-14 w-100 my-5' justifyContent={"left"}>No Invoices Found!</Row>
                    }
                </TableBody>
            </Table>
        </div>
    )
}


const InvoiceTableHead = (props) => {
    return (
        <TableHead>
            <TableRow style={{padding: 20}}>
                <TableCell className='fs-14 pl-0'>Invoice Number</TableCell>
                <TableCell className='fs-14 pl-0'>Issue Date</TableCell>
                <TableCell className='fs-14 pl-0'>Amount</TableCell>
                <TableCell className='fs-14'></TableCell>
                <TableCell className='fs-14'></TableCell>
            </TableRow>
        </TableHead>
    )
};

const RenderInvoiceCell = (props, invoiceData) => {
    return (
        <TableRow style={{padding: 20}}>
            <TableCell className='fs-14 pl-0'>{invoiceData.invoiceNumber}</TableCell>
            <TableCell className='fs-14 pl-0'>{Utils.getDateString(invoiceData.issueDate)}</TableCell>
            <TableCell className='fs-14 pl-0'>{invoiceData.price}</TableCell>
            <TableCell className='fs-14'></TableCell>
            <TableCell className='fs-14'>
                <Row className="cursor-pointer fc-blue"
                     onClick={() => props.viewInvoice(invoiceData)}>View Receipt</Row>
            </TableCell>

        </TableRow>
    )
};


function BillingComponent(props) {
    let {state, toggleSaveButton, companyDetails, handleChange, viewInvoice, onChangeEvent, onFileCancel, onFileDrop, updateCompanyAddress, updateCompanyDetailsOnSave, changeUserPassword, onLogoutClicked} = props;

    return (
        <div className="p-4 m-2 h-100">
            <Row className="fc-dark-slate-blue fs-24 font-weight-bold">Billing</Row>

            <BillingView
                companyDetails={companyDetails}
                state={state}
                handleChange={handleChange}
                onLogoutClicked={onLogoutClicked}
                toggleSaveButton={toggleSaveButton}
                updateCompanyAddress={updateCompanyAddress}
                updateCompanyDetailsOnSave={updateCompanyDetailsOnSave}
                viewInvoice={viewInvoice}
            />
        </div>)

}

export default BillingComponent;
