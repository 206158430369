import {Column, Row} from "simple-flexbox";
import React from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, LinearProgress,
    Select, withStyles
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import CustomSelectInput from "../../../common/components/CustomSelectInput";
import {genericConstants} from "../../../constants";
import Utils from "../../../utility";
import CustomButton from "../../../common/components/CustomButton";
import AvatarView from "../../../common/components/AvatarView";
import SectionCellComponent from "../../../common/components/SectionCell";
import CustomImage from "../../../common/components/CustomImage";
import {Dropdown, Input} from 'semantic-ui-react'
import CriteriaInputComponent from "../../../common/components/CriteriaInputComponent";
import {getSignedURL} from "../../../managers/awsService";
import SortingComponent from "../../../common/components/SortingComponent";
import Notification from "../../notification";
import CriteriaMenuComponent from "../../../common/components/CriteriaMenuComponent";
import MapComponent from "../../../common/components/MapView";
import Avatar from "@material-ui/core/Avatar/Avatar";

const HeaderComponent = (props) => {
    let {state, downloadReport} = props;
    let [selectReportType, updateReportType] = React.useState('Export Report');
    React.useEffect(() => {
        updateReportType(state.selectedReportType)
    }, [state.selectedReportType]);


    function onChangeEvent(event) {
        downloadReport(event.target.value);
        return;
    }

    return (
        <Row className="justify-content-between" style={{maxHeight: props.height}}>
            <div className="fs-24 font-weight-bold">Information Records</div>
            <Row vertical="center">
                <Notification/>
                <Select value={selectReportType} className="my-1 br-2 input-border" id="reportType"
                        input={<CustomSelectInput name="reportType" id="reportType"/>}
                        onChange={onChangeEvent}>
                    <MenuItem
                        value={genericConstants.REPORT_TYPE.EXPORT_REPORT_TITLE}>{genericConstants.VIEWABLE_REPORT_TYPE.EXPORT_REPORT_TITLE}</MenuItem>
                    <MenuItem
                        value={genericConstants.REPORT_TYPE.REP_EXCEPTION}>{genericConstants.VIEWABLE_REPORT_TYPE.REP_EXCEPTION}</MenuItem>
                    <MenuItem
                        value={genericConstants.REPORT_TYPE.SERVICING_ON_BOARDING}>{genericConstants.VIEWABLE_REPORT_TYPE.SERVICING_ON_BOARDING_TITLE}</MenuItem>
                    <MenuItem
                        value={genericConstants.REPORT_TYPE.OWNER_BORROWER}>{genericConstants.VIEWABLE_REPORT_TYPE.OWNER_BORROWER_TITLE}</MenuItem>
                    <MenuItem
                        value={genericConstants.REPORT_TYPE.DUE_DILIGENCE}>{genericConstants.VIEWABLE_REPORT_TYPE.DUE_DILIGENCE_TITLE}</MenuItem>
                    <MenuItem
                        value={genericConstants.REPORT_TYPE.REPO_REVIEW}>{genericConstants.VIEWABLE_REPORT_TYPE.REPO_REVIEW_TITLE}</MenuItem>
                </Select>
                <img src="/images/more-icon.svg" className="p-1 mx-1 cursor-pointer"/>
            </Row>
        </Row>
    )
};

const recordDetailHeaderComponent = (props) => {
    return (
        <Row className="justify-content-between" style={{maxHeight: props.height}}>
            <div className="display-flex flex-direction-row align-items-center">
                <img src="/images/back-icon.svg"/>
                <div className="fs-24 font-weight-bold ml-4">Record Details</div>
            </div>
            <Row vertical="center">
                <img src="/images/notification-bell.svg"
                     className="p-2 br-4 b-1-white-two bg-white-two mx-1 cursor-pointer"/>
                <img src="/images/more-icon.svg" className="p-1 mx-1 cursor-pointer"/>
            </Row>
        </Row>
    )
};

const BorderLinearProgress = withStyles({
    root: {
        height: 6,
        backgroundColor: '#dfe2e8',
        borderRadius: '3px',
        width: '100%'
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#0052cc',
    },
})(LinearProgress);

const RecentlyViewedCell = (Obj, index, props) => {
    return (
        <Column className="br-4 b-1-white-two m-2 max-w-250 h-160px w-100-per cursor-pointer justify-content-between"
                key={index}
                onClick={() => props.onRecordClicked(props.userDetails.miscellaneous.recentlyVisitedRecord[index])}>
            <img src={getSignedURL(Obj.propertyPhoto)} placeholder={"/images/recent-companies.svg"}
                 className="max-h-130 w-100-per px-2 pt-2"/>
            <Row className="p-1 justify-content-between" vertical="center">
                <div className="w-75">
                    <BorderLinearProgress
                        variant="determinate"
                        color="secondary"
                        value={Obj && Object.keys(Obj).length > 0 && Obj.progress ? parseInt(Obj.progress) : 0}
                    />
                </div>
                <div className="fs-12 w-25 fc-warm-grey text-right">{Utils.epocToPrettyTime(Obj.accessedOn)}</div>
            </Row>
        </Column>
    )
};

const RecentlyViewedComponent = (props) => {
    let recentlyVisitedRecord = props.userDetails && props.userDetails.miscellaneous && props.userDetails.miscellaneous.recentlyVisitedRecord && props.userDetails.miscellaneous.recentlyVisitedRecord.length > 0 ?
        props.userDetails.miscellaneous.recentlyVisitedRecord : [];
    return (
        <Column>
            <div className="fs-15 fc-blue-grey  my-2">Recently Accessed</div>
            <Row>
                {recentlyVisitedRecord && recentlyVisitedRecord.length > 0 ?
                    recentlyVisitedRecord.map((obj, index) => RecentlyViewedCell(obj, index, props))
                    : ""}
            </Row>
        </Column>
    )
};

const SectionComponent = (props) => {

    return (
        <div className={"display-flex flex-direction-column mt-4"}>
            <SectionCellComponent/>
        </div>
    )
};

const MapViewSelectionComponent = (props) => {
    let {isMapViewRequested} = props.state;

    return (<div className=" outline-none
                       cursor-pointer position-absolute bottom-10 right-20"
                 onClick={() => props.toggleMapView()}>
        <Avatar
            src={isMapViewRequested ? "/images/list-view.svg" : "/images/map-view.svg"}
            className="w-60 h-60px"/>
    </div>)
};

const renderMapView = (props) => {
    return (
        <div>
            <MapComponent {...props}/>
        </div>
    )
};

const RecordsTableComponent = (props) => {
    const {updateSelectedRecordList, onRecordClicked, state, updateInvitation, deleteInvitation, onSortClicked, toggleAll, handleOnSelect, toggleEmployeeDetailView, onCheckAdmin, onSearchTextChanged, handleFilter} = props;
    let {filteredList: recordList, isMapViewRequested} = state;

    let [sortingState, updateSortingState] = React.useState({fileNo: null, pool: null, status: null, completion: null});
    const [selected, setSelected] = React.useState([]);

    function isSelected(recordId) {
        return selected.indexOf(recordId) !== -1
    }

    function onDescendingSort(key) {
        onSortClicked(key, genericConstants.SORTING_ORDER.DESCENDING);
        updateSortingState({[key]: genericConstants.SORTING_ORDER.DESCENDING});
    }

    function onAscendingSort(key) {
        onSortClicked(key, genericConstants.SORTING_ORDER.ASCENDING);
        updateSortingState({[key]: genericConstants.SORTING_ORDER.ASCENDING});
    }

    function selectRecord(event, name) {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        updateSelectedRecordList(newSelected);

    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = recordList.map(n => n.recordId);
            setSelected(newSelecteds);
            updateSelectedRecordList(newSelecteds);
            return;
        }
        setSelected([]);
        updateSelectedRecordList([]);
    };
    return (
        <>
            {!isMapViewRequested ? <div className="my-2 overflow-x-auto">
            <div className="display-flex ml-lg-4 mt-4"><span
                className="fw-900 fc-blue mr-2">{recordList && recordList.length}</span> records found
            </div>
            {recordList ?
                <Column className="bg-white h-100-per fs-15">
                    <Table size="small" className='br-1 p-1'>
                        <TableHead className="data-table fw-500">
                            <TableRow>
                                <TableCell padding="checkbox" style={{width: 12}} className={'p-none'}>
                                    <Checkbox className='table-checkbox'
                                              onClick={event => handleSelectAllClick(event)}
                                    />
                                </TableCell>
                                <TableCell component="th" className={'p-none'}>
                                    <Row vertical={"center"}>File Number
                                        <SortingComponent state={sortingState.fileNo}
                                                          onDescendingSort={() => onDescendingSort("fileNo")}
                                                          onAscendingSort={() => onAscendingSort("fileNo")}/>
                                    </Row>
                                </TableCell>
                                <TableCell component="th" className={'p-none'}>
                                    <Row vertical={"center"}>Loan Tag
                                        <SortingComponent state={sortingState.loanTag}
                                                          onDescendingSort={() => onDescendingSort("loanTag")}
                                                          onAscendingSort={() => onAscendingSort("loanTag")}/>
                                    </Row>
                                </TableCell>
                                <TableCell component="th" className={'p-none'}>Location</TableCell>
                                <TableCell component="th" className={'p-none'}>
                                    <Row vertical={"center"}>Pool
                                        <SortingComponent state={sortingState['pool.name']}
                                                          onDescendingSort={() => onDescendingSort("pool.name")}
                                                          onAscendingSort={() => onAscendingSort("pool.name")}/>
                                    </Row>
                                </TableCell>
                                <TableCell component="th" className={'p-none'}>Completion</TableCell>
                                <TableCell component="th" className={'p-none'}>
                                    <Row vertical={"center"}>Status
                                        <SortingComponent state={sortingState.status}
                                                          onDescendingSort={() => onDescendingSort("status")}
                                                          onAscendingSort={() => onAscendingSort("status")}/>
                                    </Row>
                                </TableCell>
                                <TableCell component="th" className={'p-none'}>Lender</TableCell>
                                <TableCell component="th" className={'p-none'}>Origination Counsel</TableCell>
                                <TableCell component="th" className={'p-none'}>Counsel Sub-Users</TableCell>
                                <TableCell component="th" className={'p-none'}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={'data-table'}>
                            {recordList.map((record, index) => {
                                const isItemSelected = isSelected(record.recordId);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow role="checkbox" key={index} aria-checked={isItemSelected} tabIndex={-1}
                                        // onClick={() => onRecordClicked(record)}
                                              className="cursor-pointer"
                                              selected={false}>
                                        <TableCell padding="checkbox" className={'p-none'}>
                                            <Checkbox checked={isItemSelected} className={'table-checkbox'}
                                                      onClick={event => selectRecord(event, record.recordId)}
                                                      inputProps={{'aria-label': labelId}}/>
                                        </TableCell>
                                        <TableCell component="td" id={labelId} scope="row"
                                                   onClick={() => onRecordClicked(record)}
                                                   padding="none">
                                            {record.fileNo ? record.fileNo : ""}
                                        </TableCell>
                                        <TableCell className="max-w-250" component="td" id={labelId} scope="row"
                                                   onClick={() => onRecordClicked(record)}
                                                   padding="none">
                                            {record.loanTag ? record.loanTag : ""}
                                        </TableCell>
                                        <TableCell className="max-w-250" component="td" id={labelId} scope="row"
                                                   onClick={() => onRecordClicked(record)}
                                                   padding="none">
                                            {record.address ? Utils.generateSortAddressString(record.address) : ""}
                                        </TableCell>
                                        <TableCell component="td" id={labelId} scope="row"
                                                   onClick={() => onRecordClicked(record)}
                                                   padding="none">
                                            {record.pool && record.pool.name && record.pool.name.trim().length > 0? record.pool.name :
                                                <p className="fc-dark-slate-blue-5opacity m-0">{"Not Assigned"}</p>}
                                        </TableCell>
                                        <TableCell component="td" id={labelId} scope="row"
                                                   onClick={() => onRecordClicked(record)}
                                                   padding="none">
                                            <BorderLinearProgress
                                                variant="determinate"
                                                color="secondary"
                                                value={record && Object.keys(record).length > 0 && record.progress ? parseInt(record.progress) : 0}
                                            />
                                        </TableCell>
                                        <TableCell component="td" id={labelId} scope="row"
                                                   onClick={() => onRecordClicked(record)}
                                                   padding="none">
                                            {record.status ? Utils.viewSectionStatus(record.status) : genericConstants.SECTION_STATUS_FOR_VIEW.OPEN}
                                        </TableCell>
                                        <TableCell component="td" id={labelId} scope="row"
                                                   onClick={() => onRecordClicked(record)}
                                                   padding="none">
                                            <div className="display-flex flex-direction-row align-items-center">
                                                <AvatarView
                                                    source={record && record.lender ? getSignedURL(record.lender.logo, "/images/company-placeholder.svg") : "/images/company-placeholder.svg"}
                                                    class="p-1 mr-2"/>
                                                {record.lender ? record.lender.name ? record.lender.name : "" : ""}
                                            </div>
                                        </TableCell>
                                        <TableCell component="td" id={labelId} scope="row"
                                                   onClick={() => onRecordClicked(record)}
                                                   padding="none">
                                            <div className="display-flex flex-direction-row align-items-center">
                                                <AvatarView
                                                    source={record && record.originationCounsel ? getSignedURL(record.originationCounsel.logo, "/images/company-placeholder.svg") : "/images/company-placeholder.svg"}
                                                    class="p-1 mr-2"/>
                                                {record && record.originationCounsel && record.originationCounsel.name ? record.originationCounsel.name : ""}
                                            </div>
                                        </TableCell>
                                        <TableCell component="td" id={labelId} scope="row"
                                                   padding="none">
                                            <div
                                                className="display-flex flex-direction-row mr-2">{record.subSellerCounsel && record.subSellerCounsel.length > 0 ? record.subSellerCounsel.map((counsel, index) => {
                                                return (
                                                    <AvatarView id={index}
                                                                source={counsel.photo && typeof counsel.photo === 'string' ? getSignedURL(counsel.photo, "/images/user-placeholder.svg") : "/images/user-placeholder.svg"}
                                                                class="m-1"/>
                                                )
                                            }) : <p className="fc-dark-slate-blue-5opacity m-0">Not Assigned</p>}</div>
                                        </TableCell>
                                        <TableCell component="td" id={labelId} scope="row"
                                                   padding="none">
                                            {record.sellerCounsel ?
                                                (record.sellerCounsel.invitationStatus === genericConstants.INVITATION_STATUS.INVITED || record.sellerCounsel.invitationStatus === genericConstants.INVITATION_STATUS.PENDING) ?
                                                    <CustomButton
                                                        class={"my-2 outline-none text-transform-capitalize accept-button cursor-pointer"}
                                                        buttonText={"Accept"}
                                                        onClickHandler={props.onAcceptHandler(record, genericConstants.INVITATION_STATUS.ACCEPTED)}
                                                    /> : (record.sellerCounsel.invitationStatus === genericConstants.INVITATION_STATUS.ACCEPTED || record.sellerCounsel.invitationStatus === genericConstants.INVITATION_STATUS.ACTIVE) ?
                                                    <img src="/images/edit-icon.svg" alt="edit-logo"
                                                         onClick={() => props.onEditClick(record)}/> : ""
                                                : ""}
                                        </TableCell>

                                    </TableRow>)
                            })}
                        </TableBody>
                    </Table>
                </Column>
                : <div className="p-5 mx-4 mb-4 jc-center flex-container bg-color-white">
                    No record found
                </div>}
        </div> : renderMapView(props)}
        </>
    );
};

const renderMenuItem = (value, index) => {
    if (!value)
        return "";
    return <MenuItem className="p-2 cursor-pointer outline-none" value={value} key={index}>{value}</MenuItem>
};

const criteriaComponentView = (props) => {
    if (!props.state || !props.state.selectedCriteria || props.state.selectedCriteria.length < 1)
        return "";
    return renderCriteriaCells(props);
};

const renderCriteriaCells = (props) => {
    return (
        <div className="display-flex flex-direction-row w-100 flex-wrap">
            {props.state.selectedCriteria.map((data, index) => {
                let propData = {...props, data: data, index};
                return <CriteriaInputComponent {...propData}/>
            })}
        </div>
    );
};

const SearchFilterComponent = (props) => {
    let {state} = props;
    return (
        <Column>
            <Row className="bg-color-white fs-12 mt-4" vertical="center">
                <Row className="br-2 bg-pale-grey p-2 b-1-pale-grey-three">
                    <input placeholder={'Search Records'} id={'search'} onChange={props.onSearch}
                           className="outline-none b-none bg-pale-grey fw-500"/>
                    <img className="mx-2" src="/images/search-icon.svg" alt="search"/>
                </Row>
                <Select value={state.locationFilter}
                        className="br-2 mx-2 fc-warm-grey"
                        onChange={props.onFilterChanged}
                        input={<CustomSelectInput name="locationFilter"/>}>
                    <MenuItem className="p-2 cursor-pointer outline-none"
                              value={genericConstants.INITIAL_FILTER_VALUES.LOCATION}>
                        {genericConstants.INITIAL_FILTER_VALUES.LOCATION}
                    </MenuItem>
                    {state && state.dataFilter && Object.keys(state.dataFilter).length > 0 && state.dataFilter.state && Object.keys(state.dataFilter.state).length > 0 ?
                        (state.dataFilter.state).map((key, index) => {
                            return renderMenuItem(key, index)
                        }) : ""}
                </Select>
                <Select value={state.lenderFilter}
                        className="br-2 mx-2 fc-warm-grey"
                        onChange={props.onFilterChanged}
                        input={<CustomSelectInput name="lenderFilter"/>}>
                    <MenuItem className="p-2 cursor-pointer outline-none"
                              value={genericConstants.INITIAL_FILTER_VALUES.LENDER}>
                        {genericConstants.INITIAL_FILTER_VALUES.LENDER}
                    </MenuItem>
                    {state && state.dataFilter && Object.keys(state.dataFilter).length > 0 && state.dataFilter.lender && Object.keys(state.dataFilter.lender).length > 0 ?
                        Object.keys(state.dataFilter.lender).map((key, index) => {
                            return renderMenuItem(state.dataFilter.lender[key], index)
                        }) : ""}
                </Select>
                <Select value={state.poolFilter}
                        className="br-2 mx-2 fc-warm-grey"
                        onChange={props.onFilterChanged}
                        input={<CustomSelectInput name="poolFilter"/>}>
                    <MenuItem className="p-2 cursor-pointer outline-none"
                              value={genericConstants.INITIAL_FILTER_VALUES.POOL}>
                        {genericConstants.INITIAL_FILTER_VALUES.POOL}
                    </MenuItem>
                    {state && state.dataFilter && Object.keys(state.dataFilter).length > 0 && state.dataFilter.pool && Object.keys(state.dataFilter.pool).length > 0 ?
                        Object.keys(state.dataFilter.pool).map((key, index) => {
                            return renderMenuItem(state.dataFilter.pool[key], index)
                        }) : ""}
                </Select>
                <Select value={state.statusFilter}
                        className="br-2 mx-2 fc-warm-grey"
                        onChange={props.onFilterChanged}
                        input={<CustomSelectInput name="statusFilter"/>}>
                    <MenuItem className="p-2 cursor-pointer outline-none"
                              value={genericConstants.INITIAL_FILTER_VALUES.STATUS}>
                        {genericConstants.INITIAL_FILTER_VALUES.STATUS}
                    </MenuItem>
                    {state && state.dataFilter && Object.keys(state.dataFilter).length > 0 && state.dataFilter.status && Object.keys(state.dataFilter.status).length > 0 ?
                        Object.keys(state.dataFilter.status).map((key, index) => {
                            return renderMenuItem(state.dataFilter.status[key], index)
                        }) : ""}
                </Select>
                <CriteriaMenuComponent {...props}/>
            </Row>


        </Column>
    )
};


const informationRecordsComponent = (props) => {
    return (
        <Column className="min-vh-100 p-4 fc-dark-slate-blue" style={{maxHeight: props.height}}>
            {props.state.recordId ? recordDetailHeaderComponent(props) : HeaderComponent(props)}
            {RecentlyViewedComponent(props)}
            {props.state.recordId ? "" : SearchFilterComponent(props)}
            {criteriaComponentView(props)}
            {props.state.recordId ? SectionComponent(props) : RecordsTableComponent(props)}
            {MapViewSelectionComponent(props)}
        </Column>
    )
};
export default informationRecordsComponent;
