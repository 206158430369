import {httpService} from "../managers/httpService";
import {httpConstants} from "../constants";

export default {
    registerDevice,
    updateUser,
    loginUser,
    logoutUser,
    registerUser,
    getUserRoles,
    getUserList,
    getUserDetails,
    resetPassword,
    changePassword,
    deleteUser,
    getUserListByQueryObj,
    getUserPermissions,
    recoverPassword
}

function getHeaders() {
    return {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'skip': true}
}

async function updateUser(requestData) {
    let url = process.env.REACT_APP_USER_SERVICE_URL + httpConstants.API_END_POINT.UPDATE_USER;
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {

                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};

async function registerDevice(requestData) {
    let url = process.env.REACT_APP_USER_SERVICE_URL + httpConstants.API_END_POINT.REGISTER_DEVICE;
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {

                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};

async function registerUser(requestData) {
    let url = process.env.REACT_APP_USER_SERVICE_URL + httpConstants.API_END_POINT.REGISTER_USER;
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {

                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject(response.message);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};

async function loginUser(requestData) {
    let url = process.env.REACT_APP_USER_SERVICE_URL + httpConstants.API_END_POINT.LOGIN;
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {

                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};
async function logoutUser(requestData) {
    let url = process.env.REACT_APP_USER_SERVICE_URL + httpConstants.API_END_POINT.LOGOUT;
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {

                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};

async function getUserRoles(requestData) {
    let url = process.env.REACT_APP_USER_SERVICE_URL + httpConstants.API_END_POINT.GET_ROLE_LIST;
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), {}, url)
        .then(
            response => {

                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};


async function getUserDetails(requestData) {
    let url = process.env.REACT_APP_USER_SERVICE_URL + httpConstants.API_END_POINT.GET_USER_DETAILS;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {

                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};

async function getUserList(queryObj) {
    let url = process.env.REACT_APP_USER_SERVICE_URL + httpConstants.API_END_POINT.GET_USER_LIST;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, queryObj, url)
        .then(
            response => {

                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};

async function deleteUser(queryObj) {
    let url = process.env.REACT_APP_USER_SERVICE_URL + httpConstants.API_END_POINT.DELETE_USER;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, queryObj, url)
        .then(
            response => {

                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};

async function changePassword(requestData) {
    let url = process.env.REACT_APP_USER_SERVICE_URL + httpConstants.API_END_POINT.CHANGE_PASSWORD;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {

                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};

async function resetPassword(requestData) {
    let url = process.env.REACT_APP_USER_SERVICE_URL + httpConstants.API_END_POINT.RESET_PASSWORD;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {

                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};

async function getUserListByQueryObj(requestData) {
    let url = process.env.REACT_APP_USER_SERVICE_URL + httpConstants.API_END_POINT.GET_USER_LIST_BY_QUERY_OBJ;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {

                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
};

async function getUserPermissions(requestData) {
    requestData = {...requestData, applicationID: process.env.REACT_APP_APPLICATION_ID};
    let url = process.env.REACT_APP_USER_SERVICE_URL + httpConstants.API_END_POINT.GET_PERMISSION_LIST;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {

                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function recoverPassword(requestData) {
    let url = process.env.REACT_APP_USER_SERVICE_URL + httpConstants.API_END_POINT.FORGOT_PASSWORD;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
