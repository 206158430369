import {Column, Row} from "simple-flexbox";
import React from "react";
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails
} from "@material-ui/core"
import {ExpandMoreTwoTone} from '@material-ui/icons';
import Utils from "../../../utility";
import ConfirmationPopUp from "../../../common/components/ConfirmationPopUp";
import {stringConstants} from "../../../constants";


const viewCategorisedDocumentList = (props) => {
    let {documentList, openCategoryTypeDocumentList} = props;
    return (
        <Row className="display-flex justify-content-around" wrap={true}>
            {documentList && Object.keys(documentList).length > 0 ?
                Object.keys(documentList).map((document, index) => {
                    return (
                        documentList[document][stringConstants.DEFAULT]?
                            <div className="pt-2 pb-2 cursor-pointer"
                                 onClick={() => openCategoryTypeDocumentList(document)}>
                                <div className="text-center"><img src="/images/folder-icon.svg" className="w-65"/></div>
                                <div className="fs-12 fc-dark-slate-blue font-weight-normal">
                                    {`${document} (${documentList[document][stringConstants.DEFAULT] ? documentList[document][stringConstants.DEFAULT].length : Object.keys(documentList[document]).length})`}
                                </div>
                            </div>
                            :null
                    )
                })
                : ""}
        </Row>
    )

}

const ViewCategoryTypeDocumentList = (props) => {
    let {openCategoryDocumentList, state, openExpendableDocumentList, closeExpendableDocumentList} = props;
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = panel => (event, isExpanded) => {
        console.log("panel", panel)
        console.log("isExpanded", isExpanded)
        console.log("event", event)
        if (isExpanded)
            openExpendableDocumentList(panel);
        else
            closeExpendableDocumentList();
        setExpanded(isExpanded ? panel : false);

    };
    return (
        <Column className="display-flex justify-content-around">
            <Row className="fs-12 font-weight-bold">
                <Column className="p-2"><img onClick={() => openCategoryDocumentList()}
                                             src="/images/back-icon.svg" className="w-20px"/></Column>
                <Column className="p-1">
                    <img src="/images/folder-icon.svg" className="w-35px pr-2"/>
                </Column>
                <Column className="fs-12 fc-dark-slate-blue font-weight-normal p-2">{state.selectedCategory}</Column>
            </Row>
            {state.categoryTypeDocumentList && Object.keys(state.categoryTypeDocumentList).length > 0 ?
                Object.keys(state.categoryTypeDocumentList).map((documentType) => {
                    return (
                        <div>
                            {documentType === stringConstants.DEFAULT ?
                                getExpensionPanelDetails(props)
                                :
                                <ExpansionPanel expanded={expanded === `${documentType}`}
                                                onChange={handleChange(documentType)}
                                                className=" bg-pale-grey box-shadow-none">
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreTwoTone/>}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className="pl-2 pr-2 min-h-0"
                                    >
                                        <div
                                            className="fs-12 fc-dark-slate-blue font-weight-normal">{documentType}</div>
                                    </ExpansionPanelSummary>
                                    {getExpensionPanelDetails(props)}
                                </ExpansionPanel>
                            }

                        </div>
                    )
                }) : ""
            }

        </Column>
    )
};

const getExpensionPanelDetails = (props) => {
    let {state, openCategoryTypeDocumentList, viewDocument, toggleConfirmationPopUp} = props;
    return (
        <Row className="display-flex justify-content-around" wrap={true}>
            {state.expendableDocumentList && state.expendableDocumentList.length > 0 ?
                state.expendableDocumentList.map((document, index) => {
                    return (
                        <ExpansionPanelDetails className="w-100-per pl-2 pr-2 pt-1 pb-1">
                            <Row className="pt-1 pb-1 cursor-pointer bg-white justify-content-between w-100-per"
                                 onClick={() => openCategoryTypeDocumentList(document, index)}>
                                <Column className="fs-12 fc-dark-slate-blue font-weight-normal pl-2">
                                    {document.name}
                                </Column>
                                <Row className="cursor-pointer">
                                    <div onClick={() => viewDocument(document)}><img src="/images/visible-icon.svg"
                                                                                     width={16}/></div>
                                    <div className="pl-1 pr-1" onClick={() => toggleConfirmationPopUp(document)}><img
                                        src="/images/delete.svg" width={16}/></div>
                                </Row>

                            </Row>
                        </ExpansionPanelDetails>

                    )

                })
                : ""}
        </Row>
    )
}


const documentManagementComponent = (props) => {
    let {state, toggleConfirmationPopUp, deleteDocument} = props;

    function closePopup() {
        toggleConfirmationPopUp();
    }

    function deletedDocument() {
        deleteDocument();
    }

    return (
        <div className="w-100-per">
            {state.categoryTypeDocumentList && Object.keys(state.categoryTypeDocumentList).length > 0 ?
                ViewCategoryTypeDocumentList(props)
                : viewCategorisedDocumentList(props)}
            {state.isConfirmationPopUpOpen ?
                <ConfirmationPopUp
                    isConfirmationPopUpOpen={state.isConfirmationPopUpOpen}
                    headerMessage={state.headerMessage}
                    closePopup={closePopup}
                    onYesClicked={deletedDocument}
                /> : ""}
        </div>
    )
};
export default documentManagementComponent;