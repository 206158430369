import React from "react";
import BaseComponent from '../../modules/baseComponent'
import {genericConstants} from "../../constants";
import Utils from "../../utility";
import {Column, Row} from "simple-flexbox";
import {Button} from "@material-ui/core";

class OptionsComponent extends BaseComponent {
    constructor(props) {
        super(props);
        let answerState = this.props.questionObject ? this.props.questionObject.answer : null;

        let optionID = this.props.optionObj ? this.props.optionObj._id : "";

        this.state = {
            answer: this.props.questionObject ? this.props.questionObject.answer : null,
            isSaveButtonVisible: false,
            userInput: (answerState.id === optionID) ? answerState.value : "",
            description: answerState && answerState.id === optionID ? answerState.value : "",
            selectedCheckBox: (answerState.id === optionID)
        };
    }

    handleUserInput = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };

    onChangeEvent = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };

    toggleCheckbox = () => {
        this.setState({selectedCheckBox: !this.state.selectedCheckBox});
    };

    toggleSaveButtonVisibility = () => {
        this.setState({isSaveButtonVisible: !this.state.isSaveButtonVisible});
    };

    async updateState(key, value) {
        this.setState({[key]: value});


        if (key === "selectedCheckBox") {
            await this.setState({answer: {...this.state.answer, id: value}});
            this.toggleSaveButtonVisibility();
        }
        if (key === "isSaveButtonVisible") {
            this.toggleCheckbox();
            await this.setState({answer: {...this.state.answer, value: this.state.userInput}});
        }

        /*
        if (key === "userInputCheckbox") {
            //this.updateState("userInput", null);
            Utils.consoleLogger("userInputOnDescriptionCheckbox");
        }*/
    }

    saveAnswer = async (questionObject, optionObj) => {
        let {submitAnswer} = this.props;
        await this.setState({answer: {id: optionObj._id, value: this.state.description, type: optionObj.type}});
        await submitAnswer(questionObject, this.state.answer);
        this.toggleSaveButtonVisibility();
    };

    renderDescriptiveOptionView = (props) => {
        let {optionObj, questionObject, isDisabled, answer} = props;
        return (
            <Column vertical="start" horizontal="start" className="w-100 flex-wrap">
                <Row vertical={"start"} className="m-2">
                    <Row vertical="center">
                        <input type="checkbox" name="answer"
                               onClick={() => {
                                   this.updateState("selectedCheckBox", optionObj._id);
                               }}
                               checked={answer && answer.id === optionObj._id}/>
                        <div className="mx-2">{optionObj.value}</div>
                    </Row>
                    {isDisabled ? <div>{this.state.description ? this.state.description : "-"}</div> :
                        <textarea id="description" className="outline-none input-border p-2 w-100 mx-1 h-175px textarea-resize-both"
                                  onChange={this.handleUserInput}
                                  value={this.state.isSaveButtonVisible || (answer && answer.id === optionObj._id) ? this.state.description : ""}
                                  onBlur={() => this.saveAnswer(questionObject, optionObj)}
                                  onFocus={() => {
                                      this.updateState("isSaveButtonVisible", true);
                                      this.setState({selectedCheckBox: optionObj._id})
                                  }}/>}
                </Row>
                {this.state.isSaveButtonVisible ?
                    <Row className="display-flex align-content-end my-2" horizontal={"end"}>
                        <div>
                            <Button onClick={() => {
                                this.updateState("isSaveButtonVisible", false);
                                this.setState({selectedCheckBox: null})
                            }}
                                    className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-pale-grey fc-brownish-grey mx-1 fw-500 px-3 cursor-pointer">
                                Cancel
                            </Button>
                            <Button onClick={() => this.saveAnswer(questionObject, optionObj)}
                                    className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 px-3 cursor-pointer">
                                Save
                            </Button>
                        </div>
                    </Row> : null}
            </Column>
        );
    };

    render() {
        return (
            <div key={this.props.key} className="mr-2">
                {this.renderDescriptiveOptionView(this.props)}
            </div>
        );
    }
}


export default OptionsComponent;