import React from "react";
import Utils from "../../utility";
import {getSignedURL} from "../../managers/awsService";

const recordInformationCard = (props) => {
    Utils.descSortOnCriteria(props.publishInfo, "publishedOn");
    return (
        <div className='display-flex flex-direction-row justify-content-between p-4 b-1-pale-grey-three mt-1 w-100 min-h-300'>
            <div className="display-flex flex-direction-row">
                <img src={props.propertyPhoto ? getSignedURL(props.propertyPhoto, "/images/company-placeholder.svg") : "/images/company-placeholder.svg"} alt='company-logo' className="justify-content-start w-200 max-h-200"/>
                <div className=" flex-direction-column justify-content-between align-items-center p-h-sm m-r-10rem ">
                    <div className="fs-18">File# {props.fileNo ? props.fileNo : ""}</div>
                    <div className="fs-22 fw-500">{props.address ? Utils.generateSortAddressString(props.address) : ""}</div>
                    <div className="fs-16 fc-dark-grey ">Last
                        published: {props.publishInfo && props.publishInfo.length > 0 ? Utils.epochToDate(props.publishInfo[0].publishedOn, "DD MMM YYYY"): "Unpublished"}</div>
                    <div className="fs-16 fc-dark-grey">Last
                        Updated: {props.modifiedOn ? Utils.epochToDate(props.modifiedOn, "DD MMM YYYY") : "NA"}</div>
                </div>
            </div>
        </div>
    )
};

export default recordInformationCard;