import {Column, Row} from "simple-flexbox";
import React from "react";
import {Button, Select, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import CustomSelectInput from "../../../common/components/CustomSelectInput";
import MenuItem from "@material-ui/core/MenuItem";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import {genericConstants, pathConstants, stringConstants} from "../../../constants";
import Utility from "../../../utility";
import Notification from "../../notification";
import {history} from "../../../managers/history";
import SortingComponent from "../../../common/components/SortingComponent";

const SearchFilterComponent = (props) => {
    function searchInList(event) {
        props.counselFilteredListSetValues(event.target.id, event.target.value);
    }

    const {state} = props;
    return (
        <Column>
            <Row className="bg-color-white fs-12 mt-4" vertical="center">
                <Row className="br-2 bg-pale-grey p-2 b-1-pale-grey-three">
                    <input placeholder={'Search Request'} name={'searchText'}
                           onChange={props.onSearchTextChanged}
                           className="outline-none b-none fc-bluey-grey bg-pale-grey fw-500"/>
                    <img className="mx-2" src="/images/search-icon.svg" alt="search"/>
                </Row>
                <Select value={props.state.locationFilter} className="br-2 mx-3 fc-warm-grey"
                        style={{borderBottom: '0px', transition: 'none'}}
                        onChange={props.onFilterChanged}
                        input={<CustomSelectInput name="locationFilter"/>}>
                    <MenuItem className="p-2 cursor-pointer outline-none"
                              value={genericConstants.INITIAL_FILTER_VALUES.LOCATION}>
                        {genericConstants.INITIAL_FILTER_VALUES.LOCATION}
                    </MenuItem>
                    {state && state.dataFilter && Object.keys(state.dataFilter).length > 0 && state.dataFilter.state && Object.keys(state.dataFilter.state).length > 0 ?
                        (state.dataFilter.state).map((key, index) => {
                            return renderMenuItem(key, index)
                        }) : ""}
                </Select>
                <Select value={props.state.statusFilter} className="br-2 mx-3 fc-warm-grey"
                        style={{borderBottom: '0px', transition: 'none'}}
                        onChange={props.onFilterChanged}
                        input={<CustomSelectInput name="statusFilter"/>}>
                    <MenuItem className="p-2 cursor-pointer outline-none"
                              value={genericConstants.INITIAL_FILTER_VALUES.STATUS}>
                        {genericConstants.INITIAL_FILTER_VALUES.STATUS}
                    </MenuItem>
                    {state && state.dataFilter && Object.keys(state.dataFilter).length > 0 && state.dataFilter.status && Object.keys(state.dataFilter.status).length > 0 ?
                        Object.keys(state.dataFilter.status).map((key, index) => {
                            return renderMenuItem(state.dataFilter.status[key], index)
                        }) : ""}
                </Select>
            </Row>
        </Column>
    )
};

const renderMenuItem = (value, index) => {
    if (!value)
        return "";
    return <MenuItem className="p-2 cursor-pointer outline-none text-capitalize" value={value}
                     key={index}>{value}</MenuItem>
};

const PublishRequestTableComponent = (props) => {
    const {handlePageChange, state, roleType} = props;
    const {filteredList: counselList} = state;

    let [sortingState, updateSortingState] = React.useState({
        name: null,
        invitationStatus: null,
        "address.city": null
    });

    return (
        <div className="my-2 overflow-x-auto">
            {state.data && state.data.length > 0 ?
                <Column className="bg-white h-100-per fs-15">
                    <Table>
                        {getRoleBasedRecordTableHeader(roleType, props, sortingState, updateSortingState)}
                        <TableBody>
                            {counselList?.length !== 0 ? counselList.map((counsels, index) => (
                                getRoleBasedRecordTableBody(roleType, props, counsels, index)
                            )) : null
                            }
                        </TableBody>
                    </Table>

                </Column>
                : <div className="p-5 mx-4 mb-4 jc-center flex-container bg-color-white">
                    {'No Request Found'}
                </div>}
            {state.filteredList && state.filteredList.length > 0 ?
                <div>
                    <Pagination style={{marginTop: 20}}
                                onChange={(pn) => handlePageChange(pn)}
                                current={state.activePage}
                                total={state.filteredList ? state.filteredList.length : 0}
                                pageSize={10}
                    />
                </div>
                :
                ""}
        </div>
    );
};


const getRoleBasedRecordTableHeader = (role, props, sortingState, updateSortingState) => {
    const {state, onSortClicked} = props;

    function onDescendingSort(key) {
        onSortClicked(key, genericConstants.SORTING_ORDER.DESCENDING);
        updateSortingState({[key]: genericConstants.SORTING_ORDER.DESCENDING});
    }

    function onAscendingSort(key) {
        onSortClicked(key, genericConstants.SORTING_ORDER.ASCENDING);
        updateSortingState({[key]: genericConstants.SORTING_ORDER.ASCENDING});
    }

    return (<TableHead>
        <TableRow style={{padding: 20}}>
            <TableCell className='fs-14'>
                <Row vertical="center">
                    Company Name
                    <SortingComponent
                        state={sortingState["publishedBy.name"]}
                        onDescendingSort={() => onDescendingSort("publishedBy.name")}
                        onAscendingSort={() => onAscendingSort("publishedBy.name")}
                    />
                </Row>
            </TableCell>
            <TableCell className='fs-14'>
                <Row>
                    File Number
                </Row>
            </TableCell>
            <TableCell className='fs-14'>
                <Row>
                    Loan Tag
                </Row>
            </TableCell>
            <TableCell className='fs-14'>
                <Row vertical="center">
                    Location
                    <SortingComponent
                        state={sortingState.fileNo}
                        onDescendingSort={() => onDescendingSort("fileNo")}
                        onAscendingSort={() => onAscendingSort("fileNo")}
                    />
                </Row>
            </TableCell>
            <TableCell className='fs-14'>
                <Row vertical="center">
                    Request Date
                    <SortingComponent
                        state={sortingState.addedOn}
                        onDescendingSort={() => onDescendingSort("addedOn")}
                        onAscendingSort={() => onAscendingSort("addedOn")}
                    />
                </Row>
            </TableCell>
            <TableCell></TableCell>
        </TableRow>
    </TableHead>);
};
const getRoleBasedRecordTableBody = (role, props, counsels, index) => {
    const {onReviewClick} = props;
    return (<TableRow className='h-60px' key={index}>
        <TableCell className='fs-15 fc-blue-grey'>{counsels.publishedBy.name}</TableCell>
        <TableCell className='fs-15 fc-blue-grey'>{counsels.fileNo}</TableCell>
        <TableCell className='fs-15 fc-blue-grey'>{counsels.loanTag}</TableCell>
        <TableCell
            className='fs-15 fc-blue-grey'>{counsels.address.city + ", " + counsels.address.state}</TableCell>
        <TableCell
            className='fs-15 fc-blue-grey'>{Utility.epochToDate(counsels.addedOn, "MM-DD-YYYY")}</TableCell>
        <TableCell>
            <Row
                className="text-transform-capitalize p-2 b-1-blue outline-none fs-15  br-4 fc-blue mx-1 px-3 cursor-pointer max-w-100 justify-content-center"
                onClick={() => onReviewClick(counsels)}
            >
                Review
            </Row>

        </TableCell>
    </TableRow>);
};
const headerComponent = (props) => {

    return (
        <Row className='justify-content-between'>
            <div className="fs-24 font-weight-bold display-flex flex-row">{'Blockchain Publish Requests'}</div>
            <Row className="justify-content-between">
                <Row>
                    <Notification/>
                </Row>
            </Row>
        </Row>
    )
};
const publishRequestComponent = (props) => {

    return (
        <Column className="min-vh-100 p-4 fc-dark-slate-blue" style={{maxHeight: props.height}}>
            {headerComponent(props)}
            {SearchFilterComponent(props)}
            {PublishRequestTableComponent(props)}
        </Column>
    )
};
export default publishRequestComponent;