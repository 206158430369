import {Row} from "simple-flexbox";
import React from "react";
import Utils from "../../utility";

const publishHistoryCell = (props, recordDescription) => {

    return (
        <Row className='display-flex justify-content-center align-items-center mt-4 mb-4 b-1-pale-grey-three'>
            <Row flex={1}>
                <img src="/images/information-records.svg" className='p-2 w-100px'/>
                <div className="display-flex flex-direction-column justify-content-center p-h-sm">
                    {props?.publishTag?.tag && <div className="fs-16">{props?.publishTag?.tag}</div>}
                    <div className="fs-16">Published On : {Utils.epochToDate(props.publishedOn, "LLL")}</div>
                    <div className="fs-16">{recordDescription}</div>
                    <div className="fs-18 fw-500">Transaction ID : {props.transactionId}</div>
                </div>
            </Row>
        </Row>
    )
};

export default publishHistoryCell;
