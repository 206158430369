import sessionManager from '../managers/sessionManager'
import {cookiesConstants, eventConstants, pathConstants} from '../constants'
import {history} from "../managers/history";

const userData = sessionManager.getDataFromCookies(cookiesConstants.USER);
const userPermission = sessionManager.getDataFromCookies(cookiesConstants.USER_PERMISSION);
const deviceID = sessionManager.getDataFromCookies(cookiesConstants.DEVICE_ID);
const sessionToken = sessionManager.getDataFromCookies(cookiesConstants.SESSION_TOKEN);
const sectionInfo = sessionManager.getDataFromCookies(cookiesConstants.SECTION_INFO);
const sectionListInfo = sessionManager.getDataFromLocalStorage(cookiesConstants.SECTION_LIST);

let initialState = {
    userPermission: userPermission,
    userDetails: userData,
    isLoggedIn: false,
    loginFailure: null,
    deviceID: deviceID,
    sessionToken: sessionToken,
    isLoading: false,
    currentSection: sectionInfo,
    sectionList: sectionListInfo,
    isForgotPasswordSuccess: false
};
export default function user(state = initialState, action) {
    switch (action.type) {

        case eventConstants.SHOW_LOADER:
            return {
                ...state,
                loading: true
            };
        case eventConstants.HIDE_LOADER:
            return {
                ...state,
                loading: false
            };
        case eventConstants.REGISTER_DEVICE_SUCCESS:
            sessionManager.setDataInCookies(action.data ? action.data : null, cookiesConstants.DEVICE_ID);
            return {
                ...state,
                deviceID: action.data
            };
        case eventConstants.REGISTER_DEVICE_FAILURE:
            return {
                ...state,
            };
        case eventConstants.SET_SECTION:
            sessionManager.setDataInCookies(action.data ? action.data : null, cookiesConstants.SECTION_INFO);
            return {
                ...state,
                currentSection: action.data
            };
        case eventConstants.SECTION_LIST:
            sessionManager.setDataInLocalStorage(action.data ? action.data : null, cookiesConstants.SECTION_LIST);
            return {
                ...state,
                sectionList: action.data
            };
        case eventConstants.SIGN_UP_SUCCESS:
            sessionManager.setDataInCookies(action.data ? action.data.userDetails : state.userDetails, cookiesConstants.USER);
            sessionManager.setDataInCookies(action.data ? action.data.sessionToken : state.sessionToken, cookiesConstants.SESSION_TOKEN);
            return {
                ...state,
                userDetails: action.data ? action.data.userDetails : state.userDetails,
                sessionToken: action.data ? action.data.sessionToken : state.sessionToken,
                loading: false
            };
        case eventConstants.GET_USER_DETAILS_SUCCESS:
            sessionManager.setDataInCookies(action.data ? action.data : null, cookiesConstants.USER);
            return {
                ...state,
                userDetails: action.data ? action.data : null,
                loading: false
            };
        case eventConstants.GET_USER_PERMISSION_SUCCESS:
            sessionManager.setDataInCookies(action.data ? action.data : null, cookiesConstants.USER_PERMISSION);
            return {
                ...state,
                userPermission: action.data ? action.data : null,
                loading: false
            };
        case eventConstants.LOGOUT_SUCCESS:
            sessionManager.setDataInCookies(null, cookiesConstants.USER);
            sessionManager.setDataInCookies(null, cookiesConstants.SESSION_TOKEN);
            sessionManager.setDataInCookies(null, cookiesConstants.USER_PERMISSION);
            return {
                ...state,
                userPermission: false,
                userDetails: false,
                sessionToken: false,
                loading: false
            };
        default:
            return state;
    }
}
