import React from 'react'
import {Column, Row} from "simple-flexbox";
import {Button} from "@material-ui/core";
import CustomInput from "../../common/components/CustomInput";
import {history} from "../../managers/history";
import {pathConstants} from "../../constants";


const resetEmailLinkComponent = (props) => {
    let {state, onChangeEvent} = props;
    return (
        <form onSubmit={props.requestPassword}
              className="w-100 my-4 display-flex align-items-center flex-direction-column">
            <Row className="jc-center mt-2 fs-28 text-center">Reset your password</Row>
            <Row className="my-4 mb-2 jc-center fs-18 fc-brownish-grey">
                Enter your registered email to get a password recovery link
            </Row>
            <CustomInput id="email" type="text" placeholder={"Email Address"}
                         value={state ? state.email : ""} onChange={onChangeEvent}
                         error={state ? state.emailError : ""} className="fs-15 p-2 mt-1"/>

            <Button type='submit' className="bg-blue outline-none text-transform-capitalize
                fc-white w-100 py-2 fs-17 mt-3 cursor-pointer">Send Recovery
                Link</Button>
        </form>

    );
};

const resetLinkSentComponent = (props) => {
    let {state} = props;
    return (
        <Column className="w-100" horizontal={"center"}>
            <Row className="jc-center mt-2 fs-28">Email Sent !</Row>
            <Column className="mt-4 mb-2 jc-center fs-18 fc-brownish-grey">
                <div>Password reset instructions has been sent to</div>
                <Row className="fc-black m-t-10" horizontal={"center"}>{state.email}</Row>
            </Column>
        </Column>
    );
};

function RecoverPasswordForm(props) {
    let {state} = props;
    return (
        <Column className="w-450 my-5 align-items-center px-3" horizontal={'center'}>
            {state.isRecoveryEmailSent ? resetLinkSentComponent(props) : resetEmailLinkComponent(props)}
            <div className="w-100 fs-17 text-center">Return to&nbsp;
                <a className="fc-blue cursor-pointer" onClick={() => {
                    history.replace(pathConstants.LOGIN)
                }}>
                    Sign In
                </a>
            </div>
        </Column>
    );
}

function HeaderComponent(props) {
    return (
        <Row vertical="center" className="justify-content-between">
            <img src="/images/limb-logo.svg" alt='limb' className="w-150"/>
            <Row vertical="center" className="display-none">
                <Column vertical="center" className="fc-brownish-grey fs-15 px-2 py-1">New user?</Column>
                <Column vertical="center" className="fc-blue br-4 b-1-blue fs-17 px-2 py-1 cursor-pointer"
                        onClick={() => history.replace("/sign-up")}>
                    <a href="/sign-up">Sign Up</a>
                </Column>
            </Row>
        </Row>
    )
}

function recoverPasswordComponent(props) {
    return (
        <Column horizontal={'center'} className="fc-dark-slate-blue w-100 p-3 min-vh-100 h-100 justify-content-between">
            <Column className="w-100">
                {HeaderComponent(props)}
                <Row horizontal="center" className="w-100 my-5">
                    {RecoverPasswordForm(props)}
                </Row>
            </Column>
            <Column className="jc-center w-100 align-items-center fs-14 my-3">
                <div className="fc-warm-grey">©2020 LIMB CRE. All Rights Reserved.</div>
                <div className="fc-blue">Privacy Policy</div>
            </Column>
        </Column>
    );
}

export default recoverPasswordComponent;
