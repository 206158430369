import React from "react";
import BaseComponent from '../../baseComponent'
import PublishRequestComponent from './publishRequestComponent'
import {PublishRecordService} from "../../../services";
import Utility, {dispatchAction} from "../../../utility";
import {connect} from "react-redux";
import {
    apiFailureConstants,
    eventConstants,
    genericConstants,
    pathConstants,
} from "../../../constants";
import {history} from "../../../managers/history";

class PublishRequest extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            roleType: this.props.roleType,
            currentSort: 'asc',
            data: [],
            activePage: 1,
            counselList: [],
            filteredList: [],
            searchText: "",
            predictions: [],
            organisationCounsel: [],
            counselName: '',
            contactPerson: '',
            address: '',
            email: '',
            invitationStatus: '',
            selectedAssociate: {},
            subUserObjectForEdit: {},
            locationFilter: genericConstants.INITIAL_FILTER_VALUES.LOCATION,
            statusFilter: genericConstants.INITIAL_FILTER_VALUES.STATUS,
            recordDetails: ""
        };
    }

    componentDidMount() {
        this.initiateComponent();
    };

    initiateComponent() {
        this.props.dispatchAction(eventConstants.SHOW_LOADER);
        this.getPublishRequestList().then((response) => {
            if (response && Object.keys(response).length) {
                this.setState({data: response, filteredList: response});
                this.skippedCounselList(1, 10);
                this.props.dispatchAction(eventConstants.HIDE_LOADER);
            }
        }).catch(error => {
            Utility.consoleLogger("getPublishRequestList : error", error);
            this.props.dispatchAction(eventConstants.HIDE_LOADER);

        });
    }

    getPublishRequestList = async () => {
        let {company} = this.props;
        let requestObj = {
            companyId: company.companyId
        };
        return await PublishRecordService.getPublishRequestListByCompany(requestObj)
    };

    getPredictions = (value) => {
        return this.state.organisationCounsel.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)

    };

    onChangeEvent = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: event.target.value});

        if (value && value.length > 0) {
            let predictions = this.getPredictions(value);
            this.setState({predictions: predictions})
        } else {
            this.setState({predictions: []})
        }
    };

    hideSuggestionBox = async (item) => {
        let address = item.address.city + ', ' + item.address.state;
        this.setState({selectedAssociate: item});
        await this.setState({
            predictions: [],
            counselName: item.name,
            contactPerson: item.contactName,
            address: address,
            email: item.contactEmail
        });
    };

    sorting = (data, key) => {
        this.sortData(data, key);
        this.onSortChange();
    };

    sortData = (data, key) => {

        let cur = this.state.currentSort;
        // a and b are simple objects of array
        let sortedData = data.sort(function (a, b) {
            console.log('a', a);
            let statusA = a[key].toLowerCase(),
                statusB = b[key].toLowerCase();
            return (cur === 'asc' ? (statusA < statusB ? -1 : 1) : (statusA > statusB ? -1 : 1))
        });

        this.setState({counselList: sortedData.slice(0, 10)});
        return this.state.counselList;

    };

    onSortChange = () => {
        const {currentSort} = this.state;
        let nextSort;

        if (currentSort === 'asc') nextSort = 'desc';
        else if (currentSort === 'desc') nextSort = 'asc';

        this.setState({
            currentSort: nextSort
        });
    };

    skippedCounselList = async (start, end) => {
        this.setState({counselList: this.state.filteredList.slice(start - 1, end)});
    };

    skippedOriginationCounselList = async (start, end) => {
        this.setState({counselList: this.state.filteredList.slice(start - 1, end)});
    };

    handlePageChange = async (pageNumber) => {
        console.log("pageNumber", pageNumber);
        await this.setState({activePage: pageNumber});
        let start = (pageNumber * 10) - 9;
        let end = (pageNumber * 10);
        this.skippedOriginationCounselList(start, end);
    };

    counselFilteredListSetValues = async (key, value) => {
        let filterList;
        await this.setState({[key]: value, filteredList: this.state.data});
        if (this.state.searchText !== "") {
            filterList = this.state.filteredList.filter(filterLender =>
                filterLender.name.toLowerCase().includes(this.state.searchText.toLowerCase()));
            this.setState({filteredList: filterList})
        }
        this.skippedOriginationCounselList(1, 10);
    };

    onRejectionDetailClick = (rejectedRecordObject) => {
        history.push(pathConstants.DASHBOARD_MENU.PUBLISH_REQUESTS + '/' + rejectedRecordObject.publishRequestId);
    };

    onClearButtonClick = async (publishRequestObject) => {
        let requestData = {
            publishRequestId: publishRequestObject.publishRequestId
        };
        let [error, response] = await Utility.parseResponse(PublishRecordService.deletePublishRequest(requestData))
        if (error || !response)
            return Utility.apiFailureToast(apiFailureConstants.PUBLISH_REQUEST_DELETED);

        this.initiateComponent();
    };

    onSearchTextChanged = async (event) => {
        let searchValue = event.target.value;
        await this.setState({searchText: Utility.isEmpty(searchValue) ? null : searchValue});
        this.refreshList();
    };

    refreshList() {
        let {searchText, counselList} = this.state;
        let filteredList = counselList.filter(counselObj => {
                return (!searchText) || (searchText && (counselObj.fileNo.toLowerCase().includes(searchText.toLowerCase())
                    || counselObj.loanTag.toLowerCase().includes(searchText.toLowerCase())
                    || counselObj.address.city.toLowerCase().includes(searchText.toLowerCase())
                    || counselObj.address.state.toLowerCase().includes(searchText.toLowerCase())
                    || (`${counselObj.address.city.toLowerCase()}, ${counselObj.address.state.toLowerCase()}`)
                        .includes(searchText.toLowerCase())))
                    || counselObj.status.toLowerCase().includes(searchText.toLowerCase())

            }
        );

        this.setState({filteredList: filteredList})
    }


    render() {
        return (
            <PublishRequestComponent
                state={this.state}
                onChangeEvent={this.onChangeEvent}
                sorting={this.sorting}
                handlePageChange={this.handlePageChange}
                counselFilteredListSetValues={this.counselFilteredListSetValues}
                hideSuggestionBox={this.hideSuggestionBox}
                onReviewClick={this.onRejectionDetailClick}
                onClearButtonClick={this.onClearButtonClick}
                onSearchTextChanged={this.onSearchTextChanged}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.user, company: state.company, publishRequest: state.publishRequest}
};

export default connect(mapStateToProps, {dispatchAction})(PublishRequest);