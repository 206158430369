import React from 'react';
import {Column, Row} from "simple-flexbox";
import Dialog from "@material-ui/core/Dialog/Dialog";
import ProcessingLoader from "../../common/components/processingLoader";
import CustomInput from "../../common/components/CustomInput";
import Dropzone from "react-dropzone";
import {Button} from "@material-ui/core";
import {apiFailureConstants, genericConstants, stringConstants} from "../../constants";
import {getSignedURL, uploadFileToS3} from "../../managers/awsService";
import Utils from "../../utility";
import AddressForm from "../../common/components/addressForm";

function parseRecordDetailsFromError(recordDetails) {
    return ({
        fileNoError: !Utils.isEmpty(recordDetails.fileNo) ? "" : stringConstants.EMPTY_FILE_NUMBER,
        loanTagError: !Utils.isEmpty(recordDetails.loanTag) ? "" : stringConstants.EMPTY_LOAN_TAG,
        propertyPhotoError: !Utils.isEmpty(recordDetails.propertyPhoto) ? "" : stringConstants.EMPTY_PROPERTY_PHOTO,
        propertyTypeError: !Utils.isEmpty(recordDetails.propertyType) ? "" : stringConstants.EMPTY_PROPERTY_TYPE,
        streetAddress: !Utils.isEmpty(recordDetails.streetAddress) ? "" : stringConstants.EMPTY_PROPERTY_ADDRESS,
        city: !Utils.isEmpty(recordDetails.city) ? "" : stringConstants.EMPTY_CITY,
        country: !Utils.isEmpty(recordDetails.country) ? "" : stringConstants.EMPTY_COUNTRY,
        state: !Utils.isEmpty(recordDetails.state) ? "" : stringConstants.EMPTY_STATE,
    });
}

function parseRecordDetailsFromState(state) {
    return ({
        fileNo: state && state.recordDetails ? state.recordDetails.fileNo : "",
        loanTag: state && state.recordDetails ? state.recordDetails.loanTag : "",
        propertyPhoto: state && state.recordDetails ? state.recordDetails.propertyPhoto : "",
        propertyPhotoUrl: state && state.recordDetails ? getSignedURL(state.recordDetails.propertyPhoto) : "",
        propertyType: state && state.recordDetails ? state.recordDetails.propertyType : "",
        propertySubType: state && state.recordDetails ? state.recordDetails.propertySubType : "",
        streetAddress: state && state.recordDetails && state.recordDetails.address ? state.recordDetails.address.streetAddress : "",
        city: state && state.recordDetails && state.recordDetails.address ? state.recordDetails.address.city : "",
        country: state && state.recordDetails && state.recordDetails.address ? state.recordDetails.address.country : "",
        state: state && state.recordDetails && state.recordDetails.address ? state.recordDetails.address.state : "",
    });
}

function validateAddRecordForm(recordDetails, updateErrorMessage) {
    let {fileNo, loanTag, streetAddress, propertyPhoto, city, state, country} = recordDetails;
    updateErrorMessage(parseRecordDetailsFromError(recordDetails, updateErrorMessage));
    return !Utils.isEmpty(propertyPhoto) && !Utils.isEmpty(fileNo) && !Utils.isEmpty(loanTag) && !Utils.isEmpty(city) && !Utils.isEmpty(streetAddress)
        && !Utils.isEmpty(state) && !Utils.isEmpty(country);
}

const EditRecordComponent = (props) => {
    let {onEditRecordClicked, state, handleUpdateRecord} = props;

    let [recordDetails, updateDetails] = React.useState(parseRecordDetailsFromState(state));
    let [formErrorValues, updateErrorMessage] = React.useState({});

    // UseEffect hook to manage Record Detail's updated data-
    React.useEffect(() => updateDetails(parseRecordDetailsFromState(state)), [state.isEditRecordDialogOpen]);

    const onChangeEvent = (event) => {
        let targetElement = (event.target.id).split('.')[1] || event.target.id;
        updateDetails({...recordDetails, [targetElement]: event.target.value});
    };

    function updateAddressInfo(addressObject) {
        updateDetails({...recordDetails, ...addressObject});
    }

    const onFileDrop = async (files) => {
        if (!files || files.length < 1)
            return;
        updateDetails({...recordDetails, propertyPhotoUrl: ""});
        let file = files[0];
        let fileDetail = Utils.getFileName(file.name);
        let propertyPhoto = Utils.getNewFileName('rec', fileDetail.extension);

        await uploadFileToS3(file, propertyPhoto, file.type).catch(err => {
            Utils.apiFailureToast(apiFailureConstants.UPLOAD_PROPERTY_PHOTO);
            updateDetails({...recordDetails, propertyPhotoUrl: getSignedURL(recordDetails.propertyPhoto)});
            return false;
        });
        updateDetails({...recordDetails, propertyPhoto: propertyPhoto, propertyPhotoUrl: getSignedURL(propertyPhoto)});
    };

    const onUpdateRecord = () => {
        if (!validateAddRecordForm(recordDetails, updateErrorMessage))
            return;
        recordDetails['address.streetAddress'] = recordDetails.streetAddress;
        recordDetails['address.city'] = recordDetails.city;
        recordDetails['address.state'] = recordDetails.state;
        recordDetails['address.country'] = recordDetails.country;
        recordDetails['address.lat'] = recordDetails.lat;
        recordDetails['address.lng'] = recordDetails.lng;
        handleUpdateRecord(recordDetails, true);
    };

    let loader = recordDetails.propertyPhoto && !recordDetails.propertyPhotoUrl ? <ProcessingLoader/> : null;

    return (
        <Dialog open={state.isEditRecordDialogOpen} className="p-3 fc-blue-grey">

            <Column>
                <form className="m-4">
                    <Column flex={"1"}>
                        <p className=" fc-dark-slate-blue fs-18 fw-500">Edit Information Record</p>
                        <Row alignContent={"space-between"}>
                            <Column flex={"6"} alignContent={"space-between"}>
                                <Column>
                                    <Row className="fs-14 fw-500" horizontal={"start"}>File Number<p
                                        className="fc-red m-0">*</p></Row>
                                    <CustomInput id="fileNo" type="text" placeholder={''}
                                                 onChange={onChangeEvent}
                                                 value={recordDetails.fileNo}
                                                 error={formErrorValues ? formErrorValues.fileNoError : ""}
                                                 className="fs-15 p-2"/>
                                </Column>
                                <Column>
                                    <Row className="fs-14 fw-500" horizontal={"start"}>Loan Tag<p
                                        className="fc-red m-0">*</p></Row>
                                    <CustomInput id="loanTag" type="text" placeholder={''}
                                                 onChange={onChangeEvent}
                                                 value={recordDetails.loanTag}
                                                 error={formErrorValues ? formErrorValues.loanTagError : ""}
                                                 className="fs-15 p-2"/>
                                </Column>
                            </Column>
                            <Column className="ml-3 min-w-150 mb-2">
                                <Row className="fs-14 fw-500" horizontal={"start"}>Property Photo<p
                                    className="fc-red m-0">*</p></Row>
                                <Column flex={"4"} className="br-4 b-1-white-two w-100 fc-mischka" vertical={"center"}
                                        horizontal={"center"} justifyContent={"space-between"}>
                                    <Dropzone
                                        multiple={false}
                                        accept={genericConstants.ACCEPTED_IMAGE_FORMATS}
                                        onDrop={onFileDrop}
                                        noClick={true}
                                        disableClick>
                                        {({getRootProps, getInputProps, open}) => (
                                            <div {...getRootProps()} className="outline-none">
                                                <input {...getInputProps()} />
                                                <div className=" text-center" onClick={() => open()}>
                                                    {loader ? loader :
                                                        <img
                                                            src={recordDetails.propertyPhotoUrl ? recordDetails.propertyPhotoUrl : "/images/photo-icon.svg"}
                                                            alt='limb'
                                                            className={recordDetails.propertyPhotoUrl ? "min-w-40 max-w-125" : "w-40"}/>
                                                    }
                                                </div>
                                                <Column>
                                                    <Button onClick={() => open()}
                                                            className="outline-none text-transform-capitalize fc-mischka fs-13 cursor-pointer">{recordDetails.propertyPhotoUrl ? "Change Photo" : (loader ? "Loading..." : "Upload Photo")}</Button>
                                                </Column>
                                            </div>
                                        )}
                                    </Dropzone>
                                </Column>
                                {formErrorValues && formErrorValues.propertyPhotoError ?
                                    <div
                                        className="display-flex fc-red fs-14 py-1">{formErrorValues.propertyPhotoError}</div> : ""}
                            </Column>
                        </Row>

                        <Row justifyContent={"space-between"}>
                            <Column flex={"0.48"}>
                                <Row className="fs-14 mt-3 fw-500" horizontal={"start"}>Property Type</Row>
                                <CustomInput id="propertyType" type="text" placeholder={''}
                                             onChange={onChangeEvent}
                                             value={recordDetails.propertyType}
                                             error={formErrorValues ? formErrorValues.propertyTypeError : ""}
                                             className="fs-15 p-2"/>
                            </Column>
                            <Column flex={"0.48"}>
                                <Row className="fs-14 mt-3 fw-500" horizontal={"start"}>Property Sub Type</Row>
                                <CustomInput id="propertySubType" type="text" placeholder={''}
                                             onChange={onChangeEvent}
                                             value={recordDetails.propertySubType}
                                             className="fs-15 p-2"/>
                            </Column>
                        </Row>
                        <Column className="bg-seperator h-1px my-4"/>

                        {/*<Column>
                            <Row className="fs-14 fw-500" horizontal={"start"}>Property Address<p
                                className="fc-red m-0">*</p></Row>
                            <CustomInput id="propertyAddress" type="text" placeholder={''}
                                         onChange={onChangeEvent}
                                         value={recordDetails.streetAddress}
                                         error={formErrorValues ? formErrorValues.streetAddressError : ""}
                                         className="fs-15 p-2"/>
                        </Column>
                        <Row justifyContent={"space-between"} alignContent={"space-between"}>
                            <Column flex={"0.3"}>
                                <Row className="fs-14 mt-3 fw-500" horizontal={"start"}>City<p
                                    className="fc-red m-0">*</p></Row>
                                <CustomInput id="city" type="text" placeholder={''}
                                             onChange={onChangeEvent}
                                             value={recordDetails.city}
                                             error={formErrorValues ? formErrorValues.cityError : ""}
                                             className="fs-15 p-2"/>
                            </Column>
                            <Column flex={"0.3"}>
                                <Row className="fs-14 mt-3 fw-500" horizontal={"start"}>State<p
                                    className="fc-red m-0">*</p></Row>
                                <CustomInput id="state" type="text" placeholder={''}
                                             onChange={onChangeEvent}
                                             value={recordDetails.state}
                                             error={formErrorValues ? formErrorValues.stateError : ""}
                                             className="fs-15 p-2"/>

                            </Column>
                            <Column flex={"0.3"}>
                                <Row className="fs-14 mt-3 fw-500" horizontal={"start"}>Country<p
                                    className="fc-red m-0">*</p></Row>
                                <CustomInput id="country" type="text" placeholder={''}
                                             onChange={onChangeEvent}
                                             value={recordDetails.country}
                                             error={formErrorValues ? formErrorValues.countryError : ""}
                                             className="fs-15 p-2"/>

                            </Column>
                        </Row>*/}
                        <AddressForm companyInfo={recordDetails} handleInputChange={onChangeEvent}
                                     updateAddressInfo={updateAddressInfo} errorInfo={formErrorValues} requestFor={"propertyAddress"}/>
                    </Column>

                    <Row horizontal={"end"} className="mt-5">
                        <Button onClick={onEditRecordClicked}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two fc-blue mx-1 fw-500 w-150 px-3 cursor-pointer bg-transparent">Cancel
                        </Button>
                        <Button onClick={onUpdateRecord}
                                className="outline-none text-transform-capitalize p-2 br-4 b-1-white-two bg-blue fc-white mx-1 fw-500 w-150 px-3 cursor-pointer">Update
                        </Button>
                    </Row>
                </form>
            </Column>
        </Dialog>
    )
};
export default EditRecordComponent;
