import React from 'react'
import {Column, Row} from "simple-flexbox";
import {Button} from "@material-ui/core";
import CustomInput from "../../common/components/CustomInput";
import {history} from "../../managers/history";
import Utils from "../../utility";
import {genericConstants} from "../../constants";
import {getSignedURL} from "../../managers/awsService";


function SubscriptionTypeComponent(plan, index, handlePlanSelection, roleList) {
    return (
        <div id={index}
             className="display-flex flex-direction-column align-items-center p-3 cursor-pointer fc-dark-slate-blue text-center m-3 my-5 w-250 bg-white b-1-white subscription-plan-card-shadow z-index-100 br-4">
            <div className="display-flex flex-3 fs-18 font-weight-bold">{plan.planName}</div>
            <div className=" fs-15 my-5">
                {plan.description}
            </div>
            {/*<img src={SubscriptionPlanImage(plan)} alt="plan-logo" className="mt-2 mb-xl-5"/>*/}
            <img src={getSignedURL(`subscriptionPlan/${plan.planImage}`, '/images/loan-seller.svg')} alt="plan-logo"
                 className="mt-2 mb-xl-5"/>
            <Button onClick={() => handlePlanSelection(Utils.getSpecificRoleWithPlan(plan.planName, roleList), plan)}
                    className=" my-xl-5 bg-blue px-2 fc-white fs-17 outline-none text-transform-capitalize">
                Buy this plan
            </Button>
        </div>
    );
}

/**
 * @return {string}
 */
function SubscriptionPlanImage(plan) {
    switch (plan.title) {
        case genericConstants.ROLES.LENDER:
            return "/images/loan-seller.svg";
        case genericConstants.ROLES.ORIGINATION_COUNSEL:
            return "/images/loan-origination-counsel.svg";
        case genericConstants.ROLES.SELLER_COUNSEL:
            return "/images/loan-seller-counsel.svg";
        default :
            return "";
    }
}

function SubscriptionPlanComponent(props) {
    let {state, handlePlanSelection, togglePassword, onChangeEvent, onLoginClicked} = props;
    return (
        <Column className="align-items-center my-5" horizontal={'center'}>
            <div className="w-100 fs-28 text-center fc-dark-slate-blue">
                LIMB CRE Plans for your Business
            </div>
            <div className="w-100 fs-20 text-center fc-dusky-blue">
                Select a plan to match your company type
            </div>
            <Row className="mt-4">
                {props.state && props.state.planList ? props.state.planList.map((plan, index) =>
                    SubscriptionTypeComponent(plan, index, handlePlanSelection, props.state.rolesList)
                ) : ""}
                {/*{props.state && props.state.rolesList ? props.state.rolesList.map((roleData, index) =>*/}
                {/*    SubscriptionTypeComponent(roleData, index, handlePlanSelection)*/}
                {/*) : ""}*/}
            </Row>
        </Column>
    );
}

function SignUpForm(props) {
    let {state, togglePassword, onChangeEvent, onLoginClicked} = props;
    return (
        <Column className="w-450 pl-5 ml-3 border-left py-3 my-3 align-items-center fc-dark-slate-blue"
                horizontal={'center'}>
            <form onSubmit={onLoginClicked} className="w-100">

                <label className="text-left w-100 fs-20"><span
                    className='font-weight-bold'>Create your account</span> (all field required)</label>

                <div className="fs-15 mt-3 font-weight-bold">Full Name</div>
                <CustomInput id="name" type="text" value={state ? state.name : ""} onChange={onChangeEvent}
                             error={state ? state.nameError : ""} className="fs-15 p-2 mt-1"/>

                <div className="fs-15 mt-2 font-weight-bold ">Email Address</div>
                <CustomInput id="email" type="text" value={state ? state.email : ""} onChange={onChangeEvent}
                             error={state ? state.emailError : ""} className="fs-15 p-2 mt-1"/>

                <Row className="fs-15 mt-2 font-weight-bold">Password</Row>
                <CustomInput id="password" type={"password"} value={state ? state.password : ""}
                             onChange={onChangeEvent} error={state ? state.passwordError : ""}
                             className="fs-15 p-2 mt-1"/>

                <Row className="fs-15 mt-2 font-weight-bold">Confirm Password</Row>
                <CustomInput id="confirmPassword" type={"password"} value={state ? state.confirmPassword : ""}
                             onChange={onChangeEvent} error={state ? state.confirmPasswordError : ""}
                             className="fs-15 p-2 mt-1"/>

                <Button type='submit' className="bg-blue outline-none text-transform-capitalize
                fc-white w-100 py-2 fs-17 mt-4 cursor-pointer">
                    Continue
                </Button>
                <div className="w-100 text-left fs-15 my-1">By clicking on continue, you agree to the LIMB CRE
                    <a className="fc-blue"> Terms of Service </a>
                    and <a className="fc-blue">Privacy Policy.</a>
                </div>
            </form>
        </Column>
    );
}

function SelectedSubscriptionComponent(props) {
    return (
        <div
            className="px-5 mx-3 cursor-pointer fc-dark-slate-blue text-center justify-content-between m-3 w-350 bg-white">
            <div
                className="fs-20 font-weight-bold">{props.selectedPlan.planName ? props.selectedPlan.planName : ''}</div>
            <img src={getSignedURL(`subscriptionPlan/${props.selectedPlan.planImage}`, '/images/loan-seller.svg')}
                 alt="plan-logo"
                 className="w-250 py-4"/>
            {/*<img src={SubscriptionPlanImage(props)} alt="loan-seller" className="w-250 py-4"/>*/}
            <div className="font-weight-bold text-left">Features</div>
            {props.selectedPlan && props.selectedPlan.features && props.selectedPlan.features.length > 0 ?
                props.selectedPlan.features.map((feature, index) =>
                    getSubscriptionFeatures(feature, index))
                : null}
        </div>
    );
}

function getSubscriptionFeatures(feature, index) {
    return (
        <Row vertical="center" className="pt-2" id={index}>
            <img src="/images/green-tick.svg" width='14px'/>
            <div className="px-2">{feature}</div>
        </Row>

    );
}

function SignUpComponent(props) {
    let {state, togglePassword, onChangeEvent, onLoginClicked} = props;
    return (
        <Row className="align-items-center my-5" horizontal={'center'}>
            {state && state.selectedRole && state.selectedPlan ? SelectedSubscriptionComponent(state) : ""}
            {SignUpForm(props)}
        </Row>
    );
}

function HeaderComponent(props) {
    return (
        <Row vertical="center" className="justify-content-between w-100">
            <img src="/images/limb-logo.svg" alt='limb' className="w-150"/>
            <Row vertical="center">
                <Column vertical="center" className="fc-brownish-grey fs-15 px-2 py-1">Existing user?</Column>
                <Column vertical="center" className="fc-blue br-4 b-1-blue fs-17 px-2 py-1 cursor-pointer"
                        onClick={() => history.replace("/")}>

                    Log in</Column>
            </Row>
        </Row>
    )
}

function signUpComponent(props) {
    return (
        <Column horizontal={'center'} className="w-100 p-3 min-vh-100">
            {HeaderComponent(props)}
            {props.state && props.state.selectedRole && Object.keys(props.state.selectedRole).length ? "" : SubscriptionPlanComponent(props)}
            {props.state && props.state.selectedRole && Object.keys(props.state.selectedRole).length ? SignUpComponent(props) : ""}
        </Column>
    );
}

export default signUpComponent;