import React, {useState} from 'react';
import {GoogleMap, OverlayView, Marker, InfoWindow} from '@react-google-maps/api'
import LinearProgressBar from "./LinearProgressBar";
import {getSignedURL} from "../../managers/awsService";

const MapViewComponent = (props) => {
    const [selectedMarker, setMarkerSelection] = useState(null);

    const handleClick = (record) => {
        setMarkerSelection(record);
    };

    const handleMarkerOnClose = () => {
        setMarkerSelection(null);
    };

    let defaultCenter = {lat: 32.6610351, lng: -96.73610579999999};

    let {state} = props;
    return (
        <div className="mt-4">
            <div className='map-container'>
                <GoogleMap
                    mapContainerClassName="map-container"
                    zoom={4}
                    // center={defaultCenter}
                    center={selectedMarker? selectedMarker.location : defaultCenter}
                    //location key is always null here.
                    //Controls unwanted center to selected marker movement while render.

                    // Uncheck if you want to set selected marker to come in center on map and default center when
                    // popup closed..
                    // center={selectedMarker? selectedMarker.address : defaultCenter}
                >
                    {state && state.filteredList && state.filteredList.length > 0 && state.filteredList.map((record) => {
                        return (
                            <Marker
                                key={record.recordId}
                                onClick={() => handleClick(record)}
                                position={{lat: record.address.lat, lng: record.address.lng}}
                                icon={"/images/map-pin-shadow.png"}
                            >

                                {selectedMarker && selectedMarker.recordId === record.recordId && (
                                    <InfoWindow
                                        position={{lat: record.address.lat, lng: record.address.lng}}
                                        mapPaneName={OverlayView.MARKER_LAYER}
                                        onCloseClick={() => handleMarkerOnClose(record.recordId)}
                                    >
                                        <div >
                                            <div className="display-flex flex-direction-row ">
                                                <img
                                                    src={record.propertyPhoto ? getSignedURL(record.propertyPhoto, "/images/company-placeholder.svg") : "/images/company-placeholder.svg"}
                                                    alt='record-logo'
                                                    onClick={() => props.onRecordClicked(record)}
                                                    className="justify-content-start w-100px max-h-100 br-4 cursor-pointer"/>
                                                <div
                                                    className=" flex-direction-column justify-content-between align-items-center pl-2">
                                                    <div
                                                        className="fs-10 fc-warm-grey">{record.fileNo ? record.fileNo : ""}</div>
                                                    <div
                                                        className="fs-14 fw-900">{record.loanTag ? record.loanTag : ""}</div>
                                                    <div className="fs-10 m-v-2px fc-warm-grey">Loan Pool: <span
                                                        className="fc-warm-grey fw-500">{record.pool && record.pool.name ? record.pool.name : "Not assigned"}</span>
                                                    </div>
                                                    <div className="p-r-10p mt-2"><LinearProgressBar
                                                        variant="determinate"
                                                        color="secondary"
                                                        value={record.progress}
                                                    /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </InfoWindow>)}
                            </Marker>
                        );
                    })}
                </GoogleMap>
            </div>
        </div>
    )
};

export default MapViewComponent
