import {httpConstants} from "../constants";
import {httpService} from "../managers/httpService";

export default {
    getSubscriptionPlans,
    subscribeUser,
    updateSubscription,
    getInvoiceList,
    getSubscriptionData
}

async function getSubscriptionPlans(requestData) {
    let url = process.env.REACT_APP_SUBSCRIPTION_SERVICE_URL + httpConstants.API_END_POINT.PLANS;
    return httpService(httpConstants.METHOD_TYPE.GET, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });

}

async function subscribeUser(requestData) {
    let url = process.env.REACT_APP_SUBSCRIPTION_SERVICE_URL + httpConstants.API_END_POINT.SUBSCRIPTION;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });

}

async function updateSubscription(requestData) {
    let url = process.env.REACT_APP_SUBSCRIPTION_SERVICE_URL + httpConstants.API_END_POINT.SUBSCRIPTION;
    return httpService(httpConstants.METHOD_TYPE.PUT, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });

}

async function getInvoiceList(requestData) {
    let url = process.env.REACT_APP_SUBSCRIPTION_SERVICE_URL + httpConstants.API_END_POINT.INVOICES;
    return httpService(httpConstants.METHOD_TYPE.POST, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });

}

async function getSubscriptionData(requestData) {
    let url = process.env.REACT_APP_SUBSCRIPTION_SERVICE_URL + httpConstants.API_END_POINT.SUBSCRIPTION+"/"+requestData.subscriptionId;
    return httpService(httpConstants.METHOD_TYPE.GET, {'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON}, requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });

}