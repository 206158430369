import "react-s-alert/dist/s-alert-default.css";
import {history} from "../managers/history";
import swal from "sweetalert";
import Cookies from "universal-cookie";
import React from "react";
import ToastService from 'react-material-toast';
import aws from "aws-sdk";
import {dateQuestionIdsList, genericConstants, stringConstants} from "../constants";
import customToast from "../common/components/ToastComponent";
import path from "path";

const toast = ToastService.new({
    place: 'topRight',
    duration: 1,
    maxCount: 2
});
let moment = require('moment');
const cookies = new Cookies();
const utility = {
    parseResponse,
    descSortOnCriteria,
    ascSortOnCriteria,
    epochToCalender,
    getHeader,
    apiFailureToast,
    apiSuccessToast,
    getAddedByObject,
    getCompanyObject,
    generateGUID,
    basicAlert,
    getActivityDateEpochRange,
    getAddress,
    decodeBase64,
    validationAlert,
    isNumber,
    trackEvent,
    navigateToPath,
    toggleDropDown,
    validateName,
    validateEmail,
    isEmpty,
    isEmptyArray,
    isMenuActive,
    isPasswordValid,
    consoleLogger,
    generateAddressString,
    generateFullAddressString,
    generateSortAddressString,
    getSignedUrl,
    uploadFileToS3,
    showUnderDevelopment,
    epochToDate,
    getMiniUserModel,
    getTimeFromNow,
    epocToPrettyTime,
    getTimeDifference,
    getTimestampFromDate,
    secondsToTime,
    getCompanyID,
    getUserRole,
    isSubUser,
    getCompanyType,
    parseAssociateObject,
    sortArrayForKey,
    getNewFileName,
    getNewFileNameWithId,
    getFileName,
    decodeBase64Key,
    getSectionStatusForView,
    parseLocationObject,
    getSectionStatus,
    getUserRoleLevel,
    getUserRoleID,
    getUserRoleLevelForCompany,
    viewRoleLevel,
    viewSectionStatus,
    viewInvitationStatus,
    generateCriteriaListForDropdown,
    romanize,
    getDateFromTimestamp,
    getMiniSectionStatusUserModel,
    generateActivityArray,
    generateActivityView,
    parseDocumentsRequestObject,
    viewCompanyName,
    getSpecificRoleWithPlan,
    getSpecificPlanWithRole,
    getSubscriptionPlanText,
    isDateInputRequired: isDataInputRequired,
    generateUniqueEntityList,
    getAmountUnitByConfiguration,
    getAmountMultiplierUnitByConfiguration,
    generateActualValueOfAmountRangeValue,
    extractNumericValueFromString,
    getEpochFromTimeString,
    isPositiveNumberInput,
    getDateString,
    getCompleteCriteriaObject,
    getDateStringWithSubtractionOfMonth
};
export default utility;


function getCompleteCriteriaObject(criteriaList, completeCriteriaList) {
    if (!criteriaList || criteriaList.length < 1 || !completeCriteriaList || completeCriteriaList.length < 1)
        return [];

    let nestedCriteriaObjectArray = [];
    completeCriteriaList.forEach((completeCriteriaObject) => {
        criteriaList.forEach((criteriaObject) => {
            if (criteriaObject.criteriaId === completeCriteriaObject.criteriaId)
                nestedCriteriaObjectArray.push(completeCriteriaObject);
        })
    });
    return nestedCriteriaObjectArray;
}

function getDateString(timestamp, format = 'MM-DD-YYYY') {
    return moment(timestamp).format(format) || ""
}

function getDateStringWithSubtractionOfMonth(timestamp, noOfMonthToSubtract, format = 'MM-DD-YYYY') {
    return moment(timestamp).subtract(noOfMonthToSubtract, 'months').format(format) || ""
}

/**
 * Replaces every character except numeric characters from the string
 * @param answerObject
 * @returns {*}
 */
function extractNumericValueFromString(answerObject) {
    let value = answerObject.value;
    return value && value.replace(/[^0-9\.]+/g, '');
}

function isPositiveNumberInput(value) {
    // value = value && value.replace(/[^0-9\.]+/g, '');
    let positiveIntRegEx = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
    // consoleLogger("Test result", value+" :  "+(positiveIntRegEx).test(value));
    return (positiveIntRegEx).test(value)
}

function getAmountUnitByConfiguration() {
    let currentAmountUnit = process.env.REACT_APP_AMOUNT_UNIT || genericConstants.AMOUNT_UNIT.THOUSAND;

    switch (currentAmountUnit) {
        case genericConstants.AMOUNT_UNIT.THOUSAND:
            return genericConstants.AMOUNT_UNIT_SIGN.THOUSAND;
        case genericConstants.AMOUNT_UNIT.MILLION:
            return genericConstants.AMOUNT_UNIT_SIGN.MILLION;
        case genericConstants.AMOUNT_UNIT.BILLION:
            return genericConstants.AMOUNT_UNIT_SIGN.BILLION;
    }
}

function getAmountMultiplierUnitByConfiguration() {
    let currentAmountUnit = process.env.REACT_APP_AMOUNT_UNIT || genericConstants.AMOUNT_UNIT.THOUSAND;

    switch (currentAmountUnit) {
        case genericConstants.AMOUNT_UNIT.THOUSAND:
            return genericConstants.AMOUNT_MULTIPLIER_VALUE_BY_UNIT.THOUSAND;
        case genericConstants.AMOUNT_UNIT.MILLION:
            return genericConstants.AMOUNT_MULTIPLIER_VALUE_BY_UNIT.MILLION;
        case genericConstants.AMOUNT_UNIT.BILLION:
            return genericConstants.AMOUNT_MULTIPLIER_VALUE_BY_UNIT.BILLION;
    }
}

function generateActualValueOfAmountRangeValue(inputArray) {
    if (!inputArray || inputArray.length < 1)
        return inputArray;
    return inputArray.map((element) => element * getAmountMultiplierUnitByConfiguration());
}

/**
 *   Generates array of unique values of {key} from the
 *   object array {objectArray}
 *
 * @param objectArray
 * @param key
 * @returns {Set<any>}
 */
function generateUniqueEntityList(objectArray, key) {
    let entitySet = new Set();
    objectArray.forEach((object) => {
        if (object[key])
            entitySet.add(object[key])
    });
    return entitySet;
}

function isDataInputRequired(questionId) {
    return dateQuestionIdsList.includes(questionId);
}

function getSubscriptionPlanText(plan) {
    switch (plan) {
        case genericConstants.SUBSCRIPTION_PLAN.YEARLY:
            return genericConstants.SUBSCRIPTION_PLAN_TEXT.YEARLY;
        case genericConstants.SUBSCRIPTION_PLAN.MONTHLY:
            return genericConstants.SUBSCRIPTION_PLAN_TEXT.MONTHLY;
        default:
            return;
    }
}

function getSpecificRoleWithPlan(planName, roleList) {
    let roleTittle = '';
    switch (planName) {
        case genericConstants.PLAN_NAME.LOAN_ORIGINATION_COUNSEL:
            roleTittle = genericConstants.ROLES.ORIGINATION_COUNSEL;
            break;
        case genericConstants.PLAN_NAME.LOAN_SELLER:
            roleTittle = genericConstants.ROLES.LENDER;
            break;
        case genericConstants.PLAN_NAME.LOAN_SELLER_COUNSEL:
            roleTittle = genericConstants.ROLES.SELLER_COUNSEL;
            break;
        default:
            break;
    }
    if (!roleTittle || !roleList || roleList.length < 1)
        return;
    for (let index = 0; index < roleList.length; index++) {
        if (roleList[index].title === roleTittle) {
            return roleList[index];
        }
    }
}

function getSpecificPlanWithRole(roleTitle, planList) {
    let planName = '';
    switch (roleTitle) {
        case  genericConstants.ROLES.ORIGINATION_COUNSEL:
            planName = genericConstants.PLAN_NAME.LOAN_ORIGINATION_COUNSEL;
            break;
        case genericConstants.ROLES.LENDER:
            planName = genericConstants.PLAN_NAME.LOAN_SELLER;
            break;
        case genericConstants.ROLES.SELLER_COUNSEL:
            planName = genericConstants.PLAN_NAME.LOAN_SELLER_COUNSEL;
            break;
        default:
            break;
    }
    if (!planName || !planList || planList.length < 1)
        return;
    for (let index = 0; index < planList.length; index++) {
        if (planList[index].planName === planName) {
            return planList[index];
        }
    }
}

function parseDocumentsRequestObject(documentList) {
    let parseDocumentRequestObject = [];
    if (!documentList || documentList.length < 1)
        return parseDocumentRequestObject;
    documentList.forEach(document => {
        let documentObject = {};
        documentObject["documentId"] = document.documentId;
        documentObject["recordId"] = document.recordId;
        documentObject["sectionId"] = document.sectionId;
        documentObject["questionId"] = document.questionId;
        documentObject["category"] = document.category;
        documentObject["categoryType"] = document.categoryType;
        documentObject["name"] = document.name;
        documentObject["key"] = document.key;
        parseDocumentRequestObject.push(documentObject);
    });
    return parseDocumentRequestObject;
}

function romanize(num) {
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
            "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
            "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}

function getSectionStatusForView(status) {
    let statusValue = {value: "", color: ""};
    switch (status) {
        case genericConstants.SECTION_STATUS.OPEN:
            statusValue = {value: genericConstants.SECTION_STATUS_FOR_VIEW.OPEN, color: " bg-greyish-two "};
            break;
        case genericConstants.SECTION_STATUS.APPROVED:
            statusValue = {value: genericConstants.SECTION_STATUS_FOR_VIEW.APPROVE, color: " bg-green "};
            break;
        case genericConstants.SECTION_STATUS.REJECTED:
            statusValue = {value: genericConstants.SECTION_STATUS_FOR_VIEW.REJECT, color: " bg-red "};
            break;
        case genericConstants.SECTION_STATUS.IN_PROGRESS:
            statusValue = {value: genericConstants.SECTION_STATUS_FOR_VIEW.IN_PROGRESS, color: " bg-azure "};
            break;
        case genericConstants.SECTION_STATUS.IN_REVIEW:
            statusValue = {value: genericConstants.SECTION_STATUS_FOR_VIEW.REVIEW_PENDING, color: " bg-orange "};
            break;
        case genericConstants.SECTION_STATUS.COMPLETED:
            statusValue = {value: genericConstants.SECTION_STATUS_FOR_VIEW.DONE, color: " bg-green "};
            break;
    }
    return statusValue;
}

function getSectionStatus(status) {
    let statusValue = "";
    switch (status) {
        case genericConstants.SECTION_STATUS_FOR_VIEW.OPEN:
            statusValue = genericConstants.SECTION_STATUS.OPEN;
            break;
        case genericConstants.SECTION_STATUS_FOR_VIEW.IN_PROGRESS:
            statusValue = genericConstants.SECTION_STATUS.IN_PROGRESS;
            break;
        case genericConstants.SECTION_STATUS_FOR_VIEW.REVIEW_PENDING:
        case genericConstants.SECTION_STATUS_FOR_VIEW.SEND_FOR_REVIEW:
            statusValue = genericConstants.SECTION_STATUS.IN_REVIEW;
            break;
        case genericConstants.SECTION_STATUS_FOR_VIEW.APPROVE:
        case genericConstants.SECTION_STATUS_FOR_VIEW.APPROVED_FOR_SC_REVIEW:
            statusValue = genericConstants.SECTION_STATUS.APPROVED;
            break;
        case genericConstants.SECTION_STATUS_FOR_VIEW.REJECT:
            statusValue = genericConstants.SECTION_STATUS.REJECTED;
            break;
        case genericConstants.SECTION_STATUS_FOR_VIEW.COMPLETED:
            statusValue = genericConstants.SECTION_STATUS.COMPLETED;
            break;
    }
    return statusValue;

}

function decodeBase64Key(name) {
    if (!name || !name.length)
        return "";

    return new Buffer(name, 'base64').toString('ascii');
};

function getNewFileName(baseName, extension) {
    let fileName = `${baseName}_${(new Date).getTime()}${extension}`;
    return fileName.replace(/ /g, "_");
};

function getNewFileNameWithId(baseName, extension, id) {
    let fileName = `${baseName}_${id}${extension}`;
    return fileName.replace(/ /g, "_");
};

function getFileName(fileName) {
    let extension = path.extname(fileName);
    let baseName = path.basename(fileName, extension);
    return {baseName, extension}
};

function consoleLogger(title, data) {
    console.log("LIMB Web Log -> " + title + "  :  ", data);
}

function generateAddressString(addressData) {
    let address = "";
    if (!addressData)
        return address;
    if (addressData.streetAddress)
        address = address + addressData.streetAddress + " ";
    if (addressData.suite)
        address = address + addressData.suite + " ";
    if (addressData.city)
        address = address + addressData.city + " ";
    if (addressData.state)
        address = address + addressData.state + " ";
    return address;
}

function generateFullAddressString(addressData) {
    let address = "";
    if (!addressData)
        return address;
    if (addressData.streetAddress)
        address = address + addressData.streetAddress + " ";
    if (addressData.suite)
        address = address + addressData.suite + " ";
    if (addressData.city)
        address = address + addressData.city + " ";
    if (addressData.state)
        address = address + addressData.state + " ";
    if (addressData.country)
        address = address + addressData.country + " ";
    return address;
}

function generateSortAddressString(addressData) {
    let address = "";
    if (!addressData)
        return address;
    if (addressData.city)
        address = address + addressData.city + " ";
    if (addressData.state)
        address = address + addressData.state + " ";
    return address;
}

function descSortOnCriteria(data, criteria) {
    data.sort((a, b) => {
        return b[criteria] - a[criteria];
    });
}

function ascSortOnCriteria(data, criteria) {
    data.sort((a, b) => {
        return a[criteria] - b[criteria];
    });
}

/**
 *              This function is made to handle success and error callback!
 * @param promise
 * @returns {Promise<Promise|Bluebird<*[] | R>|Bluebird<any | R>|*|Promise<T | *[]>>}
 */
function parseResponse(promise) {
    return promise.then(data => {
        return [null, data];
    }).catch(err => [err]);
};

export const dispatchAction = (type, data) => {
    return dispatch => dispatch({type, data});
};

function parseAssociateObject(company, companyType) {
    return {
        "companyType": companyType || "",
        "companyId": company.companyId || "",
        "name": company.name || "",
        "logo": company.logo || "",
        "contactName": company.contactName || "",
        "email": company.contactEmail || "",
        "limbId": company.limbId || "",
        "owner": company.owner ? Object.assign({}, company.owner) : {},
        "location": parseLocationObject(company.address)
    }
}

function parseLocationObject(locationData) {
    let location = {};
    if (!locationData)
        return location;
    location['lat'] = locationData.lat || 0;
    location['lng'] = locationData.lng || 0;
    location['streetAddress'] = locationData.streetAddress || "";
    location['city'] = locationData.city || "";
    location['state'] = locationData.state || "";
    location['suite'] = locationData.suite || "";
    location['country'] = locationData.country || "";
    return location;
}

function trackEvent(event, eventData) {
    // try {
    //     if (!eventData)
    //         mixpanel.track(event);
    //     else
    //         mixpanel.track(event, eventData);
    // } catch (err) {
    //     console.log(err)
    // }
}

function getHeader() {
    // return {
    //     'session-token': sessionManager.getDataFromCookies(genericConstants.COOKIES_KEY.SESSION_TOKEN),
    //     'device-id': sessionManager.getDataFromCookies(genericConstants.COOKIES_KEY.DEVICE_ID),
    //     'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON
    // };
}

function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

//TODO: update apiConstant.API_FAILURE
function apiFailureToast(message) {
    customToast(false, message ? message : "Failure!")
    // toast.error(message ? message : "apiConstant.API_FAILURE");
}

function apiSuccessToast(message) {
    customToast(true, message ? message : "Success!")

    // toast.success(message ? message : "apiConstant.API_SUCCESS");
}

function generateGUID() {
    var nav = window.navigator;
    var screen = window.screen;
    var guid = nav.mimeTypes.length;
    guid += nav.userAgent.replace(/\D+/g, '');
    guid += nav.plugins.length;
    guid += screen.height || '';
    guid += screen.width || '';
    guid += screen.pixelDepth || '';
    return guid;
}

function basicAlert(message) {
    swal({
        title: message,
        icon: '/images/activation_pending.svg',
    })
}

function validationAlert(message, type = 'info') {
    swal({
        title: message,
        icon: type
    })
}

function getTimeDifference(timeStampTo) {
    let minFive = 300000;
    let oneDay = 86400000;
    let difference = "";
    let am = " AM";
    let pm = " PM";
    let hh = epochToDate(timeStampTo, 'hh');
    let mm = epochToDate(timeStampTo, 'mm');
    let dateFormat = epochToDate(timeStampTo, 'DD MMM YYYY');
    let hours = new Date(timeStampTo).getHours();
    let timeDifference = new Date().getTime() - timeStampTo;
    if (timeDifference < oneDay) {
        if (timeDifference < minFive) {
            difference = "Just Now";
        } else {
            if (hours < 12)
                difference = "Today at " + hh + ":" + mm + am;
            else
                difference = "Today at " + hh + ":" + mm + pm;
        }
    } else {
        if (hours < 12)
            difference = dateFormat + ", " + hh + ':' + mm + am;
        else
            difference = dateFormat + ", " + hh + ':' + mm + pm;
    }
    return difference;
}

function epochToDate(timeStamp, timeFormat) {
    timeStamp = Math.floor(timeStamp);  //to convert to integer if seconds is String.
    let dateObject = new Date(timeStamp);
    return moment(dateObject).format(timeFormat)//DD MMM YYYY
}

function epochToCalender(timeStamp, timeFormat) {
    return moment().calendar(timeStamp, timeFormat); // Today at 4:58 PM
}

function getEpochFromTimeString(timeString, format) {
    return moment(timeString, format).valueOf();
}

function getTimeFromNow(timeStamp) {
    return moment(timeStamp, "YYYYMMDD").fromNow();
}

function getActivityDateEpochRange(activityDate) {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    let startDayEpochOfCurrentWeek = moment().startOf('isoweek').unix() * 1000;
    let startDayEpochOfCurrentMonth = moment().startOf('month').unix() * 1000;
    let startDayEpochOfCurrentQuarter = moment().startOf('quarter').unix() * 1000;
    let startDayEpochOfCurrentYear = moment().startOf('year').unix() * 1000;
    let endDayEpochOfCurrentWeek = moment().endOf('isoweek').unix() * 1000;
    let endDayEpochOfCurrentMonth = moment().endOf('month').unix() * 1000;
    let endDayEpochOfCurrentQuarter = moment().endOf('quarter').unix() * 1000;
    let endDayEpochOfCurrentYear = moment().endOf('year').unix() * 1000;
    let day, start;
    switch (activityDate) {
        case "Today":
            return {start: currentDate.getTime(), end: new Date().getTime()};
        case "Yesterday":
            day = new Date(currentDate);
            day.setDate(currentDate.getDate() - 1);
            return {start: day.getTime(), end: currentDate.getTime()};
        case "Last seven days":
            day = new Date(currentDate);
            day.setDate(currentDate.getDate() - 7);
            return {start: day.getTime(), end: currentDate.getTime()};
        case "Last fourteen days":
            day = new Date(currentDate);
            day.setDate(currentDate.getDate() - 14);
            return {start: day.getTime(), end: currentDate.getTime()};
        case "Last twenty one days":
            day = new Date(currentDate);
            day.setDate(currentDate.getDate() - 21);
            return {start: day.getTime(), end: currentDate.getTime()};
        case "Last Week":
            start = new Date(startDayEpochOfCurrentWeek);
            start.setDate(start.getDate() - 7);
            return {start: start.getTime(), end: startDayEpochOfCurrentWeek};
        case "Last two weeks":
            start = new Date(startDayEpochOfCurrentWeek);
            start.setDate(start.getDate() - 14);
            return {start: start.getTime(), end: startDayEpochOfCurrentWeek};
        case "Last three weeks":
            start = new Date(startDayEpochOfCurrentWeek);
            start.setDate(start.getDate() - 21);
            return {start: start.getTime(), end: startDayEpochOfCurrentWeek};
        case "Last Month":
            start = new Date(startDayEpochOfCurrentMonth);
            start.setMonth(start.getMonth() - 1);
            return {start: start.getTime(), end: startDayEpochOfCurrentMonth};
        case "Last Quarter":
            start = new Date(startDayEpochOfCurrentQuarter);
            start.setMonth(start.getMonth() - 3);
            return {start: start.getTime(), end: startDayEpochOfCurrentQuarter};
        case "Last Year":
            start = new Date(startDayEpochOfCurrentYear);
            start.setFullYear(start.getFullYear() - 1);
            return {start: start.getTime(), end: startDayEpochOfCurrentYear};
        case "This Week":
            return {start: startDayEpochOfCurrentWeek, end: endDayEpochOfCurrentWeek};
        case "This Quarter":
            return {start: startDayEpochOfCurrentQuarter, end: endDayEpochOfCurrentQuarter};
        case "This Year":
            return {start: startDayEpochOfCurrentYear, end: endDayEpochOfCurrentYear};
        case "Current Month":
            return {start: startDayEpochOfCurrentMonth, end: endDayEpochOfCurrentMonth};
        default:
            return {start: currentDate.getTime(), end: new Date().getTime()};
    }

}

function getAddress(addressObj) {
    if (!addressObj || !Object.keys(addressObj).length || !(addressObj.addressLine1 || addressObj.poBoxNumber))
        return "";
    return (
        <span>
            {addressObj.addressLine1 ? addressObj.addressLine1 : `PO Box ${addressObj.poBoxNumber}`}<br/>
            {addressObj.addressLine2 ? <span>{addressObj.addressLine2}<br/></span> : ''}
            {addressObj.city}, {addressObj.state} {addressObj.zipCode}<br/>
            {addressObj.country}
        </span>
    )
}

function decodeBase64(s) {
    let e = {}, i, b = 0, c, x, l = 0, a, r = '', w = String.fromCharCode, L = s.length;
    let A = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    for (i = 0; i < 64; i++) {
        e[A.charAt(i)] = i;
    }
    for (x = 0; x < L; x++) {
        c = e[s.charAt(x)];
        b = (b << 6) + c;
        l += 6;
        while (l >= 8) {
            ((a = (b >>> (l -= 8)) & 0xff) || (x < (L - 2))) && (r += w(a));
        }
    }
    return r;
}

function navigateToPath(path) {
    history.push(path)
}

function toggleDropDown(dropdownID) {
    // $("#" + dropdownID).toggle("show");
}

function validateName(name) {
    let reg = /[A-Z][a-zA-Z]*/;
    return reg.test(name)
}

function validateEmail(email) {
    let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(email);
}

function isPasswordValid(password) {
    let reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    return reg.test(password);
}

function isEmpty(string) {
    return !string || string.trim().length === 0;
}

function isEmptyArray(array) {
    return !array || array.length === 0;
}

function isMenuActive(path) {
    return window.location.pathname.includes(path);
}

function getAddedByObject(propsOfComponent) {
    if (!propsOfComponent || !propsOfComponent.user || !propsOfComponent.user.userDetails)
        return null;
    let user = propsOfComponent.user.userDetails;
    return {
        password: user.password,
        publicKey: user.publicKey,
        email: user.email,
        wercPlaceID: user.wercPlaceID,
        photo: user.photo ? user.photo : '',
        designation: user.designation ? user.designation : {},
        department: user.department ? user.department : {},
        name: (user.firstName || user.lastName) ? (user.firstName + " " + user.lastName) : (user.company && user.company.name ? user.company.name : ""),
        _id: user._id
    };
}

function getCompanyID(propsOfComponent) {
    if (!propsOfComponent || !propsOfComponent.user || !propsOfComponent.user.userDetails || !propsOfComponent.user.userDetails.miscellaneous
        || !propsOfComponent.user.userDetails.miscellaneous.company) {
        return null;
    }
    return propsOfComponent.user.userDetails.miscellaneous.company.companyId;

}

function getUserRole(userDetails) {
    if (!userDetails || !userDetails.role)
        return;
    for (let roleObject of userDetails.role) {
        if (!roleObject || !roleObject.title)
            continue;
        return roleObject.title;
    }
}

function getUserRoleLevel(userDetails) {
    if (!userDetails || !userDetails.miscellaneous || Object.keys(userDetails.miscellaneous).length < 1 || !userDetails.miscellaneous.roleLevel)
        return;
    return userDetails.miscellaneous.roleLevel;
}

function getUserRoleLevelForCompany(roleObject) {
    if (!roleObject || Object.keys(roleObject).length < 1 || !roleObject.title)
        return;
    let roleLevel = '';
    switch (roleObject.title) {
        case genericConstants.ROLES.LENDER:
            roleLevel = genericConstants.LEVEL_TYPE.LEVEL_2;
            break;

        case genericConstants.ROLES.ORIGINATION_COUNSEL:
            roleLevel = genericConstants.LEVEL_TYPE.LEVEL_3;
            break;

        case genericConstants.ROLES.SELLER_COUNSEL:
            roleLevel = genericConstants.LEVEL_TYPE.LEVEL_2;
            break;

    }
    ;
    return roleLevel;
}

function viewRoleLevel(roleLevel) {
    if (!roleLevel)
        return '';
    let viewableLevel = '';
    switch (roleLevel) {
        case genericConstants.LEVEL_TYPE.LEVEL_1:
            viewableLevel = genericConstants.VIEWABLE_LEVEL_TYPE.LEVEL_1;
            break;

        case genericConstants.LEVEL_TYPE.LEVEL_2:
            viewableLevel = genericConstants.VIEWABLE_LEVEL_TYPE.LEVEL_2;
            break;

        case genericConstants.LEVEL_TYPE.LEVEL_3:
            viewableLevel = genericConstants.VIEWABLE_LEVEL_TYPE.LEVEL_3;
            break;

    }
    ;
    return viewableLevel;
}

function viewCompanyName(type) {
    if (!type)
        return '';
    let viewableCompanyName = '';
    switch (type) {
        case genericConstants.ROLES.LENDER:
            viewableCompanyName = genericConstants.VIEWABLE_COMPANY_NAME.LENDER;
            break;

        case genericConstants.ROLES.ORIGINATION_COUNSEL:
            viewableCompanyName = genericConstants.VIEWABLE_COMPANY_NAME.ORIGINATION_COUNSEL;
            break;

        case genericConstants.ROLES.SELLER_COUNSEL:
            viewableCompanyName = genericConstants.VIEWABLE_COMPANY_NAME.SELLER_COUNSEL;
            break;

    }
    ;
    return viewableCompanyName;
}

function viewSectionStatus(status) {
    if (!status)
        return '';
    let viewableStatus = '';
    switch (status) {
        case genericConstants.SECTION_STATUS.OPEN:
            viewableStatus = genericConstants.SECTION_STATUS_FOR_VIEW.OPEN;
            break;
        case genericConstants.SECTION_STATUS.IN_PROGRESS:
            viewableStatus = genericConstants.SECTION_STATUS_FOR_VIEW.IN_PROGRESS;
            break;
        case genericConstants.SECTION_STATUS.IN_REVIEW:
            viewableStatus = genericConstants.SECTION_STATUS_FOR_VIEW.REVIEW_PENDING;
            break;
        case genericConstants.SECTION_STATUS.APPROVED:
            viewableStatus = genericConstants.SECTION_STATUS_FOR_VIEW.APPROVE;
            break;
        case genericConstants.SECTION_STATUS.REJECTED:
            viewableStatus = genericConstants.SECTION_STATUS_FOR_VIEW.REJECT;
            break;
        case genericConstants.SECTION_STATUS.COMPLETED:
            viewableStatus = genericConstants.SECTION_STATUS_FOR_VIEW.COMPLETED;
            break;
        case genericConstants.SECTION_STATUS.PUBLISHED:
            viewableStatus = genericConstants.SECTION_STATUS_FOR_VIEW.PUBLISHED;
            break;
        case genericConstants.SECTION_STATUS.PENDING:
        case genericConstants.SECTION_STATUS.IN_COMPLETE:
            viewableStatus = genericConstants.SECTION_STATUS_FOR_VIEW.PENDING;
            break;

    }
    ;
    return viewableStatus;
}

function viewInvitationStatus(status) {
    if (!status)
        return '';
    let viewableStatus = '';
    switch (status) {
        case genericConstants.INVITATION_STATUS.PENDING:
        case genericConstants.INVITATION_STATUS.INVITED:
            viewableStatus = stringConstants.INVITATION_PENDING;
            break;
        case genericConstants.INVITATION_STATUS.ACCEPTED:
        case genericConstants.INVITATION_STATUS.ACTIVE:
        case genericConstants.INVITATION_STATUS.COMPLETED:
            viewableStatus = stringConstants.ACTIVE;
            break;
        case genericConstants.INVITATION_STATUS.IN_ACTIVE:
            viewableStatus = stringConstants.INACTIVE;
            break;
        case genericConstants.INVITATION_STATUS.REJECTED:
            viewableStatus = stringConstants.REJECTED;
            break;

    }
    ;
    return viewableStatus;
}

function getUserRoleID(roleTittle, roleLevel) {
    if (!roleTittle || !roleLevel)
        return;
    let roleID = '';
    switch (roleTittle) {
        case genericConstants.ROLES.SUPER_ADMIN:
            roleID = genericConstants.ROLES.SUPER_ADMIN;
            break;
        case genericConstants.ROLES.LENDER:
        case genericConstants.ROLES.SUB_LENDER:
            roleID = genericConstants.PART_TYPE.PART_B;
            break;

        case genericConstants.ROLES.ORIGINATION_COUNSEL:
        case genericConstants.ROLES.SUB_ORIGINATION_COUNSEL:
            roleID = genericConstants.PART_TYPE.PART_A;
            break;

        case genericConstants.ROLES.SELLER_COUNSEL:
        case genericConstants.ROLES.SUB_SELLER_COUNSEL:
            roleID = genericConstants.PART_TYPE.PART_C;
            break;

    }
    ;

    switch (roleLevel) {
        case genericConstants.LEVEL_TYPE.LEVEL_1:
            roleID = roleID + `-${genericConstants.LEVEL_TYPE.LEVEL_1}`;
            break;

        case genericConstants.LEVEL_TYPE.LEVEL_2:
            roleID = roleID + `-${genericConstants.LEVEL_TYPE.LEVEL_2}`;
            break;

        case genericConstants.LEVEL_TYPE.LEVEL_3:
            roleID = roleID + `-${genericConstants.LEVEL_TYPE.LEVEL_3}`;
            break;
    }
    return roleID;
}

function isSubUser(userRole) {
    return !userRole || userRole === genericConstants.ROLES.SUB_LENDER || userRole === genericConstants.ROLES.SUB_SELLER_COUNSEL || userRole === genericConstants.ROLES.SUB_ORIGINATION_COUNSEL;
}

function getCompanyType(role) {
    switch (role) {
        case genericConstants.ROLES.SUB_ORIGINATION_COUNSEL || genericConstants.ROLES.SUB_LENDER || genericConstants.ROLES.SUB_SELLER_COUNSEL || genericConstants.ROLES.SUPER_ADMIN:
            return null;
        default :
            return role;
    }
}

function getMiniUserModel(user) {
    if (!user)
        return null;
    return {
        photo: user.photo ? user.photo : '',
        emailID: user.emailID,
        firstName: user.firstName,
        userID: user.userID
    };
}

function getMiniSectionStatusUserModel(user) {
    if (!user)
        return null;
    return {
        email: user.emailID || '',
        name: user.firstName || '',
        role: this.getUserRole(user) || '',
        _id: user.userID || ''
    };
}

function generateRandomAlphaNumericString(length) {
    var randomAlphaNumericString = "";
    var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (var i = 0; i < length; i++)
        randomAlphaNumericString += charset.charAt(Math.floor(Math.random() * charset.length));
    return randomAlphaNumericString;
}

function uploadFileToS3(fileObject, fileName, mimeType, isPublic = false) {
    let config = {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
    }
    aws.config.update(config);
    const S3 = new aws.S3();
    const params = {
        Body: fileObject,
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
        ContentType: mimeType,
        Key: fileName
    };
    if (isPublic)
        params.ACL = 'public-read';

    return new Promise(function (resolve, reject) {
        S3.upload(params, function (err, uploadData) {
            if (err)
                reject(err);
            resolve(uploadData);
        });
    });
}

function getSignedUrl(fileName, placeholderPath) {
    if (typeof fileName !== 'string' || isEmpty(fileName))
        return placeholderPath;
    refreshAWSConfig();
    const s3 = new aws.S3();
    const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        Key: fileName ? fileName : ''
    };
    try {
        s3.headObject(params).promise();
        return s3.getSignedUrl('getObject', {...params, Expires: 600000});
    } catch (headErr) {
        return placeholderPath;
    }
}

function refreshAWSConfig() {
    aws.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
        region: process.env.REACT_APP_AWS_S3_BUCKET_REGION
    });
}

function showUnderDevelopment() {
    basicAlert("Under Development")
}

function getCompanyObject(propsOfComponent) {
    if (!propsOfComponent || !propsOfComponent.user || !propsOfComponent.user.userDetails || !propsOfComponent.user.userDetails.company)
        return null;
    return propsOfComponent.user.userDetails.company;
}

function epocToPrettyTime(seconds) {
    seconds = Math.floor(seconds);//to convert to integer if seconds is String.
    var nowTimeMilliseconds = (new Date).getTime();
    var date = new Date(seconds);
    var dateObject = moment(date).format('DD MMM YY');
    //var dateObject = moment(date).format('ddd, MMM DD hh:mm A');
    seconds = Math.floor((nowTimeMilliseconds / 1000) - (seconds / 1000));
    var interval = Math.floor(seconds / 172800);
    if (interval >= 1)
        return dateObject;
    //if (interval >= 1) return dateObject+" "+moment.tz(moment.tz.guess()).format('z');
    interval = Math.floor(seconds / 86400);
    if (interval >= 1)
        return "Yesterday";

    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
        if (interval === 1)
            return interval + " hr ago";
        return interval + " hrs ago";
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
        if (interval === 1)
            return interval + " min ago";
        return interval + " min ago";
    } else
        return "Just now";
}

function secondsToTime(milliseconds) {
    let date = new Date(milliseconds)
    var duration = moment.duration(milliseconds, 'milliseconds');
    return (duration.hours() + ":" + duration.minutes() + ":" + duration.seconds())
    let dateObject = moment(date, 'hh:mm:ss').fromNow();
    return dateObject

    // let hours = Math.floor(secs / (60 * 60));
    //
    // let divisor_for_minutes = secs % (60 * 60);
    // let minutes = Math.floor(divisor_for_minutes / 60);
    //
    // let divisor_for_seconds = divisor_for_minutes % 60;
    // let seconds = Math.ceil(divisor_for_seconds);
    //
    // let obj = {
    //     "h": hours,
    //     "m": minutes,
    //     "s": seconds
    // };
    // return obj;
}

function getTimestampFromDate(year, month, date = 0) {
    let days = new Date(year, month, date).getDate();
    return new Date(year + "/" + month + "/" + days).getTime();

}

function getDateFromTimestamp(timestamp) {
    let d = new Date(timestamp);
    return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
}

function sortArrayForKey(array, key, order = genericConstants.SORTING_ORDER.ASCENDING) {
    if (!array || !Array.isArray(array))
        return;
    switch (order) {
        case genericConstants.SORTING_ORDER.ASCENDING:
            array.sort((a, b) => (Object.byString(a, key) > Object.byString(b, key)) ? 1 : ((Object.byString(b, key) > Object.byString(a, key)) ? -1 : 0));
            break;
        case genericConstants.SORTING_ORDER.DESCENDING:
            array.sort((b, a) => (Object.byString(a, key) > Object.byString(b, key)) ? 1 : ((Object.byString(b, key) > Object.byString(a, key)) ? -1 : 0));
            break;
    }
}

Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (typeof o === 'object' && k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
};

function generateCriteriaListForDropdown(dataList) {
    if (!dataList || dataList.length < 1)
        return [];
    let inputList = [];
    dataList.map((data) => {
        inputList.push({key: data.name, text: data.name, value: data})
    });
    return inputList;
}


function generateActivityArray(activityArray) {
    if (!activityArray || activityArray.length < 1)
        return [];
    let parsedActivityArray = [];
    activityArray.forEach(activity => {
        let parsedActivityObject = {};
        parsedActivityObject['activityText'] = generateActivityView(activity);
        parsedActivityObject['activityTime'] = activity.addedOn;
        parsedActivityArray.push(parsedActivityObject);
    });
    return parsedActivityArray;
}

function generateActivityView(activityObject) {
    if (!activityObject || !activityObject.payload || !activityObject.payload.performedBy || !activityObject.payload.performedBy.name || !activityObject.payload.performedFor)
        return;
    switch (activityObject.type) {
        case genericConstants.ACTIVITY_TYPE.ASSIGNMENT_FOR_REVIEW:
            return <div>{activityObject.payload.performedBy.name} assigned the section to <span
                className="fw-500">{activityObject.payload.performedFor.name}</span> for <span
                className="fw-500">Review</span>
            </div>;
        case genericConstants.ACTIVITY_TYPE.APPROVAL_FOR_SC_REVIEW:
            return <div>{activityObject.payload.performedBy.name} approved the section for <span className="fw-500">Seller
                Counsel Review</span></div>;
        case genericConstants.ACTIVITY_TYPE.REJECTION:
            return <div><span className="fw-500">{activityObject.payload.performedBy.name}</span> rejected the section
                updates.</div>;
        default :
            return '';
    }
}
